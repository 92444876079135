@import "styles/helpers/mixins";
@import "styles/helpers/media";
@import "styles/helpers/colors";

.guides-footer {
  @include display-flex();
  @include justify-content(space-between);
  width: 100%;

  &__author {
    @include display-flex();
    @include align-items(center);

    img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px;
    }

    span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%;
    }

    @media #{$small} {
      @include flex-direction(column);
      @include flex(0 0 100px);
      @include justify-content(center);

      img {
        flex-basis: 18px;
        height: 18px;
        margin: 0;
        width: 18px;
      }

      span {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  &__rate {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);

    > span {
      display: block;
      font-size: 14px;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__button {
    background: $light-orange;
    border: 0;
    border-radius: 6px;
    color: $flat-white;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px;

    @media #{$tablet} {
      margin-right: 20px;
      width: 140px;
    }

    @media #{$mobile} {
      width: 120px;
    }
    @media #{$small} {
      margin-right: 10px;
      width: 90px;
    }
  }
}
