@import "styles/helpers/colors";
@import "styles/helpers/media";

.wrapper {
  color: $basic-grey;
  padding-left: 15px;
  padding-right: 15px;

  @media #{$tablet} {
    color: $basic-grey;
  }

  @media #{$desktop} {
    .clm-location {
      color: $basic-grey;
      flex: 0 0 20.5%;
      max-width: 20.5%;
    }

    .clm-date {
      flex: 0 0 18.2%;
      max-width: 18.2%;
    }

    .clm-daymulti {
      flex: 0 0 17.3%;
      max-width: 17.3%;
    }

    .clm-category {
      flex: 0 0 14%;
      max-width: 14%;
    }

    .clm-difficulty {
      flex: 0 0 15%;
      max-width: 15%;
    }

    .clm-findbtn {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }

  h1 {
    @media (min-width: 767px) {
      max-width: 400px;
    }

    @media (min-width: 1089px) {
      max-width: 600px;
    }
  }

  .banner {
    background-color: #93886e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 15px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 67px;
    width: 100%;
  }

  .row {
    @media (min-width: 767px) {
      margin: 0 auto;
      padding: 0 15px;
      width: 90%;
    }
  }

  .grid {
    @media (min-width: 767px) {
      display: grid;
      grid-column-gap: 5%;
      grid-template-columns: 1fr 300px;
      margin-bottom: 40px;
    }
  }

  p {
    color: $basic-grey;
  }

  hr {
    background-color: $flat-white;
  }

  .guides_faces {
    align-items: center;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    grid-column-gap: 11px;
    grid-template-columns: auto 1fr;
    padding: 25px 0;
    text-transform: uppercase;
  }

  .faces_wrapper {
    position: relative;
  }

  .face {
    border: 2px solid $basic-grey;
    border-radius: 50%;
    display: inline-block;
    height: 38px;
    margin-left: -7px;
    overflow: hidden;
    width: 38px;

    &:first-child {
      margin-left: 0;
    }

    img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .find_button {
    align-items: center;
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    letter-spacing: 0.25px;
    margin: 4px;
    padding: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    svg {
      fill: $flat-white;
      height: 12px;
      margin-left: 10px;
      width: 18px;
    }
  }

  .find_adventure {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    max-width: 20%;
  }

  .input {
    align-items: center;
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    padding: 11px;

    ::placeholder {
      color: $basic-grey;
    }

    input {
      background: transparent;
      border: 0;
      color: $basic-grey;
      margin-left: 10px;
    }

    svg {
      fill: $basic-grey;
      height: 16px;
      width: 16px;
    }
  }

  .input:focus-within {
    border: 1px solid #ff9514;
  }

  .dropdown {
    align-items: center;
    background-color: $flat-white;
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    overflow: hidden;
    padding: 12px 8px;
    position: static;

    .dropdown-day {
      background-color: $flat-white;
      border: 0;
      color: $basic-grey;
    }

    .dropdown-day::after {
      border-bottom: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid;
      content: "";
      display: inline-block;
      margin-left: 2.55px;
      position: absolute;
      right: 20px;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      vertical-align: 2.55px;
      vertical-align: 2.55px;
    }

    svg {
      fill: #333;
      height: 16px;
      width: 16px;
    }

    span {
      background: transparent;
      border: 0;
      color: #333;
      margin-left: 10px;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $flat-white;
}
