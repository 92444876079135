/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext");
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

:global .fadeIn-appear {
  opacity: 0.01; }

:global .fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes slideInTop {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.short_hr {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.App {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .App.modal-open {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.checkbox {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.checkboxInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.radioInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.checkboxInput:checked ~ .checkmark {
  background-color: #ff9514; }

.checkboxInput:checked ~ .guideCheckmark {
  background-color: #ff9514;
  color: #fff; }

.radioInput:checked ~ .radioMark {
  background-color: #fff; }

/* Create a custom checkbox */
.checkmark {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.guideCheckmark {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.radioMark {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  display: none;
  position: absolute; }

.guideCheckmark::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.radioMark::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.checkboxInput:checked ~ .checkmark::after {
  display: block; }

.checkboxInput:checked ~ .guideCheckmark::after {
  display: block; }

.radioInput:checked ~ .radioMark::after {
  display: block; }

/* Style the checkmark/indicator */
.checkmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.guideCheckmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.radioMark::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.loaderSpinner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.loader {
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.lazy-load-image-background {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .lazy-load-image-background img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.lazyImageNP {
  padding-top: 0 !important; }

.finish_google_survey {
  color: #666;
  text-decoration: none; }

.finish_google_survey:hover {
  color: #fff; }

.finish_btn button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.home-locations {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .home-locations ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .home-locations li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .home-locations li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .home-locations li h3 {
        font-size: 14px; } }
  .home-locations li img {
    height: 28px;
    width: 28px; }
  .home-locations li .rightArrow {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.home-locations ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .home-locations ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .home-locations ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .home-locations ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .home-locations ul li {
      width: 100%; } }

.location-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.location-text p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .location-text p {
      display: none; } }

.leftArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .leftArrow__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.rightArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .rightArrow__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.phone-icon {
  max-width: 30px; }

.search-filter.header-filter button {
  width: 260px; }
  @media (max-width: 767px) {
    .search-filter.header-filter button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.search-filter-bg {
  background: #f1f1f2; }

.search-filter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .search-filter__select {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .search-filter__select__dropdown-indicator {
      color: #333 !important; }
      .search-filter__select__dropdown-indicator svg {
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        -ms-transform: rotate(0);
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease; }
    .search-filter__select__clear-indicator {
      color: #fff !important; }
      .search-filter__select__clear-indicator:hover {
        color: #666 !important; }
    .search-filter__select__placeholder, .search-filter__select__single-value {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .search-filter__select__placeholder {
      -moz-transform: translateY(0) !important;
      -o-transform: translateY(0) !important;
      -ms-transform: translateY(0) !important;
      -webkit-transform: translateY(0) !important;
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .search-filter__select__input input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .search-filter__select__value-container {
      -webkit-flex-wrap: nowrap !important;
      -moz-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .search-filter__select__indicator {
      padding: 0 !important; }
    .search-filter__select__indicator-separator {
      display: none; }
    .search-filter__select__menu-list {
      padding: 0 !important; }
    .search-filter__select__menu {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .search-filter__select {
        width: 100%; } }
  .search-filter__destination {
    width: 260px; }
  .search-filter button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .search-filter button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .search-filter button:hover {
      border: 1px solid #dedede; }
      .search-filter button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .search-filter button {
        width: 100%; } }
  @media (max-width: 767px) {
    .search-filter__destination, .search-filter__select,
    .search-filter button {
      margin: 8px 0;
      width: 100%; } }

.search-filter__select__option {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .search-filter__select__option .sub {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .search-filter__select__option__item {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .search-filter__select__option__item span {
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .search-filter__select__option__item img {
      display: block;
      height: 100%;
      width: 100%; }
  .search-filter__select__option:hover, .search-filter__select__option__selected {
    background: rgba(255, 149, 20, 0.1); }
    .search-filter__select__option:hover .sub, .search-filter__select__option__selected .sub {
      color: #ff8c00; }
    .search-filter__select__option:hover.search-filter__select__option:hover span, .search-filter__select__option:hover .search-filter__select__option__selected__item span,
    .search-filter__select__option:hover .search-filter__select__option__item span, .search-filter__select__option__selected.search-filter__select__option:hover span, .search-filter__select__option__selected .search-filter__select__option__selected__item span,
    .search-filter__select__option__selected .search-filter__select__option__item span {
      background: #ff8c00; }
      .search-filter__select__option:hover.search-filter__select__option:hover span::before, .search-filter__select__option:hover .search-filter__select__option__selected__item span::before,
      .search-filter__select__option:hover .search-filter__select__option__item span::before, .search-filter__select__option__selected.search-filter__select__option:hover span::before, .search-filter__select__option__selected .search-filter__select__option__selected__item span::before,
      .search-filter__select__option__selected .search-filter__select__option__item span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.search-filter__select__control {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .search-filter__select__control .css-1g6gooi {
    margin: 0 !important;
    padding: 0 !important; }
  .search-filter__select__control__focused, .search-filter__select__control__isOpen, .search-filter__select__control:hover {
    border-color: #ff9514; }
  .search-filter__select__control__fill {
    background-color: #ff9514;
    border-color: #ff9514; }
    .search-filter__select__control__fill .search-filter__select__dropdown-indicator,
    .search-filter__select__control__fill .search-filter__select__single-value,
    .search-filter__select__control__fill .search-filter__select__placeholder {
      color: #fff !important; }
    .search-filter__select__control__fill .search-filter__select__input input {
      color: #fff !important; }
  .search-filter__select__control__isOpen .search-filter__select__dropdown-indicator svg {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.adventure-about {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .adventure-about {
      padding: 5px 0; } }
  .adventure-about__container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .adventure-about__container {
        padding: 0 10px; } }
  .adventure-about__author {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .adventure-about__author img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .adventure-about__author span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .adventure-about__author {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 0 0 100px;
        -moz-box-flex: 0 0 100px;
        -webkit-flex: 0 0 100px;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .adventure-about__author img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .adventure-about__author span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .adventure-about__rate {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .adventure-about__rate > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .adventure-about__rate {
        display: none; } }
  .adventure-about__buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex-pack: flex-end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .adventure-about__buttons button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .adventure-about__buttons button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .adventure-about__buttons button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .adventure-about__buttons button {
          width: 120px; } }
      @media (max-width: 576px) {
        .adventure-about__buttons button {
          margin-right: 10px;
          width: 90px; } }
    .adventure-about__buttons__check-dates {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .adventure-about__buttons__request {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.guides-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .guides-footer__author {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .guides-footer__author img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .guides-footer__author span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .guides-footer__author {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 0 0 100px;
        -moz-box-flex: 0 0 100px;
        -webkit-flex: 0 0 100px;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .guides-footer__author img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .guides-footer__author span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .guides-footer__rate {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .guides-footer__rate > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .guides-footer__rate {
        display: none; } }
  .guides-footer__button {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .guides-footer__button {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .guides-footer__button {
        width: 120px; } }
    @media (max-width: 576px) {
      .guides-footer__button {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.guide-modal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .guide-modal__close {
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .guide-modal__close:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .guide-modal {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .guide-modal {
      padding: 10px;
      width: 300px; } }

.guide-modal__header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .guide-modal__header__photo {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .guide-modal__header__photo img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .guide-modal__header__info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .guide-modal__header__info p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .guide-modal__header__info div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      .guide-modal__header__info div span {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .guide-modal__header__info div span {
            text-align: center; } }
        .guide-modal__header__info div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .guide-modal__header__info div span:last-child {
          margin-right: 0; }
          .guide-modal__header__info div span:last-child::after {
            display: none; }

.guide-modal__body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .guide-modal__body__error .guide-modal__body__item {
    border-color: #f00; }
  .guide-modal__body__error p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .guide-modal__body__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .guide-modal__body__item img,
    .guide-modal__body__item svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .guide-modal__body__item input,
    .guide-modal__body__item textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .guide-modal__body__item textarea {
      height: 70px;
      resize: none; }
    .guide-modal__body__item__down {
      position: absolute;
      right: 9px; }
  .guide-modal__body .react-datepicker-wrapper,
  .guide-modal__body .react-datepicker__input-container {
    width: 100%; }
  .guide-modal__body .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled) {
    background: #fa9923;
    border-radius: 4.8px; }
    .guide-modal__body .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
      color: #fff;
      font-weight: bold; }
  .guide-modal__body__submit {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .guide-modal__body__submit .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.guide-modal__footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .guide-modal__footer p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .guide-modal__footer label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .guide-modal__footer a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .guide-modal__footer input {
    left: -9999px;
    position: absolute; }
  .guide-modal__footer span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .guide-modal__footer input:checked + span::after {
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.modal-overlay {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.modal-popup {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .modal-popup__close {
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .modal-popup__close:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .modal-popup {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .modal-popup {
      padding: 10px;
      width: 300px; } }

.modal-open {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .modal-open .fixed-footer {
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.fixed-footer {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .fixed-footer {
      padding: 5px 0; } }
  .fixed-footer__container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .fixed-footer__container {
        padding: 0 10px; } }
  .fixed-footer.hidden {
    bottom: -80px; }

.wrapper {
  display: block;
  margin-top: 61px; }
  .wrapper .row {
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 15px;
    width: 100%; }
  .wrapper a {
    color: #333; }
  .wrapper h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px; }
  .wrapper .title {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    height: 46px;
    line-height: 46px; }
  .wrapper .back {
    color: #333;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    line-height: 28px; }
  .wrapper .subInfo {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 21px;
    margin-right: 5px; }
  .wrapper .paymentContent {
    background-color: #fafafa;
    border-radius: 8px;
    margin: 30px -15px;
    padding: 30px 15px; }
  .wrapper .sectionTitle {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 4px; }
  .wrapper .price {
    display: flex;
    justify-content: space-between; }
  .wrapper .value {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px; }
  .wrapper .categories {
    display: flex;
    flex-wrap: wrap; }
  .wrapper .service {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; }
  .wrapper .section {
    align-items: center;
    display: flex; }
  .wrapper .serviceName {
    margin-top: 10px; }
  .wrapper .hr_br {
    background-color: #e6e6e6;
    border-radius: 6px;
    height: 1px;
    margin: 30px -15px;
    transform: scaleY(-1); }
  .wrapper .insurance {
    display: flex; }
    @media (max-width: 767px) {
      .wrapper .insurance {
        flex-direction: column; } }
  .wrapper .details {
    border-bottom: 1px solid black;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    width: 41px; }
  .wrapper .totalPrice {
    display: flex;
    justify-content: space-between; }
  .wrapper .context {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 15px;
    line-height: 21px; }
  .wrapper .paymentMethod {
    margin-top: 40px; }
  .wrapper .ppTitle {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 20px;
    line-height: 46px; }
  .wrapper .methdos {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto auto; }
    @media (max-width: 500px) {
      .wrapper .methdos {
        grid-row-gap: 10px;
        grid-template-columns: none;
        grid-template-rows: auto; } }
  .wrapper .method {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px; }
  .wrapper .paymentName {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%; }
  .wrapper form {
    float: left; }
  .wrapper .paypalButton {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%; }
