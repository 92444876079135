@import "styles/helpers/media";

.menuFilter {
  position: relative;

  .DateInput_input {
    color: hsl(0, 0%, 50%) !important;
    font-size: 100%;
    margin: 0 !important;
    padding: 0;
    text-align: center;
  }

  .DateRangePickerInput {
    color: #fff;
    display: flex;
  }

  .DateInput {
    width: unset;
  }

  .DateRangePicker {
    margin-left: 10px;

    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .DateRangePicker_picker__directionLeft {
    left: -37px !important;
    top: 50px !important;
  }

  .DateInput_fang {
    display: none;
  }

  // Initial DayPicker
  .DayPicker {
    box-shadow: none;
    @media (max-width: 320px) {
      width: 286px !important;
    }
  }

  .DayPicker_1 {
    @media (max-width: 320px) {
      width: 286px;
    }

    // & > div > div:not([class^="Da"]) {
    //   width: 286px !important;
    // }

    .DayPicker_weekHeaders {
      width: 300px !important;
    }
  }

  // DayPicker WeekHeader
  .DayPicker_weekHeaders {
    margin-left: 10px;
  }

  // Canlender main part
  .DayPicker_transitionContainer {
    @media (max-width: 320px) {
      width: 286px !important;
    }
  }

  .CalendarMonthGrid {
    @media (max-width: 320px) {
      left: -7px;
      width: 286px !important;
    }
  }

  .CalendarMonth_caption {
    caption-side: top;
    caption-side: initial;
    color: #333;
    font-family: "Abril Fatface";
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 37px;
    padding-top: 22px;
    text-align: center;
  }

  .CalendarDay__default {
    background-color: #f8f8f9;
    border: 2px solid #fff;
    vertical-align: middle;
  }

  .CalendarDay__selected_span {
    background-color: #ff9514;
    border-radius: 8px;
    color: #fff;
    opacity: 0.55;
  }

  .CalendarDay__hovered_span {
    background-color: #ff9514;
    border-radius: 8px;
    color: #fff;
    opacity: 0.55;
  }

  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
    background-color: #ff9514;
    border: 2px solid #fff;
    border-radius: 8px;
    color: #fff;
    opacity: 0.9;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: #ff9514;
    border: 2px solid #fff;
    border-radius: 8px;
  }

  .DayPicker_weekHeader_li > small {
    font-size: 100%;
  }

  .CalendarDay__selected_span:hover,
  .CalendarDay__default:hover {
    border: 0;
  }

  .DateRangePicker_1:focus-within + svg {
    transform: rotate(180deg);
  }

  /* Customization for Calendar with Horizonal direction */

  .DateRangePicker_picker__directionLeft {
    transform: translate(25%, 0);

    @media #{$mobile} {
      transform: translate(0, 0);
    }
  }

  .DayPickerNavigation {
    width: 300px;
  }

  .DayPicker_weekHeaders {
    width: 300px;
  }

  .DayPicker_weekHeaders {
    left: -8px !important;
  }

  .DayPicker_weekHeader:last-child {
    display: none;
  }

  .CalendarMonthGrid {
    bottom: 0;
    height: 300px !important;
    left: 0;
    position: absolute;
    right: 30%;
    top: 0;
    width: 300px !important;
  }

  .DayPicker_transitionContainer {
    height: 500px !important;
    width: 300px !important;
  }

  .DayPicker_focusRegion {
    width: 300px !important;
  }

  .DateRangePicker_picker__directionLeft {
    left: -40px !important;
    top: 42px !important;

    @media (max-width: 320px) {
      left: -60px !important;
      width: 286px !important;
    }
  }

  .DayPickerNavigation_button {
    align-items: center;
    background-color: #f8f8f9;
    // border: none;
    border-radius: 50%;
    display: flex;
    height: 30px;
    width: 30px;

    svg {
      display: none;
    }
  }

  .DayPickerNavigation_leftButton__horizontalDefault::before {
    border: solid #666;
    border-width: 0 1px 1px 0;
    color: #666;
    content: "";
    display: inline-block;
    height: 8px;
    margin-left: 5px;
    padding: 3px;
    transform: rotate(135deg);
    width: 36px !important;
  }

  .DayPickerNavigation_rightButton__horizontalDefault::before {
    border: solid #666;
    border-width: 0 1px 1px 0;
    color: #666;
    content: "";
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    padding: 3px;
    transform: rotate(-45deg);
    width: 36px !important;
  }
}
