@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes slideInTop {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.short_hr {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.App {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .App.modal-open {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.checkbox {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.checkboxInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.radioInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.checkboxInput:checked ~ .checkmark {
  background-color: #ff9514; }

.checkboxInput:checked ~ .guideCheckmark {
  background-color: #ff9514;
  color: #fff; }

.radioInput:checked ~ .radioMark {
  background-color: #fff; }

/* Create a custom checkbox */
.checkmark {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.guideCheckmark {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.radioMark {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  display: none;
  position: absolute; }

.guideCheckmark::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.radioMark::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.checkboxInput:checked ~ .checkmark::after {
  display: block; }

.checkboxInput:checked ~ .guideCheckmark::after {
  display: block; }

.radioInput:checked ~ .radioMark::after {
  display: block; }

/* Style the checkmark/indicator */
.checkmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.guideCheckmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.radioMark::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.loaderSpinner {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.loader {
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.lazy-load-image-background {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .lazy-load-image-background img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.lazyImageNP {
  padding-top: 0 !important; }

.finish_google_survey {
  color: #666;
  text-decoration: none; }

.finish_google_survey:hover {
  color: #fff; }

.finish_btn button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.home-locations {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .home-locations ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .home-locations li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .home-locations li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .home-locations li h3 {
        font-size: 14px; } }
  .home-locations li img {
    height: 28px;
    width: 28px; }
  .home-locations li .rightArrow {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.home-locations ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .home-locations ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .home-locations ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .home-locations ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .home-locations ul li {
      width: 100%; } }

.location-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.location-text p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .location-text p {
      display: none; } }

.leftArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .leftArrow__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.rightArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .rightArrow__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.phone-icon {
  max-width: 30px; }

.search-filter.header-filter button {
  width: 260px; }
  @media (max-width: 767px) {
    .search-filter.header-filter button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.search-filter-bg {
  background: #f1f1f2; }

.search-filter {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .search-filter__select {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .search-filter__select__dropdown-indicator {
      color: #333 !important; }
      .search-filter__select__dropdown-indicator svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .search-filter__select__clear-indicator {
      color: #fff !important; }
      .search-filter__select__clear-indicator:hover {
        color: #666 !important; }
    .search-filter__select__placeholder, .search-filter__select__single-value {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .search-filter__select__placeholder {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .search-filter__select__input input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .search-filter__select__value-container {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .search-filter__select__indicator {
      padding: 0 !important; }
    .search-filter__select__indicator-separator {
      display: none; }
    .search-filter__select__menu-list {
      padding: 0 !important; }
    .search-filter__select__menu {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .search-filter__select {
        width: 100%; } }
  .search-filter__destination {
    width: 260px; }
  .search-filter button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .search-filter button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .search-filter button:hover {
      border: 1px solid #dedede; }
      .search-filter button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .search-filter button {
        width: 100%; } }
  @media (max-width: 767px) {
    .search-filter__destination, .search-filter__select,
    .search-filter button {
      margin: 8px 0;
      width: 100%; } }

.search-filter__select__option {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .search-filter__select__option .sub {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .search-filter__select__option__item {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .search-filter__select__option__item span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .search-filter__select__option__item img {
      display: block;
      height: 100%;
      width: 100%; }
  .search-filter__select__option:hover, .search-filter__select__option__selected {
    background: rgba(255, 149, 20, 0.1); }
    .search-filter__select__option:hover .sub, .search-filter__select__option__selected .sub {
      color: #ff8c00; }
    .search-filter__select__option:hover.search-filter__select__option:hover span, .search-filter__select__option:hover .search-filter__select__option__selected__item span,
    .search-filter__select__option:hover .search-filter__select__option__item span, .search-filter__select__option__selected.search-filter__select__option:hover span, .search-filter__select__option__selected .search-filter__select__option__selected__item span,
    .search-filter__select__option__selected .search-filter__select__option__item span {
      background: #ff8c00; }
      .search-filter__select__option:hover.search-filter__select__option:hover span::before, .search-filter__select__option:hover .search-filter__select__option__selected__item span::before,
      .search-filter__select__option:hover .search-filter__select__option__item span::before, .search-filter__select__option__selected.search-filter__select__option:hover span::before, .search-filter__select__option__selected .search-filter__select__option__selected__item span::before,
      .search-filter__select__option__selected .search-filter__select__option__item span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.search-filter__select__control {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .search-filter__select__control .css-1g6gooi {
    margin: 0 !important;
    padding: 0 !important; }
  .search-filter__select__control__focused, .search-filter__select__control__isOpen, .search-filter__select__control:hover {
    border-color: #ff9514; }
  .search-filter__select__control__fill {
    background-color: #ff9514;
    border-color: #ff9514; }
    .search-filter__select__control__fill .search-filter__select__dropdown-indicator,
    .search-filter__select__control__fill .search-filter__select__single-value,
    .search-filter__select__control__fill .search-filter__select__placeholder {
      color: #fff !important; }
    .search-filter__select__control__fill .search-filter__select__input input {
      color: #fff !important; }
  .search-filter__select__control__isOpen .search-filter__select__dropdown-indicator svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.adventure-about {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .adventure-about {
      padding: 5px 0; } }
  .adventure-about__container {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .adventure-about__container {
        padding: 0 10px; } }
  .adventure-about__author {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .adventure-about__author img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .adventure-about__author span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .adventure-about__author {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .adventure-about__author img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .adventure-about__author span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .adventure-about__rate {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .adventure-about__rate > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .adventure-about__rate {
        display: none; } }
  .adventure-about__buttons {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .adventure-about__buttons button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .adventure-about__buttons button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .adventure-about__buttons button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .adventure-about__buttons button {
          width: 120px; } }
      @media (max-width: 576px) {
        .adventure-about__buttons button {
          margin-right: 10px;
          width: 90px; } }
    .adventure-about__buttons__check-dates {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .adventure-about__buttons__request {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.guides-footer {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .guides-footer__author {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .guides-footer__author img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .guides-footer__author span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .guides-footer__author {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .guides-footer__author img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .guides-footer__author span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .guides-footer__rate {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .guides-footer__rate > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .guides-footer__rate {
        display: none; } }
  .guides-footer__button {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .guides-footer__button {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .guides-footer__button {
        width: 120px; } }
    @media (max-width: 576px) {
      .guides-footer__button {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.guide-modal {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .guide-modal__close {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .guide-modal__close:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .guide-modal {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .guide-modal {
      padding: 10px;
      width: 300px; } }

.guide-modal__header {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .guide-modal__header__photo {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .guide-modal__header__photo img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .guide-modal__header__info {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .guide-modal__header__info p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .guide-modal__header__info div {
      display: flex; }
      .guide-modal__header__info div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .guide-modal__header__info div span {
            text-align: center; } }
        .guide-modal__header__info div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .guide-modal__header__info div span:last-child {
          margin-right: 0; }
          .guide-modal__header__info div span:last-child::after {
            display: none; }

.guide-modal__body {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .guide-modal__body__error .guide-modal__body__item {
    border-color: #f00; }
  .guide-modal__body__error p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .guide-modal__body__item {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .guide-modal__body__item img,
    .guide-modal__body__item svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .guide-modal__body__item input,
    .guide-modal__body__item textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .guide-modal__body__item textarea {
      height: 70px;
      resize: none; }
    .guide-modal__body__item__down {
      position: absolute;
      right: 9px; }
  .guide-modal__body .react-datepicker-wrapper,
  .guide-modal__body .react-datepicker__input-container {
    width: 100%; }
  .guide-modal__body .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled) {
    background: #fa9923;
    border-radius: 4.8px; }
    .guide-modal__body .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
      color: #fff;
      font-weight: bold; }
  .guide-modal__body__submit {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .guide-modal__body__submit .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.guide-modal__footer {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .guide-modal__footer p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .guide-modal__footer label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .guide-modal__footer a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .guide-modal__footer input {
    left: -9999px;
    position: absolute; }
  .guide-modal__footer span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .guide-modal__footer input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.modal-overlay {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.modal-popup {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .modal-popup__close {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .modal-popup__close:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .modal-popup {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .modal-popup {
      padding: 10px;
      width: 300px; } }

.modal-open {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .modal-open .fixed-footer {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.fixed-footer {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .fixed-footer {
      padding: 5px 0; } }
  .fixed-footer__container {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .fixed-footer__container {
        padding: 0 10px; } }
  .fixed-footer.hidden {
    bottom: -80px; }

.HomeFilter_wrapper__29XI- {
  background-color: #fff;
  color: #333;
  height: 100%;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 31; }
  .HomeFilter_wrapper__29XI- .HomeFilter_menuSetDateFindBtn__3mvzA {
    bottom: 5px;
    left: 50%;
    position: absolute;
    transform: translate(-50% 0);
    z-index: 2; }
  .HomeFilter_wrapper__29XI- .HomeFilter_exitIcon__385jS {
    height: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px; }
  .HomeFilter_wrapper__29XI- .HomeFilter_section__2fnFd {
    padding: 0 15px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_section__2fnFd .HomeFilter_sectionTitle__17DKB {
      font-size: 24px;
      line-height: 24px;
      padding-bottom: 5px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_section__2fnFd .HomeFilter_tipDetail__2wkxu {
      font-size: 16px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .HomeFilter_wrapper__29XI- {
      height: 237px;
      padding-top: 90px; } }
  @media (min-width: 1025px) {
    .HomeFilter_wrapper__29XI- {
      height: 167px;
      padding-top: 90px; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-location__2Uoae {
        flex: 0 0 20.5%;
        max-width: 20.5%; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-date__1fIUk {
        flex: 0 0 18.2%;
        max-width: 18.2%; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-daymulti__2DgJv {
        flex: 0 0 17.3%;
        max-width: 17.3%; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-category__2kj6n {
        flex: 0 0 14%;
        max-width: 14%; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-difficulty__3iD0m {
        flex: 0 0 15%;
        max-width: 15%; }
      .HomeFilter_wrapper__29XI- .HomeFilter_clm-findbtn__2kSV2 {
        flex: 0 0 15%;
        margin-top: 300px;
        max-width: 15%; } }
  @media (min-width: 767px) {
    .HomeFilter_wrapper__29XI- h1 {
      max-width: 400px; } }
  @media (min-width: 1089px) {
    .HomeFilter_wrapper__29XI- h1 {
      max-width: 600px; } }
  .HomeFilter_wrapper__29XI- a:hover {
    text-decoration: none; }
  .HomeFilter_wrapper__29XI- .HomeFilter_banner__12-LU {
    background-color: #93886e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 15px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 67px;
    width: 100%; }
  @media (min-width: 767px) {
    .HomeFilter_wrapper__29XI- .HomeFilter_row__3OCvZ {
      margin: 0 auto;
      padding: 0 15px;
      width: 90%; } }
  @media (min-width: 767px) {
    .HomeFilter_wrapper__29XI- .HomeFilter_grid__1focz {
      display: grid;
      grid-column-gap: 5%;
      grid-template-columns: 1fr 300px;
      margin-bottom: 40px; } }
  .HomeFilter_wrapper__29XI- p {
    color: #333; }
  .HomeFilter_wrapper__29XI- hr {
    background-color: #fff; }
  .HomeFilter_wrapper__29XI- .HomeFilter_guides_faces__2SXYm {
    align-items: center;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    grid-column-gap: 11px;
    grid-template-columns: auto 1fr;
    padding: 25px 0;
    text-transform: uppercase; }
  .HomeFilter_wrapper__29XI- .HomeFilter_faces_wrapper__2srRS {
    position: relative; }
  .HomeFilter_wrapper__29XI- .HomeFilter_face__KU554 {
    border: 2px solid #333;
    border-radius: 50%;
    display: inline-block;
    height: 38px;
    margin-left: -7px;
    overflow: hidden;
    width: 38px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_face__KU554:first-child {
      margin-left: 0; }
    .HomeFilter_wrapper__29XI- .HomeFilter_face__KU554 img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%; }
  .HomeFilter_wrapper__29XI- .HomeFilter_find_button__anvoI {
    align-items: center;
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0.25px;
    margin: 0 1%;
    padding: 14px;
    text-transform: uppercase;
    width: 98%; }
    .HomeFilter_wrapper__29XI- .HomeFilter_find_button__anvoI svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      width: 18px; }
  .HomeFilter_wrapper__29XI- .HomeFilter_find_adventure__2ca38 {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    max-width: 20%; }
    @media (max-width: 767px) {
      .HomeFilter_wrapper__29XI- .HomeFilter_find_adventure__2ca38 div {
        display: none !important;
        width: 120% !important; } }
  .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    padding: 11px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC ::-webkit-input-placeholder {
      color: #333; }
    .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC ::placeholder {
      color: #333; }
    .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC input {
      background: transparent;
      border: 0;
      color: #333;
      margin-left: 10px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC svg {
      fill: #333;
      height: 16px;
      width: 16px; }
  .HomeFilter_wrapper__29XI- .HomeFilter_input__2M3PC:focus-within {
    border: 1px solid #ff9514; }
  .HomeFilter_wrapper__29XI- .HomeFilter_dropdown__IKUbH {
    align-items: center;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    overflow: hidden;
    padding: 12px 8px;
    position: static; }
    .HomeFilter_wrapper__29XI- .HomeFilter_dropdown__IKUbH .HomeFilter_dropdown-day__3e1Ci {
      background-color: #fff;
      border: 0;
      color: #333; }
    .HomeFilter_wrapper__29XI- .HomeFilter_dropdown__IKUbH .HomeFilter_dropdown-day__3e1Ci::after {
      border-bottom: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid;
      content: "";
      display: inline-block;
      margin-left: 2.55px;
      position: absolute;
      right: 20px;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      vertical-align: 2.55px;
      vertical-align: 2.55px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_dropdown__IKUbH svg {
      fill: #333;
      height: 16px;
      width: 16px; }
    .HomeFilter_wrapper__29XI- .HomeFilter_dropdown__IKUbH span {
      background: transparent;
      border: 0;
      color: #333;
      margin-left: 10px; }

.HomeFilter_btn-primary__LTUDS:not(:disabled):not(.HomeFilter_disabled__2PTLl):active,
.HomeFilter_btn-primary__LTUDS:not(:disabled):not(.HomeFilter_disabled__2PTLl).HomeFilter_active__1l2hM,
.HomeFilter_show__3MQse > .HomeFilter_btn-primary__LTUDS.HomeFilter_dropdown-toggle__2PpQ5 {
  background-color: #fff; }

.menuFilter {
  position: relative;
  /* Customization for Calendar with Horizonal direction */ }
  .menuFilter .DateInput_input {
    color: gray !important;
    font-size: 100%;
    margin: 0 !important;
    padding: 0;
    text-align: center; }
  .menuFilter .DateRangePickerInput {
    color: #fff;
    display: flex; }
  .menuFilter .DateInput {
    width: unset; }
  .menuFilter .DateRangePicker {
    margin-left: 10px; }
    @media (max-width: 768px) {
      .menuFilter .DateRangePicker {
        width: 50%; } }
  .menuFilter .DateRangePicker_picker__directionLeft {
    left: -37px !important;
    top: 50px !important; }
  .menuFilter .DateInput_fang {
    display: none; }
  .menuFilter .DayPicker {
    box-shadow: none; }
    @media (max-width: 320px) {
      .menuFilter .DayPicker {
        width: 286px !important; } }
  @media (max-width: 320px) {
    .menuFilter .DayPicker_1 {
      width: 286px; } }
  .menuFilter .DayPicker_1 .DayPicker_weekHeaders {
    width: 300px !important; }
  .menuFilter .DayPicker_weekHeaders {
    margin-left: 10px; }
  @media (max-width: 320px) {
    .menuFilter .DayPicker_transitionContainer {
      width: 286px !important; } }
  @media (max-width: 320px) {
    .menuFilter .CalendarMonthGrid {
      left: -7px;
      width: 286px !important; } }
  .menuFilter .CalendarMonth_caption {
    caption-side: top;
    caption-side: initial;
    color: #333;
    font-family: "Abril Fatface";
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 37px;
    padding-top: 22px;
    text-align: center; }
  .menuFilter .CalendarDay__default {
    background-color: #f8f8f9;
    border: 2px solid #fff;
    vertical-align: middle; }
  .menuFilter .CalendarDay__selected_span {
    background-color: #ff9514;
    border-radius: 8px;
    color: #fff;
    opacity: 0.55; }
  .menuFilter .CalendarDay__hovered_span {
    background-color: #ff9514;
    border-radius: 8px;
    color: #fff;
    opacity: 0.55; }
  .menuFilter .CalendarDay__hovered_span:active,
  .menuFilter .CalendarDay__hovered_span:hover {
    background-color: #ff9514;
    border: 2px solid #fff;
    border-radius: 8px;
    color: #fff;
    opacity: 0.9; }
  .menuFilter .CalendarDay__selected,
  .menuFilter .CalendarDay__selected:active,
  .menuFilter .CalendarDay__selected:hover {
    background-color: #ff9514;
    border: 2px solid #fff;
    border-radius: 8px; }
  .menuFilter .DayPicker_weekHeader_li > small {
    font-size: 100%; }
  .menuFilter .CalendarDay__selected_span:hover,
  .menuFilter .CalendarDay__default:hover {
    border: 0; }
  .menuFilter .DateRangePicker_1:focus-within + svg {
    transform: rotate(180deg); }
  .menuFilter .DateRangePicker_picker__directionLeft {
    transform: translate(25%, 0); }
    @media (max-width: 767px) {
      .menuFilter .DateRangePicker_picker__directionLeft {
        transform: translate(0, 0); } }
  .menuFilter .DayPickerNavigation {
    width: 300px; }
  .menuFilter .DayPicker_weekHeaders {
    width: 300px; }
  .menuFilter .DayPicker_weekHeaders {
    left: -8px !important; }
  .menuFilter .DayPicker_weekHeader:last-child {
    display: none; }
  .menuFilter .CalendarMonthGrid {
    bottom: 0;
    height: 300px !important;
    left: 0;
    position: absolute;
    right: 30%;
    top: 0;
    width: 300px !important; }
  .menuFilter .DayPicker_transitionContainer {
    height: 500px !important;
    width: 300px !important; }
  .menuFilter .DayPicker_focusRegion {
    width: 300px !important; }
  .menuFilter .DateRangePicker_picker__directionLeft {
    left: -40px !important;
    top: 42px !important; }
    @media (max-width: 320px) {
      .menuFilter .DateRangePicker_picker__directionLeft {
        left: -60px !important;
        width: 286px !important; } }
  .menuFilter .DayPickerNavigation_button {
    align-items: center;
    background-color: #f8f8f9;
    border-radius: 50%;
    display: flex;
    height: 30px;
    width: 30px; }
    .menuFilter .DayPickerNavigation_button svg {
      display: none; }
  .menuFilter .DayPickerNavigation_leftButton__horizontalDefault::before {
    border: solid #666;
    border-width: 0 1px 1px 0;
    color: #666;
    content: "";
    display: inline-block;
    height: 8px;
    margin-left: 5px;
    padding: 3px;
    transform: rotate(135deg);
    width: 36px !important; }
  .menuFilter .DayPickerNavigation_rightButton__horizontalDefault::before {
    border: solid #666;
    border-width: 0 1px 1px 0;
    color: #666;
    content: "";
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    padding: 3px;
    transform: rotate(-45deg);
    width: 36px !important; }

/* stylelint-disable */
/* stylelint-enable */
.HeaderContinents_menu__title__1pIeq {
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative; }
  .HeaderContinents_menu__title__1pIeq:hover {
    background: rgba(255, 255, 255, 0.2);
    text-decoration: underline; }
    .HeaderContinents_menu__title__1pIeq:hover > div {
      display: flex; }

.HeaderContinents_submenu__3Klfr {
  transform: translateX(-50%);
  background-color: #fff;
  cursor: auto;
  display: none;
  left: 50%;
  padding: 10px;
  position: absolute; }
  .HeaderContinents_submenu__3Klfr div a {
    transition: all 0.2s ease;
    border-radius: 6px;
    color: #333;
    display: block;
    line-height: normal;
    padding: 5px; }
    .HeaderContinents_submenu__3Klfr div a::after {
      display: none; }
    .HeaderContinents_submenu__3Klfr div a:hover {
      background: rgba(255, 149, 20, 0.2); }

.HeaderContinents_submenu__item__26v19 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  width: 150px; }
  .HeaderContinents_submenu__item__26v19 h4 {
    color: #333;
    font-size: 18px;
    line-height: 20px;
    margin: 5px 5px 15px;
    text-align: left; }

.Header_hamburgeMenu__3x4wF {
  background-color: #fff;
  bottom: 0;
  height: 100vh !important;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10; }

.Header_wrapper__2ROeI {
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: none;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr auto;
  height: 67px;
  padding: 0 15px;
  position: relative;
  z-index: 10; }
  .Header_wrapper__2ROeI .Header_icon_search__TOltk,
  .Header_wrapper__2ROeI .Header_icon_burger__3ypKg {
    fill: #333;
    height: 19px;
    width: 19px; }
  @media (min-width: 767px) {
    .Header_wrapper__2ROeI {
      grid-template-columns: auto 1fr auto auto;
      padding: 0 40px; }
      .Header_wrapper__2ROeI .Header_icon-search__3H-qz {
        height: 18px;
        width: 18px; } }

.Header_logo__1DNb8 {
  align-items: center;
  display: grid;
  height: inherit;
  position: relative;
  z-index: 10; }
  .Header_logo__1DNb8 a {
    display: block; }
  .Header_logo__1DNb8 svg {
    height: 38px;
    width: 58px; }

.Header_nav__1CdNo {
  font-size: 14px;
  font-weight: 600;
  justify-self: end; }
  .Header_nav__1CdNo a,
  .Header_nav__1CdNo span {
    color: #666;
    display: inline-block;
    line-height: 67px;
    padding: 0 10px;
    position: relative; }
    .Header_nav__1CdNo a::after,
    .Header_nav__1CdNo span::after {
      background-color: #666;
      bottom: 1px;
      content: "";
      height: 2px;
      left: 0;
      margin: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: opacity 0.2s ease-out;
      width: calc(100% - 20px); }
    .Header_nav__1CdNo a:hover::after,
    .Header_nav__1CdNo span:hover::after {
      opacity: 1; }

.Header_right_column__1Bv2k {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto;
  justify-items: end;
  position: relative;
  z-index: 100; }
  @media (min-width: 767px) {
    .Header_right_column__1Bv2k {
      align-items: center;
      border-right: 1px solid #e6e6e6;
      height: inherit;
      padding-right: 12px; } }

.Header_profile__2jSyW {
  align-items: center;
  display: grid;
  position: relative; }
  .Header_profile__2jSyW div {
    border-radius: 50%;
    height: 40px;
    margin-left: 15px;
    margin-right: 0;
    overflow: hidden;
    width: 40px; }
  .Header_profile__2jSyW img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.Header_buttons__1mf5e {
  align-items: center;
  padding-left: 14px;
  position: relative;
  z-index: 10; }
  .Header_buttons__1mf5e button {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    transition: color 0.12s ease-out; }
    .Header_buttons__1mf5e button:first-child {
      border: 1px solid #e6e6e6;
      color: #666; }
    .Header_buttons__1mf5e button:last-child {
      background-color: #f8f8f9;
      border: 1px solid #f8f8f9;
      color: #333; }
    .Header_buttons__1mf5e button:hover {
      color: #fa9923; }
  .Header_buttons__1mf5e span {
    color: #666;
    font-size: 12px;
    padding: 0 12px; }
  .Header_buttons__1mf5e li button {
    color: #666 !important;
    line-height: 20px !important;
    width: 100% !important; }

.Header_desktopbtn__1KmO8 {
  display: block; }
  @media (max-width: 767px) {
    .Header_desktopbtn__1KmO8 {
      display: none; } }

.Header_dropdown__3lisR {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  display: block;
  position: absolute;
  right: -25px;
  top: 50px;
  width: 160px;
  z-index: 99; }
  @media (max-width: 767px) {
    .Header_dropdown__3lisR {
      display: block;
      right: 0; } }
  .Header_dropdown__3lisR li {
    transition: background-color 0.14s ease-out; }
    .Header_dropdown__3lisR li:hover {
      background-color: #fa99231a; }
      .Header_dropdown__3lisR li:hover a,
      .Header_dropdown__3lisR li:hover button {
        color: #fa9923; }
  .Header_dropdown__3lisR a,
  .Header_dropdown__3lisR button {
    color: #666667;
    display: block;
    height: 44px;
    line-height: 44px;
    padding-left: 20px;
    text-align: left;
    transition: color 0.14s ease-out; }

.Header_home_page__1aWLx {
  border-color: rgba(255, 255, 255, 0.2); }
  .Header_home_page__1aWLx a,
  .Header_home_page__1aWLx span {
    color: #fff; }
  .Header_home_page__1aWLx .Header_dropdown__3lisR a {
    color: #666667; }
  .Header_home_page__1aWLx .Header_nav__1CdNo a:hover::after,
  .Header_home_page__1aWLx span:hover::after {
    background-color: #fff;
    opacity: 0.2; }
  .Header_home_page__1aWLx .Header_right_column__1Bv2k {
    border-color: rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 10; }
  .Header_home_page__1aWLx .Header_icon_search__TOltk,
  .Header_home_page__1aWLx .Header_icon_burger__3ypKg {
    fill: #fff; }
  .Header_home_page__1aWLx .Header_buttons__1mf5e span {
    color: #fff; }
  .Header_home_page__1aWLx .Header_buttons__1mf5e button:first-child {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff; }
  .Header_home_page__1aWLx .Header_buttons__1mf5e button:last-child {
    background-color: rgba(255, 255, 255, 0.2);
    border: 0;
    color: #fff; }
  .Header_home_page__1aWLx .Header_logo__1DNb8 svg {
    fill: #fff; }

.Header_phoneNumber__7wJtf {
  color: #b7b7b7;
  font-size: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center; }
  .Header_phoneNumber__7wJtf a {
    color: #d6d3d3;
    text-decoration: none; }
  @media (max-width: 1310px) and (min-width: 860px) {
    .Header_phoneNumber__7wJtf {
      width: 50%; } }
  @media (max-width: 859px) and (min-width: 768px) {
    .Header_phoneNumber__7wJtf {
      font-size: 10px;
      width: 40%; } }
  @media (max-width: 400px) {
    .Header_phoneNumber__7wJtf {
      font-size: 11px; } }
  .Header_phoneNumber__7wJtf b {
    font-weight: bold; }

@media (max-width: 767px) {
  .Header_hamburgerMenu__tHWLY {
    position: relative;
    top: 0; } }

@media (max-width: 767px) {
  .Header_mobileMenu__3DLpz {
    position: absolute; } }

@media (max-width: 767px) {
  .Header_profilePicture__3skNc {
    display: none; } }

.Footer_wrapper__hKyfO {
  background-color: #fff;
  height: 127px; }
  @media (min-width: 767px) {
    .Footer_wrapper__hKyfO {
      height: 88px; } }

.Footer_row__2a--u {
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas: "logo social" "copy copy";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1110px;
  padding: 24px 15px; }
  @media (min-width: 767px) {
    .Footer_row__2a--u {
      grid-template-areas: "logo social copy";
      margin: auto;
      padding: 24px 0;
      width: 90%; } }

.Footer_logo__2weEh {
  align-items: center;
  display: grid;
  grid-area: logo; }
  .Footer_logo__2weEh img {
    height: 40px;
    width: 60px; }

.Footer_social__2YALa {
  align-items: center;
  display: grid;
  grid-area: social;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, auto);
  justify-items: end;
  justify-self: end;
  max-width: 160px; }
  @media (min-width: 767px) {
    .Footer_social__2YALa {
      justify-self: center; } }
  .Footer_social__2YALa a {
    align-items: center;
    background-color: #f8f8f9;
    border-radius: 50%;
    display: grid;
    height: 40px;
    justify-content: center;
    overflow: hidden;
    width: 40px; }

.Footer_copyright__BLdAy {
  color: #666;
  font-size: 12px;
  grid-area: copy;
  line-height: 1.5; }
  .Footer_copyright__BLdAy a {
    color: #666;
    text-decoration: underline; }
  @media (min-width: 767px) {
    .Footer_copyright__BLdAy {
      align-items: center;
      display: grid; } }

.Footer_facebook__1az6T {
  fill: #3b5998;
  height: 16px;
  width: 15px; }

.Footer_twitter__1DwZO {
  fill: #00aced;
  height: 16px;
  width: 16px; }

.Footer_instagram__zF9Wg {
  height: 18px;
  width: 18px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.HomePage_chromeframe__3_Dui {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes HomePage_slideInTop__3ny1n {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.HomePage_short_hr__2PpHy {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.HomePage_App__2B6iI {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .HomePage_App__2B6iI.HomePage_modal-open__2opUU {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.HomePage_checkbox__3HhRy {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.HomePage_checkboxInput__1GGGL {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.HomePage_radioInput__1rl3J {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.HomePage_checkboxInput__1GGGL:checked ~ .HomePage_checkmark__3w8MA {
  background-color: #ff9514; }

.HomePage_checkboxInput__1GGGL:checked ~ .HomePage_guideCheckmark__3nL72 {
  background-color: #ff9514;
  color: #fff; }

.HomePage_radioInput__1rl3J:checked ~ .HomePage_radioMark__1WsKU {
  background-color: #fff; }

/* Create a custom checkbox */
.HomePage_checkmark__3w8MA {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.HomePage_guideCheckmark__3nL72 {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.HomePage_radioMark__1WsKU {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.HomePage_checkmark__3w8MA::after {
  content: "";
  display: none;
  position: absolute; }

.HomePage_guideCheckmark__3nL72::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.HomePage_radioMark__1WsKU::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.HomePage_checkboxInput__1GGGL:checked ~ .HomePage_checkmark__3w8MA::after {
  display: block; }

.HomePage_checkboxInput__1GGGL:checked ~ .HomePage_guideCheckmark__3nL72::after {
  display: block; }

.HomePage_radioInput__1rl3J:checked ~ .HomePage_radioMark__1WsKU::after {
  display: block; }

/* Style the checkmark/indicator */
.HomePage_checkmark__3w8MA::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.HomePage_guideCheckmark__3nL72::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.HomePage_radioMark__1WsKU::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.HomePage_loaderSpinner__D8HkS {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.HomePage_loader__1hh5S {
  animation: HomePage_spin__1jnzW 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes HomePage_spin__1jnzW {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.HomePage_lazy-load-image-background__1da0Q {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .HomePage_lazy-load-image-background__1da0Q img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.HomePage_lazyImageNP__ThNZm {
  padding-top: 0 !important; }

.HomePage_finish_google_survey__3fRuE {
  color: #666;
  text-decoration: none; }

.HomePage_finish_google_survey__3fRuE:hover {
  color: #fff; }

.HomePage_finish_btn__2fizr button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.HomePage_home-locations__dK7YQ {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .HomePage_home-locations__dK7YQ ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .HomePage_home-locations__dK7YQ li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .HomePage_home-locations__dK7YQ li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .HomePage_home-locations__dK7YQ li h3 {
        font-size: 14px; } }
  .HomePage_home-locations__dK7YQ li img {
    height: 28px;
    width: 28px; }
  .HomePage_home-locations__dK7YQ li .HomePage_rightArrow__1Vagk {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.HomePage_home-locations__dK7YQ ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .HomePage_home-locations__dK7YQ ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .HomePage_home-locations__dK7YQ ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .HomePage_home-locations__dK7YQ ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .HomePage_home-locations__dK7YQ ul li {
      width: 100%; } }

.HomePage_location-header__3KQcd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.HomePage_location-text__3ajtp p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .HomePage_location-text__3ajtp p {
      display: none; } }

.HomePage_leftArrow__MV424 {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .HomePage_leftArrow__arrow__3JM5- {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.HomePage_rightArrow__1Vagk {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .HomePage_rightArrow__arrow__1BRwg {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.HomePage_phone-icon__1skc4 {
  max-width: 30px; }

.HomePage_search-filter__3dAO4.HomePage_header-filter__3VCdd button {
  width: 260px; }
  @media (max-width: 767px) {
    .HomePage_search-filter__3dAO4.HomePage_header-filter__3VCdd button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_search-filter-bg__3ou9z {
  background: #f1f1f2; }

.HomePage_search-filter__3dAO4 {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .HomePage_search-filter__select__2hixt {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .HomePage_search-filter__select__dropdown-indicator__3SbaX {
      color: #333 !important; }
      .HomePage_search-filter__select__dropdown-indicator__3SbaX svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .HomePage_search-filter__select__clear-indicator__2xVpj {
      color: #fff !important; }
      .HomePage_search-filter__select__clear-indicator__2xVpj:hover {
        color: #666 !important; }
    .HomePage_search-filter__select__placeholder__2kW18, .HomePage_search-filter__select__single-value__16Kot {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .HomePage_search-filter__select__placeholder__2kW18 {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .HomePage_search-filter__select__input__3RXO7 input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .HomePage_search-filter__select__value-container__38iBI {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .HomePage_search-filter__select__indicator__1sgbb {
      padding: 0 !important; }
    .HomePage_search-filter__select__indicator-separator__1hRM1 {
      display: none; }
    .HomePage_search-filter__select__menu-list__2F38l {
      padding: 0 !important; }
    .HomePage_search-filter__select__menu__auPey {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .HomePage_search-filter__select__2hixt {
        width: 100%; } }
  .HomePage_search-filter__destination__16TVM {
    width: 260px; }
  .HomePage_search-filter__3dAO4 button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .HomePage_search-filter__3dAO4 button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .HomePage_search-filter__3dAO4 button:hover {
      border: 1px solid #dedede; }
      .HomePage_search-filter__3dAO4 button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .HomePage_search-filter__3dAO4 button {
        width: 100%; } }
  @media (max-width: 767px) {
    .HomePage_search-filter__destination__16TVM, .HomePage_search-filter__select__2hixt,
    .HomePage_search-filter__3dAO4 button {
      margin: 8px 0;
      width: 100%; } }

.HomePage_search-filter__select__option__1jgMt {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .HomePage_search-filter__select__option__1jgMt .HomePage_sub__2-Wrm {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .HomePage_search-filter__select__option__item__JXrUO {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .HomePage_search-filter__select__option__item__JXrUO span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .HomePage_search-filter__select__option__item__JXrUO img {
      display: block;
      height: 100%;
      width: 100%; }
  .HomePage_search-filter__select__option__1jgMt:hover, .HomePage_search-filter__select__option__selected__1ulmV {
    background: rgba(255, 149, 20, 0.1); }
    .HomePage_search-filter__select__option__1jgMt:hover .HomePage_sub__2-Wrm, .HomePage_search-filter__select__option__selected__1ulmV .HomePage_sub__2-Wrm {
      color: #ff8c00; }
    .HomePage_search-filter__select__option__1jgMt:hover.HomePage_search-filter__select__option__1jgMt:hover span, .HomePage_search-filter__select__option__1jgMt:hover .HomePage_search-filter__select__option__selected__item__27zCb span,
    .HomePage_search-filter__select__option__1jgMt:hover .HomePage_search-filter__select__option__item__JXrUO span, .HomePage_search-filter__select__option__selected__1ulmV.HomePage_search-filter__select__option__1jgMt:hover span, .HomePage_search-filter__select__option__selected__1ulmV .HomePage_search-filter__select__option__selected__item__27zCb span,
    .HomePage_search-filter__select__option__selected__1ulmV .HomePage_search-filter__select__option__item__JXrUO span {
      background: #ff8c00; }
      .HomePage_search-filter__select__option__1jgMt:hover.HomePage_search-filter__select__option__1jgMt:hover span::before, .HomePage_search-filter__select__option__1jgMt:hover .HomePage_search-filter__select__option__selected__item__27zCb span::before,
      .HomePage_search-filter__select__option__1jgMt:hover .HomePage_search-filter__select__option__item__JXrUO span::before, .HomePage_search-filter__select__option__selected__1ulmV.HomePage_search-filter__select__option__1jgMt:hover span::before, .HomePage_search-filter__select__option__selected__1ulmV .HomePage_search-filter__select__option__selected__item__27zCb span::before,
      .HomePage_search-filter__select__option__selected__1ulmV .HomePage_search-filter__select__option__item__JXrUO span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.HomePage_search-filter__select__control__3UjyF {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .HomePage_search-filter__select__control__3UjyF .HomePage_css-1g6gooi__3W8xB {
    margin: 0 !important;
    padding: 0 !important; }
  .HomePage_search-filter__select__control__focused__18Bmg, .HomePage_search-filter__select__control__isOpen__2YYPL, .HomePage_search-filter__select__control__3UjyF:hover {
    border-color: #ff9514; }
  .HomePage_search-filter__select__control__fill__g4EkP {
    background-color: #ff9514;
    border-color: #ff9514; }
    .HomePage_search-filter__select__control__fill__g4EkP .HomePage_search-filter__select__dropdown-indicator__3SbaX,
    .HomePage_search-filter__select__control__fill__g4EkP .HomePage_search-filter__select__single-value__16Kot,
    .HomePage_search-filter__select__control__fill__g4EkP .HomePage_search-filter__select__placeholder__2kW18 {
      color: #fff !important; }
    .HomePage_search-filter__select__control__fill__g4EkP .HomePage_search-filter__select__input__3RXO7 input {
      color: #fff !important; }
  .HomePage_search-filter__select__control__isOpen__2YYPL .HomePage_search-filter__select__dropdown-indicator__3SbaX svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_adventure-about__mNuwz {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .HomePage_adventure-about__mNuwz {
      padding: 5px 0; } }
  .HomePage_adventure-about__container__1lyS0 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .HomePage_adventure-about__container__1lyS0 {
        padding: 0 10px; } }
  .HomePage_adventure-about__author__XPfdI {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .HomePage_adventure-about__author__XPfdI img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .HomePage_adventure-about__author__XPfdI span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .HomePage_adventure-about__author__XPfdI {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .HomePage_adventure-about__author__XPfdI img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .HomePage_adventure-about__author__XPfdI span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .HomePage_adventure-about__rate__2Y2t3 {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .HomePage_adventure-about__rate__2Y2t3 > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .HomePage_adventure-about__rate__2Y2t3 {
        display: none; } }
  .HomePage_adventure-about__buttons__2w1iN {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .HomePage_adventure-about__buttons__2w1iN button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .HomePage_adventure-about__buttons__2w1iN button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .HomePage_adventure-about__buttons__2w1iN button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .HomePage_adventure-about__buttons__2w1iN button {
          width: 120px; } }
      @media (max-width: 576px) {
        .HomePage_adventure-about__buttons__2w1iN button {
          margin-right: 10px;
          width: 90px; } }
    .HomePage_adventure-about__buttons__check-dates__hsKV_ {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .HomePage_adventure-about__buttons__request__219tM {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_guides-footer__2c-c9 {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .HomePage_guides-footer__author__1847X {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .HomePage_guides-footer__author__1847X img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .HomePage_guides-footer__author__1847X span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .HomePage_guides-footer__author__1847X {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .HomePage_guides-footer__author__1847X img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .HomePage_guides-footer__author__1847X span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .HomePage_guides-footer__rate__2d9kV {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .HomePage_guides-footer__rate__2d9kV > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .HomePage_guides-footer__rate__2d9kV {
        display: none; } }
  .HomePage_guides-footer__button__1R_1_ {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .HomePage_guides-footer__button__1R_1_ {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .HomePage_guides-footer__button__1R_1_ {
        width: 120px; } }
    @media (max-width: 576px) {
      .HomePage_guides-footer__button__1R_1_ {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_guide-modal__1JG4R {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .HomePage_guide-modal__close__3Mzh4 {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .HomePage_guide-modal__close__3Mzh4:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .HomePage_guide-modal__1JG4R {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .HomePage_guide-modal__1JG4R {
      padding: 10px;
      width: 300px; } }

.HomePage_guide-modal__header__2GtOn {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .HomePage_guide-modal__header__photo__19s6y {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .HomePage_guide-modal__header__photo__19s6y img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .HomePage_guide-modal__header__info__22Xwe {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .HomePage_guide-modal__header__info__22Xwe p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .HomePage_guide-modal__header__info__22Xwe div {
      display: flex; }
      .HomePage_guide-modal__header__info__22Xwe div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .HomePage_guide-modal__header__info__22Xwe div span {
            text-align: center; } }
        .HomePage_guide-modal__header__info__22Xwe div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .HomePage_guide-modal__header__info__22Xwe div span:last-child {
          margin-right: 0; }
          .HomePage_guide-modal__header__info__22Xwe div span:last-child::after {
            display: none; }

.HomePage_guide-modal__body__1yUAe {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .HomePage_guide-modal__body__error__rx_1H .HomePage_guide-modal__body__item__3MfDm {
    border-color: #f00; }
  .HomePage_guide-modal__body__error__rx_1H p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .HomePage_guide-modal__body__item__3MfDm {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .HomePage_guide-modal__body__item__3MfDm img,
    .HomePage_guide-modal__body__item__3MfDm svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .HomePage_guide-modal__body__item__3MfDm input,
    .HomePage_guide-modal__body__item__3MfDm textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .HomePage_guide-modal__body__item__3MfDm textarea {
      height: 70px;
      resize: none; }
    .HomePage_guide-modal__body__item__down__3nV0W {
      position: absolute;
      right: 9px; }
  .HomePage_guide-modal__body__1yUAe .HomePage_react-datepicker-wrapper__3LI5Y,
  .HomePage_guide-modal__body__1yUAe .HomePage_react-datepicker__input-container__3vtKZ {
    width: 100%; }
  .HomePage_guide-modal__body__1yUAe .HomePage_react-datepicker__2bwu9 .HomePage_react-datepicker__day__3S3Xc:not(.HomePage_react-datepicker__day--disabled__UZvbG) {
    background: #fa9923;
    border-radius: 4.8px; }
    .HomePage_guide-modal__body__1yUAe .HomePage_react-datepicker__2bwu9 .HomePage_react-datepicker__day__3S3Xc:not(.HomePage_react-datepicker__day--disabled__UZvbG):hover {
      color: #fff;
      font-weight: bold; }
  .HomePage_guide-modal__body__submit__2D6aJ {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .HomePage_guide-modal__body__submit__2D6aJ .HomePage_loader__1hh5S {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.HomePage_guide-modal__footer__2e_i7 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .HomePage_guide-modal__footer__2e_i7 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .HomePage_guide-modal__footer__2e_i7 label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .HomePage_guide-modal__footer__2e_i7 a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .HomePage_guide-modal__footer__2e_i7 input {
    left: -9999px;
    position: absolute; }
  .HomePage_guide-modal__footer__2e_i7 span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .HomePage_guide-modal__footer__2e_i7 input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_modal-overlay__lSPxb {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.HomePage_modal-popup__1neYu {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .HomePage_modal-popup__close__2keJ5 {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .HomePage_modal-popup__close__2keJ5:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .HomePage_modal-popup__1neYu {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .HomePage_modal-popup__1neYu {
      padding: 10px;
      width: 300px; } }

.HomePage_modal-open__2opUU {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .HomePage_modal-open__2opUU .HomePage_fixed-footer__1Celb {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.HomePage_fixed-footer__1Celb {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .HomePage_fixed-footer__1Celb {
      padding: 5px 0; } }
  .HomePage_fixed-footer__container__2r7-w {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .HomePage_fixed-footer__container__2r7-w {
        padding: 0 10px; } }
  .HomePage_fixed-footer__1Celb.HomePage_hidden__1z8C6 {
    bottom: -80px; }

.HomePage_wrapper__1pvbB {
  position: relative; }
  .HomePage_wrapper__1pvbB .HomePage_map__DAwMT img {
    width: 100%; }
  .HomePage_wrapper__1pvbB a:hover {
    text-decoration: none; }

@media (max-width: 767px) {
  .HomePage_mapboxgl-map__1zXsA {
    display: none; } }

.HomeHeader_wrapper__3Itr2 {
  background-image: url("/images/home-page-bg-mobile-375.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 667px;
  margin-top: -67px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 120px;
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-location__2D_gt {
      color: #fff;
      flex: 0 0 30%;
      margin-right: 15px;
      max-width: 30%; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-date__pAN_- {
      flex: 0 0 30%;
      margin-right: 15px;
      max-width: 30%; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-findbtn__3xM83 {
      flex: 0 0 20%;
      max-width: 20%; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-location__2D_gt {
      flex: 0 0 33%;
      margin-right: 15px; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-date__pAN_- {
      flex: 0 0 33%;
      margin-right: 15px; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-findbtn__3xM83 {
      flex: 0 0 28%; } }
  @media (max-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-location__2D_gt {
      margin-bottom: 16px;
      width: 50%; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-date__pAN_- {
      margin-bottom: 16px; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_clm-findbtn__3xM83 {
      margin-bottom: 16px; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .HomeHeader_wrapper__3Itr2 {
      background-image: url("/images/home-page-bg-1600x900.jpg");
      height: 767px;
      padding-top: 220px; } }
  @media (min-width: 1025px) {
    .HomeHeader_wrapper__3Itr2 {
      background-image: url("/images/home-page-bg-1920x1080.jpg");
      height: 767px;
      padding-top: 220px; } }
  @media (min-width: 767px) {
    .HomeHeader_wrapper__3Itr2 h1 {
      max-width: 400px; } }
  @media (min-width: 1089px) {
    .HomeHeader_wrapper__3Itr2 h1 {
      max-width: 600px; } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_banner__VjXwN {
    background-color: #93886e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 15px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 67px;
    width: 100%; }
  @media (min-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_row__3XY50 {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%; } }
  @media (min-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_grid__ETCjW {
      display: grid;
      grid-column-gap: 5%;
      grid-template-columns: 1fr 300px;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_grid__ETCjW {
      margin-bottom: 48px; } }
  .HomeHeader_wrapper__3Itr2 p {
    color: #fff; }
  .HomeHeader_wrapper__3Itr2 hr {
    background-color: #fff; }
  @media (max-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_divider__v5wcw {
      display: none; } }
  @media (max-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_explanation__2xHkf {
      display: none; } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_guides_faces__FKnGk {
    align-items: center;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    grid-column-gap: 11px;
    grid-template-columns: auto 1fr;
    padding: 25px 0;
    text-transform: uppercase; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_faces_wrapper__1nl40 {
    display: flex;
    flex-direction: row;
    position: relative; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_details__2AtpO {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 104px;
    justify-content: center;
    left: 50%;
    padding-bottom: 5px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, 0);
    width: 232px; }
    @media (max-width: 767px) {
      .HomeHeader_wrapper__3Itr2 .HomeHeader_details__2AtpO {
        left: 0;
        transform: translate(0, 0); } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_country__3HDYQ {
    color: #ff9514;
    font-size: 13px;
    margin-bottom: 7px;
    padding-top: 35px;
    text-align: center; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_name__9p5Ls {
    color: #000;
    font-size: 18px;
    margin-bottom: 7px;
    text-align: center; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_extra__23JrW {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_rating__2erwq {
    align-items: center;
    color: #666;
    display: flex;
    margin-bottom: 4px; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_star__1UN0p {
    color: #666;
    font-size: 8px; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_review__1oNli {
    color: #666; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_adventures__3iLtm {
    color: #666; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_allGuides__3sdSl {
    cursor: pointer; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_face__B4uEd {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-left: -10px !important;
    position: relative;
    width: 48px;
    z-index: 1; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_face__B4uEd:first-child {
      margin-left: 0; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_face__B4uEd img {
      border: 2px solid #fff;
      border-radius: 50%;
      height: 48px;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 48px; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_find_button__22SKH {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    letter-spacing: 0.25px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .HomeHeader_wrapper__3Itr2 .HomeHeader_find_button__22SKH {
        margin-left: 15px !important;
        margin-right: 15px !important; } }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_find_button__22SKH svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      margin-top: 3px;
      width: 18px; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_button__2AGW6 {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    justify-content: space-between;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    width: 100%; }
  @media (max-width: 767px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 {
      flex-direction: column !important; }
      .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 div {
        width: 100% !important; }
      .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 div:last-child {
        max-width: 250px !important; } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 {
    display: flex;
    flex-direction: row;
    max-width: 80%; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 button {
      height: 50px; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 > div {
    height: 50px;
    margin-bottom: 16px;
    margin-right: 10px;
    max-width: 250px;
    width: 35%; }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 > div:last-child {
    height: 50px;
    max-width: 185px;
    width: 30%; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .HomeHeader_wrapper__3Itr2 .HomeHeader_find_adventure__bLtT6 {
      max-width: 100%; } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 {
    align-items: center;
    background-color: rgba(98, 98, 94, 0.9);
    border-radius: 8px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    opacity: 1;
    padding: 13px 17px;
    position: relative; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 ::-webkit-input-placeholder {
      color: #fff; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 ::placeholder {
      color: #fff; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 input {
      background: transparent;
      border: 0;
      color: #fff;
      margin-left: 10px; }
    .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 svg {
      fill: #fff;
      height: 16px;
      width: 16px; }
    @media (max-width: 767px) {
      .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40 {
        margin-left: 15px !important;
        margin-right: 15px !important; } }
  .HomeHeader_wrapper__3Itr2 .HomeHeader_input__12Y40::before {
    background: inherit;
    border-radius: 8px;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1; }

.HomeHeader_DayPicker_transitionContainer__1uTX9 {
  border-radius: 8px;
  display: none; }

.HomeHeader_DateRangePickerInput__23zvz {
  background-color: #f00 !important;
  display: none !important; }
  .HomeHeader_DateRangePickerInput__23zvz input {
    background-color: #f00 !important;
    display: none; }

.homeFilter .DateInput_input {
  background-color: #62625e !important;
  /* color: hsl(0, 0%, 50%) !important; */
  font-size: 100%;
  margin: 0 !important;
  padding: 0;
  text-align: center; }

.homeFilter .DateRangePickerInput {
  align-items: center;
  background-color: rgba(98, 98, 94, 0.9) !important;
  color: #fff;
  display: none;
  display: flex; }
  @media (max-width: 768px) {
    .homeFilter .DateRangePickerInput {
      width: 50%; } }

.homeFilter .DateRangePickerInput_arrow {
  color: #fff; }

.homeFilter .DateInput {
  width: unset; }

.homeFilter .DateRangePicker {
  margin-left: 10px; }

.homeFilter .DateRangePicker_picker {
  border-radius: 8px;
  opacity: 1; }

.homeFilter .DateRangePicker_picker__directionLeft {
  left: -37px !important;
  top: 50px !important; }

.homeFilter .DateInput_fang {
  display: none; }

@media (max-width: 320px) {
  .homeFilter .DayPicker {
    width: 286px !important; } }

@media (max-width: 320px) {
  .homeFilter .DayPicker_1 {
    width: 286px; } }

.homeFilter .DayPicker_1 .DayPicker_weekHeaders {
  width: 300px !important; }

.homeFilter .DayPicker_weekHeaders {
  margin-left: -5px; }
  @media (min-width: 1025px) {
    .homeFilter .DayPicker_weekHeaders {
      margin-left: 10px; } }

@media (max-width: 320px) {
  .homeFilter .DayPicker_transitionContainer {
    width: 286px !important; } }

@media (max-width: 320px) {
  .homeFilter .CalendarMonthGrid {
    left: 0;
    width: 286px !important; } }

.homeFilter .CalendarMonth_caption {
  caption-side: top;
  caption-side: initial;
  color: #333;
  font-family: "Abril Fatface";
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 37px;
  padding-top: 22px;
  text-align: center; }

.homeFilter .CalendarDay__default {
  background-color: #f8f8f9;
  border: 2px solid #fff;
  vertical-align: middle; }

.homeFilter .CalendarDay__selected_span {
  background-color: #ff9514;
  border-radius: 8px;
  color: #fff;
  opacity: 0.55; }

.homeFilter .CalendarDay__hovered_span {
  background-color: #ff9514;
  border-radius: 8px;
  color: #fff;
  opacity: 0.55; }

.homeFilter .CalendarDay__hovered_span:active,
.homeFilter .CalendarDay__hovered_span:hover {
  background-color: #ff9514;
  border: 2px solid #fff;
  border-radius: 8px;
  color: #fff;
  opacity: 0.9; }

.homeFilter .CalendarDay__selected,
.homeFilter .CalendarDay__selected:active,
.homeFilter .CalendarDay__selected:hover {
  background-color: #ff9514;
  border: 2px solid #fff;
  border-radius: 8px; }

.homeFilter .DayPicker_weekHeader_li > small {
  font-size: 100%; }

.homeFilter .CalendarDay__selected_span:hover,
.homeFilter .CalendarDay__default:hover {
  border: 0; }

.homeFilter .DateRangePicker_1:focus-within + svg {
  transform: rotate(180deg); }

@media (max-width: 320px) {
  .homeFilter .DayPickerNavigation {
    left: -14px; } }

.homeFilter .DayPickerNavigation_button {
  align-items: center;
  background-color: #f8f8f9;
  border: 0;
  border-radius: 50%;
  display: flex;
  height: 30px;
  width: 30px; }
  .homeFilter .DayPickerNavigation_button svg {
    display: none; }

.homeFilter .DayPickerNavigation_leftButton__horizontalDefault::before {
  border: solid #666;
  border-width: 0 1px 1px 0;
  color: #666;
  content: "";
  display: inline-block;
  height: 8px;
  margin-left: 5px;
  padding: 3px;
  transform: rotate(135deg);
  width: 36px !important; }

.homeFilter .DayPickerNavigation_rightButton__horizontalDefault::before {
  border: solid #666;
  border-width: 0 1px 1px 0;
  color: #666;
  content: "";
  display: inline-block;
  height: 8px;
  margin-right: 5px;
  padding: 3px;
  transform: rotate(-45deg);
  width: 36px !important; }

@media screen and (max-width: 768px) {
  .container {
    margin-left: 0;
    padding-left: 0; } }

.AdventureCard_wrapper__3OXkV {
  display: block;
  margin-bottom: 15px;
  position: relative; }
  @media (min-width: 767px) {
    .AdventureCard_wrapper__3OXkV {
      margin: 15px; } }

.AdventureCard_thumb__7NmN- {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
  height: 290px;
  overflow: hidden;
  position: relative; }

.AdventureCard_background__TTTLM {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }
  .AdventureCard_background__TTTLM::after {
    background: linear-gradient(to bottom, #fff0 60%, #000000a8 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .AdventureCard_background__TTTLM img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.AdventureCard_price__26G82 {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  left: 15px;
  padding: 9px 10px;
  position: absolute;
  top: 15px;
  z-index: 1; }

.AdventureCard_edit_icons__2Ce0c {
  display: flex;
  flex-direction: row;
  z-index: 1; }

.AdventureCard_edit__1Bjau {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 65px;
  top: 15px;
  z-index: 1; }
  .AdventureCard_edit__1Bjau svg {
    fill: #fff;
    height: 18px;
    width: 18px; }

.AdventureCard_delete__2OBvK {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1; }
  .AdventureCard_delete__2OBvK svg {
    fill: #fff;
    height: 18px;
    width: 18px; }

.AdventureCard_like__20Ltr {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1; }
  .AdventureCard_like__20Ltr svg {
    fill: #fff;
    height: 18px;
    width: 18px; }

.AdventureCard_guide__2yL13 {
  bottom: 15px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas: "face name" "face info";
  grid-template-columns: auto 1fr;
  left: 15px;
  position: absolute;
  z-index: 1; }
  .AdventureCard_guide__2yL13 p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    grid-area: name;
    margin-bottom: 0; }
  .AdventureCard_guide__2yL13 svg {
    fill: #fff;
    height: 8px;
    width: 8px; }

.AdventureCard_info__1LAXJ {
  align-items: center;
  display: grid;
  font-size: 75%;
  grid-area: info;
  grid-column-gap: 5px;
  grid-template-columns: repeat(4, auto); }

.AdventureCard_face__zC-FS {
  border-radius: 50%;
  grid-area: face;
  height: 36px;
  overflow: hidden;
  width: 36px; }
  .AdventureCard_face__zC-FS img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.AdventureCard_description__35HSx {
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 125px;
  padding: 15px; }
  .AdventureCard_description__35HSx p {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 10px; }

.AdventureCard_type__3WCmw {
  color: #fa9923;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase; }

.AdventureCard_location__3Ox9J {
  align-items: center;
  color: #666;
  display: grid;
  font-size: 14px;
  grid-column-gap: 7px;
  grid-template-columns: auto 1fr; }
  .AdventureCard_location__3Ox9J svg {
    fill: #666;
    height: 12px;
    width: 12px; }

.GuideCard_wrapper__1JwBO {
  position: relative; }
  .GuideCard_wrapper__1JwBO .GuideCard_thumb__1Kf94 {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    height: 280px;
    overflow: hidden;
    position: relative; }
    .GuideCard_wrapper__1JwBO .GuideCard_thumb__1Kf94::after {
      background: linear-gradient(to bottom, #fff0 60%, #000000a8 100%);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .GuideCard_wrapper__1JwBO .GuideCard_thumb__1Kf94 img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%; }

.GuideCard_description__1wepl {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 125px;
  padding: 15px 0;
  word-break: break-word; }
  .GuideCard_description__1wepl p {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 5px; }

.GuideCard_location__3bt9O {
  align-items: center;
  color: #fa9923;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
  vertical-align: middle;
  vertical-align: middle; }
  .GuideCard_location__3bt9O img {
    height: 16px;
    margin-right: 5px;
    width: 16px; }

.GuideCard_info__1X1Gc {
  align-items: center;
  color: #666;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  max-width: 250px; }
  .GuideCard_info__1X1Gc svg {
    fill: #666;
    height: 8px;
    width: 8px; }
  .GuideCard_info__1X1Gc .GuideCard_rating__2W1TM {
    align-items: center;
    display: flex; }

.CategorySelector_wrapper__2DI-y {
  background-color: #fff;
  border-radius: 40px;
  color: #666;
  display: flex;
  justify-content: center;
  width: 150px; }

.CardList_wrapper__2dnJP {
  background-color: #f8f8f9;
  padding: 40px 15px 0;
  position: relative; }
  @media (min-width: 767px) {
    .CardList_wrapper__2dnJP .CardList_row__3Nqwt {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%; } }
  .CardList_wrapper__2dnJP .CardList_section__SZsZz {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1025px) {
      .CardList_wrapper__2dnJP .CardList_section__SZsZz {
        flex-direction: row; } }
  .CardList_wrapper__2dnJP .CardList_category__208DH {
    display: flex;
    flex: 2 1;
    flex-direction: row;
    margin-bottom: 50px; }
    @media (max-width: 380px) {
      .CardList_wrapper__2dnJP .CardList_category__208DH {
        flex-direction: column;
        max-width: 1110px; } }
  .CardList_wrapper__2dnJP .CardList_detail__1nlIk {
    flex: 1 1 50%;
    max-width: 445px; }
  .CardList_wrapper__2dnJP .CardList_categories__VlnO0 {
    color: #666;
    margin-bottom: 30px; }
    .CardList_wrapper__2dnJP .CardList_categories__VlnO0 li {
      cursor: pointer;
      display: inline-block;
      padding: 0 3px;
      transition: color 0.2s ease-out; }
      .CardList_wrapper__2dnJP .CardList_categories__VlnO0 li:hover {
        color: #fa9923; }
      .CardList_wrapper__2dnJP .CardList_categories__VlnO0 li:first-child {
        color: #fa9923;
        padding-left: 0; }
    .CardList_wrapper__2dnJP .CardList_categories__VlnO0 span:last-child {
      display: none; }
  .CardList_wrapper__2dnJP .CardList_regions__2rm85 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .CardList_wrapper__2dnJP .CardList_region__3jFLZ {
    color: #666;
    cursor: pointer;
    font-size: 16px; }
  .CardList_wrapper__2dnJP .CardList_regionActive__L7URT {
    color: #ff9514;
    cursor: pointer;
    font-size: 16px; }
  @media (min-width: 767px) {
    .CardList_wrapper__2dnJP .CardList_cards__o7ewR {
      display: grid;
      grid-column-gap: 30px; } }
  .CardList_wrapper__2dnJP .CardList_see_more__3IlNn {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase; }
    .CardList_wrapper__2dnJP .CardList_see_more__3IlNn span {
      text-align: right; }
    .CardList_wrapper__2dnJP .CardList_see_more__3IlNn svg {
      height: 35px;
      width: 35px; }

.CardList_c3__35ifN {
  grid-template-columns: repeat(3, 1fr); }

.CardList_c4__2h0z2 {
  grid-template-columns: repeat(4, 1fr); }

.CardList_wrapper__2dnJP.CardList_white__1O6Ne {
  background-color: #fff; }

.CardList_wrapper__2dnJP.CardList_grey__27dLE {
  background-color: #f8f8f9; }

.Response_wrapper__t50qY {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }
  .Response_wrapper__t50qY .Response_row__22HcA {
    background: #fff;
    border-radius: 5px;
    left: 50%;
    margin: 0 auto;
    min-width: 450px;
    padding: 29px 17px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }
    @media (max-width: 768px) {
      .Response_wrapper__t50qY .Response_row__22HcA {
        left: 50%;
        margin-top: 0;
        min-width: 0;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 10; } }
  .Response_wrapper__t50qY h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0; }
  .Response_wrapper__t50qY p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px; }
  .Response_wrapper__t50qY .Response_button__3TEIk {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    text-transform: uppercase;
    width: 100px; }

.SubscribeToNewsletter_wrapper__3oyCm {
  background-color: #f8f8f9;
  padding: 40px 15px;
  position: relative;
  text-align: center; }
  .SubscribeToNewsletter_wrapper__3oyCm h3 {
    font-size: 27px;
    line-height: 37px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    max-width: 240px; }
    @media (min-width: 767px) {
      .SubscribeToNewsletter_wrapper__3oyCm h3 {
        font-size: 38px;
        max-width: none; } }
  .SubscribeToNewsletter_wrapper__3oyCm p {
    margin-left: auto;
    margin-right: auto;
    max-width: 230px; }
    @media (min-width: 767px) {
      .SubscribeToNewsletter_wrapper__3oyCm p {
        max-width: none; } }
  .SubscribeToNewsletter_wrapper__3oyCm .SubscribeToNewsletter_messagetip__24ERR {
    left: 70%;
    position: absolute;
    top: 180%;
    visibility: hidden; }
  .SubscribeToNewsletter_wrapper__3oyCm .SubscribeToNewsletter_messagetip_text__3PzLC {
    background-color: #000;
    border-radius: 6px;
    color: #fff;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 200px;
    z-index: 10; }
  .SubscribeToNewsletter_wrapper__3oyCm form {
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: 1fr auto; }
    @media (min-width: 767px) {
      .SubscribeToNewsletter_wrapper__3oyCm form {
        margin: 0 auto;
        max-width: 530px; } }
  .SubscribeToNewsletter_wrapper__3oyCm input {
    background: #fff;
    border: 0;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 15px 20px;
    width: 100%; }
  .SubscribeToNewsletter_wrapper__3oyCm .SubscribeToNewsletter_inputError__2KRbS {
    background: #fff;
    border: 1px solid rgba(255, 0, 0, 0.7);
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 15px 20px;
    width: 100%; }
  .SubscribeToNewsletter_wrapper__3oyCm button {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.25px;
    max-width: 135px;
    padding: 0 12px;
    text-transform: uppercase;
    white-space: nowrap; }
    .SubscribeToNewsletter_wrapper__3oyCm button svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      width: 18px; }

.ReadMoreTextBlock_wrapper__6toBG {
  padding: 40px 0;
  position: relative; }
  @media (min-width: 767px) {
    .ReadMoreTextBlock_wrapper__6toBG {
      padding: 90px 0; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_reverse__JS-gL .ReadMoreTextBlock_column__em6s6:first-child {
    order: 2; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_reverse__JS-gL .ReadMoreTextBlock_column__em6s6:last-child {
    order: 1; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_row__3drBQ {
    padding: 0 15px; }
    @media (min-width: 767px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_row__3drBQ {
        display: grid;
        grid-column-gap: 125px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
        max-width: 1110px;
        padding: 0;
        width: 90%; } }
  .ReadMoreTextBlock_wrapper__6toBG h3 {
    margin-bottom: 30px; }
  .ReadMoreTextBlock_wrapper__6toBG q {
    color: #666;
    font-size: 16px;
    line-height: 28px; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_read_more__2SRCg {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: auto 1fr;
    letter-spacing: 0.3px;
    padding-bottom: 30px;
    text-align: center;
    text-transform: uppercase; }
    .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_read_more__2SRCg span {
      text-align: left; }
    .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_read_more__2SRCg svg {
      height: 35px;
      width: 35px; }
  .ReadMoreTextBlock_wrapper__6toBG img {
    width: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_referenceImages__3NObS {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative; }
    @media (min-width: 1025px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_referenceImages__3NObS {
        flex-direction: row; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partOne__2ShRZ {
    flex-direction: column;
    margin-right: 6%;
    position: relative;
    width: 48%; }
    @media (max-width: 1024px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partOne__2ShRZ {
        display: flex;
        flex-direction: row;
        height: 30%;
        position: relative;
        width: 100%; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_dots__3bIJ- {
    color: #666;
    left: 30%;
    max-height: 250px;
    overflow: hidden;
    position: absolute;
    right: -10%;
    top: -5%; }
    @media (min-width: 1025px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_dots__3bIJ- {
        top: -10%; } }
    @media (max-width: 768px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_dots__3bIJ- {
        max-height: 300px;
        right: -1%; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_dots__3bIJ-::before {
    color: #666;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
    letter-spacing: 4px; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partOneImg__FjmeL {
    height: 48%;
    margin-bottom: 8%;
    margin-right: 4%;
    width: 100%; }
    @media (max-width: 1024px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partOneImg__FjmeL {
        height: 100%;
        position: relative; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_imgContentFirst__240sY {
    border-radius: 8px;
    height: 100%;
    object-fit: cover; }
    @media (max-width: 1024px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_imgContentFirst__240sY {
        bottom: 100%;
        left: 0;
        position: absolute;
        right: 100%;
        top: 0; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partOneImg__FjmeL:last-child {
    margin-bottom: 0;
    margin-right: 0; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partTwo__3PapX {
    height: 70%;
    padding-top: 5%;
    position: relative;
    width: 100%; }
    .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partTwo__3PapX .ReadMoreTextBlock_imgContentThird__1sbeE {
      border-radius: 8px;
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0; }
    @media (min-width: 1025px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_partTwo__3PapX {
        height: auto;
        padding-top: 0;
        width: 47%; } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitImages__SDexg {
    display: flex;
    flex: 2 1 10px;
    flex-direction: row; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitOneImg__ImfZl {
    display: flex;
    flex-direction: column;
    margin-right: 4%;
    position: relative;
    width: 98%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitTwoImg__XCAbs {
    display: flex;
    flex-direction: column;
    width: 98%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_decCircle__2TDpg {
    background-color: #ff9514;
    border-radius: 50%;
    height: 74px;
    left: 0;
    position: absolute;
    top: 100%;
    transform: translate(-30%, -80%);
    width: 74px; }
    @media (max-width: 768px) {
      .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_decCircle__2TDpg {
        transform: translate(-8%, -70%); } }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitSquare__3gXwO {
    position: relative; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitOneFirst__2KqeT {
    border-radius: 8px;
    bottom: 0;
    height: 80%;
    left: 0;
    margin-bottom: 5px;
    margin-top: -20%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 20%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitSecond__2FNdC {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    left: 0;
    margin-bottom: 5px;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_hitTwoFirst__2uG_f {
    border-radius: 8px;
    bottom: 0;
    height: 80%;
    left: 0;
    margin-bottom: 5px;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 20%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdImages__mbVpo {
    display: flex;
    flex-direction: row;
    height: 458px;
    position: relative; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdMask__1dFmP {
    float: left;
    height: 100%;
    margin-right: 10px;
    width: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdOne__25Mvd {
    border-radius: 8px;
    bottom: 0;
    height: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdTwo__2bTCn {
    border-radius: 8px;
    bottom: 0;
    height: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdThird__3Zecx {
    border-radius: 8px;
    bottom: 0;
    height: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdOneImg__2_FCe {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdTwoImg__3ET7z {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .ReadMoreTextBlock_wrapper__6toBG .ReadMoreTextBlock_wdThirdImg__2b3j6 {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    width: 100%; }

.ReadMoreTextBlock_wrapper__6toBG.ReadMoreTextBlock_white__2eeuM {
  background-color: #fff; }

.ReadMoreTextBlock_wrapper__6toBG.ReadMoreTextBlock_grey__25cCy {
  background-color: #f8f8f9; }


.mapboxgl-marker {
  z-index: 1;
}
/* Customize Popup Menu */

.mapboxgl-popup {
  z-index: 3;
}

.mapboxgl-popup-content {
  border-radius: 12px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
  max-width: 243px;
  min-height: 85px;
  min-width: 180px;
  padding-bottom: 0;
  z-index: 20;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapGuideName {
  color: #333;
  font-size: 18px;
  word-break: break-word;
}

.mapDetails {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.star {
  font-size: 8px;
}

.address {
  color: #ff9514;
  margin-bottom: 6px;
}

.react-tel-input input {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 15px;
  height: 50px !important;
  letter-spacing: 0.3px;
  line-height: 19px;
  opacity: 0.5;
  padding-left: 10px;
  width: 100% !important; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.AccountDetailsPage_chromeframe__3zk9p {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes AccountDetailsPage_slideInTop__2dpR7 {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.AccountDetailsPage_short_hr__1-Fov {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.AccountDetailsPage_App__2p6ec {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .AccountDetailsPage_App__2p6ec.AccountDetailsPage_modal-open__1arvx {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.AccountDetailsPage_checkbox__3ytkZ {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.AccountDetailsPage_checkboxInput__iOcUG {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.AccountDetailsPage_radioInput__3gZEc {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.AccountDetailsPage_checkboxInput__iOcUG:checked ~ .AccountDetailsPage_checkmark__1dg7p {
  background-color: #ff9514; }

.AccountDetailsPage_checkboxInput__iOcUG:checked ~ .AccountDetailsPage_guideCheckmark__3vW81 {
  background-color: #ff9514;
  color: #fff; }

.AccountDetailsPage_radioInput__3gZEc:checked ~ .AccountDetailsPage_radioMark__3gviG {
  background-color: #fff; }

/* Create a custom checkbox */
.AccountDetailsPage_checkmark__1dg7p {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.AccountDetailsPage_guideCheckmark__3vW81 {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.AccountDetailsPage_radioMark__3gviG {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.AccountDetailsPage_checkmark__1dg7p::after {
  content: "";
  display: none;
  position: absolute; }

.AccountDetailsPage_guideCheckmark__3vW81::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.AccountDetailsPage_radioMark__3gviG::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.AccountDetailsPage_checkboxInput__iOcUG:checked ~ .AccountDetailsPage_checkmark__1dg7p::after {
  display: block; }

.AccountDetailsPage_checkboxInput__iOcUG:checked ~ .AccountDetailsPage_guideCheckmark__3vW81::after {
  display: block; }

.AccountDetailsPage_radioInput__3gZEc:checked ~ .AccountDetailsPage_radioMark__3gviG::after {
  display: block; }

/* Style the checkmark/indicator */
.AccountDetailsPage_checkmark__1dg7p::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.AccountDetailsPage_guideCheckmark__3vW81::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.AccountDetailsPage_radioMark__3gviG::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.AccountDetailsPage_loaderSpinner__llNAO {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.AccountDetailsPage_loader__1hj6I {
  animation: AccountDetailsPage_spin__219o2 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes AccountDetailsPage_spin__219o2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.AccountDetailsPage_lazy-load-image-background__2ZcBO {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .AccountDetailsPage_lazy-load-image-background__2ZcBO img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.AccountDetailsPage_lazyImageNP__3IzRe {
  padding-top: 0 !important; }

.AccountDetailsPage_finish_google_survey__1KR1N {
  color: #666;
  text-decoration: none; }

.AccountDetailsPage_finish_google_survey__1KR1N:hover {
  color: #fff; }

.AccountDetailsPage_finish_btn__3BO60 button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.AccountDetailsPage_home-locations__3R5Nj {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .AccountDetailsPage_home-locations__3R5Nj ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .AccountDetailsPage_home-locations__3R5Nj li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .AccountDetailsPage_home-locations__3R5Nj li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .AccountDetailsPage_home-locations__3R5Nj li h3 {
        font-size: 14px; } }
  .AccountDetailsPage_home-locations__3R5Nj li img {
    height: 28px;
    width: 28px; }
  .AccountDetailsPage_home-locations__3R5Nj li .AccountDetailsPage_rightArrow__1fvDS {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.AccountDetailsPage_home-locations__3R5Nj ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .AccountDetailsPage_home-locations__3R5Nj ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .AccountDetailsPage_home-locations__3R5Nj ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .AccountDetailsPage_home-locations__3R5Nj ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .AccountDetailsPage_home-locations__3R5Nj ul li {
      width: 100%; } }

.AccountDetailsPage_location-header__14nf- {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.AccountDetailsPage_location-text__2d3uK p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .AccountDetailsPage_location-text__2d3uK p {
      display: none; } }

.AccountDetailsPage_leftArrow__36Cbi {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AccountDetailsPage_leftArrow__arrow__2IVKq {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.AccountDetailsPage_rightArrow__1fvDS {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AccountDetailsPage_rightArrow__arrow__3COY9 {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.AccountDetailsPage_phone-icon__3u81k {
  max-width: 30px; }

.AccountDetailsPage_search-filter__3i1aJ.AccountDetailsPage_header-filter__1RQ7Z button {
  width: 260px; }
  @media (max-width: 767px) {
    .AccountDetailsPage_search-filter__3i1aJ.AccountDetailsPage_header-filter__1RQ7Z button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_search-filter-bg__2skW0 {
  background: #f1f1f2; }

.AccountDetailsPage_search-filter__3i1aJ {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .AccountDetailsPage_search-filter__select__cOLnY {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .AccountDetailsPage_search-filter__select__dropdown-indicator__137a9 {
      color: #333 !important; }
      .AccountDetailsPage_search-filter__select__dropdown-indicator__137a9 svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .AccountDetailsPage_search-filter__select__clear-indicator__3Sj4i {
      color: #fff !important; }
      .AccountDetailsPage_search-filter__select__clear-indicator__3Sj4i:hover {
        color: #666 !important; }
    .AccountDetailsPage_search-filter__select__placeholder__3yzv2, .AccountDetailsPage_search-filter__select__single-value__1riLn {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .AccountDetailsPage_search-filter__select__placeholder__3yzv2 {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .AccountDetailsPage_search-filter__select__input__1F6NZ input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .AccountDetailsPage_search-filter__select__value-container__2XcXV {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .AccountDetailsPage_search-filter__select__indicator__3aExG {
      padding: 0 !important; }
    .AccountDetailsPage_search-filter__select__indicator-separator__2dEI7 {
      display: none; }
    .AccountDetailsPage_search-filter__select__menu-list__8Ag9I {
      padding: 0 !important; }
    .AccountDetailsPage_search-filter__select__menu__1fuCE {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .AccountDetailsPage_search-filter__select__cOLnY {
        width: 100%; } }
  .AccountDetailsPage_search-filter__destination__37iiU {
    width: 260px; }
  .AccountDetailsPage_search-filter__3i1aJ button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .AccountDetailsPage_search-filter__3i1aJ button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .AccountDetailsPage_search-filter__3i1aJ button:hover {
      border: 1px solid #dedede; }
      .AccountDetailsPage_search-filter__3i1aJ button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .AccountDetailsPage_search-filter__3i1aJ button {
        width: 100%; } }
  @media (max-width: 767px) {
    .AccountDetailsPage_search-filter__destination__37iiU, .AccountDetailsPage_search-filter__select__cOLnY,
    .AccountDetailsPage_search-filter__3i1aJ button {
      margin: 8px 0;
      width: 100%; } }

.AccountDetailsPage_search-filter__select__option__2asvw {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .AccountDetailsPage_search-filter__select__option__2asvw .AccountDetailsPage_sub__1E75T {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .AccountDetailsPage_search-filter__select__option__item__3ZlZZ {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .AccountDetailsPage_search-filter__select__option__item__3ZlZZ span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .AccountDetailsPage_search-filter__select__option__item__3ZlZZ img {
      display: block;
      height: 100%;
      width: 100%; }
  .AccountDetailsPage_search-filter__select__option__2asvw:hover, .AccountDetailsPage_search-filter__select__option__selected__1LYEn {
    background: rgba(255, 149, 20, 0.1); }
    .AccountDetailsPage_search-filter__select__option__2asvw:hover .AccountDetailsPage_sub__1E75T, .AccountDetailsPage_search-filter__select__option__selected__1LYEn .AccountDetailsPage_sub__1E75T {
      color: #ff8c00; }
    .AccountDetailsPage_search-filter__select__option__2asvw:hover.AccountDetailsPage_search-filter__select__option__2asvw:hover span, .AccountDetailsPage_search-filter__select__option__2asvw:hover .AccountDetailsPage_search-filter__select__option__selected__item__3YcFx span,
    .AccountDetailsPage_search-filter__select__option__2asvw:hover .AccountDetailsPage_search-filter__select__option__item__3ZlZZ span, .AccountDetailsPage_search-filter__select__option__selected__1LYEn.AccountDetailsPage_search-filter__select__option__2asvw:hover span, .AccountDetailsPage_search-filter__select__option__selected__1LYEn .AccountDetailsPage_search-filter__select__option__selected__item__3YcFx span,
    .AccountDetailsPage_search-filter__select__option__selected__1LYEn .AccountDetailsPage_search-filter__select__option__item__3ZlZZ span {
      background: #ff8c00; }
      .AccountDetailsPage_search-filter__select__option__2asvw:hover.AccountDetailsPage_search-filter__select__option__2asvw:hover span::before, .AccountDetailsPage_search-filter__select__option__2asvw:hover .AccountDetailsPage_search-filter__select__option__selected__item__3YcFx span::before,
      .AccountDetailsPage_search-filter__select__option__2asvw:hover .AccountDetailsPage_search-filter__select__option__item__3ZlZZ span::before, .AccountDetailsPage_search-filter__select__option__selected__1LYEn.AccountDetailsPage_search-filter__select__option__2asvw:hover span::before, .AccountDetailsPage_search-filter__select__option__selected__1LYEn .AccountDetailsPage_search-filter__select__option__selected__item__3YcFx span::before,
      .AccountDetailsPage_search-filter__select__option__selected__1LYEn .AccountDetailsPage_search-filter__select__option__item__3ZlZZ span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.AccountDetailsPage_search-filter__select__control__3lZHE {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .AccountDetailsPage_search-filter__select__control__3lZHE .AccountDetailsPage_css-1g6gooi__2PYh6 {
    margin: 0 !important;
    padding: 0 !important; }
  .AccountDetailsPage_search-filter__select__control__focused__3YYhz, .AccountDetailsPage_search-filter__select__control__isOpen__3zaeq, .AccountDetailsPage_search-filter__select__control__3lZHE:hover {
    border-color: #ff9514; }
  .AccountDetailsPage_search-filter__select__control__fill__1CgxD {
    background-color: #ff9514;
    border-color: #ff9514; }
    .AccountDetailsPage_search-filter__select__control__fill__1CgxD .AccountDetailsPage_search-filter__select__dropdown-indicator__137a9,
    .AccountDetailsPage_search-filter__select__control__fill__1CgxD .AccountDetailsPage_search-filter__select__single-value__1riLn,
    .AccountDetailsPage_search-filter__select__control__fill__1CgxD .AccountDetailsPage_search-filter__select__placeholder__3yzv2 {
      color: #fff !important; }
    .AccountDetailsPage_search-filter__select__control__fill__1CgxD .AccountDetailsPage_search-filter__select__input__1F6NZ input {
      color: #fff !important; }
  .AccountDetailsPage_search-filter__select__control__isOpen__3zaeq .AccountDetailsPage_search-filter__select__dropdown-indicator__137a9 svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_adventure-about__1SdxB {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AccountDetailsPage_adventure-about__1SdxB {
      padding: 5px 0; } }
  .AccountDetailsPage_adventure-about__container__3OkGS {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AccountDetailsPage_adventure-about__container__3OkGS {
        padding: 0 10px; } }
  .AccountDetailsPage_adventure-about__author__33H1N {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AccountDetailsPage_adventure-about__author__33H1N img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AccountDetailsPage_adventure-about__author__33H1N span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AccountDetailsPage_adventure-about__author__33H1N {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AccountDetailsPage_adventure-about__author__33H1N img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AccountDetailsPage_adventure-about__author__33H1N span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AccountDetailsPage_adventure-about__rate__wD1Sz {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AccountDetailsPage_adventure-about__rate__wD1Sz > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AccountDetailsPage_adventure-about__rate__wD1Sz {
        display: none; } }
  .AccountDetailsPage_adventure-about__buttons__1l2ja {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .AccountDetailsPage_adventure-about__buttons__1l2ja button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .AccountDetailsPage_adventure-about__buttons__1l2ja button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .AccountDetailsPage_adventure-about__buttons__1l2ja button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .AccountDetailsPage_adventure-about__buttons__1l2ja button {
          width: 120px; } }
      @media (max-width: 576px) {
        .AccountDetailsPage_adventure-about__buttons__1l2ja button {
          margin-right: 10px;
          width: 90px; } }
    .AccountDetailsPage_adventure-about__buttons__check-dates__1KvpL {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .AccountDetailsPage_adventure-about__buttons__request__3dIzM {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_guides-footer__3laOM {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .AccountDetailsPage_guides-footer__author__1e9x2 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AccountDetailsPage_guides-footer__author__1e9x2 img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AccountDetailsPage_guides-footer__author__1e9x2 span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AccountDetailsPage_guides-footer__author__1e9x2 {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AccountDetailsPage_guides-footer__author__1e9x2 img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AccountDetailsPage_guides-footer__author__1e9x2 span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AccountDetailsPage_guides-footer__rate__3v8Zq {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AccountDetailsPage_guides-footer__rate__3v8Zq > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AccountDetailsPage_guides-footer__rate__3v8Zq {
        display: none; } }
  .AccountDetailsPage_guides-footer__button__2pEPW {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .AccountDetailsPage_guides-footer__button__2pEPW {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .AccountDetailsPage_guides-footer__button__2pEPW {
        width: 120px; } }
    @media (max-width: 576px) {
      .AccountDetailsPage_guides-footer__button__2pEPW {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_guide-modal__Yampq {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AccountDetailsPage_guide-modal__close__3bGES {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AccountDetailsPage_guide-modal__close__3bGES:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AccountDetailsPage_guide-modal__Yampq {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AccountDetailsPage_guide-modal__Yampq {
      padding: 10px;
      width: 300px; } }

.AccountDetailsPage_guide-modal__header__2bAOc {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .AccountDetailsPage_guide-modal__header__photo__1XmUO {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .AccountDetailsPage_guide-modal__header__photo__1XmUO img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .AccountDetailsPage_guide-modal__header__info__2bmkf {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .AccountDetailsPage_guide-modal__header__info__2bmkf p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .AccountDetailsPage_guide-modal__header__info__2bmkf div {
      display: flex; }
      .AccountDetailsPage_guide-modal__header__info__2bmkf div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .AccountDetailsPage_guide-modal__header__info__2bmkf div span {
            text-align: center; } }
        .AccountDetailsPage_guide-modal__header__info__2bmkf div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .AccountDetailsPage_guide-modal__header__info__2bmkf div span:last-child {
          margin-right: 0; }
          .AccountDetailsPage_guide-modal__header__info__2bmkf div span:last-child::after {
            display: none; }

.AccountDetailsPage_guide-modal__body__2dpNK {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .AccountDetailsPage_guide-modal__body__error__16EuT .AccountDetailsPage_guide-modal__body__item__2xNpt {
    border-color: #f00; }
  .AccountDetailsPage_guide-modal__body__error__16EuT p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AccountDetailsPage_guide-modal__body__item__2xNpt {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .AccountDetailsPage_guide-modal__body__item__2xNpt img,
    .AccountDetailsPage_guide-modal__body__item__2xNpt svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .AccountDetailsPage_guide-modal__body__item__2xNpt input,
    .AccountDetailsPage_guide-modal__body__item__2xNpt textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .AccountDetailsPage_guide-modal__body__item__2xNpt textarea {
      height: 70px;
      resize: none; }
    .AccountDetailsPage_guide-modal__body__item__down__38v4D {
      position: absolute;
      right: 9px; }
  .AccountDetailsPage_guide-modal__body__2dpNK .AccountDetailsPage_react-datepicker-wrapper__3LSmR,
  .AccountDetailsPage_guide-modal__body__2dpNK .AccountDetailsPage_react-datepicker__input-container__1aG32 {
    width: 100%; }
  .AccountDetailsPage_guide-modal__body__2dpNK .AccountDetailsPage_react-datepicker__2-HY7 .AccountDetailsPage_react-datepicker__day__3Q6R6:not(.AccountDetailsPage_react-datepicker__day--disabled__37FXb) {
    background: #fa9923;
    border-radius: 4.8px; }
    .AccountDetailsPage_guide-modal__body__2dpNK .AccountDetailsPage_react-datepicker__2-HY7 .AccountDetailsPage_react-datepicker__day__3Q6R6:not(.AccountDetailsPage_react-datepicker__day--disabled__37FXb):hover {
      color: #fff;
      font-weight: bold; }
  .AccountDetailsPage_guide-modal__body__submit__1o_us {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .AccountDetailsPage_guide-modal__body__submit__1o_us .AccountDetailsPage_loader__1hj6I {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.AccountDetailsPage_guide-modal__footer__LerT0 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .AccountDetailsPage_guide-modal__footer__LerT0 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AccountDetailsPage_guide-modal__footer__LerT0 label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .AccountDetailsPage_guide-modal__footer__LerT0 a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .AccountDetailsPage_guide-modal__footer__LerT0 input {
    left: -9999px;
    position: absolute; }
  .AccountDetailsPage_guide-modal__footer__LerT0 span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .AccountDetailsPage_guide-modal__footer__LerT0 input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_modal-overlay__25QWz {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.AccountDetailsPage_modal-popup__3JrL5 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AccountDetailsPage_modal-popup__close__2HpoI {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AccountDetailsPage_modal-popup__close__2HpoI:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AccountDetailsPage_modal-popup__3JrL5 {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AccountDetailsPage_modal-popup__3JrL5 {
      padding: 10px;
      width: 300px; } }

.AccountDetailsPage_modal-open__1arvx {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .AccountDetailsPage_modal-open__1arvx .AccountDetailsPage_fixed-footer__3t2MB {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.AccountDetailsPage_fixed-footer__3t2MB {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AccountDetailsPage_fixed-footer__3t2MB {
      padding: 5px 0; } }
  .AccountDetailsPage_fixed-footer__container__9SCc_ {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AccountDetailsPage_fixed-footer__container__9SCc_ {
        padding: 0 10px; } }
  .AccountDetailsPage_fixed-footer__3t2MB.AccountDetailsPage_hidden___o7pS {
    bottom: -80px; }

.AccountDetailsPage_wrapper__3FjlO {
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px; }
  @media (min-width: 767px) {
    .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_row__1CYOV {
      margin: 0 auto;
      max-width: 730px;
      padding: 0 15px;
      width: 90%; } }
  .AccountDetailsPage_wrapper__3FjlO h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px; }
  .AccountDetailsPage_wrapper__3FjlO p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form__1VJN3 {
    margin-bottom: 25px;
    position: relative; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_content__36BGN {
    display: flex;
    flex-wrap: wrap; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_fields__2w7cM {
    margin-top: 50px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_avatar__3jI98 {
    margin-right: 30px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_avatar__29RtT {
    border-radius: 50%;
    height: 50px;
    margin-top: 8px;
    width: 50px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_avatar_exp__1is3y {
    margin-left: 20px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_header__37U4S {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 10px 0 4px;
    text-align: left;
    text-transform: uppercase; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_uploadAvatar__bL5E6 {
    background-color: #ff9514;
    border-radius: 6px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 10px 0 4px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    width: 120px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_title__2Drbo {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 0;
    opacity: 0.7; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_button__2m1Oj {
    background: transparent;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-right: 10px;
    min-width: 100px;
    text-transform: uppercase; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_button__2m1Oj:hover {
    background: #fa9923;
    border: 1px solid transparent;
    color: #fff; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_form_group__2XCcs {
    display: block;
    margin-top: 20px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_input__2Lve- {
    width: 74%; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_label__1ovp9 {
    color: #333;
    display: block;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px !important; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_inputBorder__3cRbO {
    border: 1px solid rgba(230, 230, 230, 0.6);
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding-left: 10px;
    width: 100%; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_inputBorderError__2IBLv {
    border: 1px solid rgba(250, 149, 20, 0.8);
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding-left: 10px;
    width: 100%; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_errorText__8d5dk {
    color: #ff9514;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_radio__XdRAk {
    display: inline-block;
    margin-right: 20px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_categoryList__wlUBf {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto 2fr; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_checkbox__3ytkZ {
    margin-bottom: 20px;
    min-width: 80px;
    padding: 0;
    text-align: left; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_managementText__uFuiU {
    margin-top: 20px; }
  .AccountDetailsPage_wrapper__3FjlO .AccountDetailsPage_textArea__3sws- {
    border: 1px solid #ff9514 !important;
    border-radius: 6px;
    height: 88px;
    padding: 10px;
    width: 540px; }

.AccountTabList_title__2y-Du {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 34px;
  height: 46px;
  line-height: 46px; }

.AccountTabList_list__2dk2Y {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px; }
  .AccountTabList_list__2dk2Y .AccountTabList_dot__jvThr {
    margin: 0 5px; }
  .AccountTabList_list__2dk2Y .AccountTabList_active__15ZwK {
    color: #ff930f;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px; }
  .AccountTabList_list__2dk2Y .AccountTabList_general__3lDdi {
    color: #666;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px; }

.userAboutSection .fr-box {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 750px;
  max-width: 740px; }
  .userAboutSection .fr-box .fr-toolbar {
    border-bottom: 1px solid #e6e6e6;
    box-shadow: none;
    display: block; }

.FileUpload_wrapper__2oJuv {
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
  min-height: 96px;
  min-width: 126px;
  text-align: center;
  width: 100%; }
  .FileUpload_wrapper__2oJuv .FileUpload_borderTop__3GFbs {
    background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-repeat: repeat-x;
    background-size: 50px 1px;
    border-radius: 8px;
    height: 96px; }
  .FileUpload_wrapper__2oJuv .FileUpload_borderBottom__1VNBh {
    background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 50px 1px;
    border-radius: 8px;
    height: 100%; }
  .FileUpload_wrapper__2oJuv .FileUpload_borderLeft__2zOF3 {
    background-image: linear-gradient(to bottom, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-repeat: repeat-y;
    background-size: 1px 30px;
    border-radius: 8px;
    height: 100%; }
    .FileUpload_wrapper__2oJuv .FileUpload_borderLeft__2zOF3 section {
      height: 100%; }
  .FileUpload_wrapper__2oJuv .FileUpload_borderRight__2w4T7 {
    background-image: linear-gradient(to top, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-repeat: repeat-y;
    background-size: 1px 30px;
    border-radius: 8px;
    height: 100%; }
  .FileUpload_wrapper__2oJuv .FileUpload_uploader__3lLZo {
    height: 30px;
    margin-top: 20px;
    width: 30px; }
  .FileUpload_wrapper__2oJuv .FileUpload_title__2Tcp4 {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    opacity: 0.7;
    padding-bottom: 20px; }

.SingleDatePicker_picker {
  left: -10px !important;
  position: absolute;
  top: 40px !important; }

.DayPickerKeyboardShortcuts_buttonReset {
  display: none; }

.Requirements_section__2PsuS {
  margin-top: 40px; }
  .Requirements_section__2PsuS .Requirements_sectionHeader__35P89 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0.3px;
    line-height: 19px; }
  .Requirements_section__2PsuS .Requirements_sectionTitle__1Qh-T {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    width: 540px; }
  .Requirements_section__2PsuS .Requirements_sectionContent__3SRcF {
    margin-top: 8px; }
  .Requirements_section__2PsuS .Requirements_radio__19oBy {
    display: inline-block;
    margin-right: 20px; }
  .Requirements_section__2PsuS .Requirements_inputs__2F3Rl {
    display: flex;
    flex: 2 1;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; }
  .Requirements_section__2PsuS .Requirements_date__19u7U {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    display: grid;
    flex: 1 1 48%;
    font-size: 15px;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
    height: 50px;
    margin-right: 2%;
    margin-top: 20px;
    padding: 11px;
    position: relative; }
    .Requirements_section__2PsuS .Requirements_date__19u7U ::-webkit-input-placeholder {
      color: #333; }
    .Requirements_section__2PsuS .Requirements_date__19u7U ::placeholder {
      color: #333; }
    .Requirements_section__2PsuS .Requirements_date__19u7U input {
      background: transparent;
      border: 0;
      color: #333;
      text-align: left; }
    .Requirements_section__2PsuS .Requirements_date__19u7U svg {
      fill: #333;
      height: 16px;
      width: 16px; }
  .Requirements_section__2PsuS .Requirements_company__e3v4D {
    flex: 1 1 48%; }
  .Requirements_section__2PsuS a {
    color: #ff9514 !important;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px; }

.Requirements_noData__2fTeu {
  width: 100%; }

.Requirements_form_item__3Xuwr {
  align-items: center;
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 98px;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 126px;
  min-width: 126px;
  position: relative;
  text-align: center; }

/*  Close Icon  */
.Requirements_close__hjINN {
  background-color: #666;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0%;
  transform: translate(25%, -50%);
  width: 24px; }

.Requirements_close__hjINN:hover {
  opacity: 1; }

.Requirements_close__hjINN::before {
  background-color: #fff;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px; }

.Requirements_close__hjINN::after {
  background-color: #fff;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px; }

.Requirements_close__hjINN::before {
  transform: rotate(45deg); }

.Requirements_close__hjINN::after {
  transform: rotate(-45deg); }

.RequireSignUp_wrapper__BYvMp {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }
  .RequireSignUp_wrapper__BYvMp .RequireSignUp_row__2p8gu {
    background: #fff;
    border-radius: 5px;
    left: 50%;
    max-width: 450px;
    padding: 29px 17px;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }
    @media (max-width: 768px) {
      .RequireSignUp_wrapper__BYvMp .RequireSignUp_row__2p8gu {
        left: 50%;
        margin-top: 0;
        min-width: 0;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 10; } }
  .RequireSignUp_wrapper__BYvMp h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0; }
    @media (max-width: 768px) {
      .RequireSignUp_wrapper__BYvMp h2 {
        font-size: 24px !important; } }
  .RequireSignUp_wrapper__BYvMp p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px; }
  .RequireSignUp_wrapper__BYvMp .RequireSignUp_buttons__39CyC {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .RequireSignUp_wrapper__BYvMp .RequireSignUp_button__1BKgK {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-right: 20px;
    text-transform: uppercase;
    width: 100px; }
  .RequireSignUp_wrapper__BYvMp .RequireSignUp_signUp__1CDre {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    width: 100px; }
    .RequireSignUp_wrapper__BYvMp .RequireSignUp_signUp__1CDre svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      width: 18px; }
  .RequireSignUp_wrapper__BYvMp .RequireSignUp_exitIcon__cNh1v {
    height: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.NewGuidePage_chromeframe__3Po2N {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes NewGuidePage_slideInTop__2Xk3N {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.NewGuidePage_short_hr__3Gkgz {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.NewGuidePage_App__2JZjy {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .NewGuidePage_App__2JZjy.NewGuidePage_modal-open__iEpBR {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.NewGuidePage_checkbox__3u2nt {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.NewGuidePage_checkboxInput__14Jzv {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.NewGuidePage_radioInput__G6Gyc {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.NewGuidePage_checkboxInput__14Jzv:checked ~ .NewGuidePage_checkmark__3M6Iv {
  background-color: #ff9514; }

.NewGuidePage_checkboxInput__14Jzv:checked ~ .NewGuidePage_guideCheckmark__1FD0H {
  background-color: #ff9514;
  color: #fff; }

.NewGuidePage_radioInput__G6Gyc:checked ~ .NewGuidePage_radioMark__29aIW {
  background-color: #fff; }

/* Create a custom checkbox */
.NewGuidePage_checkmark__3M6Iv {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.NewGuidePage_guideCheckmark__1FD0H {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.NewGuidePage_radioMark__29aIW {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.NewGuidePage_checkmark__3M6Iv::after {
  content: "";
  display: none;
  position: absolute; }

.NewGuidePage_guideCheckmark__1FD0H::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.NewGuidePage_radioMark__29aIW::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.NewGuidePage_checkboxInput__14Jzv:checked ~ .NewGuidePage_checkmark__3M6Iv::after {
  display: block; }

.NewGuidePage_checkboxInput__14Jzv:checked ~ .NewGuidePage_guideCheckmark__1FD0H::after {
  display: block; }

.NewGuidePage_radioInput__G6Gyc:checked ~ .NewGuidePage_radioMark__29aIW::after {
  display: block; }

/* Style the checkmark/indicator */
.NewGuidePage_checkmark__3M6Iv::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.NewGuidePage_guideCheckmark__1FD0H::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.NewGuidePage_radioMark__29aIW::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.NewGuidePage_loaderSpinner__3yOtW {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.NewGuidePage_loader__3fUpG {
  animation: NewGuidePage_spin__2MPCQ 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes NewGuidePage_spin__2MPCQ {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.NewGuidePage_lazy-load-image-background__1VRV4 {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .NewGuidePage_lazy-load-image-background__1VRV4 img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.NewGuidePage_lazyImageNP__lhxny {
  padding-top: 0 !important; }

.NewGuidePage_finish_google_survey__3khKX {
  color: #666;
  text-decoration: none; }

.NewGuidePage_finish_google_survey__3khKX:hover {
  color: #fff; }

.NewGuidePage_finish_btn__37DaM button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.NewGuidePage_home-locations__2hgSl {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .NewGuidePage_home-locations__2hgSl ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .NewGuidePage_home-locations__2hgSl li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .NewGuidePage_home-locations__2hgSl li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .NewGuidePage_home-locations__2hgSl li h3 {
        font-size: 14px; } }
  .NewGuidePage_home-locations__2hgSl li img {
    height: 28px;
    width: 28px; }
  .NewGuidePage_home-locations__2hgSl li .NewGuidePage_rightArrow__1Esnc {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.NewGuidePage_home-locations__2hgSl ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .NewGuidePage_home-locations__2hgSl ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .NewGuidePage_home-locations__2hgSl ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .NewGuidePage_home-locations__2hgSl ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .NewGuidePage_home-locations__2hgSl ul li {
      width: 100%; } }

.NewGuidePage_location-header__1P-yU {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.NewGuidePage_location-text__25jrs p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .NewGuidePage_location-text__25jrs p {
      display: none; } }

.NewGuidePage_leftArrow__28L9m {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .NewGuidePage_leftArrow__arrow__1O-Zi {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.NewGuidePage_rightArrow__1Esnc {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .NewGuidePage_rightArrow__arrow__1tBcp {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.NewGuidePage_phone-icon__10BDe {
  max-width: 30px; }

.NewGuidePage_search-filter__14obK.NewGuidePage_header-filter__2euQp button {
  width: 260px; }
  @media (max-width: 767px) {
    .NewGuidePage_search-filter__14obK.NewGuidePage_header-filter__2euQp button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_search-filter-bg__3XqtI {
  background: #f1f1f2; }

.NewGuidePage_search-filter__14obK {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .NewGuidePage_search-filter__select__1i7QY {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .NewGuidePage_search-filter__select__dropdown-indicator__2ld_m {
      color: #333 !important; }
      .NewGuidePage_search-filter__select__dropdown-indicator__2ld_m svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .NewGuidePage_search-filter__select__clear-indicator__1NSf4 {
      color: #fff !important; }
      .NewGuidePage_search-filter__select__clear-indicator__1NSf4:hover {
        color: #666 !important; }
    .NewGuidePage_search-filter__select__placeholder__1OSVO, .NewGuidePage_search-filter__select__single-value__2Oa9L {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .NewGuidePage_search-filter__select__placeholder__1OSVO {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .NewGuidePage_search-filter__select__input__3807- input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .NewGuidePage_search-filter__select__value-container__3FmAE {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .NewGuidePage_search-filter__select__indicator__rzmR1 {
      padding: 0 !important; }
    .NewGuidePage_search-filter__select__indicator-separator__2j-Gp {
      display: none; }
    .NewGuidePage_search-filter__select__menu-list__2Yve_ {
      padding: 0 !important; }
    .NewGuidePage_search-filter__select__menu__2Cx1C {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .NewGuidePage_search-filter__select__1i7QY {
        width: 100%; } }
  .NewGuidePage_search-filter__destination__mcrcr {
    width: 260px; }
  .NewGuidePage_search-filter__14obK button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .NewGuidePage_search-filter__14obK button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .NewGuidePage_search-filter__14obK button:hover {
      border: 1px solid #dedede; }
      .NewGuidePage_search-filter__14obK button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .NewGuidePage_search-filter__14obK button {
        width: 100%; } }
  @media (max-width: 767px) {
    .NewGuidePage_search-filter__destination__mcrcr, .NewGuidePage_search-filter__select__1i7QY,
    .NewGuidePage_search-filter__14obK button {
      margin: 8px 0;
      width: 100%; } }

.NewGuidePage_search-filter__select__option__59iUs {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .NewGuidePage_search-filter__select__option__59iUs .NewGuidePage_sub__3Tfk0 {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .NewGuidePage_search-filter__select__option__item__1AmEW {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .NewGuidePage_search-filter__select__option__item__1AmEW span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .NewGuidePage_search-filter__select__option__item__1AmEW img {
      display: block;
      height: 100%;
      width: 100%; }
  .NewGuidePage_search-filter__select__option__59iUs:hover, .NewGuidePage_search-filter__select__option__selected__10uuS {
    background: rgba(255, 149, 20, 0.1); }
    .NewGuidePage_search-filter__select__option__59iUs:hover .NewGuidePage_sub__3Tfk0, .NewGuidePage_search-filter__select__option__selected__10uuS .NewGuidePage_sub__3Tfk0 {
      color: #ff8c00; }
    .NewGuidePage_search-filter__select__option__59iUs:hover.NewGuidePage_search-filter__select__option__59iUs:hover span, .NewGuidePage_search-filter__select__option__59iUs:hover .NewGuidePage_search-filter__select__option__selected__item__1plGX span,
    .NewGuidePage_search-filter__select__option__59iUs:hover .NewGuidePage_search-filter__select__option__item__1AmEW span, .NewGuidePage_search-filter__select__option__selected__10uuS.NewGuidePage_search-filter__select__option__59iUs:hover span, .NewGuidePage_search-filter__select__option__selected__10uuS .NewGuidePage_search-filter__select__option__selected__item__1plGX span,
    .NewGuidePage_search-filter__select__option__selected__10uuS .NewGuidePage_search-filter__select__option__item__1AmEW span {
      background: #ff8c00; }
      .NewGuidePage_search-filter__select__option__59iUs:hover.NewGuidePage_search-filter__select__option__59iUs:hover span::before, .NewGuidePage_search-filter__select__option__59iUs:hover .NewGuidePage_search-filter__select__option__selected__item__1plGX span::before,
      .NewGuidePage_search-filter__select__option__59iUs:hover .NewGuidePage_search-filter__select__option__item__1AmEW span::before, .NewGuidePage_search-filter__select__option__selected__10uuS.NewGuidePage_search-filter__select__option__59iUs:hover span::before, .NewGuidePage_search-filter__select__option__selected__10uuS .NewGuidePage_search-filter__select__option__selected__item__1plGX span::before,
      .NewGuidePage_search-filter__select__option__selected__10uuS .NewGuidePage_search-filter__select__option__item__1AmEW span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.NewGuidePage_search-filter__select__control__znAo1 {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .NewGuidePage_search-filter__select__control__znAo1 .NewGuidePage_css-1g6gooi__7PBRO {
    margin: 0 !important;
    padding: 0 !important; }
  .NewGuidePage_search-filter__select__control__focused__Aiv3u, .NewGuidePage_search-filter__select__control__isOpen__16OdT, .NewGuidePage_search-filter__select__control__znAo1:hover {
    border-color: #ff9514; }
  .NewGuidePage_search-filter__select__control__fill__-LhEq {
    background-color: #ff9514;
    border-color: #ff9514; }
    .NewGuidePage_search-filter__select__control__fill__-LhEq .NewGuidePage_search-filter__select__dropdown-indicator__2ld_m,
    .NewGuidePage_search-filter__select__control__fill__-LhEq .NewGuidePage_search-filter__select__single-value__2Oa9L,
    .NewGuidePage_search-filter__select__control__fill__-LhEq .NewGuidePage_search-filter__select__placeholder__1OSVO {
      color: #fff !important; }
    .NewGuidePage_search-filter__select__control__fill__-LhEq .NewGuidePage_search-filter__select__input__3807- input {
      color: #fff !important; }
  .NewGuidePage_search-filter__select__control__isOpen__16OdT .NewGuidePage_search-filter__select__dropdown-indicator__2ld_m svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_adventure-about__1Neb4 {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .NewGuidePage_adventure-about__1Neb4 {
      padding: 5px 0; } }
  .NewGuidePage_adventure-about__container__lT1n2 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .NewGuidePage_adventure-about__container__lT1n2 {
        padding: 0 10px; } }
  .NewGuidePage_adventure-about__author__3olF- {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .NewGuidePage_adventure-about__author__3olF- img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .NewGuidePage_adventure-about__author__3olF- span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .NewGuidePage_adventure-about__author__3olF- {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .NewGuidePage_adventure-about__author__3olF- img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .NewGuidePage_adventure-about__author__3olF- span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .NewGuidePage_adventure-about__rate__360mt {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .NewGuidePage_adventure-about__rate__360mt > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .NewGuidePage_adventure-about__rate__360mt {
        display: none; } }
  .NewGuidePage_adventure-about__buttons__3A4qU {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .NewGuidePage_adventure-about__buttons__3A4qU button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .NewGuidePage_adventure-about__buttons__3A4qU button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .NewGuidePage_adventure-about__buttons__3A4qU button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .NewGuidePage_adventure-about__buttons__3A4qU button {
          width: 120px; } }
      @media (max-width: 576px) {
        .NewGuidePage_adventure-about__buttons__3A4qU button {
          margin-right: 10px;
          width: 90px; } }
    .NewGuidePage_adventure-about__buttons__check-dates__1EG_o {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .NewGuidePage_adventure-about__buttons__request__xraKJ {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_guides-footer__3k4MT {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .NewGuidePage_guides-footer__author__2at_y {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .NewGuidePage_guides-footer__author__2at_y img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .NewGuidePage_guides-footer__author__2at_y span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .NewGuidePage_guides-footer__author__2at_y {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .NewGuidePage_guides-footer__author__2at_y img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .NewGuidePage_guides-footer__author__2at_y span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .NewGuidePage_guides-footer__rate__3DIs4 {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .NewGuidePage_guides-footer__rate__3DIs4 > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .NewGuidePage_guides-footer__rate__3DIs4 {
        display: none; } }
  .NewGuidePage_guides-footer__button__1H3zX {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .NewGuidePage_guides-footer__button__1H3zX {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .NewGuidePage_guides-footer__button__1H3zX {
        width: 120px; } }
    @media (max-width: 576px) {
      .NewGuidePage_guides-footer__button__1H3zX {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_guide-modal__3BNQD {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .NewGuidePage_guide-modal__close__xshqj {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .NewGuidePage_guide-modal__close__xshqj:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .NewGuidePage_guide-modal__3BNQD {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .NewGuidePage_guide-modal__3BNQD {
      padding: 10px;
      width: 300px; } }

.NewGuidePage_guide-modal__header__6tv6T {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .NewGuidePage_guide-modal__header__photo__20YvL {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .NewGuidePage_guide-modal__header__photo__20YvL img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .NewGuidePage_guide-modal__header__info__2BMdT {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .NewGuidePage_guide-modal__header__info__2BMdT p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .NewGuidePage_guide-modal__header__info__2BMdT div {
      display: flex; }
      .NewGuidePage_guide-modal__header__info__2BMdT div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .NewGuidePage_guide-modal__header__info__2BMdT div span {
            text-align: center; } }
        .NewGuidePage_guide-modal__header__info__2BMdT div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .NewGuidePage_guide-modal__header__info__2BMdT div span:last-child {
          margin-right: 0; }
          .NewGuidePage_guide-modal__header__info__2BMdT div span:last-child::after {
            display: none; }

.NewGuidePage_guide-modal__body__Zx-6t {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .NewGuidePage_guide-modal__body__error__2wvU_ .NewGuidePage_guide-modal__body__item__2dYUt {
    border-color: #f00; }
  .NewGuidePage_guide-modal__body__error__2wvU_ p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .NewGuidePage_guide-modal__body__item__2dYUt {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .NewGuidePage_guide-modal__body__item__2dYUt img,
    .NewGuidePage_guide-modal__body__item__2dYUt svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .NewGuidePage_guide-modal__body__item__2dYUt input,
    .NewGuidePage_guide-modal__body__item__2dYUt textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .NewGuidePage_guide-modal__body__item__2dYUt textarea {
      height: 70px;
      resize: none; }
    .NewGuidePage_guide-modal__body__item__down__3Ybjk {
      position: absolute;
      right: 9px; }
  .NewGuidePage_guide-modal__body__Zx-6t .NewGuidePage_react-datepicker-wrapper__28dWl,
  .NewGuidePage_guide-modal__body__Zx-6t .NewGuidePage_react-datepicker__input-container__6eRfE {
    width: 100%; }
  .NewGuidePage_guide-modal__body__Zx-6t .NewGuidePage_react-datepicker__1Li28 .NewGuidePage_react-datepicker__day__68nHK:not(.NewGuidePage_react-datepicker__day--disabled__1jv-x) {
    background: #fa9923;
    border-radius: 4.8px; }
    .NewGuidePage_guide-modal__body__Zx-6t .NewGuidePage_react-datepicker__1Li28 .NewGuidePage_react-datepicker__day__68nHK:not(.NewGuidePage_react-datepicker__day--disabled__1jv-x):hover {
      color: #fff;
      font-weight: bold; }
  .NewGuidePage_guide-modal__body__submit__11C_n {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .NewGuidePage_guide-modal__body__submit__11C_n .NewGuidePage_loader__3fUpG {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.NewGuidePage_guide-modal__footer__1-HFA {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .NewGuidePage_guide-modal__footer__1-HFA p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .NewGuidePage_guide-modal__footer__1-HFA label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .NewGuidePage_guide-modal__footer__1-HFA a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .NewGuidePage_guide-modal__footer__1-HFA input {
    left: -9999px;
    position: absolute; }
  .NewGuidePage_guide-modal__footer__1-HFA span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .NewGuidePage_guide-modal__footer__1-HFA input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_modal-overlay__3yPIW {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.NewGuidePage_modal-popup__1zYtg {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .NewGuidePage_modal-popup__close__1eED7 {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .NewGuidePage_modal-popup__close__1eED7:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .NewGuidePage_modal-popup__1zYtg {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .NewGuidePage_modal-popup__1zYtg {
      padding: 10px;
      width: 300px; } }

.NewGuidePage_modal-open__iEpBR {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .NewGuidePage_modal-open__iEpBR .NewGuidePage_fixed-footer__1Qonq {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.NewGuidePage_fixed-footer__1Qonq {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .NewGuidePage_fixed-footer__1Qonq {
      padding: 5px 0; } }
  .NewGuidePage_fixed-footer__container__nvMa1 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .NewGuidePage_fixed-footer__container__nvMa1 {
        padding: 0 10px; } }
  .NewGuidePage_fixed-footer__1Qonq.NewGuidePage_hidden__DvBvl {
    bottom: -80px; }

.NewGuidePage_wrapper__3XbMk {
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px; }
  @media (min-width: 767px) {
    .NewGuidePage_wrapper__3XbMk .NewGuidePage_row__3U2IK {
      margin: 0 auto;
      max-width: 730px;
      padding: 0 15px;
      width: 120%; } }
  .NewGuidePage_wrapper__3XbMk h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px; }
  .NewGuidePage_wrapper__3XbMk p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin-top: 10px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form__1TS9U {
    margin-bottom: 25px;
    position: relative; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_content__2DoqU {
    display: flex;
    flex-wrap: wrap; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_avatar__1fLJ- {
    margin-right: 30px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_avatar__24gdl {
    border-radius: 50%;
    height: 50px;
    margin-top: 8px;
    width: 50px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_avatar_exp__2-Nzz {
    margin-left: 20px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_header__tilPs {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 10px 0 4px;
    text-align: left;
    text-transform: uppercase; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_title__yNiLT {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 0;
    opacity: 0.7; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_button__3KEgv {
    background: transparent;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-right: 10px;
    min-width: 100px;
    text-transform: uppercase; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_button__3KEgv:hover {
    background: #fa9923;
    border: 1px solid transparent;
    color: #fff; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_form_group__8mUJv {
    display: block;
    margin-top: 20px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_input__1-OXE {
    width: 74%; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_label__3qviP {
    color: #333;
    display: block;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_inputBorder__XFCWf {
    border: 1px solid rgba(230, 230, 230, 0.6);
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding-left: 10px;
    width: 100%; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_inputBorderError__1k0Nd {
    border: 1px solid rgba(250, 149, 20, 0.8);
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding-left: 10px;
    width: 100%; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_errorText__1Xn2N {
    color: #ff9514;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px; }
  .NewGuidePage_wrapper__3XbMk .NewGuidePage_circles__1vT1Z {
    display: flex;
    flex-direction: row; }
    .NewGuidePage_wrapper__3XbMk .NewGuidePage_circles__1vT1Z .NewGuidePage_circle__3jIgN {
      background-color: #e6e6e6;
      border-radius: 50%;
      height: 10px;
      margin-right: 8px;
      width: 10px; }
    .NewGuidePage_wrapper__3XbMk .NewGuidePage_circles__1vT1Z .NewGuidePage_circleActive__3To7j {
      background-color: #ff9514;
      border-radius: 50%;
      height: 10px;
      margin-right: 8px;
      width: 10px; }

.IntroduceForGuide_wrapper__3H-sw {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 767px) {
    .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_row__2bMzY {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%; } }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_guides_wrapper__2iZ6k {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16.5px;
    margin-top: 58.5px;
    position: relative; }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_face__3pdY3 {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-left: -10px !important;
    position: relative;
    width: 48px;
    z-index: 1; }
    .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_face__3pdY3:first-child {
      margin-left: 0; }
    .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_face__3pdY3 img {
      border: 2px solid #fff;
      border-radius: 50%;
      height: 48px;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 48px; }
  .IntroduceForGuide_wrapper__3H-sw h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    text-align: center; }
  .IntroduceForGuide_wrapper__3H-sw p {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 16px;
    letter-spacing: 0.11px;
    line-height: 28px;
    text-align: center; }
  .IntroduceForGuide_wrapper__3H-sw a {
    text-decoration: none; }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_startQuestion__1XrEH {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    line-height: 28px;
    margin-left: 15px;
    text-align: left; }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_instruction_step__3OT_s {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
    margin-left: 15px;
    text-align: left; }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_instruction__1momp {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
    margin-left: 15px;
    position: relative;
    text-align: left; }
  .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_become_guide_button__7mQuP {
    align-items: center;
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    letter-spacing: 0.25px;
    margin: 4px;
    margin: auto;
    padding: 14px;
    text-transform: uppercase;
    width: 183px; }
    .IntroduceForGuide_wrapper__3H-sw .IntroduceForGuide_become_guide_button__7mQuP svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      width: 18px; }

.Certification_section__1yS0- {
  margin-top: 20px; }
  .Certification_section__1yS0- .Certification_youtube__3tT7u {
    align-items: center;
    background-color: #f8f8f9;
    border-radius: 8px;
    color: #666;
    display: flex;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 401px;
    justify-content: center;
    line-height: 28px;
    max-width: 730px; }
  .Certification_section__1yS0- .Certification_agree__1B6eb {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 18px; }
  .Certification_section__1yS0- span {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.AdventuresPage_chromeframe__1N7rI {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes AdventuresPage_slideInTop__3fKRe {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.AdventuresPage_short_hr__3qdsS {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.AdventuresPage_App__27pb1 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .AdventuresPage_App__27pb1.AdventuresPage_modal-open__qM8v_ {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.AdventuresPage_checkbox__3AD9q {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.AdventuresPage_checkboxInput__11JUY {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.AdventuresPage_radioInput__iJ5mo {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.AdventuresPage_checkboxInput__11JUY:checked ~ .AdventuresPage_checkmark__4uFTH {
  background-color: #ff9514; }

.AdventuresPage_checkboxInput__11JUY:checked ~ .AdventuresPage_guideCheckmark__EGWXD {
  background-color: #ff9514;
  color: #fff; }

.AdventuresPage_radioInput__iJ5mo:checked ~ .AdventuresPage_radioMark__3HyKE {
  background-color: #fff; }

/* Create a custom checkbox */
.AdventuresPage_checkmark__4uFTH {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.AdventuresPage_guideCheckmark__EGWXD {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.AdventuresPage_radioMark__3HyKE {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.AdventuresPage_checkmark__4uFTH::after {
  content: "";
  display: none;
  position: absolute; }

.AdventuresPage_guideCheckmark__EGWXD::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.AdventuresPage_radioMark__3HyKE::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.AdventuresPage_checkboxInput__11JUY:checked ~ .AdventuresPage_checkmark__4uFTH::after {
  display: block; }

.AdventuresPage_checkboxInput__11JUY:checked ~ .AdventuresPage_guideCheckmark__EGWXD::after {
  display: block; }

.AdventuresPage_radioInput__iJ5mo:checked ~ .AdventuresPage_radioMark__3HyKE::after {
  display: block; }

/* Style the checkmark/indicator */
.AdventuresPage_checkmark__4uFTH::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.AdventuresPage_guideCheckmark__EGWXD::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.AdventuresPage_radioMark__3HyKE::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.AdventuresPage_loaderSpinner__1a88G {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.AdventuresPage_loader__uwfr9 {
  animation: AdventuresPage_spin__2SP_E 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes AdventuresPage_spin__2SP_E {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.AdventuresPage_lazy-load-image-background__3-YI5 {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .AdventuresPage_lazy-load-image-background__3-YI5 img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.AdventuresPage_lazyImageNP__2oWYK {
  padding-top: 0 !important; }

.AdventuresPage_finish_google_survey__2eFxK {
  color: #666;
  text-decoration: none; }

.AdventuresPage_finish_google_survey__2eFxK:hover {
  color: #fff; }

.AdventuresPage_finish_btn__1vGzQ button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.AdventuresPage_home-locations__RebFO {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .AdventuresPage_home-locations__RebFO ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .AdventuresPage_home-locations__RebFO li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .AdventuresPage_home-locations__RebFO li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .AdventuresPage_home-locations__RebFO li h3 {
        font-size: 14px; } }
  .AdventuresPage_home-locations__RebFO li img {
    height: 28px;
    width: 28px; }
  .AdventuresPage_home-locations__RebFO li .AdventuresPage_rightArrow__2jmt6 {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.AdventuresPage_home-locations__RebFO ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .AdventuresPage_home-locations__RebFO ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .AdventuresPage_home-locations__RebFO ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .AdventuresPage_home-locations__RebFO ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .AdventuresPage_home-locations__RebFO ul li {
      width: 100%; } }

.AdventuresPage_location-header__3x8sO {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.AdventuresPage_location-text__2FIJI p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .AdventuresPage_location-text__2FIJI p {
      display: none; } }

.AdventuresPage_leftArrow__3B-3s {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AdventuresPage_leftArrow__arrow__2QHEW {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.AdventuresPage_rightArrow__2jmt6 {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AdventuresPage_rightArrow__arrow__1ZrLC {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.AdventuresPage_phone-icon__35xzM {
  max-width: 30px; }

.AdventuresPage_search-filter__11V5H.AdventuresPage_header-filter__1bF3p button {
  width: 260px; }
  @media (max-width: 767px) {
    .AdventuresPage_search-filter__11V5H.AdventuresPage_header-filter__1bF3p button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_search-filter-bg__1vVkU {
  background: #f1f1f2; }

.AdventuresPage_search-filter__11V5H {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .AdventuresPage_search-filter__select__1Kn0O {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .AdventuresPage_search-filter__select__dropdown-indicator__3Ot7F {
      color: #333 !important; }
      .AdventuresPage_search-filter__select__dropdown-indicator__3Ot7F svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .AdventuresPage_search-filter__select__clear-indicator__1OPT9 {
      color: #fff !important; }
      .AdventuresPage_search-filter__select__clear-indicator__1OPT9:hover {
        color: #666 !important; }
    .AdventuresPage_search-filter__select__placeholder__CEMV_, .AdventuresPage_search-filter__select__single-value___OLFQ {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .AdventuresPage_search-filter__select__placeholder__CEMV_ {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .AdventuresPage_search-filter__select__input__1Z5O1 input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .AdventuresPage_search-filter__select__value-container__1UDWQ {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .AdventuresPage_search-filter__select__indicator__2aEg9 {
      padding: 0 !important; }
    .AdventuresPage_search-filter__select__indicator-separator__37L8c {
      display: none; }
    .AdventuresPage_search-filter__select__menu-list__2V9tA {
      padding: 0 !important; }
    .AdventuresPage_search-filter__select__menu__1kDe4 {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .AdventuresPage_search-filter__select__1Kn0O {
        width: 100%; } }
  .AdventuresPage_search-filter__destination__V2hyq {
    width: 260px; }
  .AdventuresPage_search-filter__11V5H button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .AdventuresPage_search-filter__11V5H button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .AdventuresPage_search-filter__11V5H button:hover {
      border: 1px solid #dedede; }
      .AdventuresPage_search-filter__11V5H button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .AdventuresPage_search-filter__11V5H button {
        width: 100%; } }
  @media (max-width: 767px) {
    .AdventuresPage_search-filter__destination__V2hyq, .AdventuresPage_search-filter__select__1Kn0O,
    .AdventuresPage_search-filter__11V5H button {
      margin: 8px 0;
      width: 100%; } }

.AdventuresPage_search-filter__select__option__3tYp3 {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .AdventuresPage_search-filter__select__option__3tYp3 .AdventuresPage_sub__2qbyf {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .AdventuresPage_search-filter__select__option__item__2F70f {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .AdventuresPage_search-filter__select__option__item__2F70f span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .AdventuresPage_search-filter__select__option__item__2F70f img {
      display: block;
      height: 100%;
      width: 100%; }
  .AdventuresPage_search-filter__select__option__3tYp3:hover, .AdventuresPage_search-filter__select__option__selected__1Y-1P {
    background: rgba(255, 149, 20, 0.1); }
    .AdventuresPage_search-filter__select__option__3tYp3:hover .AdventuresPage_sub__2qbyf, .AdventuresPage_search-filter__select__option__selected__1Y-1P .AdventuresPage_sub__2qbyf {
      color: #ff8c00; }
    .AdventuresPage_search-filter__select__option__3tYp3:hover.AdventuresPage_search-filter__select__option__3tYp3:hover span, .AdventuresPage_search-filter__select__option__3tYp3:hover .AdventuresPage_search-filter__select__option__selected__item__3LsCv span,
    .AdventuresPage_search-filter__select__option__3tYp3:hover .AdventuresPage_search-filter__select__option__item__2F70f span, .AdventuresPage_search-filter__select__option__selected__1Y-1P.AdventuresPage_search-filter__select__option__3tYp3:hover span, .AdventuresPage_search-filter__select__option__selected__1Y-1P .AdventuresPage_search-filter__select__option__selected__item__3LsCv span,
    .AdventuresPage_search-filter__select__option__selected__1Y-1P .AdventuresPage_search-filter__select__option__item__2F70f span {
      background: #ff8c00; }
      .AdventuresPage_search-filter__select__option__3tYp3:hover.AdventuresPage_search-filter__select__option__3tYp3:hover span::before, .AdventuresPage_search-filter__select__option__3tYp3:hover .AdventuresPage_search-filter__select__option__selected__item__3LsCv span::before,
      .AdventuresPage_search-filter__select__option__3tYp3:hover .AdventuresPage_search-filter__select__option__item__2F70f span::before, .AdventuresPage_search-filter__select__option__selected__1Y-1P.AdventuresPage_search-filter__select__option__3tYp3:hover span::before, .AdventuresPage_search-filter__select__option__selected__1Y-1P .AdventuresPage_search-filter__select__option__selected__item__3LsCv span::before,
      .AdventuresPage_search-filter__select__option__selected__1Y-1P .AdventuresPage_search-filter__select__option__item__2F70f span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.AdventuresPage_search-filter__select__control__4WUhs {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .AdventuresPage_search-filter__select__control__4WUhs .AdventuresPage_css-1g6gooi__2IzY_ {
    margin: 0 !important;
    padding: 0 !important; }
  .AdventuresPage_search-filter__select__control__focused__26DXj, .AdventuresPage_search-filter__select__control__isOpen__3wOko, .AdventuresPage_search-filter__select__control__4WUhs:hover {
    border-color: #ff9514; }
  .AdventuresPage_search-filter__select__control__fill__kp69p {
    background-color: #ff9514;
    border-color: #ff9514; }
    .AdventuresPage_search-filter__select__control__fill__kp69p .AdventuresPage_search-filter__select__dropdown-indicator__3Ot7F,
    .AdventuresPage_search-filter__select__control__fill__kp69p .AdventuresPage_search-filter__select__single-value___OLFQ,
    .AdventuresPage_search-filter__select__control__fill__kp69p .AdventuresPage_search-filter__select__placeholder__CEMV_ {
      color: #fff !important; }
    .AdventuresPage_search-filter__select__control__fill__kp69p .AdventuresPage_search-filter__select__input__1Z5O1 input {
      color: #fff !important; }
  .AdventuresPage_search-filter__select__control__isOpen__3wOko .AdventuresPage_search-filter__select__dropdown-indicator__3Ot7F svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_adventure-about__3jQnK {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AdventuresPage_adventure-about__3jQnK {
      padding: 5px 0; } }
  .AdventuresPage_adventure-about__container__3rFLx {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AdventuresPage_adventure-about__container__3rFLx {
        padding: 0 10px; } }
  .AdventuresPage_adventure-about__author__MdeiY {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AdventuresPage_adventure-about__author__MdeiY img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AdventuresPage_adventure-about__author__MdeiY span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AdventuresPage_adventure-about__author__MdeiY {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AdventuresPage_adventure-about__author__MdeiY img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AdventuresPage_adventure-about__author__MdeiY span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AdventuresPage_adventure-about__rate__3cvsd {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AdventuresPage_adventure-about__rate__3cvsd > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AdventuresPage_adventure-about__rate__3cvsd {
        display: none; } }
  .AdventuresPage_adventure-about__buttons__dfnZD {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .AdventuresPage_adventure-about__buttons__dfnZD button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .AdventuresPage_adventure-about__buttons__dfnZD button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .AdventuresPage_adventure-about__buttons__dfnZD button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .AdventuresPage_adventure-about__buttons__dfnZD button {
          width: 120px; } }
      @media (max-width: 576px) {
        .AdventuresPage_adventure-about__buttons__dfnZD button {
          margin-right: 10px;
          width: 90px; } }
    .AdventuresPage_adventure-about__buttons__check-dates__3zvbv {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .AdventuresPage_adventure-about__buttons__request__1Sv9O {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_guides-footer__mi-ur {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .AdventuresPage_guides-footer__author__14mRd {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AdventuresPage_guides-footer__author__14mRd img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AdventuresPage_guides-footer__author__14mRd span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AdventuresPage_guides-footer__author__14mRd {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AdventuresPage_guides-footer__author__14mRd img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AdventuresPage_guides-footer__author__14mRd span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AdventuresPage_guides-footer__rate__3yHpO {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AdventuresPage_guides-footer__rate__3yHpO > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AdventuresPage_guides-footer__rate__3yHpO {
        display: none; } }
  .AdventuresPage_guides-footer__button__1b2eC {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .AdventuresPage_guides-footer__button__1b2eC {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .AdventuresPage_guides-footer__button__1b2eC {
        width: 120px; } }
    @media (max-width: 576px) {
      .AdventuresPage_guides-footer__button__1b2eC {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_guide-modal__1EWl2 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AdventuresPage_guide-modal__close__ZaFRZ {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AdventuresPage_guide-modal__close__ZaFRZ:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AdventuresPage_guide-modal__1EWl2 {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AdventuresPage_guide-modal__1EWl2 {
      padding: 10px;
      width: 300px; } }

.AdventuresPage_guide-modal__header__3kHBo {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .AdventuresPage_guide-modal__header__photo__11N8Z {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .AdventuresPage_guide-modal__header__photo__11N8Z img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .AdventuresPage_guide-modal__header__info__3UT6Z {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .AdventuresPage_guide-modal__header__info__3UT6Z p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .AdventuresPage_guide-modal__header__info__3UT6Z div {
      display: flex; }
      .AdventuresPage_guide-modal__header__info__3UT6Z div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .AdventuresPage_guide-modal__header__info__3UT6Z div span {
            text-align: center; } }
        .AdventuresPage_guide-modal__header__info__3UT6Z div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .AdventuresPage_guide-modal__header__info__3UT6Z div span:last-child {
          margin-right: 0; }
          .AdventuresPage_guide-modal__header__info__3UT6Z div span:last-child::after {
            display: none; }

.AdventuresPage_guide-modal__body__38XD7 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .AdventuresPage_guide-modal__body__error__1bLo6 .AdventuresPage_guide-modal__body__item__2Y8ZM {
    border-color: #f00; }
  .AdventuresPage_guide-modal__body__error__1bLo6 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AdventuresPage_guide-modal__body__item__2Y8ZM {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .AdventuresPage_guide-modal__body__item__2Y8ZM img,
    .AdventuresPage_guide-modal__body__item__2Y8ZM svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .AdventuresPage_guide-modal__body__item__2Y8ZM input,
    .AdventuresPage_guide-modal__body__item__2Y8ZM textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .AdventuresPage_guide-modal__body__item__2Y8ZM textarea {
      height: 70px;
      resize: none; }
    .AdventuresPage_guide-modal__body__item__down__3lBe0 {
      position: absolute;
      right: 9px; }
  .AdventuresPage_guide-modal__body__38XD7 .AdventuresPage_react-datepicker-wrapper__BYyd3,
  .AdventuresPage_guide-modal__body__38XD7 .AdventuresPage_react-datepicker__input-container__2MwTe {
    width: 100%; }
  .AdventuresPage_guide-modal__body__38XD7 .AdventuresPage_react-datepicker__1qvbH .AdventuresPage_react-datepicker__day__2vnEI:not(.AdventuresPage_react-datepicker__day--disabled__23mRl) {
    background: #fa9923;
    border-radius: 4.8px; }
    .AdventuresPage_guide-modal__body__38XD7 .AdventuresPage_react-datepicker__1qvbH .AdventuresPage_react-datepicker__day__2vnEI:not(.AdventuresPage_react-datepicker__day--disabled__23mRl):hover {
      color: #fff;
      font-weight: bold; }
  .AdventuresPage_guide-modal__body__submit__2UUhl {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .AdventuresPage_guide-modal__body__submit__2UUhl .AdventuresPage_loader__uwfr9 {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.AdventuresPage_guide-modal__footer__1Q_WB {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .AdventuresPage_guide-modal__footer__1Q_WB p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AdventuresPage_guide-modal__footer__1Q_WB label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .AdventuresPage_guide-modal__footer__1Q_WB a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .AdventuresPage_guide-modal__footer__1Q_WB input {
    left: -9999px;
    position: absolute; }
  .AdventuresPage_guide-modal__footer__1Q_WB span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .AdventuresPage_guide-modal__footer__1Q_WB input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_modal-overlay__1EDzx {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.AdventuresPage_modal-popup__1IXjZ {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AdventuresPage_modal-popup__close__1AsiV {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AdventuresPage_modal-popup__close__1AsiV:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AdventuresPage_modal-popup__1IXjZ {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AdventuresPage_modal-popup__1IXjZ {
      padding: 10px;
      width: 300px; } }

.AdventuresPage_modal-open__qM8v_ {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .AdventuresPage_modal-open__qM8v_ .AdventuresPage_fixed-footer__2e9E3 {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.AdventuresPage_fixed-footer__2e9E3 {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AdventuresPage_fixed-footer__2e9E3 {
      padding: 5px 0; } }
  .AdventuresPage_fixed-footer__container__18hg- {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AdventuresPage_fixed-footer__container__18hg- {
        padding: 0 10px; } }
  .AdventuresPage_fixed-footer__2e9E3.AdventuresPage_hidden__3_yFz {
    bottom: -80px; }

.AdventuresPage_wrapper__ElI5I {
  position: relative; }
  .AdventuresPage_wrapper__ElI5I .AdventuresPage_map__3BS0L img {
    width: 100%; }

.Filter_wrapper__2BbEf {
  color: #333;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .Filter_wrapper__2BbEf {
      color: #333; } }
  @media (min-width: 1025px) {
    .Filter_wrapper__2BbEf .Filter_clm-location__h0eME {
      color: #333;
      flex: 0 0 20.5%;
      max-width: 20.5%; }
    .Filter_wrapper__2BbEf .Filter_clm-date__3cmbr {
      flex: 0 0 18.2%;
      max-width: 18.2%; }
    .Filter_wrapper__2BbEf .Filter_clm-daymulti__2dHJA {
      flex: 0 0 17.3%;
      max-width: 17.3%; }
    .Filter_wrapper__2BbEf .Filter_clm-category__24Eu2 {
      flex: 0 0 14%;
      max-width: 14%; }
    .Filter_wrapper__2BbEf .Filter_clm-difficulty__2Z4fJ {
      flex: 0 0 15%;
      max-width: 15%; }
    .Filter_wrapper__2BbEf .Filter_clm-findbtn__2AoKd {
      flex: 0 0 15%;
      max-width: 15%; } }
  @media (min-width: 767px) {
    .Filter_wrapper__2BbEf h1 {
      max-width: 400px; } }
  @media (min-width: 1089px) {
    .Filter_wrapper__2BbEf h1 {
      max-width: 600px; } }
  .Filter_wrapper__2BbEf .Filter_banner__3to8- {
    background-color: #93886e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 15px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 67px;
    width: 100%; }
  @media (min-width: 767px) {
    .Filter_wrapper__2BbEf .Filter_row__1RmLs {
      margin: 0 auto;
      padding: 0 15px;
      width: 90%; } }
  @media (min-width: 767px) {
    .Filter_wrapper__2BbEf .Filter_grid__2-Vja {
      display: grid;
      grid-column-gap: 5%;
      grid-template-columns: 1fr 300px;
      margin-bottom: 40px; } }
  .Filter_wrapper__2BbEf p {
    color: #333; }
  .Filter_wrapper__2BbEf hr {
    background-color: #fff; }
  .Filter_wrapper__2BbEf .Filter_guides_faces__1fhLf {
    align-items: center;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    grid-column-gap: 11px;
    grid-template-columns: auto 1fr;
    padding: 25px 0;
    text-transform: uppercase; }
  .Filter_wrapper__2BbEf .Filter_faces_wrapper__3op5e {
    position: relative; }
  .Filter_wrapper__2BbEf .Filter_face__24wrc {
    border: 2px solid #333;
    border-radius: 50%;
    display: inline-block;
    height: 38px;
    margin-left: -7px;
    overflow: hidden;
    width: 38px; }
    .Filter_wrapper__2BbEf .Filter_face__24wrc:first-child {
      margin-left: 0; }
    .Filter_wrapper__2BbEf .Filter_face__24wrc img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%; }
  .Filter_wrapper__2BbEf .Filter_find_button__2O3J1 {
    align-items: center;
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    letter-spacing: 0.25px;
    margin: 4px;
    padding: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .Filter_wrapper__2BbEf .Filter_find_button__2O3J1 svg {
      fill: #fff;
      height: 12px;
      margin-left: 10px;
      width: 18px; }
  .Filter_wrapper__2BbEf .Filter_find_adventure__2cQwr {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    max-width: 20%; }
  .Filter_wrapper__2BbEf .Filter_input__18IWy {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    padding: 11px; }
    .Filter_wrapper__2BbEf .Filter_input__18IWy ::-webkit-input-placeholder {
      color: #333; }
    .Filter_wrapper__2BbEf .Filter_input__18IWy ::placeholder {
      color: #333; }
    .Filter_wrapper__2BbEf .Filter_input__18IWy input {
      background: transparent;
      border: 0;
      color: #333;
      margin-left: 10px; }
    .Filter_wrapper__2BbEf .Filter_input__18IWy svg {
      fill: #333;
      height: 16px;
      width: 16px; }
  .Filter_wrapper__2BbEf .Filter_input__18IWy:focus-within {
    border: 1px solid #ff9514; }
  .Filter_wrapper__2BbEf .Filter_dropdown__3qw7D {
    align-items: center;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    margin: 4px;
    overflow: hidden;
    padding: 12px 8px;
    position: static; }
    .Filter_wrapper__2BbEf .Filter_dropdown__3qw7D .Filter_dropdown-day__25InB {
      background-color: #fff;
      border: 0;
      color: #333; }
    .Filter_wrapper__2BbEf .Filter_dropdown__3qw7D .Filter_dropdown-day__25InB::after {
      border-bottom: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid;
      content: "";
      display: inline-block;
      margin-left: 2.55px;
      position: absolute;
      right: 20px;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      vertical-align: 2.55px;
      vertical-align: 2.55px; }
    .Filter_wrapper__2BbEf .Filter_dropdown__3qw7D svg {
      fill: #333;
      height: 16px;
      width: 16px; }
    .Filter_wrapper__2BbEf .Filter_dropdown__3qw7D span {
      background: transparent;
      border: 0;
      color: #333;
      margin-left: 10px; }

.Filter_btn-primary__3UTWD:not(:disabled):not(.Filter_disabled__TQOa4):active,
.Filter_btn-primary__3UTWD:not(:disabled):not(.Filter_disabled__TQOa4).Filter_active__1mP3g,
.Filter_show___A7Ti > .Filter_btn-primary__3UTWD.Filter_dropdown-toggle__3AEJM {
  background-color: #fff; }

.AdventureCardList_wrapper__2FBlA {
  padding: 40px 15px 0;
  position: relative; }
  @media (min-width: 767px) {
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_row__3YQ9b {
      margin: 0 auto;
      padding: 0 15px 100px;
      width: 90%; } }
  .AdventureCardList_wrapper__2FBlA h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px; }
  .AdventureCardList_wrapper__2FBlA .AdventureCardList_categories__1IcB3 {
    color: #666;
    margin-bottom: 30px; }
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_categories__1IcB3 li {
      cursor: pointer;
      display: inline-block;
      padding: 0 3px;
      transition: color 0.2s ease-out; }
      .AdventureCardList_wrapper__2FBlA .AdventureCardList_categories__1IcB3 li:hover {
        color: #fa9923; }
      .AdventureCardList_wrapper__2FBlA .AdventureCardList_categories__1IcB3 li:first-child {
        color: #fa9923;
        padding-left: 0; }
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_categories__1IcB3 span:last-child {
      display: none; }
  @media (min-width: 767px) {
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_cards__3mhIo {
      display: grid;
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
  .AdventureCardList_wrapper__2FBlA .AdventureCardList_see_more__2U9Tf {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase; }
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_see_more__2U9Tf span {
      text-align: right; }
    .AdventureCardList_wrapper__2FBlA .AdventureCardList_see_more__2U9Tf svg {
      height: 35px;
      width: 35px; }

.AdventureCardList_c3__Nf4sF {
  grid-template-columns: repeat(3, 1fr); }

.AdventureCardList_c4__1_5x8 {
  grid-template-columns: repeat(4, 1fr); }
  .AdventureCardList_c4__adventures__3IRr0 {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 255px);
    justify-content: space-between; }
    .AdventureCardList_c4__adventures__3IRr0 > div {
      margin: 0; }
    @media (max-width: 992px) {
      .AdventureCardList_c4__adventures__3IRr0 {
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between; } }
    @media (max-width: 767px) {
      .AdventureCardList_c4__adventures__3IRr0 {
        grid-gap: 15px;
        grid-template-columns: repeat(1, 100%);
        justify-content: normal; } }

.AdventureCardList_wrapper__2FBlA.AdventureCardList_white__128nh {
  background-color: #fff; }

.AdventureCardList_wrapper__2FBlA.AdventureCardList_grey__3AvWc {
  background-color: #f8f8f9; }

.Rating_star_ratings__35grM {
  align-items: center;
  color: #c5c5c5;
  display: flex;
  font-size: 25px;
  height: 25px;
  padding: 0;
  position: relative;
  text-shadow: 0 1px 0 #a2a2a2;
  unicode-bidi: bidi-override;
  width: 90px; }
  .Rating_star_ratings__35grM .Rating_star_ratings_top__1ZHGc {
    color: #ff9514;
    display: block;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 1; }
  .Rating_star_ratings__35grM .Rating_star_ratings_bottom__MLmsN {
    display: block;
    padding: 0;
    z-index: 0; }
  .Rating_star_ratings__35grM span {
    font-size: 18px; }

.Rating_ratingText__2OAS0 {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.09px;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 5px; }

.PhotoSlider_wrapper__32Tn0 {
  height: 305px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .PhotoSlider_wrapper__32Tn0 .PhotoSlider_row__PwmGh {
    height: 100%;
    max-width: 100%; }
    @media (min-width: 767px) {
      .PhotoSlider_wrapper__32Tn0 .PhotoSlider_row__PwmGh {
        margin: 0 auto;
        width: 70%; } }
  @media (min-width: 769px) {
    .PhotoSlider_wrapper__32Tn0 {
      height: 460px; } }
  .PhotoSlider_wrapper__32Tn0 img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.PhotoSlider_controls__rU86n {
  display: grid;
  grid-template-columns: repeat(2, auto);
  height: 100%;
  padding: 0 5px;
  position: absolute;
  width: 100%; }
  .PhotoSlider_controls__rU86n button:first-child {
    text-align: left; }
  .PhotoSlider_controls__rU86n button:last-child {
    text-align: right; }
  .PhotoSlider_controls__rU86n svg {
    fill: #fff;
    height: 20px;
    width: 20px; }

.PhotoSlider_info__2IoNU {
  bottom: 65px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, -webkit-max-content);
  grid-template-columns: repeat(3, max-content);
  left: 15px;
  position: absolute; }
  @media (min-width: 767px) {
    .PhotoSlider_info__2IoNU {
      left: 0;
      margin: auto;
      right: 0;
      width: 65%; } }
  .PhotoSlider_info__2IoNU svg {
    height: 18px;
    width: 18px; }

.PhotoSlider_count__26Ab2 {
  align-items: center;
  color: #fa9923;
  display: grid;
  font-size: 14px;
  font-weight: 600;
  grid-template-columns: repeat(3, auto);
  text-shadow: #f00; }
  .PhotoSlider_count__26Ab2 svg {
    margin-right: 7px; }

.PhotoSlider_like__1XLNQ {
  cursor: pointer; }

.PhotoSlider_count__26Ab2,
.PhotoSlider_like__1XLNQ {
  border-radius: 6px;
  padding: 10px 18px; }

.slide {
  height: 500px; }
  @media (max-width: 768px) {
    .slide {
      height: 324px; } }
  .slide li {
    background-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid #fff !important;
    border-color: #fff !important;
    border-radius: 50%;
    border-width: 1px;
    box-sizing: border-box !important;
    height: 15px !important;
    margin-bottom: 5px;
    width: 15px !important; }
  .slide .active {
    background-color: white !important; }
  @media (max-width: 768px) {
    .slide .img {
      display: none;
      height: 324px; } }
  @media (max-width: 768px) {
    .slide a {
      height: 300px; } }
  .slide .carousel-inner {
    height: 500px;
    object-fit: cover;
    position: relative; }
  .slide .carousel-indicators {
    bottom: 60px; }
    @media (max-width: 768px) {
      .slide .carousel-indicators {
        bottom: 20px; } }
  .slide img {
    height: 500px;
    max-height: 500px;
    object-fit: cover; }
    @media (max-width: 768px) {
      .slide img {
        height: 500px; } }

/* stylelint-disable */
/* stylelint-enable */
.slick-slider,
.slick-list,
.slick-track,
.slick-slide div {
  height: 100%; }

.slick-prev {
  -webkit-mask-image: url(/static/media/caret-left.4312a4c2.svg);
  mask-image: url(/static/media/caret-left.4312a4c2.svg);
  left: 10px; }

.slick-next {
  -webkit-mask-image: url(/static/media/caret-right.1afaf754.svg);
  mask-image: url(/static/media/caret-right.1afaf754.svg);
  right: 10px; }

.slick-arrow {
  transform: translateY(-50%);
  transition: all 0.2s ease;
  background-color: #fa9923;
  cursor: pointer;
  font-size: 0;
  height: 50px;
  opacity: 0.8;
  outline: none !important;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 1; }
  .slick-arrow:hover {
    opacity: 1; }

.slick-slide img {
  height: 100%;
  width: 100%; }

.slick-slider,
.slick-list,
.slick-track,
.slick-slide div {
  height: 100%; }

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }

.slick-slider {
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track::before,
.slick-track::after {
  content: "";
  display: table; }

.slick-initialized .slick-slide {
  display: block; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide-item {
  position: relative; }
  .slick-slide-item span {
    background: rgba(102, 102, 103, 0.8);
    color: #fa9923;
    display: block;
    font-family: "Abril Fatface", cursive;
    position: absolute; }
  .slick-slide-item .slick-slide-item-title {
    font-size: 20px;
    padding: 10px;
    text-align: center;
    top: 0;
    width: 100%; }
    @media (max-width: 767px) {
      .slick-slide-item .slick-slide-item-title {
        font-size: 14px;
        padding: 5px; } }
  .slick-slide-item .slick-slide-item-copy {
    bottom: 0;
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    padding: 5px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (max-width: 767px) {
      .slick-slide-item .slick-slide-item-copy {
        font-size: 10px;
        padding: 2px; } }

.slick-dots {
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  bottom: 15px;
  display: flex !important;
  padding: 10px;
  position: absolute;
  width: 100%; }
  .slick-dots li button {
    transition: all 0.2s ease;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 0;
    height: 20px;
    margin: 0 3px;
    outline: none !important;
    padding: 0;
    position: relative;
    width: 20px; }
    .slick-dots li button::before {
      transition: all 0.2s ease;
      background: #fa9923;
      border-radius: 50%;
      content: "";
      display: block;
      height: 8px;
      margin: 0 auto;
      opacity: 0;
      width: 8px; }
  .slick-dots li:hover, .slick-dots li.slick-active {
    /* stylelint-disable */ }
    .slick-dots li:hover button, .slick-dots li.slick-active button {
      border-color: #fa9923; }
      .slick-dots li:hover button::before, .slick-dots li.slick-active button::before {
        opacity: 1; }

.slick-initialized .slick-slide {
  display: block; }

.TourDescription_wrapper__1_JQK {
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0; }
  .TourDescription_wrapper__1_JQK h3 {
    color: #333;
    font-size: 20px;
    padding: 0 0 20px; }
  .TourDescription_wrapper__1_JQK p {
    color: #666;
    line-height: 1.8;
    max-height: 185px;
    overflow: hidden; }
    .TourDescription_wrapper__1_JQK p br {
      line-height: 2.5; }
  .TourDescription_wrapper__1_JQK .TourDescription_extended__2bNjv {
    max-height: none; }
  .TourDescription_wrapper__1_JQK button {
    border-bottom: 1px solid rgba(250, 153, 35, 0.5);
    color: #fa9923;
    padding: 0; }
  .TourDescription_wrapper__1_JQK .TourDescription_read_more__2K_xv {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: auto 1fr;
    letter-spacing: 0.3px;
    padding-bottom: 30px;
    text-align: center;
    text-transform: uppercase; }
    .TourDescription_wrapper__1_JQK .TourDescription_read_more__2K_xv span {
      text-align: left; }
    .TourDescription_wrapper__1_JQK .TourDescription_read_more__2K_xv svg {
      height: 35px;
      width: 35px; }

.TourDescription_specs__3wLaL {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(2, 1fr); }
  @media (min-width: 320px) {
    .TourDescription_specs__3wLaL {
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)); } }
  .TourDescription_specs__3wLaL .TourDescription_specs_items__3sRSt {
    align-items: center;
    display: grid;
    grid-column-gap: 10px;
    grid-template-areas: "a b" "a c";
    grid-template-columns: auto 1fr;
    padding: 3px 0;
    position: relative; }
    .TourDescription_specs__3wLaL .TourDescription_specs_items__3sRSt::before {
      border-radius: 50%;
      content: "";
      height: 36px;
      margin-left: -10px;
      position: absolute;
      width: 36px;
      z-index: -1; }
    .TourDescription_specs__3wLaL .TourDescription_specs_items__3sRSt svg {
      fill: #fa9923;
      grid-area: a;
      height: 24px;
      width: 24px; }
    .TourDescription_specs__3wLaL .TourDescription_specs_items__3sRSt p {
      color: #333;
      color: #333;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-weight: 666;
      grid-area: b;
      letter-spacing: 0.2px;
      line-height: 15px;
      margin-bottom: 0;
      text-transform: uppercase; }
    .TourDescription_specs__3wLaL .TourDescription_specs_items__3sRSt span {
      color: #666;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-size: 14px;
      letter-spacing: 0.17px;
      line-height: 18px;
      text-transform: uppercase; }
  .TourDescription_specs__3wLaL .TourDescription_specs_items_suffix__BlcXt {
    margin-right: 8px; }

.sectionFroala .fr-box.fr-basic {
  border-bottom: 1px solid #e6e6e6;
  margin: 1px 0 45px;
  padding: 0 0 30px; }
  .sectionFroala .fr-box.fr-basic .fr-element {
    background-clip: padding-box;
    border-radius: 0 0 2px 2px;
    border-top: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #000;
    min-height: 52px;
    overflow-x: auto;
    padding: 0; }
  .sectionFroala .fr-box.fr-basic li {
    line-height: 23px; }
  .sectionFroala .fr-box.fr-basic h2 {
    font-size: 38px; }
  .sectionFroala .fr-box.fr-basic h3 {
    margin: 0;
    margin-top: 25px;
    padding: 0 0 15px; }
  .sectionFroala .fr-box.fr-basic td {
    border: 0; }

.sectionFroala p {
  color: #000; }

.adventure-subTitle {
  margin: 0; }

.froala_notice {
  background: #ef5350;
  color: #fff;
  display: block;
  font-size: 15px;
  max-width: 840px;
  padding: 8px 10px;
  text-decoration: none; }

.included_list .fr-box.fr-basic {
  border: 0;
  margin: 0;
  padding: 0; }
  .included_list .fr-box.fr-basic li {
    color: #333;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px; }

.include_exclude {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 30px; }

.adventureDetailButton {
  background: #fff !important;
  border-color: #fa9923 !important;
  color: #fa9923 !important; }

.row-grid {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 88%; }

.row-grid .new-access {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 10px 0 !important;
  padding: 0 !important;
  width: 130px !important; }
  .row-grid .new-access .select-children-age {
    background: transparent;
    border: 0;
    height: 39px;
    width: 100%; }

.box-item label {
  display: block;
  font-size: 12px;
  text-align: left; }

.contact-detail-picker {
  border-width: 0;
  box-shadow: none;
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 15px;
  height: 50px;
  letter-spacing: 0.3px;
  line-height: 19px; }

.input-style {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  color: #333;
  display: flex;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin: 10px auto;
  padding: 7px 16px;
  position: relative;
  width: 88%; }
  .input-style .arrow-right {
    position: absolute;
    right: 5px; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #fa9923 !important; }

.react-datepicker__day--selected {
  background-color: #fa9923 !important; }

.CarryThings_wrapper__1zFCs {
  border-bottom: 1px solid #e6e6e6;
  color: #333;
  font-size: 16px;
  padding: 30px 0; }
  .CarryThings_wrapper__1zFCs svg {
    fill: #fa9923;
    height: 12px;
    width: 16px; }
  .CarryThings_wrapper__1zFCs h3 {
    color: #333;
    font-size: 20px;
    padding: 0 0 30px; }
  .CarryThings_wrapper__1zFCs ul {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    padding-bottom: 20px; }
    .CarryThings_wrapper__1zFCs ul li {
      align-items: center;
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: auto 1fr; }
  .CarryThings_wrapper__1zFCs button {
    border-bottom: 1px solid rgba(250, 153, 35, 0.5);
    color: #fa9923;
    padding: 0; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.AdventureDetailsPage_chromeframe__3izlj {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes AdventureDetailsPage_slideInTop__2d8ho {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.AdventureDetailsPage_short_hr__22kUF {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.AdventureDetailsPage_App__1qoCK {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .AdventureDetailsPage_App__1qoCK.AdventureDetailsPage_modal-open__27gSg {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.AdventureDetailsPage_checkbox__3Z2rD {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.AdventureDetailsPage_checkboxInput__1gxCH {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.AdventureDetailsPage_radioInput__3v1nn {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.AdventureDetailsPage_checkboxInput__1gxCH:checked ~ .AdventureDetailsPage_checkmark__R7SVv {
  background-color: #ff9514; }

.AdventureDetailsPage_checkboxInput__1gxCH:checked ~ .AdventureDetailsPage_guideCheckmark__88tIm {
  background-color: #ff9514;
  color: #fff; }

.AdventureDetailsPage_radioInput__3v1nn:checked ~ .AdventureDetailsPage_radioMark__323YY {
  background-color: #fff; }

/* Create a custom checkbox */
.AdventureDetailsPage_checkmark__R7SVv {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.AdventureDetailsPage_guideCheckmark__88tIm {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.AdventureDetailsPage_radioMark__323YY {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.AdventureDetailsPage_checkmark__R7SVv::after {
  content: "";
  display: none;
  position: absolute; }

.AdventureDetailsPage_guideCheckmark__88tIm::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.AdventureDetailsPage_radioMark__323YY::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.AdventureDetailsPage_checkboxInput__1gxCH:checked ~ .AdventureDetailsPage_checkmark__R7SVv::after {
  display: block; }

.AdventureDetailsPage_checkboxInput__1gxCH:checked ~ .AdventureDetailsPage_guideCheckmark__88tIm::after {
  display: block; }

.AdventureDetailsPage_radioInput__3v1nn:checked ~ .AdventureDetailsPage_radioMark__323YY::after {
  display: block; }

/* Style the checkmark/indicator */
.AdventureDetailsPage_checkmark__R7SVv::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.AdventureDetailsPage_guideCheckmark__88tIm::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.AdventureDetailsPage_radioMark__323YY::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.AdventureDetailsPage_loaderSpinner___xZRD {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.AdventureDetailsPage_loader__2zqkk {
  animation: AdventureDetailsPage_spin__30xm8 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes AdventureDetailsPage_spin__30xm8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.AdventureDetailsPage_lazy-load-image-background__4ro8X {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .AdventureDetailsPage_lazy-load-image-background__4ro8X img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.AdventureDetailsPage_lazyImageNP__baKh5 {
  padding-top: 0 !important; }

.AdventureDetailsPage_finish_google_survey__1Rq2K {
  color: #666;
  text-decoration: none; }

.AdventureDetailsPage_finish_google_survey__1Rq2K:hover {
  color: #fff; }

.AdventureDetailsPage_finish_btn__3-494 button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.AdventureDetailsPage_home-locations__yyrmj {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .AdventureDetailsPage_home-locations__yyrmj ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .AdventureDetailsPage_home-locations__yyrmj li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .AdventureDetailsPage_home-locations__yyrmj li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .AdventureDetailsPage_home-locations__yyrmj li h3 {
        font-size: 14px; } }
  .AdventureDetailsPage_home-locations__yyrmj li img {
    height: 28px;
    width: 28px; }
  .AdventureDetailsPage_home-locations__yyrmj li .AdventureDetailsPage_rightArrow__3Rehk {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.AdventureDetailsPage_home-locations__yyrmj ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .AdventureDetailsPage_home-locations__yyrmj ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .AdventureDetailsPage_home-locations__yyrmj ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .AdventureDetailsPage_home-locations__yyrmj ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .AdventureDetailsPage_home-locations__yyrmj ul li {
      width: 100%; } }

.AdventureDetailsPage_location-header__3sll3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.AdventureDetailsPage_location-text__1PKve p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .AdventureDetailsPage_location-text__1PKve p {
      display: none; } }

.AdventureDetailsPage_leftArrow__MmAm8 {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AdventureDetailsPage_leftArrow__arrow__2R49Q {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.AdventureDetailsPage_rightArrow__3Rehk {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .AdventureDetailsPage_rightArrow__arrow__1q6ez {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.AdventureDetailsPage_phone-icon__2A7-0 {
  max-width: 30px; }

.AdventureDetailsPage_search-filter__2zET0.AdventureDetailsPage_header-filter__3WcXI button {
  width: 260px; }
  @media (max-width: 767px) {
    .AdventureDetailsPage_search-filter__2zET0.AdventureDetailsPage_header-filter__3WcXI button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_search-filter-bg__2WJpj {
  background: #f1f1f2; }

.AdventureDetailsPage_search-filter__2zET0 {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .AdventureDetailsPage_search-filter__select__2Jy7u {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .AdventureDetailsPage_search-filter__select__dropdown-indicator__1_WXn {
      color: #333 !important; }
      .AdventureDetailsPage_search-filter__select__dropdown-indicator__1_WXn svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .AdventureDetailsPage_search-filter__select__clear-indicator__2EH5D {
      color: #fff !important; }
      .AdventureDetailsPage_search-filter__select__clear-indicator__2EH5D:hover {
        color: #666 !important; }
    .AdventureDetailsPage_search-filter__select__placeholder__3E98q, .AdventureDetailsPage_search-filter__select__single-value__2MvCd {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .AdventureDetailsPage_search-filter__select__placeholder__3E98q {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .AdventureDetailsPage_search-filter__select__input__2ImQ2 input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .AdventureDetailsPage_search-filter__select__value-container__3XUH3 {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .AdventureDetailsPage_search-filter__select__indicator__2O7As {
      padding: 0 !important; }
    .AdventureDetailsPage_search-filter__select__indicator-separator__2G--n {
      display: none; }
    .AdventureDetailsPage_search-filter__select__menu-list__SwzX3 {
      padding: 0 !important; }
    .AdventureDetailsPage_search-filter__select__menu__2QIXD {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .AdventureDetailsPage_search-filter__select__2Jy7u {
        width: 100%; } }
  .AdventureDetailsPage_search-filter__destination__3hK95 {
    width: 260px; }
  .AdventureDetailsPage_search-filter__2zET0 button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .AdventureDetailsPage_search-filter__2zET0 button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .AdventureDetailsPage_search-filter__2zET0 button:hover {
      border: 1px solid #dedede; }
      .AdventureDetailsPage_search-filter__2zET0 button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .AdventureDetailsPage_search-filter__2zET0 button {
        width: 100%; } }
  @media (max-width: 767px) {
    .AdventureDetailsPage_search-filter__destination__3hK95, .AdventureDetailsPage_search-filter__select__2Jy7u,
    .AdventureDetailsPage_search-filter__2zET0 button {
      margin: 8px 0;
      width: 100%; } }

.AdventureDetailsPage_search-filter__select__option__1l0O- {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .AdventureDetailsPage_search-filter__select__option__1l0O- .AdventureDetailsPage_sub__153sn {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .AdventureDetailsPage_search-filter__select__option__item__1ITEI {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .AdventureDetailsPage_search-filter__select__option__item__1ITEI span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .AdventureDetailsPage_search-filter__select__option__item__1ITEI img {
      display: block;
      height: 100%;
      width: 100%; }
  .AdventureDetailsPage_search-filter__select__option__1l0O-:hover, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H {
    background: rgba(255, 149, 20, 0.1); }
    .AdventureDetailsPage_search-filter__select__option__1l0O-:hover .AdventureDetailsPage_sub__153sn, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H .AdventureDetailsPage_sub__153sn {
      color: #ff8c00; }
    .AdventureDetailsPage_search-filter__select__option__1l0O-:hover.AdventureDetailsPage_search-filter__select__option__1l0O-:hover span, .AdventureDetailsPage_search-filter__select__option__1l0O-:hover .AdventureDetailsPage_search-filter__select__option__selected__item__By0Qe span,
    .AdventureDetailsPage_search-filter__select__option__1l0O-:hover .AdventureDetailsPage_search-filter__select__option__item__1ITEI span, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H.AdventureDetailsPage_search-filter__select__option__1l0O-:hover span, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H .AdventureDetailsPage_search-filter__select__option__selected__item__By0Qe span,
    .AdventureDetailsPage_search-filter__select__option__selected__22Q6H .AdventureDetailsPage_search-filter__select__option__item__1ITEI span {
      background: #ff8c00; }
      .AdventureDetailsPage_search-filter__select__option__1l0O-:hover.AdventureDetailsPage_search-filter__select__option__1l0O-:hover span::before, .AdventureDetailsPage_search-filter__select__option__1l0O-:hover .AdventureDetailsPage_search-filter__select__option__selected__item__By0Qe span::before,
      .AdventureDetailsPage_search-filter__select__option__1l0O-:hover .AdventureDetailsPage_search-filter__select__option__item__1ITEI span::before, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H.AdventureDetailsPage_search-filter__select__option__1l0O-:hover span::before, .AdventureDetailsPage_search-filter__select__option__selected__22Q6H .AdventureDetailsPage_search-filter__select__option__selected__item__By0Qe span::before,
      .AdventureDetailsPage_search-filter__select__option__selected__22Q6H .AdventureDetailsPage_search-filter__select__option__item__1ITEI span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.AdventureDetailsPage_search-filter__select__control__3OMHh {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .AdventureDetailsPage_search-filter__select__control__3OMHh .AdventureDetailsPage_css-1g6gooi__2jcAC {
    margin: 0 !important;
    padding: 0 !important; }
  .AdventureDetailsPage_search-filter__select__control__focused__hKZSN, .AdventureDetailsPage_search-filter__select__control__isOpen__t0a-h, .AdventureDetailsPage_search-filter__select__control__3OMHh:hover {
    border-color: #ff9514; }
  .AdventureDetailsPage_search-filter__select__control__fill__141lp {
    background-color: #ff9514;
    border-color: #ff9514; }
    .AdventureDetailsPage_search-filter__select__control__fill__141lp .AdventureDetailsPage_search-filter__select__dropdown-indicator__1_WXn,
    .AdventureDetailsPage_search-filter__select__control__fill__141lp .AdventureDetailsPage_search-filter__select__single-value__2MvCd,
    .AdventureDetailsPage_search-filter__select__control__fill__141lp .AdventureDetailsPage_search-filter__select__placeholder__3E98q {
      color: #fff !important; }
    .AdventureDetailsPage_search-filter__select__control__fill__141lp .AdventureDetailsPage_search-filter__select__input__2ImQ2 input {
      color: #fff !important; }
  .AdventureDetailsPage_search-filter__select__control__isOpen__t0a-h .AdventureDetailsPage_search-filter__select__dropdown-indicator__1_WXn svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_adventure-about__3WMrm {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AdventureDetailsPage_adventure-about__3WMrm {
      padding: 5px 0; } }
  .AdventureDetailsPage_adventure-about__container__2eE5r {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AdventureDetailsPage_adventure-about__container__2eE5r {
        padding: 0 10px; } }
  .AdventureDetailsPage_adventure-about__author__ib-Ew {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AdventureDetailsPage_adventure-about__author__ib-Ew img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AdventureDetailsPage_adventure-about__author__ib-Ew span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AdventureDetailsPage_adventure-about__author__ib-Ew {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AdventureDetailsPage_adventure-about__author__ib-Ew img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AdventureDetailsPage_adventure-about__author__ib-Ew span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AdventureDetailsPage_adventure-about__rate__2LMuO {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AdventureDetailsPage_adventure-about__rate__2LMuO > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AdventureDetailsPage_adventure-about__rate__2LMuO {
        display: none; } }
  .AdventureDetailsPage_adventure-about__buttons__2V789 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .AdventureDetailsPage_adventure-about__buttons__2V789 button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .AdventureDetailsPage_adventure-about__buttons__2V789 button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .AdventureDetailsPage_adventure-about__buttons__2V789 button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .AdventureDetailsPage_adventure-about__buttons__2V789 button {
          width: 120px; } }
      @media (max-width: 576px) {
        .AdventureDetailsPage_adventure-about__buttons__2V789 button {
          margin-right: 10px;
          width: 90px; } }
    .AdventureDetailsPage_adventure-about__buttons__check-dates__I3VL7 {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .AdventureDetailsPage_adventure-about__buttons__request__14p_D {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_guides-footer__23SYC {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .AdventureDetailsPage_guides-footer__author__2IUCK {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .AdventureDetailsPage_guides-footer__author__2IUCK img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .AdventureDetailsPage_guides-footer__author__2IUCK span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .AdventureDetailsPage_guides-footer__author__2IUCK {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .AdventureDetailsPage_guides-footer__author__2IUCK img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .AdventureDetailsPage_guides-footer__author__2IUCK span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .AdventureDetailsPage_guides-footer__rate__CvssE {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .AdventureDetailsPage_guides-footer__rate__CvssE > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .AdventureDetailsPage_guides-footer__rate__CvssE {
        display: none; } }
  .AdventureDetailsPage_guides-footer__button__fTZaD {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .AdventureDetailsPage_guides-footer__button__fTZaD {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .AdventureDetailsPage_guides-footer__button__fTZaD {
        width: 120px; } }
    @media (max-width: 576px) {
      .AdventureDetailsPage_guides-footer__button__fTZaD {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_guide-modal__3u65p {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AdventureDetailsPage_guide-modal__close__1muaD {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AdventureDetailsPage_guide-modal__close__1muaD:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AdventureDetailsPage_guide-modal__3u65p {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AdventureDetailsPage_guide-modal__3u65p {
      padding: 10px;
      width: 300px; } }

.AdventureDetailsPage_guide-modal__header__1XLSo {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .AdventureDetailsPage_guide-modal__header__photo__3y8k3 {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .AdventureDetailsPage_guide-modal__header__photo__3y8k3 img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .AdventureDetailsPage_guide-modal__header__info__2OIkL {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .AdventureDetailsPage_guide-modal__header__info__2OIkL p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .AdventureDetailsPage_guide-modal__header__info__2OIkL div {
      display: flex; }
      .AdventureDetailsPage_guide-modal__header__info__2OIkL div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .AdventureDetailsPage_guide-modal__header__info__2OIkL div span {
            text-align: center; } }
        .AdventureDetailsPage_guide-modal__header__info__2OIkL div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .AdventureDetailsPage_guide-modal__header__info__2OIkL div span:last-child {
          margin-right: 0; }
          .AdventureDetailsPage_guide-modal__header__info__2OIkL div span:last-child::after {
            display: none; }

.AdventureDetailsPage_guide-modal__body__14PkW {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .AdventureDetailsPage_guide-modal__body__error__33ZKA .AdventureDetailsPage_guide-modal__body__item__23M6D {
    border-color: #f00; }
  .AdventureDetailsPage_guide-modal__body__error__33ZKA p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AdventureDetailsPage_guide-modal__body__item__23M6D {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .AdventureDetailsPage_guide-modal__body__item__23M6D img,
    .AdventureDetailsPage_guide-modal__body__item__23M6D svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .AdventureDetailsPage_guide-modal__body__item__23M6D input,
    .AdventureDetailsPage_guide-modal__body__item__23M6D textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .AdventureDetailsPage_guide-modal__body__item__23M6D textarea {
      height: 70px;
      resize: none; }
    .AdventureDetailsPage_guide-modal__body__item__down__JoB3e {
      position: absolute;
      right: 9px; }
  .AdventureDetailsPage_guide-modal__body__14PkW .AdventureDetailsPage_react-datepicker-wrapper__3EYRF,
  .AdventureDetailsPage_guide-modal__body__14PkW .AdventureDetailsPage_react-datepicker__input-container__1bL85 {
    width: 100%; }
  .AdventureDetailsPage_guide-modal__body__14PkW .AdventureDetailsPage_react-datepicker__w7cMf .AdventureDetailsPage_react-datepicker__day__2L1WF:not(.AdventureDetailsPage_react-datepicker__day--disabled__3Gr98) {
    background: #fa9923;
    border-radius: 4.8px; }
    .AdventureDetailsPage_guide-modal__body__14PkW .AdventureDetailsPage_react-datepicker__w7cMf .AdventureDetailsPage_react-datepicker__day__2L1WF:not(.AdventureDetailsPage_react-datepicker__day--disabled__3Gr98):hover {
      color: #fff;
      font-weight: bold; }
  .AdventureDetailsPage_guide-modal__body__submit__3P07E {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .AdventureDetailsPage_guide-modal__body__submit__3P07E .AdventureDetailsPage_loader__2zqkk {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.AdventureDetailsPage_guide-modal__footer__2Kz0j {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j input {
    left: -9999px;
    position: absolute; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .AdventureDetailsPage_guide-modal__footer__2Kz0j input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_modal-overlay__2L756 {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.AdventureDetailsPage_modal-popup__3aCPv {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .AdventureDetailsPage_modal-popup__close__2GObC {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .AdventureDetailsPage_modal-popup__close__2GObC:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .AdventureDetailsPage_modal-popup__3aCPv {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .AdventureDetailsPage_modal-popup__3aCPv {
      padding: 10px;
      width: 300px; } }

.AdventureDetailsPage_modal-open__27gSg {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .AdventureDetailsPage_modal-open__27gSg .AdventureDetailsPage_fixed-footer__xVMQ5 {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.AdventureDetailsPage_fixed-footer__xVMQ5 {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .AdventureDetailsPage_fixed-footer__xVMQ5 {
      padding: 5px 0; } }
  .AdventureDetailsPage_fixed-footer__container__3oNKi {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .AdventureDetailsPage_fixed-footer__container__3oNKi {
        padding: 0 10px; } }
  .AdventureDetailsPage_fixed-footer__xVMQ5.AdventureDetailsPage_hidden__o2idY {
    bottom: -80px; }

.AdventureDetailsPage_wrapper__16hU4 {
  display: block; }
  .AdventureDetailsPage_wrapper__16hU4 .AdventureDetailsPage_row__1n1KA {
    padding: 0 15px; }
    @media (min-width: 767px) {
      .AdventureDetailsPage_wrapper__16hU4 .AdventureDetailsPage_row__1n1KA {
        margin: 0 auto;
        max-width: 1110px;
        width: 100%; } }
  .AdventureDetailsPage_wrapper__16hU4 h3 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 20px;
    line-height: 27px;
    margin-top: 5px;
    padding: 0; }
  .AdventureDetailsPage_wrapper__16hU4 .AdventureDetailsPage_sectionTitle__1gxJE {
    margin: 0;
    margin-top: 25px;
    padding: 0 0 15px; }

.AdventureDetailsPage_header__1otWf {
  padding: 20px 15px; }
  .AdventureDetailsPage_header__1otWf section:last-child {
    align-items: baseline;
    display: grid;
    grid-column-gap: 10px;
    grid-template-areas: "divider divider" "price ratings";
    grid-template-columns: auto 1fr; }
    @media (min-width: 767px) {
      .AdventureDetailsPage_header__1otWf section:last-child {
        display: block;
        padding-top: 16px; } }
  .AdventureDetailsPage_header__1otWf h1 {
    color: #333;
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 10px; }
    @media (min-width: 767px) {
      .AdventureDetailsPage_header__1otWf h1 {
        font-size: 34px; } }
  .AdventureDetailsPage_header__1otWf hr {
    background-color: #666;
    border-width: 0;
    color: #333;
    grid-area: divider;
    height: 1px;
    margin: 0 0 20px;
    padding: 0;
    width: 64px; }
  @media (min-width: 767px) {
    .AdventureDetailsPage_header__1otWf {
      display: grid;
      grid-column-gap: 100px;
      grid-template-columns: 1fr auto;
      margin: 0 auto;
      max-width: 1110px;
      padding: 40px 0;
      width: 90%; } }

.AdventureDetailsPage_type__34N_v {
  color: #fa9923;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase; }

.AdventureDetailsPage_location__XmN-m {
  align-items: center;
  color: #333;
  display: grid;
  grid-column-gap: 7px;
  grid-template-columns: auto 1fr;
  margin-bottom: 0; }
  .AdventureDetailsPage_location__XmN-m svg {
    height: 16px;
    width: 14px; }

.AdventureDetailsPage_price__wxApc {
  color: #333;
  font-size: 22px;
  font-weight: 600;
  grid-area: price;
  letter-spacing: -0.61px; }
  @media (min-width: 767px) {
    .AdventureDetailsPage_price__wxApc {
      font-size: 26px; } }
  .AdventureDetailsPage_price__wxApc span {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 18px;
    padding-left: 3px; }

.AdventureDetailsPage_ratings__2aPRa {
  border-left: 1px solid #e6e6e6;
  color: #666;
  font-size: 12px;
  grid-area: ratings;
  padding-left: 10px; }
  @media (min-width: 767px) {
    .AdventureDetailsPage_ratings__2aPRa {
      border: 0;
      margin-top: 10px;
      padding: 0; } }
  .AdventureDetailsPage_ratings__2aPRa svg {
    fill: #fa9923;
    height: 13px;
    width: 14px; }
  .AdventureDetailsPage_ratings__2aPRa span {
    padding-left: 5px; }

.AdventureDetailsPage_column__1f-sD {
  display: flex;
  position: relative; }

.AdventureDetailsPage_col_1_2__3b00A {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); }
  @media (min-width: 767px) {
    .AdventureDetailsPage_col_1_2__3b00A {
      border-bottom: 1px solid #e6e6e6; } }

@media (max-width: 768px) {
  .AdventureDetailsPage_desktopBookRequest__fMKF5 {
    display: none; } }

@media (min-width: 768px) {
  .AdventureDetailsPage_mobileBookRequest__3LXsJ {
    display: none; } }

.AdventureDetailsPage_included_excluded__2ncdi {
  border-bottom: 1px solid #e6e6e6;
  color: #333;
  font-size: 16px;
  max-width: 730px;
  padding: 30px 0; }
  @media (min-width: 767px) {
    .AdventureDetailsPage_included_excluded__2ncdi {
      border: 0; } }
  .AdventureDetailsPage_included_excluded__2ncdi svg {
    height: 16px;
    width: 16px; }
  .AdventureDetailsPage_included_excluded__2ncdi .AdventureDetailsPage_icon_plus__XHj8N {
    fill: #fa9923; }
  .AdventureDetailsPage_included_excluded__2ncdi .AdventureDetailsPage_icon_minus__19Knh {
    fill: #666; }
  .AdventureDetailsPage_included_excluded__2ncdi ul li {
    align-items: center;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto 1fr;
    margin-bottom: 20px; }

.AdventureDetailsPage_mapView__2dP78 {
  max-width: 730px; }

.AdventureDetailsPage_similar__1FxLX {
  background-color: #f8f8f9;
  padding: 40px 15px; }
  @media (min-width: 767px) {
    .AdventureDetailsPage_similar__1FxLX {
      padding: 40px 0; } }
  .AdventureDetailsPage_similar__1FxLX h2 {
    color: #333;
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 30px; }
    @media (min-width: 767px) {
      .AdventureDetailsPage_similar__1FxLX h2 {
        font-size: 34px;
        margin: 0 auto;
        max-width: 1110px;
        width: 90%; } }
  @media (min-width: 767px) {
    .AdventureDetailsPage_similar__1FxLX section {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      margin: 0 auto;
      max-width: 1110px;
      padding: 40px 0;
      width: 90%; } }

/*=========== spinner =============*/
.AdventureDetailsPage_loaderSpinner___xZRD {
  width: 100%; }

.AdventureDetailsPage_loader__2zqkk {
  animation: AdventureDetailsPage_spin__30xm8 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes AdventureDetailsPage_spin__30xm8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */

.fr-toolbar {
  border-top: 1px solid #e6e6e6;
  box-shadow: none;
  display: none; }

.fr-box {
  border-width: 0;
  max-width: 840px; }
  .fr-box p {
    margin-bottom: 0; }

.Avatar_wrapper__F3dRy {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 2px #ccc;
  text-align: left;
  width: 100%; }
  @media (max-width: 992px) {
    .Avatar_wrapper__F3dRy {
      margin-top: 55px; } }
  @media (max-width: 768px) {
    .Avatar_wrapper__F3dRy {
      border: 0;
      box-shadow: none; } }
  .Avatar_wrapper__F3dRy header {
    border-bottom: 1px solid #e6e6e6;
    padding: 20px; }
    .Avatar_wrapper__F3dRy header svg {
      fill: #666667;
      height: 8px;
      width: 8px; }
    .Avatar_wrapper__F3dRy header span {
      color: #fa9923;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 17px; }
    .Avatar_wrapper__F3dRy header h4 {
      color: #fa9923;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-top: 15px; }
    .Avatar_wrapper__F3dRy header p {
      color: #666667;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 0; }
  .Avatar_wrapper__F3dRy .Avatar_avatar__341gd {
    border-radius: 8px;
    height: 60%;
    object-fit: contain;
    overflow: hidden;
    width: 100%; }
    .Avatar_wrapper__F3dRy .Avatar_avatar__341gd img {
      height: 350px;
      object-fit: cover;
      object-position: center;
      width: 100%; }
  .Avatar_wrapper__F3dRy .Avatar_sAvatar__whmM5 img {
    border-radius: 50%;
    height: 35px;
    width: 35px; }
  .Avatar_wrapper__F3dRy h5 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    margin: 0 !important;
    text-transform: capitalize; }
  .Avatar_wrapper__F3dRy .Avatar_sGuideName__1j2lc {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 5px; }
  .Avatar_wrapper__F3dRy .Avatar_guide__1jrbn {
    display: grid;
    grid-row-gap: 3px;
    grid-template-rows: repeat(3, auto);
    text-align: left; }
    @media (max-width: 992px) {
      .Avatar_wrapper__F3dRy .Avatar_guide__1jrbn {
        display: none; } }
  .Avatar_wrapper__F3dRy .Avatar_content__2ATpH {
    display: grid;
    grid-row-gap: 20px;
    grid-template-rows: repeat(3, auto);
    padding: 20px; }
    @media (max-width: 992px) {
      .Avatar_wrapper__F3dRy .Avatar_content__2ATpH {
        display: none; } }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH .Avatar_input__5qFDY {
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      color: #333;
      display: flex;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 19px;
      padding: 7px 16px;
      position: relative; }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH .Avatar_inputMessage__jDUGx {
      align-items: unset !important; }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH .Avatar_arrow_down__vKiY4 {
      width: 13px; }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH .Avatar_errorText__1vxQ7 {
      color: #f00 !important;
      margin-bottom: 5px;
      margin-left: 5px;
      padding-top: 5px;
      text-align: left !important; }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH svg {
      fill: #333;
      height: 16px;
      margin-right: 5px;
      stroke: #333;
      width: 16px; }
    .Avatar_wrapper__F3dRy .Avatar_content__2ATpH .Avatar_arrow_down__vKiY4 {
      position: absolute;
      right: 5px; }
  .Avatar_wrapper__F3dRy button {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    text-transform: uppercase; }
  .Avatar_wrapper__F3dRy .Avatar_form_control__2y9R1 {
    border-width: 0;
    box-shadow: none;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px; }
  .Avatar_wrapper__F3dRy .Avatar_messageBox__DbbrR {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 61px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    resize: none;
    width: 100%; }
  .Avatar_wrapper__F3dRy .Avatar_travelersInput__IkrHe {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 90%; }
  .Avatar_wrapper__F3dRy .Avatar_terms__2mKjX {
    color: #666667;
    font-size: 14px;
    line-height: 18px;
    text-align: center; }
    .Avatar_wrapper__F3dRy .Avatar_terms__2mKjX a {
      color: #fa9923;
      font-weight: 600;
      text-decoration: underline; }

.GuideAdventures_wrapper__2s_bd {
  background-color: #fff;
  margin-top: 20px;
  width: 100%; }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_adventureLength__pGs9W {
    color: #666667; }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_row__rhU4Z {
    display: flex;
    flex-direction: column; }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_item__1A63E {
    margin-top: 20px;
    position: relative; }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_backgroundImage__2flTE img {
    border-radius: 8px;
    height: 120px;
    object-fit: cover;
    width: 100%; }
  @media (max-width: 768px) {
    .GuideAdventures_wrapper__2s_bd .GuideAdventures_backgroundImage__2flTE img {
      height: 280px; } }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_adventureInfo__3X2cp {
    bottom: 0;
    color: #fff;
    font-weight: 600;
    left: 50%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
  .GuideAdventures_wrapper__2s_bd .GuideAdventures_title__3An0g {
    margin-top: 15px; }

.Addition_wrapper__1LoGQ {
  background-color: #fff;
  padding: 20px;
  width: 100%; }
  .Addition_wrapper__1LoGQ .Addition_title__2jPIN {
    color: #333;
    font-weight: 600; }
  .Addition_wrapper__1LoGQ .Addition_content__2pkVa {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    margin-top: 20px; }

.Description_wrapper__4fJJi {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 2px #ccc;
  height: 100%;
  padding: 0 15px;
  text-align: left;
  width: 100%; }
  .Description_wrapper__4fJJi iframe,
  .Description_wrapper__4fJJi img {
    max-width: 100%; }
  @media (max-width: 768px) {
    .Description_wrapper__4fJJi {
      border: 0;
      box-shadow: none; } }
  .Description_wrapper__4fJJi .Description_categories__22hqn {
    color: #fa9923;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0; }
  .Description_wrapper__4fJJi .Description_name__bMpPY {
    color: #000;
    font-family: Abril Fatface;
    font-size: 38px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 20px 20px 0; }
  .Description_wrapper__4fJJi .Description_address__2taIF {
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 20px; }
  .Description_wrapper__4fJJi .Description_icons__2fNsZ {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-left: 15px; }
  .Description_wrapper__4fJJi .Description_icons_item__24GLH {
    width: 150px; }
  .Description_wrapper__4fJJi .Description_icon_container__DRRnV {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    margin-top: 14px; }
  .Description_wrapper__4fJJi .Description_icon_wrapper__18qdu {
    color: #fa9923;
    font-size: 18px;
    margin-right: 10px; }
  .Description_wrapper__4fJJi .Description_title__2nPv8 {
    font-size: 12px;
    margin-right: 10px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.GuidePage_chromeframe__2BllI {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes GuidePage_slideInTop__1i2YH {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.GuidePage_short_hr__87TYz {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.GuidePage_App__3Eby8 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .GuidePage_App__3Eby8.GuidePage_modal-open__CU90T {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.GuidePage_checkbox__2xi4S {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.GuidePage_checkboxInput__VafP3 {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.GuidePage_radioInput__3iopF {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.GuidePage_checkboxInput__VafP3:checked ~ .GuidePage_checkmark__1LULZ {
  background-color: #ff9514; }

.GuidePage_checkboxInput__VafP3:checked ~ .GuidePage_guideCheckmark__3rOrF {
  background-color: #ff9514;
  color: #fff; }

.GuidePage_radioInput__3iopF:checked ~ .GuidePage_radioMark__1lPNv {
  background-color: #fff; }

/* Create a custom checkbox */
.GuidePage_checkmark__1LULZ {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.GuidePage_guideCheckmark__3rOrF {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.GuidePage_radioMark__1lPNv {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.GuidePage_checkmark__1LULZ::after {
  content: "";
  display: none;
  position: absolute; }

.GuidePage_guideCheckmark__3rOrF::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.GuidePage_radioMark__1lPNv::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.GuidePage_checkboxInput__VafP3:checked ~ .GuidePage_checkmark__1LULZ::after {
  display: block; }

.GuidePage_checkboxInput__VafP3:checked ~ .GuidePage_guideCheckmark__3rOrF::after {
  display: block; }

.GuidePage_radioInput__3iopF:checked ~ .GuidePage_radioMark__1lPNv::after {
  display: block; }

/* Style the checkmark/indicator */
.GuidePage_checkmark__1LULZ::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.GuidePage_guideCheckmark__3rOrF::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.GuidePage_radioMark__1lPNv::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.GuidePage_loaderSpinner__1b8n4 {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.GuidePage_loader__h9Nef {
  animation: GuidePage_spin__3rqLf 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes GuidePage_spin__3rqLf {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.GuidePage_lazy-load-image-background__2WwYb {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .GuidePage_lazy-load-image-background__2WwYb img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.GuidePage_lazyImageNP__K5GCl {
  padding-top: 0 !important; }

.GuidePage_finish_google_survey__1LLBr {
  color: #666;
  text-decoration: none; }

.GuidePage_finish_google_survey__1LLBr:hover {
  color: #fff; }

.GuidePage_finish_btn__3X0nT button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.GuidePage_home-locations__1IQy4 {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .GuidePage_home-locations__1IQy4 ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .GuidePage_home-locations__1IQy4 li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .GuidePage_home-locations__1IQy4 li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .GuidePage_home-locations__1IQy4 li h3 {
        font-size: 14px; } }
  .GuidePage_home-locations__1IQy4 li img {
    height: 28px;
    width: 28px; }
  .GuidePage_home-locations__1IQy4 li .GuidePage_rightArrow__1RSjP {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.GuidePage_home-locations__1IQy4 ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .GuidePage_home-locations__1IQy4 ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .GuidePage_home-locations__1IQy4 ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .GuidePage_home-locations__1IQy4 ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .GuidePage_home-locations__1IQy4 ul li {
      width: 100%; } }

.GuidePage_location-header__1wTTy {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.GuidePage_location-text__I0ecx p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .GuidePage_location-text__I0ecx p {
      display: none; } }

.GuidePage_leftArrow__K8qCo {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .GuidePage_leftArrow__arrow__1Fm98 {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.GuidePage_rightArrow__1RSjP {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .GuidePage_rightArrow__arrow__3PUus {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.GuidePage_phone-icon__AkxR- {
  max-width: 30px; }

.GuidePage_search-filter__2EE-o.GuidePage_header-filter__37VZY button {
  width: 260px; }
  @media (max-width: 767px) {
    .GuidePage_search-filter__2EE-o.GuidePage_header-filter__37VZY button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_search-filter-bg__Sb-iw {
  background: #f1f1f2; }

.GuidePage_search-filter__2EE-o {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .GuidePage_search-filter__select__2Y0SJ {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .GuidePage_search-filter__select__dropdown-indicator__2v9zK {
      color: #333 !important; }
      .GuidePage_search-filter__select__dropdown-indicator__2v9zK svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .GuidePage_search-filter__select__clear-indicator__23pVo {
      color: #fff !important; }
      .GuidePage_search-filter__select__clear-indicator__23pVo:hover {
        color: #666 !important; }
    .GuidePage_search-filter__select__placeholder__1B4I5, .GuidePage_search-filter__select__single-value__28z20 {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .GuidePage_search-filter__select__placeholder__1B4I5 {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .GuidePage_search-filter__select__input__2P4iX input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .GuidePage_search-filter__select__value-container__3HhXK {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .GuidePage_search-filter__select__indicator__3iZGI {
      padding: 0 !important; }
    .GuidePage_search-filter__select__indicator-separator__1lZLx {
      display: none; }
    .GuidePage_search-filter__select__menu-list__2d7LS {
      padding: 0 !important; }
    .GuidePage_search-filter__select__menu__3YDr7 {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .GuidePage_search-filter__select__2Y0SJ {
        width: 100%; } }
  .GuidePage_search-filter__destination__2D04L {
    width: 260px; }
  .GuidePage_search-filter__2EE-o button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .GuidePage_search-filter__2EE-o button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .GuidePage_search-filter__2EE-o button:hover {
      border: 1px solid #dedede; }
      .GuidePage_search-filter__2EE-o button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .GuidePage_search-filter__2EE-o button {
        width: 100%; } }
  @media (max-width: 767px) {
    .GuidePage_search-filter__destination__2D04L, .GuidePage_search-filter__select__2Y0SJ,
    .GuidePage_search-filter__2EE-o button {
      margin: 8px 0;
      width: 100%; } }

.GuidePage_search-filter__select__option__3riQ1 {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .GuidePage_search-filter__select__option__3riQ1 .GuidePage_sub__1xIVE {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .GuidePage_search-filter__select__option__item__3rVF8 {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .GuidePage_search-filter__select__option__item__3rVF8 span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .GuidePage_search-filter__select__option__item__3rVF8 img {
      display: block;
      height: 100%;
      width: 100%; }
  .GuidePage_search-filter__select__option__3riQ1:hover, .GuidePage_search-filter__select__option__selected__DdNB7 {
    background: rgba(255, 149, 20, 0.1); }
    .GuidePage_search-filter__select__option__3riQ1:hover .GuidePage_sub__1xIVE, .GuidePage_search-filter__select__option__selected__DdNB7 .GuidePage_sub__1xIVE {
      color: #ff8c00; }
    .GuidePage_search-filter__select__option__3riQ1:hover.GuidePage_search-filter__select__option__3riQ1:hover span, .GuidePage_search-filter__select__option__3riQ1:hover .GuidePage_search-filter__select__option__selected__item__OukNa span,
    .GuidePage_search-filter__select__option__3riQ1:hover .GuidePage_search-filter__select__option__item__3rVF8 span, .GuidePage_search-filter__select__option__selected__DdNB7.GuidePage_search-filter__select__option__3riQ1:hover span, .GuidePage_search-filter__select__option__selected__DdNB7 .GuidePage_search-filter__select__option__selected__item__OukNa span,
    .GuidePage_search-filter__select__option__selected__DdNB7 .GuidePage_search-filter__select__option__item__3rVF8 span {
      background: #ff8c00; }
      .GuidePage_search-filter__select__option__3riQ1:hover.GuidePage_search-filter__select__option__3riQ1:hover span::before, .GuidePage_search-filter__select__option__3riQ1:hover .GuidePage_search-filter__select__option__selected__item__OukNa span::before,
      .GuidePage_search-filter__select__option__3riQ1:hover .GuidePage_search-filter__select__option__item__3rVF8 span::before, .GuidePage_search-filter__select__option__selected__DdNB7.GuidePage_search-filter__select__option__3riQ1:hover span::before, .GuidePage_search-filter__select__option__selected__DdNB7 .GuidePage_search-filter__select__option__selected__item__OukNa span::before,
      .GuidePage_search-filter__select__option__selected__DdNB7 .GuidePage_search-filter__select__option__item__3rVF8 span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.GuidePage_search-filter__select__control__3BbpP {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .GuidePage_search-filter__select__control__3BbpP .GuidePage_css-1g6gooi__3xSTW {
    margin: 0 !important;
    padding: 0 !important; }
  .GuidePage_search-filter__select__control__focused__1TEwW, .GuidePage_search-filter__select__control__isOpen__18RQW, .GuidePage_search-filter__select__control__3BbpP:hover {
    border-color: #ff9514; }
  .GuidePage_search-filter__select__control__fill__3Zz5m {
    background-color: #ff9514;
    border-color: #ff9514; }
    .GuidePage_search-filter__select__control__fill__3Zz5m .GuidePage_search-filter__select__dropdown-indicator__2v9zK,
    .GuidePage_search-filter__select__control__fill__3Zz5m .GuidePage_search-filter__select__single-value__28z20,
    .GuidePage_search-filter__select__control__fill__3Zz5m .GuidePage_search-filter__select__placeholder__1B4I5 {
      color: #fff !important; }
    .GuidePage_search-filter__select__control__fill__3Zz5m .GuidePage_search-filter__select__input__2P4iX input {
      color: #fff !important; }
  .GuidePage_search-filter__select__control__isOpen__18RQW .GuidePage_search-filter__select__dropdown-indicator__2v9zK svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_adventure-about__1mQqU {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .GuidePage_adventure-about__1mQqU {
      padding: 5px 0; } }
  .GuidePage_adventure-about__container__3kLf7 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .GuidePage_adventure-about__container__3kLf7 {
        padding: 0 10px; } }
  .GuidePage_adventure-about__author__pgviN {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .GuidePage_adventure-about__author__pgviN img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .GuidePage_adventure-about__author__pgviN span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .GuidePage_adventure-about__author__pgviN {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .GuidePage_adventure-about__author__pgviN img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .GuidePage_adventure-about__author__pgviN span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .GuidePage_adventure-about__rate__1YFwa {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .GuidePage_adventure-about__rate__1YFwa > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .GuidePage_adventure-about__rate__1YFwa {
        display: none; } }
  .GuidePage_adventure-about__buttons__2tWyJ {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .GuidePage_adventure-about__buttons__2tWyJ button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .GuidePage_adventure-about__buttons__2tWyJ button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .GuidePage_adventure-about__buttons__2tWyJ button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .GuidePage_adventure-about__buttons__2tWyJ button {
          width: 120px; } }
      @media (max-width: 576px) {
        .GuidePage_adventure-about__buttons__2tWyJ button {
          margin-right: 10px;
          width: 90px; } }
    .GuidePage_adventure-about__buttons__check-dates__3CYK6 {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .GuidePage_adventure-about__buttons__request__BrcgB {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_guides-footer__2LRON {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .GuidePage_guides-footer__author__3FF7H {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .GuidePage_guides-footer__author__3FF7H img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .GuidePage_guides-footer__author__3FF7H span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .GuidePage_guides-footer__author__3FF7H {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .GuidePage_guides-footer__author__3FF7H img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .GuidePage_guides-footer__author__3FF7H span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .GuidePage_guides-footer__rate__36l7e {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .GuidePage_guides-footer__rate__36l7e > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .GuidePage_guides-footer__rate__36l7e {
        display: none; } }
  .GuidePage_guides-footer__button__3VX_g {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .GuidePage_guides-footer__button__3VX_g {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .GuidePage_guides-footer__button__3VX_g {
        width: 120px; } }
    @media (max-width: 576px) {
      .GuidePage_guides-footer__button__3VX_g {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_guide-modal__2p2B8 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .GuidePage_guide-modal__close__kuagj {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .GuidePage_guide-modal__close__kuagj:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .GuidePage_guide-modal__2p2B8 {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .GuidePage_guide-modal__2p2B8 {
      padding: 10px;
      width: 300px; } }

.GuidePage_guide-modal__header__2BeRz {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .GuidePage_guide-modal__header__photo__1IYUQ {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .GuidePage_guide-modal__header__photo__1IYUQ img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .GuidePage_guide-modal__header__info__2hYUA {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .GuidePage_guide-modal__header__info__2hYUA p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .GuidePage_guide-modal__header__info__2hYUA div {
      display: flex; }
      .GuidePage_guide-modal__header__info__2hYUA div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .GuidePage_guide-modal__header__info__2hYUA div span {
            text-align: center; } }
        .GuidePage_guide-modal__header__info__2hYUA div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .GuidePage_guide-modal__header__info__2hYUA div span:last-child {
          margin-right: 0; }
          .GuidePage_guide-modal__header__info__2hYUA div span:last-child::after {
            display: none; }

.GuidePage_guide-modal__body__3uSfk {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .GuidePage_guide-modal__body__error__3RBxr .GuidePage_guide-modal__body__item__1DdeB {
    border-color: #f00; }
  .GuidePage_guide-modal__body__error__3RBxr p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .GuidePage_guide-modal__body__item__1DdeB {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .GuidePage_guide-modal__body__item__1DdeB img,
    .GuidePage_guide-modal__body__item__1DdeB svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .GuidePage_guide-modal__body__item__1DdeB input,
    .GuidePage_guide-modal__body__item__1DdeB textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .GuidePage_guide-modal__body__item__1DdeB textarea {
      height: 70px;
      resize: none; }
    .GuidePage_guide-modal__body__item__down__1tof5 {
      position: absolute;
      right: 9px; }
  .GuidePage_guide-modal__body__3uSfk .GuidePage_react-datepicker-wrapper__HyOmm,
  .GuidePage_guide-modal__body__3uSfk .GuidePage_react-datepicker__input-container__YEaMM {
    width: 100%; }
  .GuidePage_guide-modal__body__3uSfk .GuidePage_react-datepicker___kpCm .GuidePage_react-datepicker__day__6ZjW0:not(.GuidePage_react-datepicker__day--disabled__3UtD3) {
    background: #fa9923;
    border-radius: 4.8px; }
    .GuidePage_guide-modal__body__3uSfk .GuidePage_react-datepicker___kpCm .GuidePage_react-datepicker__day__6ZjW0:not(.GuidePage_react-datepicker__day--disabled__3UtD3):hover {
      color: #fff;
      font-weight: bold; }
  .GuidePage_guide-modal__body__submit__16Xn6 {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .GuidePage_guide-modal__body__submit__16Xn6 .GuidePage_loader__h9Nef {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.GuidePage_guide-modal__footer__bUde2 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .GuidePage_guide-modal__footer__bUde2 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .GuidePage_guide-modal__footer__bUde2 label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .GuidePage_guide-modal__footer__bUde2 a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .GuidePage_guide-modal__footer__bUde2 input {
    left: -9999px;
    position: absolute; }
  .GuidePage_guide-modal__footer__bUde2 span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .GuidePage_guide-modal__footer__bUde2 input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_modal-overlay__1q0JP {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.GuidePage_modal-popup__3SIiK {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .GuidePage_modal-popup__close__2Nlo- {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .GuidePage_modal-popup__close__2Nlo-:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .GuidePage_modal-popup__3SIiK {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .GuidePage_modal-popup__3SIiK {
      padding: 10px;
      width: 300px; } }

.GuidePage_modal-open__CU90T {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .GuidePage_modal-open__CU90T .GuidePage_fixed-footer__24IRM {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.GuidePage_fixed-footer__24IRM {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .GuidePage_fixed-footer__24IRM {
      padding: 5px 0; } }
  .GuidePage_fixed-footer__container__3JEGA {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .GuidePage_fixed-footer__container__3JEGA {
        padding: 0 10px; } }
  .GuidePage_fixed-footer__24IRM.GuidePage_hidden__19a7e {
    bottom: -80px; }

.GuidePage_wrapper__8VLhV {
  display: block;
  margin-top: 70px; }
  .GuidePage_wrapper__8VLhV .GuidePage_row__3T1Iu {
    padding: 0 15px; }
    @media (min-width: 767px) {
      .GuidePage_wrapper__8VLhV .GuidePage_row__3T1Iu {
        margin: 0 auto;
        max-width: 1110px;
        width: 100%; } }
  .GuidePage_wrapper__8VLhV .GuidePage_mobile__zXfzt {
    background-color: #fff;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 20; }
    @media (min-width: 768px) {
      .GuidePage_wrapper__8VLhV .GuidePage_mobile__zXfzt {
        display: none; } }

/*=========== spinner =============*/
.GuidePage_loaderSpinner__1b8n4 {
  width: 100%; }

.GuidePage_loader__h9Nef {
  animation: GuidePage_spin__3rqLf 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes GuidePage_spin__3rqLf {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */

.fr-wrapper {
  box-shadow: none !important; }

.fr-toolbar {
  border-top: 1px solid #e6e6e6;
  box-shadow: none;
  display: none; }

.fr-box {
  max-width: 840px; }
  .fr-box p {
    margin-bottom: 0; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.GuideAgreementDoc_chromeframe__1UI5o {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes GuideAgreementDoc_slideInTop__y-kS5 {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.GuideAgreementDoc_short_hr__HeCCC {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.GuideAgreementDoc_App__AqKdf {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .GuideAgreementDoc_App__AqKdf.GuideAgreementDoc_modal-open__3sVuE {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.GuideAgreementDoc_checkbox__1SH-A {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.GuideAgreementDoc_checkboxInput__FiuzM {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.GuideAgreementDoc_radioInput__3JgXS {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.GuideAgreementDoc_checkboxInput__FiuzM:checked ~ .GuideAgreementDoc_checkmark__3LJUY {
  background-color: #ff9514; }

.GuideAgreementDoc_checkboxInput__FiuzM:checked ~ .GuideAgreementDoc_guideCheckmark__20owZ {
  background-color: #ff9514;
  color: #fff; }

.GuideAgreementDoc_radioInput__3JgXS:checked ~ .GuideAgreementDoc_radioMark__3zut_ {
  background-color: #fff; }

/* Create a custom checkbox */
.GuideAgreementDoc_checkmark__3LJUY {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.GuideAgreementDoc_guideCheckmark__20owZ {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.GuideAgreementDoc_radioMark__3zut_ {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.GuideAgreementDoc_checkmark__3LJUY::after {
  content: "";
  display: none;
  position: absolute; }

.GuideAgreementDoc_guideCheckmark__20owZ::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.GuideAgreementDoc_radioMark__3zut_::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.GuideAgreementDoc_checkboxInput__FiuzM:checked ~ .GuideAgreementDoc_checkmark__3LJUY::after {
  display: block; }

.GuideAgreementDoc_checkboxInput__FiuzM:checked ~ .GuideAgreementDoc_guideCheckmark__20owZ::after {
  display: block; }

.GuideAgreementDoc_radioInput__3JgXS:checked ~ .GuideAgreementDoc_radioMark__3zut_::after {
  display: block; }

/* Style the checkmark/indicator */
.GuideAgreementDoc_checkmark__3LJUY::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.GuideAgreementDoc_guideCheckmark__20owZ::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.GuideAgreementDoc_radioMark__3zut_::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.GuideAgreementDoc_loaderSpinner__2CJ4H {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.GuideAgreementDoc_loader__1v2CJ {
  animation: GuideAgreementDoc_spin__1rt5c 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes GuideAgreementDoc_spin__1rt5c {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.GuideAgreementDoc_lazy-load-image-background__2jpmh {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .GuideAgreementDoc_lazy-load-image-background__2jpmh img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.GuideAgreementDoc_lazyImageNP__3UQ2v {
  padding-top: 0 !important; }

.GuideAgreementDoc_finish_google_survey__1E7tW {
  color: #666;
  text-decoration: none; }

.GuideAgreementDoc_finish_google_survey__1E7tW:hover {
  color: #fff; }

.GuideAgreementDoc_finish_btn__3sKvR button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.GuideAgreementDoc_home-locations__2z3as {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .GuideAgreementDoc_home-locations__2z3as ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .GuideAgreementDoc_home-locations__2z3as li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .GuideAgreementDoc_home-locations__2z3as li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .GuideAgreementDoc_home-locations__2z3as li h3 {
        font-size: 14px; } }
  .GuideAgreementDoc_home-locations__2z3as li img {
    height: 28px;
    width: 28px; }
  .GuideAgreementDoc_home-locations__2z3as li .GuideAgreementDoc_rightArrow__36bWW {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.GuideAgreementDoc_home-locations__2z3as ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .GuideAgreementDoc_home-locations__2z3as ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .GuideAgreementDoc_home-locations__2z3as ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .GuideAgreementDoc_home-locations__2z3as ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .GuideAgreementDoc_home-locations__2z3as ul li {
      width: 100%; } }

.GuideAgreementDoc_location-header__2fGf6 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.GuideAgreementDoc_location-text__2jqHZ p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .GuideAgreementDoc_location-text__2jqHZ p {
      display: none; } }

.GuideAgreementDoc_leftArrow__2_m4Z {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .GuideAgreementDoc_leftArrow__arrow__2JPqH {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.GuideAgreementDoc_rightArrow__36bWW {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .GuideAgreementDoc_rightArrow__arrow__2YEIF {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.GuideAgreementDoc_phone-icon__29GWh {
  max-width: 30px; }

.GuideAgreementDoc_search-filter__2T8km.GuideAgreementDoc_header-filter__PCv35 button {
  width: 260px; }
  @media (max-width: 767px) {
    .GuideAgreementDoc_search-filter__2T8km.GuideAgreementDoc_header-filter__PCv35 button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_search-filter-bg__1h7KC {
  background: #f1f1f2; }

.GuideAgreementDoc_search-filter__2T8km {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .GuideAgreementDoc_search-filter__select__3JJGW {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .GuideAgreementDoc_search-filter__select__dropdown-indicator__1lH5K {
      color: #333 !important; }
      .GuideAgreementDoc_search-filter__select__dropdown-indicator__1lH5K svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .GuideAgreementDoc_search-filter__select__clear-indicator__KnQMo {
      color: #fff !important; }
      .GuideAgreementDoc_search-filter__select__clear-indicator__KnQMo:hover {
        color: #666 !important; }
    .GuideAgreementDoc_search-filter__select__placeholder__3BQ7V, .GuideAgreementDoc_search-filter__select__single-value__6c5mU {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .GuideAgreementDoc_search-filter__select__placeholder__3BQ7V {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .GuideAgreementDoc_search-filter__select__input__1D_CI input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .GuideAgreementDoc_search-filter__select__value-container__17JcG {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .GuideAgreementDoc_search-filter__select__indicator__2kR5V {
      padding: 0 !important; }
    .GuideAgreementDoc_search-filter__select__indicator-separator__2FXg3 {
      display: none; }
    .GuideAgreementDoc_search-filter__select__menu-list__1xw3L {
      padding: 0 !important; }
    .GuideAgreementDoc_search-filter__select__menu__YEF1g {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .GuideAgreementDoc_search-filter__select__3JJGW {
        width: 100%; } }
  .GuideAgreementDoc_search-filter__destination__2zVX1 {
    width: 260px; }
  .GuideAgreementDoc_search-filter__2T8km button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .GuideAgreementDoc_search-filter__2T8km button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .GuideAgreementDoc_search-filter__2T8km button:hover {
      border: 1px solid #dedede; }
      .GuideAgreementDoc_search-filter__2T8km button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .GuideAgreementDoc_search-filter__2T8km button {
        width: 100%; } }
  @media (max-width: 767px) {
    .GuideAgreementDoc_search-filter__destination__2zVX1, .GuideAgreementDoc_search-filter__select__3JJGW,
    .GuideAgreementDoc_search-filter__2T8km button {
      margin: 8px 0;
      width: 100%; } }

.GuideAgreementDoc_search-filter__select__option__3oDfO {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .GuideAgreementDoc_search-filter__select__option__3oDfO .GuideAgreementDoc_sub__3f322 {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .GuideAgreementDoc_search-filter__select__option__item__3L9OT {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .GuideAgreementDoc_search-filter__select__option__item__3L9OT span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .GuideAgreementDoc_search-filter__select__option__item__3L9OT img {
      display: block;
      height: 100%;
      width: 100%; }
  .GuideAgreementDoc_search-filter__select__option__3oDfO:hover, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy {
    background: rgba(255, 149, 20, 0.1); }
    .GuideAgreementDoc_search-filter__select__option__3oDfO:hover .GuideAgreementDoc_sub__3f322, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy .GuideAgreementDoc_sub__3f322 {
      color: #ff8c00; }
    .GuideAgreementDoc_search-filter__select__option__3oDfO:hover.GuideAgreementDoc_search-filter__select__option__3oDfO:hover span, .GuideAgreementDoc_search-filter__select__option__3oDfO:hover .GuideAgreementDoc_search-filter__select__option__selected__item__ARNEm span,
    .GuideAgreementDoc_search-filter__select__option__3oDfO:hover .GuideAgreementDoc_search-filter__select__option__item__3L9OT span, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy.GuideAgreementDoc_search-filter__select__option__3oDfO:hover span, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy .GuideAgreementDoc_search-filter__select__option__selected__item__ARNEm span,
    .GuideAgreementDoc_search-filter__select__option__selected__3ohWy .GuideAgreementDoc_search-filter__select__option__item__3L9OT span {
      background: #ff8c00; }
      .GuideAgreementDoc_search-filter__select__option__3oDfO:hover.GuideAgreementDoc_search-filter__select__option__3oDfO:hover span::before, .GuideAgreementDoc_search-filter__select__option__3oDfO:hover .GuideAgreementDoc_search-filter__select__option__selected__item__ARNEm span::before,
      .GuideAgreementDoc_search-filter__select__option__3oDfO:hover .GuideAgreementDoc_search-filter__select__option__item__3L9OT span::before, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy.GuideAgreementDoc_search-filter__select__option__3oDfO:hover span::before, .GuideAgreementDoc_search-filter__select__option__selected__3ohWy .GuideAgreementDoc_search-filter__select__option__selected__item__ARNEm span::before,
      .GuideAgreementDoc_search-filter__select__option__selected__3ohWy .GuideAgreementDoc_search-filter__select__option__item__3L9OT span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.GuideAgreementDoc_search-filter__select__control__2DZV1 {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .GuideAgreementDoc_search-filter__select__control__2DZV1 .GuideAgreementDoc_css-1g6gooi__3uEG- {
    margin: 0 !important;
    padding: 0 !important; }
  .GuideAgreementDoc_search-filter__select__control__focused__VvVy1, .GuideAgreementDoc_search-filter__select__control__isOpen__e1rb3, .GuideAgreementDoc_search-filter__select__control__2DZV1:hover {
    border-color: #ff9514; }
  .GuideAgreementDoc_search-filter__select__control__fill__2U-mp {
    background-color: #ff9514;
    border-color: #ff9514; }
    .GuideAgreementDoc_search-filter__select__control__fill__2U-mp .GuideAgreementDoc_search-filter__select__dropdown-indicator__1lH5K,
    .GuideAgreementDoc_search-filter__select__control__fill__2U-mp .GuideAgreementDoc_search-filter__select__single-value__6c5mU,
    .GuideAgreementDoc_search-filter__select__control__fill__2U-mp .GuideAgreementDoc_search-filter__select__placeholder__3BQ7V {
      color: #fff !important; }
    .GuideAgreementDoc_search-filter__select__control__fill__2U-mp .GuideAgreementDoc_search-filter__select__input__1D_CI input {
      color: #fff !important; }
  .GuideAgreementDoc_search-filter__select__control__isOpen__e1rb3 .GuideAgreementDoc_search-filter__select__dropdown-indicator__1lH5K svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_adventure-about__3N57P {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .GuideAgreementDoc_adventure-about__3N57P {
      padding: 5px 0; } }
  .GuideAgreementDoc_adventure-about__container__3UF0c {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .GuideAgreementDoc_adventure-about__container__3UF0c {
        padding: 0 10px; } }
  .GuideAgreementDoc_adventure-about__author__zS3nM {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .GuideAgreementDoc_adventure-about__author__zS3nM img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .GuideAgreementDoc_adventure-about__author__zS3nM span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .GuideAgreementDoc_adventure-about__author__zS3nM {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .GuideAgreementDoc_adventure-about__author__zS3nM img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .GuideAgreementDoc_adventure-about__author__zS3nM span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .GuideAgreementDoc_adventure-about__rate__20BjN {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .GuideAgreementDoc_adventure-about__rate__20BjN > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .GuideAgreementDoc_adventure-about__rate__20BjN {
        display: none; } }
  .GuideAgreementDoc_adventure-about__buttons__2peRI {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .GuideAgreementDoc_adventure-about__buttons__2peRI button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .GuideAgreementDoc_adventure-about__buttons__2peRI button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .GuideAgreementDoc_adventure-about__buttons__2peRI button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .GuideAgreementDoc_adventure-about__buttons__2peRI button {
          width: 120px; } }
      @media (max-width: 576px) {
        .GuideAgreementDoc_adventure-about__buttons__2peRI button {
          margin-right: 10px;
          width: 90px; } }
    .GuideAgreementDoc_adventure-about__buttons__check-dates__3PFG- {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .GuideAgreementDoc_adventure-about__buttons__request__1zeBQ {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_guides-footer__1w26D {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .GuideAgreementDoc_guides-footer__author__3nyp0 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .GuideAgreementDoc_guides-footer__author__3nyp0 img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .GuideAgreementDoc_guides-footer__author__3nyp0 span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .GuideAgreementDoc_guides-footer__author__3nyp0 {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .GuideAgreementDoc_guides-footer__author__3nyp0 img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .GuideAgreementDoc_guides-footer__author__3nyp0 span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .GuideAgreementDoc_guides-footer__rate__zQ2FO {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .GuideAgreementDoc_guides-footer__rate__zQ2FO > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .GuideAgreementDoc_guides-footer__rate__zQ2FO {
        display: none; } }
  .GuideAgreementDoc_guides-footer__button__1LGvd {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .GuideAgreementDoc_guides-footer__button__1LGvd {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .GuideAgreementDoc_guides-footer__button__1LGvd {
        width: 120px; } }
    @media (max-width: 576px) {
      .GuideAgreementDoc_guides-footer__button__1LGvd {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_guide-modal__v9i3j {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .GuideAgreementDoc_guide-modal__close__3InVc {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .GuideAgreementDoc_guide-modal__close__3InVc:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .GuideAgreementDoc_guide-modal__v9i3j {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .GuideAgreementDoc_guide-modal__v9i3j {
      padding: 10px;
      width: 300px; } }

.GuideAgreementDoc_guide-modal__header__WqBJs {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .GuideAgreementDoc_guide-modal__header__photo__gu3kL {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .GuideAgreementDoc_guide-modal__header__photo__gu3kL img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .GuideAgreementDoc_guide-modal__header__info__3Hddt {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .GuideAgreementDoc_guide-modal__header__info__3Hddt p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .GuideAgreementDoc_guide-modal__header__info__3Hddt div {
      display: flex; }
      .GuideAgreementDoc_guide-modal__header__info__3Hddt div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .GuideAgreementDoc_guide-modal__header__info__3Hddt div span {
            text-align: center; } }
        .GuideAgreementDoc_guide-modal__header__info__3Hddt div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .GuideAgreementDoc_guide-modal__header__info__3Hddt div span:last-child {
          margin-right: 0; }
          .GuideAgreementDoc_guide-modal__header__info__3Hddt div span:last-child::after {
            display: none; }

.GuideAgreementDoc_guide-modal__body__3OCan {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .GuideAgreementDoc_guide-modal__body__error__2QowI .GuideAgreementDoc_guide-modal__body__item__3B1R5 {
    border-color: #f00; }
  .GuideAgreementDoc_guide-modal__body__error__2QowI p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .GuideAgreementDoc_guide-modal__body__item__3B1R5 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .GuideAgreementDoc_guide-modal__body__item__3B1R5 img,
    .GuideAgreementDoc_guide-modal__body__item__3B1R5 svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .GuideAgreementDoc_guide-modal__body__item__3B1R5 input,
    .GuideAgreementDoc_guide-modal__body__item__3B1R5 textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .GuideAgreementDoc_guide-modal__body__item__3B1R5 textarea {
      height: 70px;
      resize: none; }
    .GuideAgreementDoc_guide-modal__body__item__down__2QTjn {
      position: absolute;
      right: 9px; }
  .GuideAgreementDoc_guide-modal__body__3OCan .GuideAgreementDoc_react-datepicker-wrapper__V7r7W,
  .GuideAgreementDoc_guide-modal__body__3OCan .GuideAgreementDoc_react-datepicker__input-container__2Vawh {
    width: 100%; }
  .GuideAgreementDoc_guide-modal__body__3OCan .GuideAgreementDoc_react-datepicker__2PYgs .GuideAgreementDoc_react-datepicker__day__3SvE4:not(.GuideAgreementDoc_react-datepicker__day--disabled__22Ly8) {
    background: #fa9923;
    border-radius: 4.8px; }
    .GuideAgreementDoc_guide-modal__body__3OCan .GuideAgreementDoc_react-datepicker__2PYgs .GuideAgreementDoc_react-datepicker__day__3SvE4:not(.GuideAgreementDoc_react-datepicker__day--disabled__22Ly8):hover {
      color: #fff;
      font-weight: bold; }
  .GuideAgreementDoc_guide-modal__body__submit__1sDWi {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .GuideAgreementDoc_guide-modal__body__submit__1sDWi .GuideAgreementDoc_loader__1v2CJ {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.GuideAgreementDoc_guide-modal__footer__19BUn {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .GuideAgreementDoc_guide-modal__footer__19BUn p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .GuideAgreementDoc_guide-modal__footer__19BUn label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .GuideAgreementDoc_guide-modal__footer__19BUn a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .GuideAgreementDoc_guide-modal__footer__19BUn input {
    left: -9999px;
    position: absolute; }
  .GuideAgreementDoc_guide-modal__footer__19BUn span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .GuideAgreementDoc_guide-modal__footer__19BUn input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_modal-overlay__2tDdO {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.GuideAgreementDoc_modal-popup__1dbbP {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .GuideAgreementDoc_modal-popup__close__1wY-q {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .GuideAgreementDoc_modal-popup__close__1wY-q:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .GuideAgreementDoc_modal-popup__1dbbP {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .GuideAgreementDoc_modal-popup__1dbbP {
      padding: 10px;
      width: 300px; } }

.GuideAgreementDoc_modal-open__3sVuE {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .GuideAgreementDoc_modal-open__3sVuE .GuideAgreementDoc_fixed-footer__WuInV {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.GuideAgreementDoc_fixed-footer__WuInV {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .GuideAgreementDoc_fixed-footer__WuInV {
      padding: 5px 0; } }
  .GuideAgreementDoc_fixed-footer__container__1d_g7 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .GuideAgreementDoc_fixed-footer__container__1d_g7 {
        padding: 0 10px; } }
  .GuideAgreementDoc_fixed-footer__WuInV.GuideAgreementDoc_hidden__7tHBC {
    bottom: -80px; }

.GuideAgreementDoc_wrapper__1T1Xs {
  color: #666;
  font-family: "Source Sans Pro, sans-serif";
  position: relative; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_row__2c2Io {
    background: #efefef;
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px; }
    @media (min-width: 767px) {
      .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_row__2c2Io {
        margin: 40px auto;
        max-width: 1110px;
        width: 100%; } }
  .GuideAgreementDoc_wrapper__1T1Xs h1 {
    color: #999;
    font-size: 77px;
    margin-top: 10px; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_guide_agreement__WW-bI {
    color: #999;
    font-family: "Source Sans Pro, sans-serif";
    font-size: x-large;
    font-weight: 700;
    line-height: 28px;
    margin-top: 20px; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_tag__2AVud {
    color: #999;
    font-family: "Source Sans Pro, sans-serif";
    font-size: 18px;
    font-weight: 900;
    line-height: 19.6px;
    margin: 10px 0; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_privacy_list__1qbHm {
    color: #999;
    font-family: "Source Sans Pro, sans-serif";
    font-size: 18px;
    font-weight: 900;
    line-height: 24.6px;
    margin: 10px 0; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_profile_head__2NjOx {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 26px;
    font-weight: 600;
    padding: 21px 0;
    text-transform: capitalize; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_list_txt__30IO7 {
    color: #999;
    font-family: "Source Sans Pro, sans-serif";
    font-weight: 600;
    line-height: 25.2px; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_compensation_data__1-t7F {
    border-top: 1px solid rgba(153, 153, 153, 0.2);
    font-family: "Source Sans Pro, sans-serif";
    font-size: 18px;
    line-height: 25.2px;
    margin-bottom: 10px; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_compensation_data_nb__6rwzz {
    font-family: "Source Sans Pro, sans-serif";
    font-size: 18px;
    font-weight: 600px;
    line-height: 25.2px;
    margin-bottom: 10px; }
  .GuideAgreementDoc_wrapper__1T1Xs .GuideAgreementDoc_sublst__38Jou {
    font-family: "Source Sans Pro, sans-serif";
    font-size: 18px;
    line-height: 25.2px;
    margin-bottom: 10px;
    padding-left: 50px; }

.container {
  margin: auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 991px) {
    .container {
      padding-left: 38px;
      padding-right: 38px; } }
  @media (max-width: 576px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }

.flex {
  display: flex; }
  .flex.justify-content {
    justify-content: space-between; }

.header {
  padding-top: 24px; }

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 90px;
  position: relative; }
  .hero .content {
    padding: 170px 0; }
    @media (max-width: 991px) {
      .hero .content {
        padding: 113px 0; } }
    @media (max-width: 576px) {
      .hero .content {
        padding: 97px 0 98px; } }
    .hero .content h3 {
      color: #fff;
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-bottom: 0;
      text-transform: uppercase; }
    .hero .content p {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 40px;
      margin-top: 16px;
      max-width: 488px;
      opacity: 0.9; }
    .hero .content a {
      display: inline-block; }
  .hero h1 {
    color: #fff;
    font-family: "Abril Fatface", cursive;
    font-size: 54px;
    font-weight: 400;
    line-height: 73px; }
    @media (max-width: 576px) {
      .hero h1 {
        font-size: 44px;
        line-height: 52px; } }

.ladakh.hero .content {
  margin: auto;
  max-width: 740px;
  padding: 40px 0 90px;
  text-align: center; }
  .ladakh.hero .content h1 {
    margin: auto;
    max-width: 500px; }
  .ladakh.hero .content p {
    max-width: 1060px; }

.row {
  display: flex;
  flex-flow: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.adventures {
  background-color: transparent;
  margin-top: -130px;
  overflow: hidden;
  padding-bottom: 120px;
  padding-top: 30px;
  position: relative;
  z-index: 100; }
  @media (max-width: 991px) {
    .adventures {
      padding-bottom: 60px;
      padding-top: 60px; } }
  @media (max-width: 576px) {
    .adventures {
      padding-bottom: 40px;
      padding-top: 40px; } }
  .adventures::before {
    background: #f8f8f9;
    content: "";
    height: 100%;
    position: absolute;
    top: 130px;
    width: 100%;
    z-index: -1; }
  .adventures .title {
    margin-bottom: 60px; }
    @media (max-width: 576px) {
      .adventures .title {
        margin-bottom: 30px; } }
  .adventures .adventures-item {
    padding: 0 15px;
    width: 50%; }
    @media (max-width: 991px) {
      .adventures .adventures-item {
        margin: 0 auto 40px;
        max-width: 100%;
        width: 100%; } }
    @media (max-width: 991px) {
      .adventures .adventures-item {
        max-width: 100%; } }
    .adventures .adventures-item .main-img {
      border-radius: 6px;
      max-width: 100%; }
    .adventures .adventures-item .status {
      color: #ff9514;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 6px;
      margin-top: 18px;
      text-transform: uppercase; }

.adventures-item h2 {
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 38px;
  margin-bottom: 8px; }
  @media (max-width: 991px) {
    .adventures-item h2 {
      font-size: 24px;
      line-height: 31px; } }

.adventures-item .place {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-bottom: 20px; }
  .adventures-item .place img {
    margin-right: 10px;
    vertical-align: middle; }

.adventures-item .description {
  margin-bottom: 20px; }
  .adventures-item .description a {
    color: #ff9514;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px; }
  .adventures-item .description p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 8px;
    margin-top: 9px; }

.shop {
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  padding: 30px; }
  @media (max-width: 576px) {
    .shop {
      flex-flow: wrap;
      padding: 20px 15px;
      position: relative; } }
  .shop b {
    display: block;
    margin-bottom: 7px; }
  .shop .regular-price {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: normal;
    line-height: 20px; }
    @media (max-width: 576px) {
      .shop .regular-price {
        margin-bottom: 15px;
        width: 100%; } }
  .shop .promotional-price {
    color: #333;
    font-size: 16px;
    line-height: 20px; }
    @media (max-width: 576px) {
      .shop .promotional-price {
        width: 100%; } }
    .shop .promotional-price span {
      color: #ff9514; }
  @media (max-width: 576px) {
    .shop .book-now {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0; } }
  .shop .book-now .orange-btn {
    display: inline-block;
    padding: 15.5px 10px; }

@media (max-width: 576px) {
  .book-now button {
    border-radius: 0;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 100%; } }

.ladakh.adventures .adventures-item {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 33.333%; }
  @media (max-width: 991px) {
    .ladakh.adventures .adventures-item {
      margin: 0 auto 40px;
      max-width: 330px;
      width: 50%; } }
  @media (max-width: 767px) {
    .ladakh.adventures .adventures-item {
      max-width: 100%;
      width: 100%; } }
  .ladakh.adventures .adventures-item h2 {
    font-size: 20px; }

.ladakh.adventures .adventures-item .main-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 380px;
  object-fit: cover;
  width: 100%; }

.ladakh.adventures .adventures-item .status {
  margin-bottom: 0;
  margin-top: 0; }

.ladakh.adventures .box {
  background: #fff;
  padding: 21px 20px; }

.ladakh.adventures .shop {
  align-items: center;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  padding: 0;
  padding-top: 20px; }

.ladakh.adventures .place {
  margin-bottom: 12px; }

.ladakh.adventures .regular-price span {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; }

.ladakh .description p {
  font-size: 14px;
  line-height: 24px;
  margin-top: 9px; }

@media (max-width: 576px) {
  .ladakh .shop .regular-price {
    width: auto; } }

@media (max-width: 576px) {
  .ladakh .shop .book-now {
    height: auto;
    position: relative;
    right: 0;
    top: 0; } }

.imageInfo {
  position: relative; }
  .imageInfo .review {
    background: transparent;
    border-radius: 0;
    bottom: 0;
    justify-content: inherit;
    margin: 0;
    position: absolute;
    width: 100%; }
    .imageInfo .review img {
      border-radius: 50%; }
  .imageInfo .rating b {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; }
  .imageInfo .rating li {
    color: #fff; }

.rating li {
  color: #666;
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-right: 6px; }
  .rating li .fa-circle {
    font-size: 4px;
    margin-right: 6px;
    vertical-align: middle; }

.review {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 20px; }
  @media (max-width: 576px) {
    .review {
      flex-flow: wrap;
      padding: 15px 20px 0; } }
  .review img {
    margin-right: 15px;
    vertical-align: middle;
    width: 42px; }

@media (max-width: 576px) {
  .review .user {
    width: 100%; } }

.review .user b {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; }
  @media (max-width: 576px) {
    .review .user b {
      position: relative;
      top: -5px; } }

.adventures-item .card {
  background-color: transparent;
  border: 0; }

/*********css for general****************/
.send_message {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 999; }
  .send_message h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0;
    margin-top: 20px !important; }
  .send_message p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px; }

.send_center_div {
  background: #fff;
  border-radius: 5px;
  left: 50%;
  margin: auto;
  padding: auto;
  padding: 15px 17px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%; }

@media (min-width: 768px) {
  .send_center_div {
    width: 400px !important; } }

.noticePara {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-bottom: 10px;
  width: 60%; }

.emailField {
  margin-bottom: 20px !important;
  margin-top: 20px !important; }

.cancelText {
  background: none !important;
  border: 0 !important;
  color: #000 !important;
  float: right !important;
  font-size: 18px !important; }

.signup_link {
  text-decoration: none; }

.person_no {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 0 auto;
  padding: 0 0 0 12px;
  text-align: left;
  width: 88%; }

.adventureBook.person_no input {
  border-width: 0;
  color: #333;
  font-size: 15px;
  padding: 10px 0;
  width: 100%; }

.adventureBook.person_no .bookDescription {
  color: #333;
  font-size: 15px;
  padding: 10px 0;
  width: 100%; }

.btn {
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  font-weight: normal; }
  .btn.white-btn {
    background: #fff;
    color: #ff9514;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 18px;
    padding: 5px 12px;
    position: relative;
    top: 5px; }
    @media (max-width: 576px) {
      .btn.white-btn {
        background: rgba(255, 255, 255, 0.12);
        color: #fff; } }
  .btn.orange-btn {
    background-color: #ff9514;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding: 15.5px 25px;
    text-transform: uppercase; }
  .btn.blur-btn {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff; }

.ContactDetail_wrapper__216e0 {
  border-radius: 0; }
  @media (max-width: 768px) {
    .ContactDetail_wrapper__216e0 {
      bottom: 0;
      margin-top: 0; } }
  .ContactDetail_wrapper__216e0 h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0;
    margin-top: 20px !important; }
  .ContactDetail_wrapper__216e0 .ContactDetail_form__17Tx2 {
    background: #fff;
    border-radius: 5px;
    margin: 0 auto;
    text-align: center;
    width: 100%; }
    @media (max-width: 768px) {
      .ContactDetail_wrapper__216e0 .ContactDetail_form__17Tx2 {
        left: 50%;
        margin-top: 0;
        min-width: 0;
        width: 100%;
        z-index: 10; } }
  .ContactDetail_wrapper__216e0 .ContactDetail_form_input__2HnX9 {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px auto;
    padding: 0 0 0 12px;
    width: 88%; }
  .ContactDetail_wrapper__216e0 .ContactDetail_form_textArea__124Ws {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 5px auto;
    width: 88%; }
  .ContactDetail_wrapper__216e0 .ContactDetail_inputField__2Myro {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 80%; }
  .ContactDetail_wrapper__216e0 textarea {
    border-width: 0;
    color: #333;
    font-size: 15px;
    height: 70px;
    padding: 10px 10px 10px 0 !important;
    width: 80%; }
  .ContactDetail_wrapper__216e0 .ContactDetail_cancel__17Cn9 {
    background: none;
    border-width: 0;
    color: #000;
    float: right;
    font-size: 18px; }
  .ContactDetail_wrapper__216e0 .ContactDetail_errorText__3SyOc {
    color: #f00 !important;
    margin-bottom: 10px;
    margin-left: 20px;
    padding-top: 5px;
    text-align: left !important; }
  .ContactDetail_wrapper__216e0 .ContactDetail_statement__3kmTZ {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding-bottom: 10px;
    width: 60%; }
  .ContactDetail_wrapper__216e0 .ContactDetail_request__28CUF {
    margin: 0 auto;
    text-align: center;
    width: 88%; }
  .ContactDetail_wrapper__216e0 .ContactDetail_contactBtn__1E_ad {
    background: #fa9923;
    border-radius: 8px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-top: 20px;
    text-transform: uppercase;
    width: 100%; }

.GuidePersonalInfo_info__39pHo {
  align-items: center;
  color: #666;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  max-width: 250px; }
  .GuidePersonalInfo_info__39pHo svg {
    fill: #666;
    height: 8px;
    width: 8px; }
  .GuidePersonalInfo_info__39pHo .GuidePersonalInfo_rating__2OKJT {
    align-items: center;
    display: flex; }
  .GuidePersonalInfo_info__39pHo span {
    color: #666 !important;
    font-size: 8px; }
  .GuidePersonalInfo_info__39pHo .GuidePersonalInfo_data__uCOWC {
    font-size: 13px;
    padding: 5px; }

.BookRequest_wrapper__R4q0a {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  margin-top: -105px;
  position: absolute;
  text-align: left;
  width: 100%; }
  @media (max-width: 768px) {
    .BookRequest_wrapper__R4q0a .BookRequest_desktopBookForm__HHMuQ {
      display: none; } }
  @media (max-width: 768px) {
    .BookRequest_wrapper__R4q0a {
      bottom: 0;
      position: fixed;
      z-index: 30; } }
  .BookRequest_wrapper__R4q0a .BookRequest_mobileBookForm__3_z7d {
    background: white;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1; }
    @media (min-width: 768px) {
      .BookRequest_wrapper__R4q0a .BookRequest_mobileBookForm__3_z7d {
        display: none; } }
  .BookRequest_wrapper__R4q0a .BookRequest_mobileBody__xgk5j {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 36px 0 rgba(0, 0, 0, 0.06);
    height: 90%;
    left: 50%;
    margin: 0 auto;
    padding: 15px 17px;
    position: absolute;
    text-align: center;
    top: 20px;
    transform: translate(-50%, 0);
    width: 90%;
    z-index: 20; }
  .BookRequest_wrapper__R4q0a .BookRequest_mobileFooter__1X4kq {
    align-items: center;
    display: flex;
    margin: 20px 0;
    padding: 0 10px;
    position: relative; }
    @media (min-width: 769px) {
      .BookRequest_wrapper__R4q0a .BookRequest_mobileFooter__1X4kq {
        display: none; } }
  .BookRequest_wrapper__R4q0a .BookRequest_contactDesktopBody__it1hQ {
    position: relative; }
  .BookRequest_wrapper__R4q0a .BookRequest_contactMobileBody__3RLOl {
    height: 500px;
    left: 50%;
    margin: 0 auto;
    padding: 15px 17px;
    position: absolute;
    text-align: center;
    top: 20px;
    transform: translate(-50%, 0);
    width: 90%; }
  .BookRequest_wrapper__R4q0a .BookRequest_cancel__2Rg2e {
    background: none;
    border-width: 0;
    color: #000;
    float: right;
    font-size: 18px; }
    @media (min-width: 769px) {
      .BookRequest_wrapper__R4q0a .BookRequest_cancel__2Rg2e {
        display: none; } }
  .BookRequest_wrapper__R4q0a .BookRequest_bookTour__3snk9 {
    position: absolute;
    right: 5px; }
  .BookRequest_wrapper__R4q0a header {
    border-bottom: 1px solid #e6e6e6;
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: auto 1fr;
    padding: 20px; }
    @media (max-width: 768px) {
      .BookRequest_wrapper__R4q0a header {
        display: flex; } }
    .BookRequest_wrapper__R4q0a header svg {
      fill: #666667;
      height: 8px;
      width: 8px; }
    .BookRequest_wrapper__R4q0a header span {
      color: #fa9923;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 17px; }
    .BookRequest_wrapper__R4q0a header h4 {
      color: #333;
      display: none;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      max-width: 40px !important; }
    .BookRequest_wrapper__R4q0a header p {
      color: #666667;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 0; }
  .BookRequest_wrapper__R4q0a .BookRequest_avatar__3o3F- {
    border-radius: 50%;
    height: 60px;
    overflow: hidden;
    width: 60px; }
    @media (max-width: 768px) {
      .BookRequest_wrapper__R4q0a .BookRequest_avatar__3o3F- {
        height: 40px;
        margin-right: 10px;
        width: 40px; } }
    .BookRequest_wrapper__R4q0a .BookRequest_avatar__3o3F- img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%; }
  .BookRequest_wrapper__R4q0a .BookRequest_fullName__3KdsU {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    max-width: 120px; }
  .BookRequest_wrapper__R4q0a .BookRequest_guide__7jsoH {
    display: grid;
    grid-row-gap: 3px;
    grid-template-rows: repeat(3, auto);
    text-align: left; }
  .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj {
    display: grid;
    grid-row-gap: 20px;
    grid-template-rows: repeat(3, auto);
    padding: 20px; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj .BookRequest_input__1ZlN9 {
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      color: #333;
      display: flex;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 19px;
      padding: 7px 16px;
      position: relative; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj .BookRequest_inputMessage___tis3 {
      align-items: unset !important; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj .BookRequest_arrow_down__VjeEg {
      width: 13px; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj .BookRequest_errorText__3NR1v {
      color: #ff9514 !important;
      margin-bottom: 5px;
      margin-left: 5px;
      padding-top: 5px;
      text-align: left !important; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj svg {
      fill: #333;
      height: 16px;
      margin-right: 5px;
      stroke: #333;
      width: 16px; }
    .BookRequest_wrapper__R4q0a .BookRequest_content__1N-wj .BookRequest_arrow_down__VjeEg {
      position: absolute;
      right: 5px; }
  .BookRequest_wrapper__R4q0a button {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 15px 0;
    text-transform: uppercase;
    width: 100%; }
  .BookRequest_wrapper__R4q0a .BookRequest_form_control__tcynf {
    border-width: 0;
    box-shadow: none;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px; }
  .BookRequest_wrapper__R4q0a .BookRequest_messageBox__3Cunz {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 61px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    resize: none;
    width: 100%; }
  .BookRequest_wrapper__R4q0a .BookRequest_travelersInput__Aj4uj {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 90%; }
  .BookRequest_wrapper__R4q0a label {
    padding-top: 3px; }
  .BookRequest_wrapper__R4q0a .BookRequest_terms__2xNR5 {
    color: #666667;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
    text-align: center; }
    .BookRequest_wrapper__R4q0a .BookRequest_terms__2xNR5 a {
      color: #fa9923;
      font-weight: 600;
      text-decoration: underline; }

.react-datepicker-popper {
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  margin-left: -38px;
  z-index: 2; }
  .react-datepicker-popper .button {
    display: none; }

.react-datepicker {
  border: 0;
  width: 270px; }
  .react-datepicker button {
    background-color: #fff;
    color: #333;
    height: 14px;
    width: 14px; }

.react-datepicker__triangle {
  display: none; }

.react-datepicker__header {
  background-color: #fff;
  border-width: 0; }

.react-datepicker__month-container {
  float: none; }

.react-datepicker__navigation--previous {
  position: relative; }

.questions {
  background-color: #f3f3f3;
  background-size: cover;
  padding-bottom: 86px;
  padding-top: 89px; }

.questions form {
  margin: 30px auto 0;
  max-width: 310px; }
  .questions form .full-orange-btn.btn {
    background: #fff;
    border-radius: 6px;
    color: #ff9514;
    display: block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }

.form-group {
  margin-bottom: 20px; }
  .form-group input {
    background-position: 16px 18px;
    background-repeat: no-repeat;
    border: 0;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    min-height: 50px;
    padding: 10px 20px 10px 44px;
    width: 100%; }
  .form-group textarea {
    background-position: 16px 18px;
    background-repeat: no-repeat;
    border: 0;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    min-height: 88px;
    padding: 15px 20px 10px 44px;
    width: 100%; }

.subTitle {
  color: #333;
  font-size: 38px;
  line-height: 51px;
  text-align: center; }
  @media (max-width: 576px) {
    .subTitle {
      font-size: 30px;
      line-height: 41px; } }

.smallDescription {
  color: #666;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 4px;
  text-align: center; }
  @media (max-width: 576px) {
    .smallDescription {
      font-size: 16px;
      line-height: 24px; } }

.signup_popup {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }
  .signup_popup h2 {
    color: #333;
    font-family: Abril Fatface;
    font-size: 30px;
    line-height: 41px;
    margin: 0; }
  .signup_popup p {
    color: #666;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px; }
  .signup_popup .guide_btn {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    min-width: 100px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    width: auto; }

.center_div {
  background: #fff;
  border-radius: 5px;
  left: 50%;
  margin: 0 auto;
  min-width: 450px;
  padding: 29px 17px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, 50%);
  width: auto; }

.footer {
  padding: 24px 0; }
  @media (max-width: 576px) {
    .footer .flex {
      flex-flow: wrap; } }
  .footer .col {
    width: 33.333%; }
    @media (max-width: 576px) {
      .footer .col:last-child {
        width: 100%; } }
  .footer .social-icons {
    text-align: center; }
    @media (max-width: 576px) {
      .footer .social-icons {
        text-align: right;
        width: 63%; } }
  .footer ul {
    list-style: none;
    padding: 0; }
    .footer ul li {
      display: inline-block;
      margin: 0 10px; }
  .footer .copyright {
    color: #666;
    font-size: 12px;
    line-height: 19px;
    text-align: right; }
    .footer .copyright a {
      color: #808080;
      display: block; }
    @media (max-width: 576px) {
      .footer .copyright {
        text-align: left; } }

.error_text {
  color: #f00 !important;
  margin: 0 !important;
  text-align: center; }

.request_btn .orangebtn {
  background: #fa9923;
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  width: 100%; }

.subscribe-popup {
  border-radius: 10px;
  bottom: 20px;
  max-width: 400px;
  position: fixed;
  right: 20px;
  width: 100%;
  z-index: 100000; }
  @media (max-width: 767px) {
    .subscribe-popup {
      max-width: 280px; } }

.subscribe-popup > div {
  background: #674a26;
  border-radius: 10px;
  color: #fff;
  opacity: 0.7; }

.subscribe-popup > div h3 {
  color: #fff;
  font-size: 22px; }

.subscribe-popup > div p {
  color: #fff;
  font-size: 16px; }

.close-subscribe {
  border-radius: 50px;
  color: #696767;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  line-height: 24px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 10px;
  width: 30px;
  z-index: 100; }

.errorEmail-popup {
  color: rgba(255, 0, 0, 0.7);
  font-size: 14px;
  margin-top: 10px; }

.news-page {
  padding-bottom: 60px;
  padding-top: 60px; }

.news-box {
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px; }
  .news-box .mini-logo {
    margin-right: 30px;
    max-width: 140px;
    width: 100%; }
  @media (max-width: 766px) {
    .news-box {
      flex-flow: wrap; } }

@media (max-width: 766px) {
  .news-logo {
    margin-bottom: 18px;
    width: 100%; } }

.news-content {
  max-width: 630px; }
  .news-content h3 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px; }
  .news-content p {
    color: #333;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px; }
  .news-content a {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px; }

.news-title h1 {
  color: #333;
  font-size: 34px;
  line-height: 46px; }

.news-title p {
  color: #333;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px; }

.news-page {
  padding-bottom: 60px;
  padding-top: 60px; }

.news-box {
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px; }
  .news-box .mini-logo {
    margin-right: 30px;
    max-width: 240px;
    width: 100%; }
  @media (max-width: 766px) {
    .news-box {
      flex-flow: wrap; } }

@media (max-width: 766px) {
  .news-logo {
    margin-bottom: 18px;
    width: 100%; } }

.news-content {
  max-width: 630px; }
  .news-content h3 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px; }
  .news-content span {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px; }
  .news-content p {
    color: #333;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px; }
  .news-content a {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px; }

.news-title h1 {
  color: #333;
  font-size: 34px;
  line-height: 46px; }

.news-title p {
  color: #333;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px; }

.guide-profile-page {
  padding-top: 41px; }
  .guide-profile-page .details {
    display: flex;
    flex-flow: wrap;
    margin: 0 -15px; }

.profile-left {
  padding: 0 15px;
  width: 66.666667%; }
  .profile-left h1 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 9px;
    margin-top: 6px; }
  .profile-left p {
    color: #666;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px; }
  .profile-left a {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px; }
  .profile-left h3 {
    color: #333;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 7px;
    margin-top: 0; }

.profile-left figure {
  margin-top: 20px;
  text-align: center; }
  .profile-left figure img {
    width: 100%; }
  .profile-left figure figcaption {
    color: #666;
    font-size: 14px;
    font-style: italic;
    line-height: 18px; }

.place {
  color: #333;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 20px;
  margin-bottom: 15px; }

.guide-nav li {
  color: #ff9514;
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-right: 7px;
  padding-right: 12px;
  position: relative; }
  .guide-nav li::after {
    content: "\B7";
    font-size: 21px;
    font-weight: bold;
    position: absolute;
    right: 0; }
  .guide-nav li:last-child::after {
    content: ""; }

.icon-views {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  padding-bottom: 50px; }
  .icon-views .icon {
    padding-left: 60px;
    position: relative;
    width: 25%; }
  .icon-views .small-icon {
    background-color: #f8f8f9;
    border-radius: 50%;
    display: inline-block;
    height: 36px;
    left: 0;
    line-height: 30px;
    margin-right: 20px;
    position: absolute;
    vertical-align: top;
    width: 36px; }
  .icon-views .small-icon img {
    position: relative;
    right: -20px; }
  .icon-views span {
    display: block; }
  .icon-views .name {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 15px;
    text-transform: uppercase; }
  .icon-views .status {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.17px;
    line-height: 18px; }

.profile-right {
  padding: 0 15px;
  width: 33.33333%; }
  .profile-right img {
    max-width: 100%; }

.top-info {
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 20px; }

.top-info h5 {
  color: #ff9514;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 20px; }

.guide-right-form {
  border-top: 1px solid #e6e6e6;
  margin-top: 20px;
  padding-top: 20px; }
  .guide-right-form select {
    background: transparent;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%; }
  .guide-right-form textarea {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    width: 100%; }
  .guide-right-form button {
    background-color: #ff8c00;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 50px;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 100%; }

.guide-profile-page {
  padding-top: 41px; }

.guide-adventures {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px; }
  .guide-adventures span {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    letter-spacing: 0.11px;
    line-height: 20px; }

.adventures-lg .box {
  position: relative; }
  .adventures-lg .box img {
    border-radius: 6px;
    max-height: 220px;
    object-fit: cover;
    width: 100%; }

.adventures-lg .small-content {
  bottom: 0;
  position: absolute;
  text-align: center;
  z-index: 1000; }
  .adventures-lg .small-content h5 {
    color: #fff;
    margin-bottom: 10px; }
  .adventures-lg .small-content p {
    color: #fff;
    line-height: 20px;
    margin: 0;
    margin-bottom: 15px; }

.see-all-adventure {
  color: #ff9514;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 30px; }

@media (max-width: 991px) {
  .profile-left {
    width: 100%; }
  .profile-right {
    width: 100%; }
  .icon-views {
    flex-flow: wrap; }
    .icon-views .icon {
      margin-bottom: 20px;
      padding-left: 60px;
      position: relative;
      width: 50%; }
  .top-info .rating {
    left: 0;
    position: relative;
    top: 0; } }

.account-page {
  display: flex;
  flex-flow: wrap; }
  .account-page .account-sidebar {
    width: 24%; }
    @media (max-width: 767px) {
      .account-page .account-sidebar {
        width: 100%; } }
  .account-page .account-sidebar-menu a {
    border-bottom: 1px solid #e6e6e6;
    color: #666;
    display: block;
    font-family: "Avenir-Book";
    font-size: 15px;
    padding: 14px 14px 14px 26px;
    text-transform: capitalize; }
    .account-page .account-sidebar-menu a:hover {
      color: #fd4d4f;
      text-decoration: none; }
  .account-page .account-sidebar-menu {
    padding-right: 30px;
    padding-top: 24px; }
  .account-page .account-right {
    width: 76%; }
    @media (max-width: 767px) {
      .account-page .account-right {
        width: 100%; } }
    .account-page .account-right h1 {
      color: #333;
      font-size: 38px;
      margin-bottom: 0;
      padding: 21px 0;
      text-transform: capitalize; }
    .account-page .account-right .hidden {
      position: absolute;
      visibility: hidden; }
  .account-page .user-image {
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    position: relative; }
    .account-page .user-image label {
      color: #428bca;
      font-size: 14px;
      line-height: 1.4; }
    .account-page .user-image p {
      margin-bottom: 0; }
  .account-page .user-form {
    padding: 35px 0; }
    .account-page .user-form figure {
      border: 2px solid #999;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 30px;
      padding: 18px 0;
      position: relative;
      text-align: center;
      width: 164px; }
    .account-page .user-form .gender {
      display: flex;
      flex-flow: wrap; }
    .account-page .user-form label {
      color: #999;
      font-family: "Avenir-Book";
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: capitalize; }
    .account-page .user-form button {
      background: #fff;
      border: 1px solid #fd6061;
      border-radius: 4px;
      color: #fd6061 !important;
      display: inline-block;
      padding: 9px 18px;
      width: auto; }

.user-form .gender > label {
  color: #999;
  margin-bottom: 22px;
  width: 100%; }

.user-form .gender figcaption {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px; }

.user-form .form-group input {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-shadow: 0 0 0;
  font-size: 15px;
  height: 42px;
  padding: 6px 12px; }

.TripList_wrapper__3Bkpc {
  padding: 40px 15px 0;
  position: relative; }
  .TripList_wrapper__3Bkpc h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px; }
  .TripList_wrapper__3Bkpc .TripList_see_more__XY1ZR {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase; }
    .TripList_wrapper__3Bkpc .TripList_see_more__XY1ZR span {
      text-align: right; }
    .TripList_wrapper__3Bkpc .TripList_see_more__XY1ZR svg {
      height: 35px;
      width: 35px; }

.TripItem_wrapper__3YKEO .TripItem_item__2ozms {
  display: flex;
  width: 100%; }
  @media (max-width: 600px) {
    .TripItem_wrapper__3YKEO .TripItem_item__2ozms {
      flex-direction: column; } }

.TripItem_wrapper__3YKEO .TripItem_border__1jwFZ {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding-bottom: 30px; }

.TripItem_wrapper__3YKEO .TripItem_details__3jABW {
  width: 100%; }

.TripItem_wrapper__3YKEO .TripItem_thumb__efIAN {
  position: relative; }
  @media (max-width: 600px) {
    .TripItem_wrapper__3YKEO .TripItem_thumb__efIAN {
      margin-bottom: 30px;
      width: 100%; } }

.TripItem_wrapper__3YKEO img {
  border-radius: 8px;
  height: 177px;
  object-fit: cover;
  width: 160px; }
  @media (max-width: 600px) {
    .TripItem_wrapper__3YKEO img {
      height: 222px;
      width: 100%; } }

.TripItem_wrapper__3YKEO .TripItem_adCategory__2Afa1 {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  height: 18px;
  line-height: 18px;
  margin-bottom: 6px; }

.TripItem_wrapper__3YKEO .TripItem_adTitle__2iGDI {
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 18px;
  margin-bottom: 10px; }

.TripItem_wrapper__3YKEO .TripItem_adLocation__1PNLz {
  align-items: center;
  color: #666;
  display: grid;
  font-size: 14px;
  grid-column-gap: 7px;
  grid-template-columns: auto 1fr;
  margin-bottom: 46px; }

.TripItem_wrapper__3YKEO .TripItem_locationIcon__1cKpy {
  fill: #666;
  height: 12px;
  width: 12px; }

.TripItem_wrapper__3YKEO .TripItem_adventure__2xpYF {
  display: flex; }

.TripItem_wrapper__3YKEO .TripItem_adventureDetail__141dZ {
  padding-left: 30px;
  width: 100%; }
  @media (max-width: 599px) {
    .TripItem_wrapper__3YKEO .TripItem_adventureDetail__141dZ {
      padding-left: 0; } }

@media (max-width: 599px) {
  .TripItem_wrapper__3YKEO .TripItem_desktopTripStatus__vvWM5 {
    display: none; } }

.TripItem_wrapper__3YKEO .TripItem_mobileTripStatus__2kr4O {
  background: #fff;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  bottom: 0;
  position: absolute;
  right: 0; }
  @media (min-width: 600px) {
    .TripItem_wrapper__3YKEO .TripItem_mobileTripStatus__2kr4O {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_status__2ykPv {
  float: right;
  padding: 0 20px; }

.TripItem_wrapper__3YKEO .TripItem_statusInfo__Octte {
  align-items: center;
  display: flex; }

.TripItem_wrapper__3YKEO .TripItem_guideInfo__2gHC6 {
  align-items: center;
  background-color: #f8f8f9;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  position: relative; }
  @media (max-width: 599px) {
    .TripItem_wrapper__3YKEO .TripItem_guideInfo__2gHC6 {
      border-radius: 8px;
      padding-left: 15px; } }

.TripItem_wrapper__3YKEO .TripItem_guideBreadCrumb__Sx2HZ {
  align-items: center;
  display: flex; }

.TripItem_wrapper__3YKEO .TripItem_guideDeskTopBreadCrumb__T_zQX {
  align-items: center;
  display: flex; }
  @media (max-width: 767px) {
    .TripItem_wrapper__3YKEO .TripItem_guideDeskTopBreadCrumb__T_zQX {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_guideMobileBreadCrumb__2H9B3 {
  align-items: center;
  display: flex; }
  @media (min-width: 768px) {
    .TripItem_wrapper__3YKEO .TripItem_guideMobileBreadCrumb__2H9B3 {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_guideThumb__2hAU7 {
  border-radius: 50%;
  height: 36px;
  margin: 5px 0;
  width: 36px; }

.TripItem_wrapper__3YKEO .TripItem_guideName__3sejX {
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 20px;
  padding: auto 0; }

.TripItem_wrapper__3YKEO .TripItem_txt__3IOEO {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  height: 18px;
  letter-spacing: 0.2px;
  line-height: 20px; }

.TripItem_wrapper__3YKEO .TripItem_message__jtUEa {
  height: 14px;
  margin-right: 12px;
  width: 18px; }
  @media (max-width: 767px) {
    .TripItem_wrapper__3YKEO .TripItem_message__jtUEa {
      margin-left: 12px;
      margin-right: 0; } }

.TripItem_wrapper__3YKEO .TripItem_messageTxt__3rWk7 {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  height: 18px;
  letter-spacing: 0.2px;
  line-height: 20px; }
  @media (max-width: 767px) {
    .TripItem_wrapper__3YKEO .TripItem_messageTxt__3rWk7 {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_shot_vr__Iw04f {
  background-color: #d6d6d6;
  height: 24px;
  margin: 0 12px;
  width: 1px; }

.TripItem_wrapper__3YKEO .TripItem_big_vr__37sAa {
  background-color: #d6d6d6;
  height: 73px;
  margin: 0 16px;
  width: 1px; }

.TripItem_wrapper__3YKEO .TripItem_bookInfo__2eyoZ {
  min-width: 80px;
  text-align: right; }

.TripItem_wrapper__3YKEO .TripItem_gudieTxt__1Ryge {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-bottom: 4px; }

.TripItem_wrapper__3YKEO .TripItem_bookStatusTxt__19OVZ {
  color: #ff9514;
  font-family: "Source Sans Pro";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-bottom: 4px; }

.TripItem_wrapper__3YKEO .TripItem_date___bMwQ {
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-bottom: 4px; }

.TripItem_wrapper__3YKEO .TripItem_payment__3V72j {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  position: relative; }
  @media (max-width: 500px) {
    .TripItem_wrapper__3YKEO .TripItem_payment__3V72j {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_mobilePayment__lYArg {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 10px;
  position: relative;
  width: 100%; }
  @media (min-width: 501px) {
    .TripItem_wrapper__3YKEO .TripItem_mobilePayment__lYArg {
      display: none; } }

.TripItem_wrapper__3YKEO .TripItem_paymentBtn__HLWQM {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  padding: 9px 12px; }

.TripItem_wrapper__3YKEO .TripItem_paymentBtn__HLWQM:hover {
  background-color: #ff9514;
  border-radius: 8px;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  padding: 9px 12px; }

.TripItem_wrapper__3YKEO a {
  color: #fff;
  text-decoration: none; }

.TripTabList_title__1l7un {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 34px;
  height: 46px;
  line-height: 46px; }

.TripTabList_list__1yygI {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px; }
  .TripTabList_list__1yygI .TripTabList_dot__2NRX1 {
    margin: 0 5px; }
  .TripTabList_list__1yygI .TripTabList_active__1zLo6 {
    color: #ff930f;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px; }
  .TripTabList_list__1yygI .TripTabList_general__GFOT7 {
    color: #666;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.MyTrip_chromeframe__3pXJo {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes MyTrip_slideInTop__45eq3 {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.MyTrip_short_hr__3X7Vw {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.MyTrip_App__3aqQM {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .MyTrip_App__3aqQM.MyTrip_modal-open__Qjok5 {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.MyTrip_checkbox__nJJR0 {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.MyTrip_checkboxInput__5cR2E {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.MyTrip_radioInput__1cGw9 {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.MyTrip_checkboxInput__5cR2E:checked ~ .MyTrip_checkmark__3opY- {
  background-color: #ff9514; }

.MyTrip_checkboxInput__5cR2E:checked ~ .MyTrip_guideCheckmark__1NrlR {
  background-color: #ff9514;
  color: #fff; }

.MyTrip_radioInput__1cGw9:checked ~ .MyTrip_radioMark__2jHLz {
  background-color: #fff; }

/* Create a custom checkbox */
.MyTrip_checkmark__3opY- {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.MyTrip_guideCheckmark__1NrlR {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.MyTrip_radioMark__2jHLz {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.MyTrip_checkmark__3opY-::after {
  content: "";
  display: none;
  position: absolute; }

.MyTrip_guideCheckmark__1NrlR::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.MyTrip_radioMark__2jHLz::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.MyTrip_checkboxInput__5cR2E:checked ~ .MyTrip_checkmark__3opY-::after {
  display: block; }

.MyTrip_checkboxInput__5cR2E:checked ~ .MyTrip_guideCheckmark__1NrlR::after {
  display: block; }

.MyTrip_radioInput__1cGw9:checked ~ .MyTrip_radioMark__2jHLz::after {
  display: block; }

/* Style the checkmark/indicator */
.MyTrip_checkmark__3opY-::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.MyTrip_guideCheckmark__1NrlR::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.MyTrip_radioMark__2jHLz::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.MyTrip_loaderSpinner__1DqxX {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.MyTrip_loader__1x_Yc {
  animation: MyTrip_spin__1gxXa 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes MyTrip_spin__1gxXa {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.MyTrip_lazy-load-image-background__1MgXJ {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .MyTrip_lazy-load-image-background__1MgXJ img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.MyTrip_lazyImageNP__Qy6hJ {
  padding-top: 0 !important; }

.MyTrip_finish_google_survey__o2xj_ {
  color: #666;
  text-decoration: none; }

.MyTrip_finish_google_survey__o2xj_:hover {
  color: #fff; }

.MyTrip_finish_btn__a-mvP button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.MyTrip_home-locations__3rB0v {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .MyTrip_home-locations__3rB0v ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .MyTrip_home-locations__3rB0v li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .MyTrip_home-locations__3rB0v li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .MyTrip_home-locations__3rB0v li h3 {
        font-size: 14px; } }
  .MyTrip_home-locations__3rB0v li img {
    height: 28px;
    width: 28px; }
  .MyTrip_home-locations__3rB0v li .MyTrip_rightArrow__3lC9Q {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.MyTrip_home-locations__3rB0v ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .MyTrip_home-locations__3rB0v ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .MyTrip_home-locations__3rB0v ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .MyTrip_home-locations__3rB0v ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .MyTrip_home-locations__3rB0v ul li {
      width: 100%; } }

.MyTrip_location-header__27Uqo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.MyTrip_location-text__1dYE8 p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .MyTrip_location-text__1dYE8 p {
      display: none; } }

.MyTrip_leftArrow__3JMGt {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .MyTrip_leftArrow__arrow__1jPIe {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.MyTrip_rightArrow__3lC9Q {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .MyTrip_rightArrow__arrow__dfPKN {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.MyTrip_phone-icon__1pWDR {
  max-width: 30px; }

.MyTrip_search-filter__-r9dp.MyTrip_header-filter__3rv84 button {
  width: 260px; }
  @media (max-width: 767px) {
    .MyTrip_search-filter__-r9dp.MyTrip_header-filter__3rv84 button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_search-filter-bg__kuvFp {
  background: #f1f1f2; }

.MyTrip_search-filter__-r9dp {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .MyTrip_search-filter__select__GTgs2 {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .MyTrip_search-filter__select__dropdown-indicator__1zifW {
      color: #333 !important; }
      .MyTrip_search-filter__select__dropdown-indicator__1zifW svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .MyTrip_search-filter__select__clear-indicator__1qVbI {
      color: #fff !important; }
      .MyTrip_search-filter__select__clear-indicator__1qVbI:hover {
        color: #666 !important; }
    .MyTrip_search-filter__select__placeholder__HlP00, .MyTrip_search-filter__select__single-value__35uGp {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .MyTrip_search-filter__select__placeholder__HlP00 {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .MyTrip_search-filter__select__input__3cuXV input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .MyTrip_search-filter__select__value-container__1WaGF {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .MyTrip_search-filter__select__indicator__1nzHH {
      padding: 0 !important; }
    .MyTrip_search-filter__select__indicator-separator__1sBx2 {
      display: none; }
    .MyTrip_search-filter__select__menu-list__1pzj8 {
      padding: 0 !important; }
    .MyTrip_search-filter__select__menu__1Kglo {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .MyTrip_search-filter__select__GTgs2 {
        width: 100%; } }
  .MyTrip_search-filter__destination__2yRRI {
    width: 260px; }
  .MyTrip_search-filter__-r9dp button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .MyTrip_search-filter__-r9dp button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .MyTrip_search-filter__-r9dp button:hover {
      border: 1px solid #dedede; }
      .MyTrip_search-filter__-r9dp button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .MyTrip_search-filter__-r9dp button {
        width: 100%; } }
  @media (max-width: 767px) {
    .MyTrip_search-filter__destination__2yRRI, .MyTrip_search-filter__select__GTgs2,
    .MyTrip_search-filter__-r9dp button {
      margin: 8px 0;
      width: 100%; } }

.MyTrip_search-filter__select__option__1YAXR {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .MyTrip_search-filter__select__option__1YAXR .MyTrip_sub__DpTE1 {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .MyTrip_search-filter__select__option__item__fTulN {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .MyTrip_search-filter__select__option__item__fTulN span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .MyTrip_search-filter__select__option__item__fTulN img {
      display: block;
      height: 100%;
      width: 100%; }
  .MyTrip_search-filter__select__option__1YAXR:hover, .MyTrip_search-filter__select__option__selected__AJMhu {
    background: rgba(255, 149, 20, 0.1); }
    .MyTrip_search-filter__select__option__1YAXR:hover .MyTrip_sub__DpTE1, .MyTrip_search-filter__select__option__selected__AJMhu .MyTrip_sub__DpTE1 {
      color: #ff8c00; }
    .MyTrip_search-filter__select__option__1YAXR:hover.MyTrip_search-filter__select__option__1YAXR:hover span, .MyTrip_search-filter__select__option__1YAXR:hover .MyTrip_search-filter__select__option__selected__item__3s61X span,
    .MyTrip_search-filter__select__option__1YAXR:hover .MyTrip_search-filter__select__option__item__fTulN span, .MyTrip_search-filter__select__option__selected__AJMhu.MyTrip_search-filter__select__option__1YAXR:hover span, .MyTrip_search-filter__select__option__selected__AJMhu .MyTrip_search-filter__select__option__selected__item__3s61X span,
    .MyTrip_search-filter__select__option__selected__AJMhu .MyTrip_search-filter__select__option__item__fTulN span {
      background: #ff8c00; }
      .MyTrip_search-filter__select__option__1YAXR:hover.MyTrip_search-filter__select__option__1YAXR:hover span::before, .MyTrip_search-filter__select__option__1YAXR:hover .MyTrip_search-filter__select__option__selected__item__3s61X span::before,
      .MyTrip_search-filter__select__option__1YAXR:hover .MyTrip_search-filter__select__option__item__fTulN span::before, .MyTrip_search-filter__select__option__selected__AJMhu.MyTrip_search-filter__select__option__1YAXR:hover span::before, .MyTrip_search-filter__select__option__selected__AJMhu .MyTrip_search-filter__select__option__selected__item__3s61X span::before,
      .MyTrip_search-filter__select__option__selected__AJMhu .MyTrip_search-filter__select__option__item__fTulN span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.MyTrip_search-filter__select__control__1_whG {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .MyTrip_search-filter__select__control__1_whG .MyTrip_css-1g6gooi__1gkWm {
    margin: 0 !important;
    padding: 0 !important; }
  .MyTrip_search-filter__select__control__focused__1HXmY, .MyTrip_search-filter__select__control__isOpen__9FJxE, .MyTrip_search-filter__select__control__1_whG:hover {
    border-color: #ff9514; }
  .MyTrip_search-filter__select__control__fill__1UBXZ {
    background-color: #ff9514;
    border-color: #ff9514; }
    .MyTrip_search-filter__select__control__fill__1UBXZ .MyTrip_search-filter__select__dropdown-indicator__1zifW,
    .MyTrip_search-filter__select__control__fill__1UBXZ .MyTrip_search-filter__select__single-value__35uGp,
    .MyTrip_search-filter__select__control__fill__1UBXZ .MyTrip_search-filter__select__placeholder__HlP00 {
      color: #fff !important; }
    .MyTrip_search-filter__select__control__fill__1UBXZ .MyTrip_search-filter__select__input__3cuXV input {
      color: #fff !important; }
  .MyTrip_search-filter__select__control__isOpen__9FJxE .MyTrip_search-filter__select__dropdown-indicator__1zifW svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_adventure-about__GYOdl {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .MyTrip_adventure-about__GYOdl {
      padding: 5px 0; } }
  .MyTrip_adventure-about__container__3SVAW {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .MyTrip_adventure-about__container__3SVAW {
        padding: 0 10px; } }
  .MyTrip_adventure-about__author__1GnMZ {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .MyTrip_adventure-about__author__1GnMZ img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .MyTrip_adventure-about__author__1GnMZ span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .MyTrip_adventure-about__author__1GnMZ {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .MyTrip_adventure-about__author__1GnMZ img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .MyTrip_adventure-about__author__1GnMZ span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .MyTrip_adventure-about__rate__3f3nq {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .MyTrip_adventure-about__rate__3f3nq > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .MyTrip_adventure-about__rate__3f3nq {
        display: none; } }
  .MyTrip_adventure-about__buttons__2v9hK {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .MyTrip_adventure-about__buttons__2v9hK button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .MyTrip_adventure-about__buttons__2v9hK button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .MyTrip_adventure-about__buttons__2v9hK button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .MyTrip_adventure-about__buttons__2v9hK button {
          width: 120px; } }
      @media (max-width: 576px) {
        .MyTrip_adventure-about__buttons__2v9hK button {
          margin-right: 10px;
          width: 90px; } }
    .MyTrip_adventure-about__buttons__check-dates__2awF1 {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .MyTrip_adventure-about__buttons__request__24TEr {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_guides-footer__dLVoa {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .MyTrip_guides-footer__author__3pp-1 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .MyTrip_guides-footer__author__3pp-1 img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .MyTrip_guides-footer__author__3pp-1 span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .MyTrip_guides-footer__author__3pp-1 {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .MyTrip_guides-footer__author__3pp-1 img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .MyTrip_guides-footer__author__3pp-1 span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .MyTrip_guides-footer__rate__38u3J {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .MyTrip_guides-footer__rate__38u3J > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .MyTrip_guides-footer__rate__38u3J {
        display: none; } }
  .MyTrip_guides-footer__button__m3Vei {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .MyTrip_guides-footer__button__m3Vei {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .MyTrip_guides-footer__button__m3Vei {
        width: 120px; } }
    @media (max-width: 576px) {
      .MyTrip_guides-footer__button__m3Vei {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_guide-modal__n072W {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .MyTrip_guide-modal__close__2cbIG {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .MyTrip_guide-modal__close__2cbIG:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .MyTrip_guide-modal__n072W {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .MyTrip_guide-modal__n072W {
      padding: 10px;
      width: 300px; } }

.MyTrip_guide-modal__header__1tsCu {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .MyTrip_guide-modal__header__photo__1DPw2 {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .MyTrip_guide-modal__header__photo__1DPw2 img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .MyTrip_guide-modal__header__info__1Xa3e {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .MyTrip_guide-modal__header__info__1Xa3e p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .MyTrip_guide-modal__header__info__1Xa3e div {
      display: flex; }
      .MyTrip_guide-modal__header__info__1Xa3e div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .MyTrip_guide-modal__header__info__1Xa3e div span {
            text-align: center; } }
        .MyTrip_guide-modal__header__info__1Xa3e div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .MyTrip_guide-modal__header__info__1Xa3e div span:last-child {
          margin-right: 0; }
          .MyTrip_guide-modal__header__info__1Xa3e div span:last-child::after {
            display: none; }

.MyTrip_guide-modal__body__3jEzG {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .MyTrip_guide-modal__body__error__2OHkd .MyTrip_guide-modal__body__item__3Z6hc {
    border-color: #f00; }
  .MyTrip_guide-modal__body__error__2OHkd p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .MyTrip_guide-modal__body__item__3Z6hc {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .MyTrip_guide-modal__body__item__3Z6hc img,
    .MyTrip_guide-modal__body__item__3Z6hc svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .MyTrip_guide-modal__body__item__3Z6hc input,
    .MyTrip_guide-modal__body__item__3Z6hc textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .MyTrip_guide-modal__body__item__3Z6hc textarea {
      height: 70px;
      resize: none; }
    .MyTrip_guide-modal__body__item__down__Bup5E {
      position: absolute;
      right: 9px; }
  .MyTrip_guide-modal__body__3jEzG .MyTrip_react-datepicker-wrapper__36W3l,
  .MyTrip_guide-modal__body__3jEzG .MyTrip_react-datepicker__input-container__WZ2yS {
    width: 100%; }
  .MyTrip_guide-modal__body__3jEzG .MyTrip_react-datepicker__3BShK .MyTrip_react-datepicker__day__-0xAM:not(.MyTrip_react-datepicker__day--disabled__1UgMi) {
    background: #fa9923;
    border-radius: 4.8px; }
    .MyTrip_guide-modal__body__3jEzG .MyTrip_react-datepicker__3BShK .MyTrip_react-datepicker__day__-0xAM:not(.MyTrip_react-datepicker__day--disabled__1UgMi):hover {
      color: #fff;
      font-weight: bold; }
  .MyTrip_guide-modal__body__submit__8dn4P {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .MyTrip_guide-modal__body__submit__8dn4P .MyTrip_loader__1x_Yc {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.MyTrip_guide-modal__footer__3tolU {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .MyTrip_guide-modal__footer__3tolU p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .MyTrip_guide-modal__footer__3tolU label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .MyTrip_guide-modal__footer__3tolU a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .MyTrip_guide-modal__footer__3tolU input {
    left: -9999px;
    position: absolute; }
  .MyTrip_guide-modal__footer__3tolU span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .MyTrip_guide-modal__footer__3tolU input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_modal-overlay__3rhr7 {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.MyTrip_modal-popup__27Sst {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .MyTrip_modal-popup__close__3d5MV {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .MyTrip_modal-popup__close__3d5MV:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .MyTrip_modal-popup__27Sst {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .MyTrip_modal-popup__27Sst {
      padding: 10px;
      width: 300px; } }

.MyTrip_modal-open__Qjok5 {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .MyTrip_modal-open__Qjok5 .MyTrip_fixed-footer__2PnfY {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.MyTrip_fixed-footer__2PnfY {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .MyTrip_fixed-footer__2PnfY {
      padding: 5px 0; } }
  .MyTrip_fixed-footer__container__1Npud {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .MyTrip_fixed-footer__container__1Npud {
        padding: 0 10px; } }
  .MyTrip_fixed-footer__2PnfY.MyTrip_hidden__18lAc {
    bottom: -80px; }

.MyTrip_wrapper__3Xsvc {
  position: relative; }
  .MyTrip_wrapper__3Xsvc .MyTrip_row__2bO1T {
    margin: 0 auto;
    max-width: 1100px; }

.blog-page {
  padding-bottom: 128px; }

.blog-header {
  display: flex;
  justify-content: space-between; }

.blog-header h1 {
  color: #333;
  font-size: 34px;
  line-height: 46px; }

.blog-header p {
  line-height: 28px; }

.blog-header a {
  background-color: #ff9514;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 19px;
  line-height: 50px;
  text-align: center;
  width: 120px; }

.blog-header {
  padding-top: 128px; }

.blog-item {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 33.333%; }
  @media (max-width: 991px) {
    .blog-item {
      width: 50%; } }
  @media (max-width: 767px) {
    .blog-item {
      width: 100%; } }
  .blog-item .box {
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 16px 20px; }
  .blog-item .box h3 {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 4px;
    text-transform: uppercase; }
  .blog-item .box h2 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 26px; }

.blog-item .imageInfo .main-img {
  border-top-left-radius: 6px;
  height: 380px;
  max-width: 100%;
  object-fit: cover; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline; }

button {
  background: none;
  border: none; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.Payment_chromeframe__fhCT- {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

.fadeIn-appear {
  opacity: 0.01; }

.fadeIn-appear.fadeIn-appear-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
  opacity: 1; }

@keyframes Payment_slideInTop__mP95- {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 44px;
  line-height: 52px; }
  @media (min-width: 767px) {
    h1 {
      font-size: 44px;
      line-height: 1.5; } }
  @media (min-width: 1089px) {
    h1 {
      font-size: 68px;
      line-height: 76px; } }

h3 {
  color: #333;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 767px) {
    h3 {
      font-size: 38px; } }

p {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px; }
  @media (min-width: 767px) {
    p {
      line-height: 1.7; } }

hr {
  background-color: #666;
  border-width: 0;
  color: #333;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px; }

.Payment_short_hr__roafW {
  margin-bottom: 12px;
  width: 50px; }

/* stylelint-disable */
/* stylelint-enable */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive; }

.Payment_App__vHZuj {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .Payment_App__vHZuj.Payment_modal-open__2drWk {
    height: 100vh;
    overflow: hidden;
    width: 100%; }

button {
  font-family: "Source Sans Pro", sans-serif; }

.Payment_checkbox__2Md7a {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left; }

/* Hide the browser's default checkbox */
.Payment_checkboxInput__3qR5g {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

.Payment_radioInput__eoBUi {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0; }

/* When the checkbox is checked, add a orange background */
.Payment_checkboxInput__3qR5g:checked ~ .Payment_checkmark__Sn3Yl {
  background-color: #ff9514; }

.Payment_checkboxInput__3qR5g:checked ~ .Payment_guideCheckmark__rDqQJ {
  background-color: #ff9514;
  color: #fff; }

.Payment_radioInput__eoBUi:checked ~ .Payment_radioMark__2Zja8 {
  background-color: #fff; }

/* Create a custom checkbox */
.Payment_checkmark__Sn3Yl {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px; }

.Payment_guideCheckmark__rDqQJ {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

.Payment_radioMark__2Zja8 {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px; }

/* Create the checkmark/indicator (hidden when not checked) */
.Payment_checkmark__Sn3Yl::after {
  content: "";
  display: none;
  position: absolute; }

.Payment_guideCheckmark__rDqQJ::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute; }

.Payment_radioMark__2Zja8::after {
  content: "";
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.Payment_checkboxInput__3qR5g:checked ~ .Payment_checkmark__Sn3Yl::after {
  display: block; }

.Payment_checkboxInput__3qR5g:checked ~ .Payment_guideCheckmark__rDqQJ::after {
  display: block; }

.Payment_radioInput__eoBUi:checked ~ .Payment_radioMark__2Zja8::after {
  display: block; }

/* Style the checkmark/indicator */
.Payment_checkmark__Sn3Yl::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px; }

.Payment_guideCheckmark__rDqQJ::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px; }

.Payment_radioMark__2Zja8::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px; }

/*=========== spinner =============*/
.Payment_loaderSpinner__3OCeM {
  display: flex;
  flex: 1 1;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%; }

.Payment_loader__1HH-y {
  animation: Payment_spin__3nm7J 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px; }

/* stylelint-disable */
/* Safari */

@keyframes Payment_spin__3nm7J {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* stylelint-enable */
.Payment_lazy-load-image-background__868aS {
  height: 100%;
  padding-top: 100%;
  width: 100%; }
  .Payment_lazy-load-image-background__868aS img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.Payment_lazyImageNP__2zdfF {
  padding-top: 0 !important; }

.Payment_finish_google_survey__1gCG8 {
  color: #666;
  text-decoration: none; }

.Payment_finish_google_survey__1gCG8:hover {
  color: #fff; }

.Payment_finish_btn__1TuKJ button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none; }

/* location */
.Payment_home-locations__2iXtO {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%; }
  .Payment_home-locations__2iXtO ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px; }
  .Payment_home-locations__2iXtO li span {
    align-items: center;
    border-radius: 6px;
    display: flex;
    position: relative; }
  .Payment_home-locations__2iXtO li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 992px) {
      .Payment_home-locations__2iXtO li h3 {
        font-size: 14px; } }
  .Payment_home-locations__2iXtO li img {
    height: 28px;
    width: 28px; }
  .Payment_home-locations__2iXtO li .Payment_rightArrow__1Hq6E {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px; }

.Payment_home-locations__2iXtO ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%; }
  @media (max-width: 992px) {
    .Payment_home-locations__2iXtO ul li {
      width: 33.333%; } }
  @media (max-width: 767px) {
    .Payment_home-locations__2iXtO ul li {
      width: 33.333%; } }
  @media (max-width: 650px) {
    .Payment_home-locations__2iXtO ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .Payment_home-locations__2iXtO ul li {
      width: 100%; } }

.Payment_location-header__18uLm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }

.Payment_location-text__iXZdH p {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .Payment_location-text__iXZdH p {
      display: none; } }

.Payment_leftArrow__3x2ut {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .Payment_leftArrow__arrow__1_ksx {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }

.Payment_rightArrow__1Hq6E {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px; }
  .Payment_rightArrow__arrow__2zJzS {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

.Payment_phone-icon__1Fxd7 {
  max-width: 30px; }

.Payment_search-filter__5o0fj.Payment_header-filter__2LNDz button {
  width: 260px; }
  @media (max-width: 767px) {
    .Payment_search-filter__5o0fj.Payment_header-filter__2LNDz button {
      width: 100%; } }

/* stylelint-disable */
/* stylelint-enable */
.Payment_search-filter-bg__19tXC {
  background: #f1f1f2; }

.Payment_search-filter__5o0fj {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5; }
  .Payment_search-filter__select__1sQns {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px; }
    .Payment_search-filter__select__dropdown-indicator__xeg76 {
      color: #333 !important; }
      .Payment_search-filter__select__dropdown-indicator__xeg76 svg {
        transform: rotate(0);
        transition: all 0.2s ease; }
    .Payment_search-filter__select__clear-indicator__1Toh7 {
      color: #fff !important; }
      .Payment_search-filter__select__clear-indicator__1Toh7:hover {
        color: #666 !important; }
    .Payment_search-filter__select__placeholder__2OYcT, .Payment_search-filter__select__single-value__39eB0 {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .Payment_search-filter__select__placeholder__2OYcT {
      transform: translateY(0) !important;
      position: relative !important;
      top: auto; }
    .Payment_search-filter__select__input__t7MyJ input {
      color: #333 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      margin: 0 !important; }
    .Payment_search-filter__select__value-container__2PSfJ {
      flex-wrap: nowrap !important;
      padding: 0 !important; }
    .Payment_search-filter__select__indicator__e85nn {
      padding: 0 !important; }
    .Payment_search-filter__select__indicator-separator__1vr6d {
      display: none; }
    .Payment_search-filter__select__menu-list__eJevn {
      padding: 0 !important; }
    .Payment_search-filter__select__menu__2zIkG {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100; }
    @media (max-width: 767px) {
      .Payment_search-filter__select__1sQns {
        width: 100%; } }
  .Payment_search-filter__destination__fcZyY {
    width: 260px; }
  .Payment_search-filter__5o0fj button {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    transition: all 0.2s ease;
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px; }
    .Payment_search-filter__5o0fj button > svg {
      fill: #fff;
      height: 12px;
      width: 18px; }
    .Payment_search-filter__5o0fj button:hover {
      border: 1px solid #dedede; }
      .Payment_search-filter__5o0fj button:hover > svg {
        fill: #fff; }
    @media (max-width: 767px) {
      .Payment_search-filter__5o0fj button {
        width: 100%; } }
  @media (max-width: 767px) {
    .Payment_search-filter__destination__fcZyY, .Payment_search-filter__select__1sQns,
    .Payment_search-filter__5o0fj button {
      margin: 8px 0;
      width: 100%; } }

.Payment_search-filter__select__option__2Lqt1 {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  transition: all 0.2s ease;
  background: #fff;
  cursor: pointer;
  padding: 10px 17px; }
  .Payment_search-filter__select__option__2Lqt1 .Payment_sub__32t5V {
    color: #666;
    font-size: 14px;
    line-height: 18px; }
  .Payment_search-filter__select__option__item__foJHL {
    height: 24px;
    margin-right: 12px;
    width: 24px; }
    .Payment_search-filter__select__option__item__foJHL span {
      transition: all 0.2s ease;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .Payment_search-filter__select__option__item__foJHL img {
      display: block;
      height: 100%;
      width: 100%; }
  .Payment_search-filter__select__option__2Lqt1:hover, .Payment_search-filter__select__option__selected__2OOfS {
    background: rgba(255, 149, 20, 0.1); }
    .Payment_search-filter__select__option__2Lqt1:hover .Payment_sub__32t5V, .Payment_search-filter__select__option__selected__2OOfS .Payment_sub__32t5V {
      color: #ff8c00; }
    .Payment_search-filter__select__option__2Lqt1:hover.Payment_search-filter__select__option__2Lqt1:hover span, .Payment_search-filter__select__option__2Lqt1:hover .Payment_search-filter__select__option__selected__item__3wUN5 span,
    .Payment_search-filter__select__option__2Lqt1:hover .Payment_search-filter__select__option__item__foJHL span, .Payment_search-filter__select__option__selected__2OOfS.Payment_search-filter__select__option__2Lqt1:hover span, .Payment_search-filter__select__option__selected__2OOfS .Payment_search-filter__select__option__selected__item__3wUN5 span,
    .Payment_search-filter__select__option__selected__2OOfS .Payment_search-filter__select__option__item__foJHL span {
      background: #ff8c00; }
      .Payment_search-filter__select__option__2Lqt1:hover.Payment_search-filter__select__option__2Lqt1:hover span::before, .Payment_search-filter__select__option__2Lqt1:hover .Payment_search-filter__select__option__selected__item__3wUN5 span::before,
      .Payment_search-filter__select__option__2Lqt1:hover .Payment_search-filter__select__option__item__foJHL span::before, .Payment_search-filter__select__option__selected__2OOfS.Payment_search-filter__select__option__2Lqt1:hover span::before, .Payment_search-filter__select__option__selected__2OOfS .Payment_search-filter__select__option__selected__item__3wUN5 span::before,
      .Payment_search-filter__select__option__selected__2OOfS .Payment_search-filter__select__option__item__foJHL span::before {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 8px;
        transform: rotate(45deg);
        width: 4px; }

.Payment_search-filter__select__control__20r_P {
  transition: all 0.2s ease;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px; }
  .Payment_search-filter__select__control__20r_P .Payment_css-1g6gooi__2j-Bg {
    margin: 0 !important;
    padding: 0 !important; }
  .Payment_search-filter__select__control__focused__1CBit, .Payment_search-filter__select__control__isOpen__3VTpx, .Payment_search-filter__select__control__20r_P:hover {
    border-color: #ff9514; }
  .Payment_search-filter__select__control__fill__2OUjd {
    background-color: #ff9514;
    border-color: #ff9514; }
    .Payment_search-filter__select__control__fill__2OUjd .Payment_search-filter__select__dropdown-indicator__xeg76,
    .Payment_search-filter__select__control__fill__2OUjd .Payment_search-filter__select__single-value__39eB0,
    .Payment_search-filter__select__control__fill__2OUjd .Payment_search-filter__select__placeholder__2OYcT {
      color: #fff !important; }
    .Payment_search-filter__select__control__fill__2OUjd .Payment_search-filter__select__input__t7MyJ input {
      color: #fff !important; }
  .Payment_search-filter__select__control__isOpen__3VTpx .Payment_search-filter__select__dropdown-indicator__xeg76 svg {
    transform: rotate(180deg); }

/* stylelint-disable */
/* stylelint-enable */
.Payment_adventure-about__2y61F {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .Payment_adventure-about__2y61F {
      padding: 5px 0; } }
  .Payment_adventure-about__container__BsIhR {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .Payment_adventure-about__container__BsIhR {
        padding: 0 10px; } }
  .Payment_adventure-about__author__2T4Z9 {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .Payment_adventure-about__author__2T4Z9 img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .Payment_adventure-about__author__2T4Z9 span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .Payment_adventure-about__author__2T4Z9 {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .Payment_adventure-about__author__2T4Z9 img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .Payment_adventure-about__author__2T4Z9 span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .Payment_adventure-about__rate__r_czP {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .Payment_adventure-about__rate__r_czP > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .Payment_adventure-about__rate__r_czP {
        display: none; } }
  .Payment_adventure-about__buttons__1VhXl {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end; }
    .Payment_adventure-about__buttons__1VhXl button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px; }
      .Payment_adventure-about__buttons__1VhXl button:last-child {
        margin-right: 0; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .Payment_adventure-about__buttons__1VhXl button {
          margin-right: 20px;
          width: 140px; } }
      @media (max-width: 767px) {
        .Payment_adventure-about__buttons__1VhXl button {
          width: 120px; } }
      @media (max-width: 576px) {
        .Payment_adventure-about__buttons__1VhXl button {
          margin-right: 10px;
          width: 90px; } }
    .Payment_adventure-about__buttons__check-dates__17TP1 {
      background: #ff9514;
      border: 0;
      color: #fff; }
    .Payment_adventure-about__buttons__request__1np08 {
      border: 1px solid #dedede;
      color: #666; }

/* stylelint-disable */
/* stylelint-enable */
.Payment_guides-footer__3_20M {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }
  .Payment_guides-footer__author__2exCc {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .Payment_guides-footer__author__2exCc img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px; }
    .Payment_guides-footer__author__2exCc span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%; }
    @media (max-width: 576px) {
      .Payment_guides-footer__author__2exCc {
        -moz-flex-direction: column;
        flex-direction: column;
        flex: 0 0 100px;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center; }
        .Payment_guides-footer__author__2exCc img {
          flex-basis: 18px;
          height: 18px;
          margin: 0;
          width: 18px; }
        .Payment_guides-footer__author__2exCc span {
          font-size: 16px;
          line-height: 16px;
          text-align: center; } }
  .Payment_guides-footer__rate__1ZgdT {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center; }
    .Payment_guides-footer__rate__1ZgdT > span {
      display: block;
      font-size: 14px; }
    @media (max-width: 767px) {
      .Payment_guides-footer__rate__1ZgdT {
        display: none; } }
  .Payment_guides-footer__button__3XlRh {
    background: #ff9514;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    width: 175px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .Payment_guides-footer__button__3XlRh {
        margin-right: 20px;
        width: 140px; } }
    @media (max-width: 767px) {
      .Payment_guides-footer__button__3XlRh {
        width: 120px; } }
    @media (max-width: 576px) {
      .Payment_guides-footer__button__3XlRh {
        margin-right: 10px;
        width: 90px; } }

/* stylelint-disable */
/* stylelint-enable */
.Payment_guide-modal__19o9G {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .Payment_guide-modal__close__2G6pT {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .Payment_guide-modal__close__2G6pT:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .Payment_guide-modal__19o9G {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .Payment_guide-modal__19o9G {
      padding: 10px;
      width: 300px; } }

.Payment_guide-modal__header__2_pOI {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  position: relative; }
  .Payment_guide-modal__header__photo__1_SPl {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px; }
    .Payment_guide-modal__header__photo__1_SPl img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%; }
  .Payment_guide-modal__header__info__2g56S {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .Payment_guide-modal__header__info__2g56S p {
      color: #333;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px; }
    .Payment_guide-modal__header__info__2g56S div {
      display: flex; }
      .Payment_guide-modal__header__info__2g56S div span {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        color: #666;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative; }
        @media (max-width: 576px) {
          .Payment_guide-modal__header__info__2g56S div span {
            text-align: center; } }
        .Payment_guide-modal__header__info__2g56S div span::after {
          background: #666;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px; }
        .Payment_guide-modal__header__info__2g56S div span:last-child {
          margin-right: 0; }
          .Payment_guide-modal__header__info__2g56S div span:last-child::after {
            display: none; }

.Payment_guide-modal__body__uz3l3 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }
  .Payment_guide-modal__body__error__2VsF8 .Payment_guide-modal__body__item__1grwt {
    border-color: #f00; }
  .Payment_guide-modal__body__error__2VsF8 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .Payment_guide-modal__body__item__1grwt {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .Payment_guide-modal__body__item__1grwt img,
    .Payment_guide-modal__body__item__1grwt svg {
      height: 16px;
      margin-right: 10px;
      width: 16px; }
    .Payment_guide-modal__body__item__1grwt input,
    .Payment_guide-modal__body__item__1grwt textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%; }
    .Payment_guide-modal__body__item__1grwt textarea {
      height: 70px;
      resize: none; }
    .Payment_guide-modal__body__item__down__2pFwE {
      position: absolute;
      right: 9px; }
  .Payment_guide-modal__body__uz3l3 .Payment_react-datepicker-wrapper__2hsBe,
  .Payment_guide-modal__body__uz3l3 .Payment_react-datepicker__input-container__27fGo {
    width: 100%; }
  .Payment_guide-modal__body__uz3l3 .Payment_react-datepicker__lkk7d .Payment_react-datepicker__day__bYDJx:not(.Payment_react-datepicker__day--disabled__2ripk) {
    background: #fa9923;
    border-radius: 4.8px; }
    .Payment_guide-modal__body__uz3l3 .Payment_react-datepicker__lkk7d .Payment_react-datepicker__day__bYDJx:not(.Payment_react-datepicker__day--disabled__2ripk):hover {
      color: #fff;
      font-weight: bold; }
  .Payment_guide-modal__body__submit__1UMjg {
    background: #ff9514;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .Payment_guide-modal__body__submit__1UMjg .Payment_loader__1HH-y {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px; }

.Payment_guide-modal__footer__3W998 {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-top: 20px; }
  .Payment_guide-modal__footer__3W998 p {
    color: #f00;
    font-size: 16px;
    margin: 3px 0; }
  .Payment_guide-modal__footer__3W998 label {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left; }
  .Payment_guide-modal__footer__3W998 a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline; }
  .Payment_guide-modal__footer__3W998 input {
    left: -9999px;
    position: absolute; }
  .Payment_guide-modal__footer__3W998 span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .Payment_guide-modal__footer__3W998 input:checked + span::after {
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.Payment_modal-overlay__2k-Kv {
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888; }

.Payment_modal-popup__Zq-Ia {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px; }
  .Payment_modal-popup__close__2c9yU {
    transition: all 0.2s ease;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1; }
    .Payment_modal-popup__close__2c9yU:hover {
      color: #ff9514; }
  @media screen and (max-height: 600px) {
    .Payment_modal-popup__Zq-Ia {
      max-height: 100%;
      overflow-y: scroll; } }
  @media (max-width: 576px) {
    .Payment_modal-popup__Zq-Ia {
      padding: 10px;
      width: 300px; } }

.Payment_modal-open__2drWk {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px); }
  .Payment_modal-open__2drWk .Payment_fixed-footer__3fpEm {
    transition: none;
    width: calc(100% - 17px); }

/* stylelint-disable */
/* stylelint-enable */
.Payment_fixed-footer__3fpEm {
  transition: all 0.5s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media (max-width: 576px) {
    .Payment_fixed-footer__3fpEm {
      padding: 5px 0; } }
  .Payment_fixed-footer__container__8cBBK {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%; }
    @media (max-width: 576px) {
      .Payment_fixed-footer__container__8cBBK {
        padding: 0 10px; } }
  .Payment_fixed-footer__3fpEm.Payment_hidden__3orRg {
    bottom: -80px; }

.Payment_wrapper__fCKfJ {
  display: block;
  margin-top: 61px; }
  .Payment_wrapper__fCKfJ .Payment_row__fjy88 {
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 15px;
    width: 100%; }
  .Payment_wrapper__fCKfJ a {
    color: #333; }
  .Payment_wrapper__fCKfJ h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px; }
  .Payment_wrapper__fCKfJ .Payment_title__20Ef4 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    height: 46px;
    line-height: 46px; }
  .Payment_wrapper__fCKfJ .Payment_back__3Xi_p {
    color: #333;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    line-height: 28px; }
  .Payment_wrapper__fCKfJ .Payment_subInfo__29gDA {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 21px;
    margin-right: 5px; }
  .Payment_wrapper__fCKfJ .Payment_paymentContent__1dZCA {
    background-color: #fafafa;
    border-radius: 8px;
    margin: 30px -15px;
    padding: 30px 15px; }
  .Payment_wrapper__fCKfJ .Payment_sectionTitle__1CPrz {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 4px; }
  .Payment_wrapper__fCKfJ .Payment_price__1jdyZ {
    display: flex;
    justify-content: space-between; }
  .Payment_wrapper__fCKfJ .Payment_value__nmAz0 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px; }
  .Payment_wrapper__fCKfJ .Payment_categories__Tvfvh {
    display: flex;
    flex-wrap: wrap; }
  .Payment_wrapper__fCKfJ .Payment_service__3ShRC {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; }
  .Payment_wrapper__fCKfJ .Payment_section__eadEP {
    align-items: center;
    display: flex; }
  .Payment_wrapper__fCKfJ .Payment_serviceName__3zzHl {
    margin-top: 10px; }
  .Payment_wrapper__fCKfJ .Payment_hr_br__3lBFs {
    background-color: #e6e6e6;
    border-radius: 6px;
    height: 1px;
    margin: 30px -15px;
    transform: scaleY(-1); }
  .Payment_wrapper__fCKfJ .Payment_insurance__3bt6v {
    display: flex; }
    @media (max-width: 767px) {
      .Payment_wrapper__fCKfJ .Payment_insurance__3bt6v {
        flex-direction: column; } }
  .Payment_wrapper__fCKfJ .Payment_details__1MUDP {
    border-bottom: 1px solid black;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    width: 41px; }
  .Payment_wrapper__fCKfJ .Payment_totalPrice__12d9F {
    display: flex;
    justify-content: space-between; }
  .Payment_wrapper__fCKfJ .Payment_context__54X1X {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 15px;
    line-height: 21px; }
  .Payment_wrapper__fCKfJ .Payment_paymentMethod__z9GYY {
    margin-top: 40px; }
  .Payment_wrapper__fCKfJ .Payment_ppTitle__GDD_n {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 20px;
    line-height: 46px; }
  .Payment_wrapper__fCKfJ .Payment_methdos__274xN {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto auto; }
    @media (max-width: 500px) {
      .Payment_wrapper__fCKfJ .Payment_methdos__274xN {
        grid-row-gap: 10px;
        grid-template-columns: none;
        grid-template-rows: auto; } }
  .Payment_wrapper__fCKfJ .Payment_method__1Jdgz {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px; }
  .Payment_wrapper__fCKfJ .Payment_paymentName__12chJ {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%; }
  .Payment_wrapper__fCKfJ form {
    float: left; }
  .Payment_wrapper__fCKfJ .Payment_paypalButton__9l1OF {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%; }

.ErrorHandling_errorHandling__12oE0 {
  color: #ff9514;
  margin-bottom: 25px; }

.form-group {
  margin-bottom: 30px; }
  .form-group input {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding: 16px; }
  .form-group label {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 12px; }

.errorBorder {
  border-color: #ff9514 !important; }

.CreateAdventure_new_adventure__2jR5b {
  margin: auto;
  max-width: 760px;
  padding-top: 59px; }
  .CreateAdventure_new_adventure__2jR5b .CreateAdventure_form__1UH-i {
    max-width: 540px; }

.CreateAdventure_circles__33CZU {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px; }
  .CreateAdventure_circles__33CZU .CreateAdventure_circle__2uNEG {
    background-color: #e6e6e6;
    border-radius: 50%;
    height: 10px;
    margin-right: 8px;
    width: 10px; }
  .CreateAdventure_circles__33CZU .CreateAdventure_circleActive__28hUR {
    background-color: #ff9514;
    border-radius: 50%;
    height: 10px;
    margin-right: 8px;
    width: 10px; }

.CreateAdventure_header_title__qKoEY h2 {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 34px;
  line-height: 46px; }

.CreateAdventure_inline_group__DDCS3 .CreateAdventure_participants__2pIyI {
  display: flex;
  padding-top: 10px; }
  .CreateAdventure_inline_group__DDCS3 .CreateAdventure_participants__2pIyI > div {
    margin: 0 5px 30px;
    width: 100%;
    z-index: 5; }
  .CreateAdventure_inline_group__DDCS3 .CreateAdventure_participants__2pIyI > div:first-child {
    margin-left: 0; }
  .CreateAdventure_inline_group__DDCS3 .CreateAdventure_participants__2pIyI > div:last-child {
    margin-right: 0; }
  .CreateAdventure_inline_group__DDCS3 .CreateAdventure_participants__2pIyI input {
    margin: 0; }

.CreateAdventure_inline_group__DDCS3 .CreateAdventure_form-group__32b4K {
  display: none; }

.CreateAdventure_inline_group__DDCS3 label {
  margin: 0 2% 0 0;
  width: 100%; }

.CreateAdventure_inline_group__DDCS3 input {
  margin: 0 2%;
  width: 100%; }

.CreateAdventure_inline_group__DDCS3 .CreateAdventure_select_options__3arir {
  margin: 0 2%;
  max-width: 46%;
  width: 100%; }

.CreateAdventure_radio_button__206_h {
  margin-bottom: 30px; }
  .CreateAdventure_radio_button__206_h > label {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 12px; }
  .CreateAdventure_radio_button__206_h ul {
    display: flex;
    flex-flow: wrap; }
    .CreateAdventure_radio_button__206_h ul li {
      margin-bottom: 15px;
      width: 25%; }

.CreateAdventure_select_options__3arir select {
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 15px;
  height: 50px;
  letter-spacing: 0.3px;
  line-height: 19px;
  opacity: 0.5;
  padding: 10px;
  width: 100%; }

.CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-size: 15px;
  grid-template-columns: auto 1fr auto;
  height: 53px;
  margin: 4px;
  padding: 0 11px;
  width: 100%; }
  .CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ ::-webkit-input-placeholder {
    color: #333; }
  .CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ ::placeholder {
    color: #333; }
  .CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ input {
    background: transparent;
    border: 0;
    color: #333;
    margin-left: 10px; }
  .CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ svg {
    fill: #333;
    height: 16px;
    width: 16px; }

.CreateAdventure_select_options__3arir .CreateAdventure_input__CalcZ:focus-within {
  border: 1px solid #ff9514; }

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  left: -9999px;
  position: absolute; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  color: #333;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.7;
  padding-left: 31px;
  position: relative;
  vertical-align: middle; }

/* select component customization */
.CreateAdventure_input__CalcZ {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-size: 15px;
  grid-template-columns: auto 1fr auto;
  height: 53px;
  margin: 4px;
  padding: 11px;
  width: 100%; }
  .CreateAdventure_input__CalcZ ::-webkit-input-placeholder {
    color: #333; }
  .CreateAdventure_input__CalcZ ::placeholder {
    color: #333; }
  .CreateAdventure_input__CalcZ input {
    background: transparent;
    border: 0;
    color: #333;
    margin-left: 10px; }
  .CreateAdventure_input__CalcZ svg {
    fill: #333;
    height: 16px;
    width: 16px; }

.CreateAdventure_input__CalcZ:focus-within {
  border: 1px solid #ff9514; }

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label::before {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 20px; }

[type="checkbox"]:checked + label::before {
  background-color: #ff6c03;
  border: 1px solid #ff6c03;
  border-radius: 4px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 20px; }

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:checked + label::after {
  color: #fff;
  content: "\2713   ";
  font-size: 14px;
  left: 4px;
  line-height: 0.8;
  position: absolute;
  top: 3px;
  transition: all 0.2s; }

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0); }

[type="checkbox"]:checked + label::after {
  opacity: 1;
  transform: scale(1); }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label::before,
[type="checkbox"]:disabled:checked + label::before {
  background-color: #ddd;
  border-color: #bbb;
  box-shadow: none; }

[type="checkbox"]:disabled:checked + label::after {
  color: #999; }

[type="checkbox"]:disabled + label {
  color: #aaa; }

.CreateAdventure_paginationButtons__1ERpP button {
  color: #666;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 50px;
  opacity: 0.7;
  text-transform: uppercase;
  width: 150px; }
  .CreateAdventure_paginationButtons__1ERpP button:first-child {
    border: 1px solid #f0f0f0;
    border-radius: 6px; }

.CreateAdventure_add_location__1fB2L .CreateAdventure_addressLabel__1cCeP {
  color: #333;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 19px; }

.CreateAdventure_add_dates__3Jcr6 .CreateAdventure_grid__24UnR {
  display: flex;
  flex-flow: wrap; }
  .CreateAdventure_add_dates__3Jcr6 .CreateAdventure_grid__24UnR .CreateAdventure_DateInput_input__2KwIE {
    border: 1px solid #999; }

.CreateAdventure_add_dates__3Jcr6 .CreateAdventure_calendarButtons__1PFWI {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center; }
  .CreateAdventure_add_dates__3Jcr6 .CreateAdventure_calendarButtons__1PFWI .CreateAdventure_months__3goA5 {
    margin: 0 15px; }

.CreateAdventure_add_dates__3Jcr6 .CreateAdventure_duration__1_faZ {
  width: 560px; }

.CreateAdventure_add_day__2QS9z span {
  color: #ff9514;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin-bottom: 40px; }

.CreateAdventure_errorBorder__1jr0g {
  border-color: #ff9514 !important; }

.calendar_date__3kn2w {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  height: 73px;
  justify-items: center;
  margin-bottom: 5px;
  margin-right: 7px;
  position: relative;
  text-align: center;
  transition: background-color 0.2s ease-out;
  width: 81px; }
  .calendar_date__3kn2w:hover {
    background-color: #fa9923; }
    .calendar_date__3kn2w:hover p,
    .calendar_date__3kn2w:hover span:first-child,
    .calendar_date__3kn2w:hover span:last-child {
      color: #fff; }
  .calendar_date__3kn2w p {
    bottom: 0;
    color: #333;
    font-size: 13px;
    font-weight: 600;
    height: 50%;
    left: 0;
    line-height: 1;
    margin: auto;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transition: color 0.1s ease-out; }
    .calendar_date__3kn2w p span:first-child {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      transition: color 0.1s ease-out; }
    .calendar_date__3kn2w p span:last-child {
      color: #666;
      display: block;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.2px;
      margin-top: 7px;
      text-transform: none;
      transition: color 0.1s ease-out; }

.calendar_calendar__GkDeW {
  bottom: -300px;
  height: 319px;
  margin-bottom: 20px;
  padding: 18px;
  text-align: center;
  width: 328px;
  z-index: 99; }
  .calendar_calendar__GkDeW h3 {
    color: #333;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 10px !important; }
  .calendar_calendar__GkDeW .calendar_daysOfWeek__3D1TQ {
    color: #666667;
    display: grid;
    font-size: 16px;
    grid-template-columns: repeat(7, 1fr);
    line-height: 20px; }
  .calendar_calendar__GkDeW .calendar_daysOfMonth__3Aiw7 {
    color: rgba(102, 102, 103, 0.4);
    display: grid;
    font-size: 16px;
    grid-gap: 2px;
    grid-template-columns: repeat(7, 1fr);
    line-height: 40px;
    padding: 10px 0; }
    .calendar_calendar__GkDeW .calendar_daysOfMonth__3Aiw7 li {
      background-color: #f8f8f9;
      border-radius: 6px;
      cursor: pointer;
      display: block;
      font-size: 16px; }
    .calendar_calendar__GkDeW .calendar_daysOfMonth__3Aiw7 .calendar_available__3DIDL {
      background-color: rgba(255, 149, 20, 0.2);
      color: #ff9514; }

.calendar_hidden__2mcZP {
  opacity: 0; }

.descriptionAdd .itinerary-label {
  margin-bottom: 15px; }

.descriptionAdd .sectionFroala {
  margin-bottom: 10px; }
  .descriptionAdd .sectionFroala .fr-element {
    margin: 10px 0 0 20px !important; }
  .descriptionAdd .sectionFroala .fr-placeholder {
    margin: 10px 0 0 20px !important; }
  .descriptionAdd .sectionFroala .fr-box {
    margin-bottom: 10px !important; }

.descriptionAdd .fr-box.fr-basic.fr-top {
  border: 1px solid #e6e6e6;
  border-radius: 6px; }
  .descriptionAdd .fr-box.fr-basic.fr-top .fr-toolbar {
    border-bottom: 1px solid #e6e6e6;
    box-shadow: none;
    display: block; }

.requirementsAdd .itinerary-label {
  margin-bottom: 15px; }

.requirementsAdd .sectionFroala {
  margin-bottom: 10px; }
  .requirementsAdd .sectionFroala .fr-element {
    margin: 10px 0 0 20px !important; }
  .requirementsAdd .sectionFroala .fr-placeholder {
    margin: 10px 0 0 20px !important; }
  .requirementsAdd .sectionFroala .fr-box {
    margin-bottom: 10px !important; }

.requirementsAdd .fr-box.fr-basic.fr-top {
  border: 1px solid #e6e6e6;
  border-radius: 6px; }
  .requirementsAdd .fr-box.fr-basic.fr-top .fr-toolbar {
    display: none; }

.Loading_loading__6qFfO {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  /* Safari */ }
  .Loading_loading__6qFfO .Loading_loader__1JRsT {
    animation: Loading_spin__3EvV0 2s linear infinite;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid rgba(0, 0, 0, 0.6);
    height: 60px;
    width: 60px; }

@keyframes Loading_spin__3EvV0 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.UploadImages_section__3QNZq {
  margin: 40px 0; }
  .UploadImages_section__3QNZq .UploadImages_sectionHeader__2t3qi {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0.3px;
    line-height: 19px; }
  .UploadImages_section__3QNZq .UploadImages_sectionTitle__-LGRz {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    width: 540px; }
  .UploadImages_section__3QNZq .UploadImages_sectionContent__3Zvz3 {
    margin-top: 8px; }
  .UploadImages_section__3QNZq .UploadImages_radio__m403J {
    display: inline-block;
    margin-right: 20px; }
  .UploadImages_section__3QNZq .UploadImages_inputs__2Uw2O {
    display: flex;
    flex: 2 1;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; }
  .UploadImages_section__3QNZq .UploadImages_date__3S_DR {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: grid;
    display: grid;
    flex: 1 1 48%;
    font-size: 15px;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
    height: 50px;
    margin-right: 2%;
    margin-top: 20px;
    padding: 11px;
    position: relative; }
    .UploadImages_section__3QNZq .UploadImages_date__3S_DR ::-webkit-input-placeholder {
      color: #333; }
    .UploadImages_section__3QNZq .UploadImages_date__3S_DR ::placeholder {
      color: #333; }
    .UploadImages_section__3QNZq .UploadImages_date__3S_DR input {
      background: transparent;
      border: 0;
      color: #333;
      text-align: left; }
    .UploadImages_section__3QNZq .UploadImages_date__3S_DR svg {
      fill: #333;
      height: 16px;
      width: 16px; }
  .UploadImages_section__3QNZq .UploadImages_company__27VNz {
    flex: 1 1 48%; }
  .UploadImages_section__3QNZq a {
    color: #ff9514 !important;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px; }

.UploadImages_noData__1eGCA {
  width: 100%; }

.UploadImages_form_content__1RQS_ {
  display: flex; }

.UploadImages_form_item__31OTG {
  align-items: center;
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  margin: 0 10px 20px 0;
  position: relative;
  text-align: center;
  width: 126px; }
  .UploadImages_form_item__31OTG img {
    border-radius: 8px;
    height: 96px;
    width: 126px; }

/*  Close Icon  */
.UploadImages_close__3ZIYs {
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 18px;
  transform: translate(25%, -50%);
  width: 24px; }

.UploadImages_close__3ZIYs:hover {
  opacity: 1; }

.UploadImages_close__3ZIYs::before {
  background-color: #000;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px; }

.UploadImages_close__3ZIYs::after {
  background-color: #000;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px; }

.UploadImages_close__3ZIYs::before {
  transform: rotate(45deg); }

.UploadImages_close__3ZIYs::after {
  transform: rotate(-45deg); }

.container {
  margin: auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px; }

.privacy_wrapper {
  padding: 45px 0; }

.privacy_col {
  background: #efefef;
  border-radius: 8px;
  padding: 30px; }

h2 {
  font-size: 38px;
  font-weight: normal; }

.privacy_col h2 {
  color: #333;
  font-family: "Abril Fatface", cursive;
  padding: 21px 0;
  text-transform: capitalize; }

.profile_head {
  border-bottom: 1px solid #dfdfdf;
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: capitalize; }

.terms_head span {
  background: #858789;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  padding: 6px 10px;
  text-transform: uppercase; }

.privacy_col p {
  margin-bottom: 10px; }

p {
  font-size: 16px; }

.privacy_col h2 {
  color: #333;
  padding: 21px 0;
  text-transform: capitalize; }

.privacy_list {
  margin: 15px 0;
  padding: 0; }

.privacy_list li {
  display: block;
  margin-bottom: 15px;
  text-align: left; }

.privacy_list li span {
  font-size: 18px;
  margin-bottom: 10px; }

/* stylelint-disable */
/* stylelint-enable */
.book-adventure {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin: 0 auto;
  max-width: 1080px;
  min-height: calc(100vh - 67px - 88px); }
  .book-adventure__head {
    margin-bottom: 20px;
    text-align: center; }
    .book-adventure__head h1 {
      font-size: 32px; }
      .book-adventure__head h1 a {
        color: #6c757d;
        text-decoration: underline; }
  .book-adventure__form {
    width: 360px; }
    @media (max-width: 576px) {
      .book-adventure__form {
        width: 300px; } }
  .book-adventure__price {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative; }
    .book-adventure__price p {
      margin: 0; }
    .book-adventure__price span {
      font-weight: bold;
      margin-left: 5px; }

