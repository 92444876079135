.mapboxgl-marker {
  z-index: 1;
}
/* Customize Popup Menu */

.mapboxgl-popup {
  z-index: 3;
}

.mapboxgl-popup-content {
  border-radius: 12px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
  max-width: 243px;
  min-height: 85px;
  min-width: 180px;
  padding-bottom: 0;
  z-index: 20;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapGuideName {
  color: #333;
  font-size: 18px;
  word-break: break-word;
}

.mapDetails {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.star {
  font-size: 8px;
}

.address {
  color: #ff9514;
  margin-bottom: 6px;
}
