.news-page {
  padding-bottom: 60px;
  padding-top: 60px;
}

.news-box {
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .mini-logo {
    margin-right: 30px;
    max-width: 240px;
    width: 100%;
  }
  @media (max-width: 766px) {
    flex-flow: wrap;
  }
}

.news-logo {
  @media (max-width: 766px) {
    margin-bottom: 18px;
    width: 100%;
  }
}

.news-content {
  max-width: 630px;

  h3 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }

  span {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px;
  }

  p {
    color: #333;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px;
  }

  a {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px;
  }
}

.news-title {
  h1 {
    color: #333;
    font-size: 34px;
    line-height: 46px;
  }

  p {
    color: #333;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 40px;
  }
}
