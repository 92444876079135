@import "styles/helpers/media";

.error_text {
  color: #f00 !important;
  margin: 0 !important;
  text-align: center;
}

.request_btn .orangebtn {
  background: #fa9923;
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  width: 100%;
}

.subscribe-popup {
  border-radius: 10px;
  bottom: 20px;
  max-width: 400px;
  position: fixed;
  right: 20px;
  width: 100%;
  z-index: 100000;

  @media #{$mobile} {
    max-width: 280px;
  }
}

.subscribe-popup > div {
  background: #674a26;
  border-radius: 10px;
  color: #fff;
  opacity: 0.7;
}

.subscribe-popup > div h3 {
  color: #fff;
  font-size: 22px;
}

.subscribe-popup > div p {
  color: #fff;
  font-size: 16px;
}

.close-subscribe {
  border-radius: 50px;
  color: #696767;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  line-height: 24px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 10px;
  width: 30px;
  z-index: 100;
}

.errorEmail-popup {
  color: rgba(255, 0, 0, 0.7);
  font-size: 14px;
  margin-top: 10px;
}
