@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  border: 1px solid $transparent-white;
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba($flat-black, 0.06);
  margin-top: -105px;
  position: absolute;
  text-align: left;
  width: 100%;

  .desktopBookForm {
    @media (max-width: 768px) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    bottom: 0;
    position: fixed;
    z-index: 30;
  }

  .mobileBookForm {
    background: rgba(255, 255, 255, 1);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .mobileBody {
    background: $flat-white;
    border-radius: 5px;
    box-shadow: 0 5px 36px 0 rgba(0, 0, 0, 0.06);
    height: 90%;
    left: 50%;
    margin: 0 auto;
    padding: 15px 17px;
    position: absolute;
    text-align: center;
    top: 20px;
    transform: translate(-50%, 0);
    width: 90%;
    z-index: 20;
  }

  .mobileFooter {
    @media (min-width: 769px) {
      display: none;
    }
    align-items: center;
    display: flex;
    margin: 20px 0;
    padding: 0 10px;
    position: relative;
  }

  .contactDesktopBody {
    position: relative;
  }

  .contactMobileBody {
    height: 500px;
    left: 50%;
    margin: 0 auto;
    padding: 15px 17px;
    position: absolute;
    text-align: center;
    top: 20px;
    transform: translate(-50%, 0);
    width: 90%;
  }

  .cancel {
    background: none;
    border-width: 0;
    color: $flat-black;
    float: right;
    font-size: 18px;

    @media (min-width: 769px) {
      display: none;
    }
  }

  .bookTour {
    position: absolute;
    right: 5px;
  }

  header {
    border-bottom: 1px solid $very-light-grey;
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: auto 1fr;
    padding: 20px;

    @media (max-width: 768px) {
      display: flex;
    }

    svg {
      fill: $lighter-grey;
      height: 8px;
      width: 8px;
    }

    span {
      color: $basic-orange;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 17px;
    }

    h4 {
      color: $basic-grey;
      display: none;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      max-width: 40px !important;
    }

    p {
      color: $lighter-grey;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .avatar {
    border-radius: 50%;
    height: 60px;
    overflow: hidden;
    width: 60px;

    @media (max-width: 768px) {
      height: 40px;
      margin-right: 10px;
      width: 40px;
    }

    img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .fullName {
    color: $basic-grey;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    max-width: 120px;
  }

  .guide {
    display: grid;
    grid-row-gap: 3px;
    grid-template-rows: repeat(3, auto);
    text-align: left;
  }

  .content {
    display: grid;
    grid-row-gap: 20px;
    grid-template-rows: repeat(3, auto);
    padding: 20px;

    .input {
      align-items: center;
      border: 1px solid $very-light-grey;
      border-radius: 6px;
      color: $basic-grey;
      display: flex;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 19px;
      padding: 7px 16px;
      position: relative;
    }

    .inputMessage {
      align-items: unset !important;
    }

    .arrow_down {
      width: 13px;
    }

    .errorText {
      color: $light-orange !important;
      margin-bottom: 5px;
      margin-left: 5px;
      padding-top: 5px;
      text-align: left !important;
    }

    svg {
      fill: $basic-grey;
      height: 16px;
      margin-right: 5px;
      stroke: $basic-grey;
      width: 16px;
    }

    .arrow_down {
      position: absolute;
      right: 5px;
    }
  }

  button {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 15px 0;
    text-transform: uppercase;
    width: 100%;
  }

  .form_control {
    border-width: 0;
    box-shadow: none;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
  }

  .messageBox {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 61px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    resize: none;
    width: 100%;
  }

  .travelersInput {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 90%;
  }

  label {
    padding-top: 3px;
  }

  .terms {
    color: $lighter-grey;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
    text-align: center;

    a {
      color: $basic-orange;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
