@import "styles/helpers/colors";
@import "styles/helpers/media";

.wrapper {
  background-image: url("/images/home-page-bg-mobile-375.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $flat-white;
  height: 667px;
  margin-top: -67px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 120px;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    .clm-location {
      color: $flat-white;
      flex: 0 0 30%;
      margin-right: 15px;
      max-width: 30%;
    }

    .clm-date {
      flex: 0 0 30%;
      margin-right: 15px;
      max-width: 30%;
    }

    .clm-findbtn {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media #{$tablet} {
    .clm-location {
      flex: 0 0 33%;
      margin-right: 15px;
    }

    .clm-date {
      flex: 0 0 33%;
      margin-right: 15px;
    }

    .clm-findbtn {
      flex: 0 0 28%;
    }
  }

  @media #{$mobile} {
    .clm-location {
      margin-bottom: 16px;
      width: 50%;
    }

    .clm-date {
      margin-bottom: 16px;
    }

    .clm-findbtn {
      margin-bottom: 16px;
    }
  }

  @media #{$tablet} {
    background-image: url("/images/home-page-bg-1600x900.jpg");
    height: 767px;
    padding-top: 220px;
  }

  @media #{$desktop} {
    background-image: url("/images/home-page-bg-1920x1080.jpg");
    height: 767px;
    padding-top: 220px;
  }

  h1 {
    @media (min-width: 767px) {
      max-width: 400px;
    }

    @media (min-width: 1089px) {
      max-width: 600px;
    }
  }

  .banner {
    background-color: #93886e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 15px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: center;
    top: 67px;
    width: 100%;
  }

  .row {
    @media (min-width: 767px) {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%;
    }
  }

  .grid {
    @media (min-width: 767px) {
      display: grid;
      grid-column-gap: 5%;
      grid-template-columns: 1fr 300px;
      margin-bottom: 40px;
    }

    @media #{$mobile} {
      margin-bottom: 48px;
    }
  }

  p {
    color: $flat-white;
  }

  hr {
    background-color: $flat-white;
  }

  .divider {
    @media #{$mobile} {
      display: none;
    }
  }

  .explanation {
    @media #{$mobile} {
      display: none;
    }
  }

  .guides_faces {
    align-items: center;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    grid-column-gap: 11px;
    grid-template-columns: auto 1fr;
    padding: 25px 0;
    text-transform: uppercase;
  }

  .faces_wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .details {
    background-color: $flat-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 104px;
    justify-content: center;
    left: 50%;
    padding-bottom: 5px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, 0);
    width: 232px;

    @media #{$mobile} {
      left: 0;
      transform: translate(0, 0);
    }
  }

  .country {
    color: #ff9514;
    font-size: 13px;
    margin-bottom: 7px;
    padding-top: 35px;
    text-align: center;
  }

  .name {
    color: $flat-black;
    font-size: 18px;
    margin-bottom: 7px;
    text-align: center;
  }

  .extra {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .rating {
    align-items: center;
    color: #666;
    display: flex;
    margin-bottom: 4px;
  }

  .star {
    color: #666;
    font-size: 8px;
  }

  .review {
    color: #666;
  }

  .adventures {
    color: #666;
  }

  .allGuides {
    cursor: pointer;
  }

  .face {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-left: -10px !important;
    position: relative;
    width: 48px;
    z-index: 1;

    &:first-child {
      margin-left: 0;
    }

    img {
      border: 2px solid $flat-white;
      border-radius: 50%;
      height: 48px;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 48px;
    }
  }

  .find_button {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    letter-spacing: 0.25px;
    text-transform: uppercase;

    @media #{$mobile} {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }

    svg {
      fill: $flat-white;
      height: 12px;
      margin-left: 10px;
      margin-top: 3px;
      width: 18px;
    }
  }

  .button {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    justify-content: space-between;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    width: 100%;
  }

  @media #{$mobile} {
    .find_adventure {
      flex-direction: column !important;

      div {
        width: 100% !important;
      }

      div:last-child {
        max-width: 250px !important;
      }
    }
  }

  .find_adventure {
    display: flex;
    flex-direction: row;
    max-width: 80%;

    button {
      height: 50px;
    }
  }

  .find_adventure > div {
    height: 50px;
    margin-bottom: 16px;
    margin-right: 10px;
    max-width: 250px;
    width: 35%;
  }

  .find_adventure > div:last-child {
    height: 50px;
    max-width: 185px;
    width: 30%;
  }

  @media #{$tablet} {
    .find_adventure {
      max-width: 100%;
    }
  }

  .input {
    align-items: center;
    background-color: rgba(98, 98, 94, 0.9);
    border-radius: 8px;
    display: grid;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    opacity: 1;
    padding: 13px 17px;
    position: relative;

    ::placeholder {
      color: $flat-white;
    }

    input {
      background: transparent;
      border: 0;
      color: $flat-white;
      margin-left: 10px;
    }

    svg {
      fill: $flat-white;
      height: 16px;
      width: 16px;
    }

    @media #{$mobile} {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }

  .input::before {
    background: inherit;
    border-radius: 8px;
    content: "";
    // filter: blur(10px);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.DayPicker_transitionContainer {
  border-radius: 8px;
  display: none;
}

.DateRangePickerInput {
  background-color: #f00 !important;
  display: none !important;

  input {
    background-color: #f00 !important;
    display: none;
  }
}
