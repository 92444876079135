@import "styles/helpers/colors";

.wrapper {
  height: 305px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  .row {
    height: 100%;
    max-width: 100%;
    @media (min-width: 767px) {
      margin: 0 auto;
      width: 70%;
    }
  }

  @media (min-width: 769px) {
    height: 460px;
  }

  img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.controls {
  display: grid;
  grid-template-columns: repeat(2, auto);
  height: 100%;
  padding: 0 5px;
  position: absolute;
  width: 100%;

  button {
    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  svg {
    fill: $flat-white;
    height: 20px;
    width: 20px;
  }
}

.info {
  bottom: 65px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, max-content);
  left: 15px;
  position: absolute;

  @media (min-width: 767px) {
    left: 0;
    margin: auto;
    right: 0;
    width: 65%;
  }

  svg {
    height: 18px;
    width: 18px;
  }
}

.count {
  align-items: center;

  color: $basic-orange;
  display: grid;
  font-size: 14px;
  font-weight: 600;
  grid-template-columns: repeat(3, auto);
  text-shadow: #f00;

  svg {
    margin-right: 7px;
  }
}

.like {
  cursor: pointer;
}

.count,
.like {
  // background-color: $flat-white;
  border-radius: 6px;
  padding: 10px 18px;
}
