@import "styles/helpers/colors";

.wrapper {
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0;

  h3 {
    color: $basic-grey;
    font-size: 20px;
    padding: 0 0 20px;
  }

  p {
    color: $light-grey;
    line-height: 1.8;
    max-height: 185px;
    overflow: hidden;

    br {
      line-height: 2.5;
    }
  }

  .extended {
    max-height: none;
  }

  button {
    border-bottom: 1px solid rgba($basic-orange, 0.5);
    color: $basic-orange;
    padding: 0;
  }

  .read_more {
    align-items: center;
    color: $basic-grey;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: auto 1fr;
    letter-spacing: 0.3px;
    padding-bottom: 30px;
    text-align: center;
    text-transform: uppercase;

    span {
      text-align: left;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }
}

.specs {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 320px) {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }

  .specs_items {
    align-items: center;
    display: grid;
    grid-column-gap: 10px;
    grid-template-areas:
      "a b"
      "a c";
    grid-template-columns: auto 1fr;
    padding: 3px 0;
    position: relative;

    &::before {
      // background-color: $transparent-grey;
      border-radius: 50%;
      content: "";
      height: 36px;
      margin-left: -10px;
      position: absolute;
      width: 36px;
      z-index: -1;
    }

    svg {
      fill: $basic-orange;
      grid-area: a;
      height: 24px;
      width: 24px;
    }

    p {
      color: $basic-grey;
      color: #333;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-weight: 666;
      grid-area: b;
      letter-spacing: 0.2px;
      line-height: 15px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    span {
      color: $light-grey;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-size: 14px;
      letter-spacing: 0.17px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }

  .specs_items_suffix {
    margin-right: 8px;
  }
}
