@import "styles/helpers/mixins";
@import "styles/helpers/colors";
@import "styles/helpers/media";

.slick-slider,
.slick-list,
.slick-track,
.slick-slide div {
  height: 100%;
}

.slick-prev {
  @include mask-image("../../assets/AdeventureDetails/caret-left.svg");
  left: 10px;
}

.slick-next {
  @include mask-image("../../assets/AdeventureDetails/caret-right.svg");
  right: 10px;
}

.slick-arrow {
  @include transform(translateY(-50%));
  @include transition(all 0.2s ease);
  background-color: $basic-orange;
  cursor: pointer;
  font-size: 0;
  height: 50px;
  opacity: 0.8;
  outline: none !important;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.slick-slide img {
  height: 100%;
  width: 100%;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide div {
  height: 100%;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.slick-slider {
  @include user-select(none);
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  @include transform(translate3d(0, 0, 0));
}

.slick-track::before,
.slick-track::after {
  content: "";
  display: table;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide-item {
  position: relative;

  span {
    background: rgba(102, 102, 103, 0.8);
    color: $basic-orange;
    display: block;
    font-family: "Abril Fatface", cursive;
    position: absolute;
  }

  .slick-slide-item-title {
    font-size: 20px;
    padding: 10px;
    text-align: center;
    top: 0;
    width: 100%;

    @media #{$mobile} {
      font-size: 14px;
      padding: 5px;
    }
  }

  .slick-slide-item-copy {
    bottom: 0;
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    padding: 5px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media #{$mobile} {
      font-size: 10px;
      padding: 2px;
    }
  }
}

.slick-dots {
  @include justify-content(center);
  @include align-items(center);
  bottom: 15px;
  display: flex !important;
  padding: 10px;
  position: absolute;
  width: 100%;

  li {
    button {
      @include transition(all 0.2s ease);
      background: $flat-white;
      border: 1px solid $flat-white;
      border-radius: 50%;
      font-size: 0;
      height: 20px;
      margin: 0 3px;
      outline: none !important;
      padding: 0;
      position: relative;
      width: 20px;

      &::before {
        @include transition(all 0.2s ease);
        background: $basic-orange;
        border-radius: 50%;
        content: "";
        display: block;
        height: 8px;
        margin: 0 auto;
        opacity: 0;
        width: 8px;
      }
    }

    &:hover,
    &.slick-active {
      /* stylelint-disable */
      button {
        border-color: $basic-orange;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.slick-initialized .slick-slide {
  display: block;
}
