.container {
  margin: auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 991px) {
    padding-left: 38px;
    padding-right: 38px;
  }
  @media (max-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.flex {
  display: flex;

  &.justify-content {
    justify-content: space-between;
  }
}
