@import "styles/helpers/colors";

.slide {
  height: 500px;
  @media (max-width: 768px) {
    height: 324px;
  }

  li {
    background-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid $flat-white !important;
    border-color: $flat-white !important;
    border-radius: 50%;
    border-width: 1px;
    box-sizing: border-box !important;
    height: 15px !important;
    margin-bottom: 5px;
    width: 15px !important;
  }

  .active {
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .img {
    @media (max-width: 768px) {
      display: none;
      height: 324px;
    }
  }

  a {
    @media (max-width: 768px) {
      height: 300px;
    }
  }

  .carousel-inner {
    height: 500px;
    object-fit: cover;
    position: relative;
  }

  .carousel-indicators {
    bottom: 60px;
    @media (max-width: 768px) {
      bottom: 20px;
    }
  }

  img {
    height: 500px;
    max-height: 500px;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 500px;
    }
  }
}
