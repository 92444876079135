@import "styles/helpers/colors";
@import "styles/helpers/media";

.wrapper {
  padding: 40px 15px 0;
  position: relative;

  .row {
    @media (min-width: 767px) {
      margin: 0 auto;
      padding: 0 15px 100px;
      width: 90%;
    }
  }

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .categories {
    color: $light-grey;
    margin-bottom: 30px;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 0 3px;
      transition: color 0.2s ease-out;

      &:hover {
        color: $basic-orange;
      }

      &:first-child {
        color: $basic-orange;
        padding-left: 0;
      }
    }

    span:last-child {
      display: none;
    }
  }

  .cards {
    @media (min-width: 767px) {
      display: grid;
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }
  }

  .see_more {
    align-items: center;
    color: $basic-grey;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase;

    span {
      text-align: right;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }
}

.c3 {
  grid-template-columns: repeat(3, 1fr);
}

.c4 {
  grid-template-columns: repeat(4, 1fr);

  &__adventures {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 255px);
    justify-content: space-between;

    > div {
      margin: 0;
    }

    @media #{$large} {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
    @media #{$mobile} {
      grid-gap: 15px;
      grid-template-columns: repeat(1, 100%);
      justify-content: normal;
    }
  }
}

.wrapper.white {
  background-color: $flat-white;
}

.wrapper.grey {
  background-color: $transparent-grey;
}
