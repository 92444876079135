@import "styles/helpers/colors";
@import "styles/helpers/media";

.section {
  margin: 40px 0;

  .sectionHeader {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0.3px;
    line-height: 19px;
  }

  .sectionTitle {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    width: 540px;
  }

  .sectionContent {
    margin-top: 8px;
  }

  .radio {
    display: inline-block;
    margin-right: 20px;
  }

  .inputs {
    display: flex;
    flex: 2;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .date {
    align-items: center;
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    display: grid;
    display: grid;
    flex: 1 1 48%;
    font-size: 15px;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    grid-template-columns: auto 1fr auto;

    height: 50px;
    margin-right: 2%;
    margin-top: 20px;
    padding: 11px;
    position: relative;

    ::placeholder {
      color: $basic-grey;
    }

    input {
      background: transparent;
      border: 0;
      color: $basic-grey;
      text-align: left;
    }

    svg {
      fill: $basic-grey;
      height: 16px;
      width: 16px;
    }
  }

  .company {
    flex: 1 1 48%;
  }

  a {
    color: #ff9514 !important;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 22px;
  }
}

.noData {
  width: 100%;
}

.form_content {
  display: flex;
}

.form_item {
  align-items: center;
  background-color: rgba(#e6e6e6, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  margin: 0 10px 20px 0;
  position: relative;
  text-align: center;
  width: 126px;

  img {
    border-radius: 8px;
    height: 96px;
    width: 126px;
  }
}

/*  Close Icon  */
.close {
  background-color: $flat-white;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 18px;
  transform: translate(25%, -50%);
  width: 24px;
}

.close:hover {
  opacity: 1;
}

.close::before {
  background-color: $flat-black;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px;
}

.close::after {
  background-color: $flat-black;
  content: " ";
  height: 16px;
  left: 50%;
  position: absolute;
  top: 15%;
  transform: translate(-50%, -25%);
  width: 1px;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}
