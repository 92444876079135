@import "styles/helpers/mixins";
@import "styles/helpers/media";
@import "styles/helpers/colors";

.adventure-about {
  background: $flat-white;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  @media #{$small} {
    padding: 5px 0;
  }

  &__container {
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%;
    @media #{$small} {
      padding: 0 10px;
    }
  }

  &__author {
    @include display-flex();
    @include align-items(center);

    img {
      border-radius: 50%;
      display: block;
      flex-basis: 50px;
      height: 50px;
      margin-right: 30px;
      width: 50px;
    }

    span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-overflow: ellipsis;
      width: 100%;
    }

    @media #{$small} {
      @include flex-direction(column);
      @include flex(0 0 100px);
      @include justify-content(center);

      img {
        flex-basis: 18px;
        height: 18px;
        margin: 0;
        width: 18px;
      }

      span {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  &__rate {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);

    > span {
      display: block;
      font-size: 14px;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__buttons {
    @include display-flex();
    @include align-items(center);
    @include justify-content(flex-end);

    button {
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      height: 50px;
      margin-right: 30px;
      outline: none;
      padding: 0;
      text-transform: uppercase;
      width: 175px;

      &:last-child {
        margin-right: 0;
      }

      @media #{$tablet} {
        margin-right: 20px;
        width: 140px;
      }

      @media #{$mobile} {
        width: 120px;
      }
      @media #{$small} {
        margin-right: 10px;
        width: 90px;
      }
    }

    &__check-dates {
      background: $light-orange;
      border: 0;
      color: $flat-white;
    }

    &__request {
      border: 1px solid #dedede;
      color: $light-grey;
    }
  }
}
