@import "styles/helpers/colors";

.new_adventure {
  margin: auto;
  max-width: 760px;
  padding-top: 59px;

  .form {
    max-width: 540px;
  }
}

.circles {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  .circle {
    background-color: #e6e6e6;
    border-radius: 50%;
    height: 10px;
    margin-right: 8px;
    width: 10px;
  }

  .circleActive {
    background-color: #ff9514;
    border-radius: 50%;
    height: 10px;
    margin-right: 8px;
    width: 10px;
  }
}

.header_title {
  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
  }
}

.inline_group {
  .participants {
    display: flex;
    padding-top: 10px;

    > div {
      margin: 0 5px 30px;
      width: 100%;
      z-index: 5;
    }

    > div:first-child {
      margin-left: 0;
    }

    > div:last-child {
      margin-right: 0;
    }

    input {
      margin: 0;
    }
  }

  .form-group {
    display: none;
  }

  label {
    margin: 0 2% 0 0;
    width: 100%;
  }

  input {
    margin: 0 2%;
    width: 100%;
  }

  .select_options {
    margin: 0 2%;
    max-width: 46%;
    width: 100%;
  }
}

.radio_button {
  margin-bottom: 30px;

  > label {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  ul {
    display: flex;
    flex-flow: wrap;

    li {
      margin-bottom: 15px;
      width: 25%;
    }
  }
}

.select_options {
  select {
    border: 1px solid #e6e6e6;
    color: #333;
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    opacity: 0.5;
    padding: 10px;
    width: 100%;
  }

  .input {
    align-items: center;
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    font-size: 15px;
    grid-template-columns: auto 1fr auto;
    height: 53px;
    margin: 4px;
    padding: 0 11px;
    width: 100%;

    ::placeholder {
      color: $basic-grey;
    }

    input {
      background: transparent;
      border: 0;
      color: $basic-grey;
      margin-left: 10px;
    }

    svg {
      fill: $basic-grey;
      height: 16px;
      width: 16px;
    }
  }

  .input:focus-within {
    border: 1px solid #ff9514;
  }
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  left: -9999px;
  position: absolute;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  color: #333;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.7;
  padding-left: 31px;
  position: relative;
  vertical-align: middle;
}

/* select component customization */
.input {
  align-items: center;
  border: 1px solid $very-light-grey;
  border-radius: 6px;
  font-size: 15px;
  grid-template-columns: auto 1fr auto;
  height: 53px;
  margin: 4px;
  padding: 11px;
  width: 100%;

  ::placeholder {
    color: $basic-grey;
  }

  input {
    background: transparent;
    border: 0;
    color: $basic-grey;
    margin-left: 10px;
  }

  svg {
    fill: $basic-grey;
    height: 16px;
    width: 16px;
  }
}

.input:focus-within {
  border: 1px solid #ff9514;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label::before {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 20px;
}

[type="checkbox"]:checked + label::before {
  background-color: #ff6c03;
  border: 1px solid #ff6c03;
  border-radius: 4px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 20px;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:checked + label::after {
  color: #fff;
  content: "\2713\0020";
  font-size: 14px;
  left: 4px;
  line-height: 0.8;
  position: absolute;
  top: 3px;
  transition: all 0.2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked + label::after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label::before,
[type="checkbox"]:disabled:checked + label::before {
  background-color: #ddd;
  border-color: #bbb;
  box-shadow: none;
}

[type="checkbox"]:disabled:checked + label::after {
  color: #999;
}

[type="checkbox"]:disabled + label {
  color: #aaa;
}

.paginationButtons {
  button {
    color: #666;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 50px;
    opacity: 0.7;
    text-transform: uppercase;
    width: 150px;

    &:first-child {
      border: 1px solid #f0f0f0;
      border-radius: 6px;
    }
  }
}

.add_location {
  .addressLabel {
    color: $basic-grey;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
  }
}

.add_dates {
  .grid {
    display: flex;
    flex-flow: wrap;

    .DateInput_input {
      border: 1px solid #999;
    }
  }

  .calendarButtons {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;

    .months {
      margin: 0 15px;
    }
  }

  .duration {
    width: 560px;
  }
}

.add_day {
  span {
    color: #ff9514;
    cursor: pointer;
    display: block;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 40px;
  }
}

.errorBorder {
  border-color: $light-orange !important;
}
