@import "styles/helpers/mixins";
@import "styles/helpers/media";
@import "styles/helpers/colors";

.guide-modal {
  @include display-flex();
  @include flex-direction(column);
  background: $flat-white;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px;

  &__close {
    @include transition(all 0.2s ease);
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;

    &:hover {
      color: $light-orange;
    }
  }

  @media screen and (max-height: 600px) {
    max-height: 100%;
    overflow-y: scroll;
  }
  @media #{$small} {
    padding: 10px;
    width: 300px;
  }
}

.guide-modal__header {
  @include display-flex();
  @include align-items(center);
  margin-bottom: 20px;
  position: relative;

  &__photo {
    display: block;
    height: 60px;
    margin-right: 12px;
    width: 60px;

    img {
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &__info {
    @include display-flex();
    @include flex-direction(column);

    p {
      color: $basic-grey;
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 10px;
    }

    div {
      @include display-flex();

      span {
        @include display-flex();
        @include align-items(center);
        color: $light-grey;
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;
        position: relative;
        @media #{$small} {
          text-align: center;
        }

        &::after {
          background: $light-grey;
          border-radius: 50%;
          content: "";
          display: block;
          height: 3px;
          margin-left: 6px;
          width: 3px;
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.guide-modal__body {
  $self: &;
  @include display-flex();
  @include flex-direction(column);

  &__error {
    #{$self}__item {
      border-color: $basic-red;
    }

    p {
      color: $basic-red;
      font-size: 16px;
      margin: 3px 0;
    }
  }

  &__item {
    @include display-flex();
    @include align-items(center);
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative;

    img,
    svg {
      height: 16px;
      margin-right: 10px;
      width: 16px;
    }

    input,
    textarea {
      background: none;
      border: 0;
      outline: none;
      width: 100%;
    }

    textarea {
      height: 70px;
      resize: none;
    }

    &__down {
      position: absolute;
      right: 9px;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker {
    .react-datepicker__day {
      &:not(.react-datepicker__day--disabled) {
        background: #fa9923;
        border-radius: 4.8px;

        &:hover {
          color: $flat-white;
          font-weight: bold;
        }
      }
    }
  }

  &__submit {
    background: #ff9514;
    border-radius: 6px;
    color: $flat-white;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    outline: none;
    padding: 15px 17px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      height: 20px;
      margin: 0 auto;
      width: 20px;
    }
  }
}

.guide-modal__footer {
  @include display-flex();
  @include flex-direction(column);
  margin-top: 20px;

  p {
    color: $basic-red;
    font-size: 16px;
    margin: 3px 0;
  }

  label {
    @include display-flex();
    @include align-items(center);
    @include user-select(none);
    color: #333;
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    line-height: 18px;
    padding-left: 35px;
    position: relative;
    text-align: left;
  }

  a {
    color: #fa9923;
    font-weight: 600;
    text-decoration: underline;
  }

  input {
    left: -9999px;
    position: absolute;
  }

  span {
    background-color: #ff9514;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }

  input:checked + span::after {
    @include transform(rotate(45deg));
    border: solid $flat-white;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 10px;
  }
}
