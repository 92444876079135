@import "styles/helpers/colors";
@import "styles/helpers/media";

.wrapper {
  padding-left: 15px;
  padding-right: 15px;

  .row {
    @media (min-width: 767px) {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%;
    }
  }

  .guides_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16.5px;
    margin-top: 58.5px;
    position: relative;
  }

  .face {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-left: -10px !important;
    position: relative;
    width: 48px;
    z-index: 1;

    &:first-child {
      margin-left: 0;
    }

    img {
      border: 2px solid $flat-white;
      border-radius: 50%;
      height: 48px;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 48px;
    }
  }

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    text-align: center;
  }

  p {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 16px;
    letter-spacing: 0.11px;
    line-height: 28px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  .startQuestion {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    line-height: 28px;
    margin-left: 15px;
    text-align: left;
  }

  .instruction_step {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
    margin-left: 15px;
    text-align: left;
  }

  .instruction {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
    margin-left: 15px;
    position: relative;
    text-align: left;
  }

  // .instruction::before {
  //   background: url("../../../assets/guides/check.svg") 0 0 no-repeat;
  //   content: "";
  //   height: 44px;
  //   left: -50px;
  //   position: absolute;
  //   top: 0;
  //   width: 44px;
  // }

  .become_guide_button {
    align-items: center;
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    letter-spacing: 0.25px;
    margin: 4px;
    margin: auto;
    padding: 14px;
    text-transform: uppercase;
    width: 183px;

    svg {
      fill: $flat-white;
      height: 12px;
      margin-left: 10px;
      width: 18px;
    }
  }
}
