.title {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 34px;
  height: 46px;
  line-height: 46px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  .dot {
    margin: 0 5px;
  }

  .active {
    color: #ff930f;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px;
  }

  .general {
    color: #666;
    cursor: pointer;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 28px;
    letter-spacing: 0.53px;
    line-height: 28px;
  }
}
