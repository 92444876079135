@import "styles/helpers/colors";
@import "styles/helpers/mixins";

.menu__title {
  @include transition(all 0.2s ease);
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    text-decoration: underline;

    > div {
      @include display-flex();
    }
  }
}

.submenu {
  @include transform(translateX(-50%));
  background-color: $flat-white;
  cursor: auto;
  display: none;
  left: 50%;
  padding: 10px;
  position: absolute;

  div {
    a {
      @include transition(all 0.2s ease);
      border-radius: 6px;
      color: $basic-grey;
      display: block;
      line-height: normal;
      padding: 5px;

      &::after {
        display: none;
      }

      &:hover {
        background: rgba(255, 149, 20, 0.2);
      }
    }
  }
}

.submenu__item {
  @include display-flex();
  @include flex-direction(column);
  width: 150px;

  h4 {
    color: $basic-grey;
    font-size: 18px;
    line-height: 20px;
    margin: 5px 5px 15px;
    text-align: left;
  }
}
