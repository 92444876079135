@import "styles/helpers/mixins";
@import "styles/helpers/media";
@import "styles/helpers/colors";

.fixed-footer {
  @include transition(all 0.5s ease);
  background: $flat-white;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  @media #{$small} {
    padding: 5px 0;
  }

  &__container {
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
    margin: 0 auto;
    max-width: 1110px;
    padding: 0 40px;
    width: 100%;
    @media #{$small} {
      padding: 0 10px;
    }
  }

  &.hidden {
    bottom: -80px;
  }
}
