@import "styles/helpers/colors";
@import "styles/helpers/media";

.wrapper {
  background-color: #f8f8f9;
  padding: 40px 15px 0;
  position: relative;

  .row {
    @media (min-width: 767px) {
      margin: 0 auto;
      max-width: 1110px;
      padding: 0 15px;
      width: 90%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;

    @media #{$desktop} {
      flex-direction: row;
    }
  }

  .category {
    display: flex;
    flex: 2;
    flex-direction: row;
    margin-bottom: 50px;

    @media (max-width: 380px) {
      flex-direction: column;
      max-width: 1110px;
    }
  }

  .detail {
    flex: 1 50%;
    max-width: 445px;
  }

  .categories {
    color: $light-grey;
    margin-bottom: 30px;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 0 3px;
      transition: color 0.2s ease-out;

      &:hover {
        color: $basic-orange;
      }

      &:first-child {
        color: $basic-orange;
        padding-left: 0;
      }
    }

    span:last-child {
      display: none;
    }
  }

  .regions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .region {
    color: #666;
    cursor: pointer;
    font-size: 16px;
  }

  .regionActive {
    color: #ff9514;
    cursor: pointer;
    font-size: 16px;
  }

  .cards {
    @media (min-width: 767px) {
      display: grid;
      grid-column-gap: 30px;
    }
  }

  .see_more {
    align-items: center;
    color: $basic-grey;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase;

    span {
      text-align: right;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }
}

.c3 {
  grid-template-columns: repeat(3, 1fr);
}

.c4 {
  grid-template-columns: repeat(4, 1fr);
}

.wrapper.white {
  background-color: $flat-white;
}

.wrapper.grey {
  background-color: $transparent-grey;
}
