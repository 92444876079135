@import "styles/helpers/mixins";
@import "styles/helpers/media";

.search-filter-bg {
  background: #f1f1f2;
}

.search-filter {
  @include display-flex;
  @include align-items(center);
  @include flex-wrap(wrap);
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 5;

  &__select {
    height: 40px;
    margin: 8px 16px 8px 0;
    width: 165px;

    &__dropdown-indicator {
      color: #333 !important;

      svg {
        @include transform(rotate(0));
        @include transition(all 0.2s ease);
      }
    }

    &__clear-indicator {
      color: #fff !important;

      &:hover {
        color: #666 !important;
      }
    }

    &__placeholder,
    &__single-value {
      color: #333;
      cursor: text;
      font-size: 15px;
      line-height: 19px;
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__placeholder {
      @include transform(translateY(0) !important);
      position: relative !important;
      top: auto;
    }

    &__input {
      input {
        color: #333 !important;
        font-size: 15px !important;
        line-height: 19px !important;
        margin: 0 !important;
      }
    }

    &__value-container {
      @include flex-wrap(nowrap !important);
      padding: 0 !important;
    }

    &__indicator {
      padding: 0 !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu-list {
      padding: 0 !important;
    }

    &__menu {
      background: #fff;
      border: 1px solid #f7f7f7;
      border-radius: 6px;
      margin-top: 4px;
      min-width: 260px;
      overflow-x: hidden;
      position: relative;
      z-index: 100;
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__destination {
    width: 260px;
  }

  button {
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    @include transition(all 0.2s ease);
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0;
    outline: none !important;
    padding: 9px 16px;
    width: 165px;

    > svg {
      fill: #fff;
      height: 12px;
      width: 18px;
    }

    &:hover {
      border: 1px solid #dedede;

      > svg {
        fill: #fff;
      }
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  @media #{$mobile} {
    &__destination,
    &__select,
    button {
      margin: 8px 0;
      width: 100%;
    }
  }
}

.search-filter__select__option {
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  @include transition(all 0.2s ease);
  background: #fff;
  cursor: pointer;
  padding: 10px 17px;

  .sub {
    color: #666;
    font-size: 14px;
    line-height: 18px;
  }

  &__item {
    height: 24px;
    margin-right: 12px;
    width: 24px;

    span {
      @include transition(all 0.2s ease);
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      height: 100%;
      position: relative;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &:hover,
  &__selected {
    background: rgba(255, 149, 20, 0.1);

    .sub {
      color: #ff8c00;
    }

    &#{&}__item,
    .search-filter__select__option__item {
      span {
        background: #ff8c00;

        &::before {
          border: solid #fff;
          border-width: 0 2px 2px 0;
          content: "";
          display: block;
          height: 8px;
          transform: rotate(45deg);
          width: 4px;
        }
      }
    }
  }
}

.search-filter__select__control {
  @include transition(all 0.2s ease);
  @include display-flex();
  @include align-items(center);
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  cursor: pointer;
  padding: 9px 16px;

  .css-1g6gooi {
    margin: 0 !important;
    padding: 0 !important;
  }

  &__focused,
  &__isOpen,
  &:hover {
    border-color: #ff9514;
  }

  &__fill {
    background-color: #ff9514;
    border-color: #ff9514;

    .search-filter__select__dropdown-indicator,
    .search-filter__select__single-value,
    .search-filter__select__placeholder {
      color: #fff !important;
    }

    .search-filter__select__input {
      input {
        color: #fff !important;
      }
    }
  }

  &__isOpen {
    .search-filter__select__dropdown-indicator {
      svg {
        @include transform(rotate(180deg));
      }
    }
  }
}
