.star_ratings {
  align-items: center;
  color: #c5c5c5;
  display: flex;
  font-size: 25px;
  height: 25px;
  padding: 0;
  position: relative;
  text-shadow: 0 1px 0 #a2a2a2;
  unicode-bidi: bidi-override;
  width: 90px;

  .star_ratings_top {
    color: #ff9514;
    display: block;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .star_ratings_bottom {
    display: block;
    padding: 0;
    z-index: 0;
  }

  span {
    font-size: 18px;
  }
}

.ratingText {
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.09px;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 5px;
}
