@import "styles/helpers/colors";

.wrapper {
  padding: 40px 0;
  position: relative;

  @media (min-width: 767px) {
    padding: 90px 0;
  }

  .reverse {
    .column:first-child {
      order: 2;
    }

    .column:last-child {
      order: 1;
    }
  }

  .row {
    padding: 0 15px;

    @media (min-width: 767px) {
      display: grid;
      grid-column-gap: 125px;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
      max-width: 1110px;
      padding: 0;
      width: 90%;
    }
  }

  h3 {
    margin-bottom: 30px;
  }

  q {
    color: #666;
    font-size: 16px;
    line-height: 28px;
  }

  .read_more {
    align-items: center;
    color: $basic-grey;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: auto 1fr;
    letter-spacing: 0.3px;
    padding-bottom: 30px;
    text-align: center;
    text-transform: uppercase;

    span {
      text-align: left;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }

  img {
    width: 100%;
  }

  .referenceImages {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @media (min-width: 1025px) {
      flex-direction: row;
    }
  }

  .partOne {
    flex-direction: column;
    margin-right: 6%;
    position: relative;
    width: 48%;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      height: 30%;
      position: relative;
      width: 100%;
    }
  }

  .dots {
    color: #666;
    left: 30%;
    max-height: 250px;
    overflow: hidden;
    position: absolute;
    right: -10%;
    top: -5%;

    @media (min-width: 1025px) {
      top: -10%;
    }

    @media (max-width: 768px) {
      max-height: 300px;
      right: -1%;
    }
  }

  .dots::before {
    color: #666;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .
      . . . . . . . . . . . . . . . . . . . . . . . . . .";
    letter-spacing: 4px;
  }

  .partOneImg {
    height: 48%;
    margin-bottom: 8%;
    margin-right: 4%;
    width: 100%;

    @media (max-width: 1024px) {
      height: 100%;
      position: relative;
    }
  }

  .imgContentFirst {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1024px) {
      bottom: 100%;
      left: 0;
      position: absolute;
      right: 100%;
      top: 0;
    }
  }

  .partOneImg:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  .partTwo {
    height: 70%;
    padding-top: 5%;
    position: relative;
    width: 100%;

    .imgContentThird {
      border-radius: 8px;
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
    }

    @media (min-width: 1025px) {
      height: auto;
      padding-top: 0;
      width: 47%;
    }
  }

  .hitImages {
    display: flex;
    flex: 2 10px;
    flex-direction: row;
  }

  .hitOneImg {
    display: flex;
    flex-direction: column;
    margin-right: 4%;
    position: relative;
    width: 98%;
  }

  .hitTwoImg {
    display: flex;
    flex-direction: column;
    width: 98%;
  }

  .decCircle {
    background-color: #ff9514;
    border-radius: 50%;
    height: 74px;
    left: 0;
    position: absolute;
    top: 100%;
    transform: translate(-30%, -80%);
    width: 74px;

    @media (max-width: 768px) {
      transform: translate(-8%, -70%);
    }
  }

  .hitSquare {
    position: relative;
  }

  .hitOneFirst {
    border-radius: 8px;
    bottom: 0;
    height: 80%;
    left: 0;
    margin-bottom: 5px;
    margin-top: -20%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 20%;
  }

  .hitSecond {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    left: 0;
    margin-bottom: 5px;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
  }

  .hitTwoFirst {
    border-radius: 8px;
    bottom: 0;
    height: 80%;
    left: 0;
    margin-bottom: 5px;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 20%;
  }

  .wdImages {
    display: flex;
    flex-direction: row;
    height: 458px;
    position: relative;
  }

  .wdMask {
    float: left;
    height: 100%;
    margin-right: 10px;
    width: 100%;
  }

  .wdOne {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    // left: 0;
    // object-fit: contain;
    // overflow: hidden;
    // position: absolute;
    // right: 68%;
    // top: 0;
  }

  .wdTwo {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    // left: 34%;
    // object-fit: contain;
    // overflow: hidden;
    // position: absolute;
    // right: 34%;
    // top: 0;
  }

  .wdThird {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    // left: 68%;
    // object-fit: contain;
    // overflow: hidden;
    // position: absolute;
    // right: 0%;
    // top: 0;
  }

  .wdOneImg {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    // margin-left: 0%;
    // margin-right: -68%;
    width: 100%;
  }

  .wdTwoImg {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    // margin-left: -100%;
    // margin-right: -34%;
    width: 100%;
  }

  .wdThirdImg {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    // margin-left: -200%;
    // margin-right: 0%;
    width: 100%;
  }
}

.wrapper.white {
  background-color: $flat-white;
}

.wrapper.grey {
  background-color: $transparent-grey;
}
