.descriptionAdd {
  .itinerary-label {
    margin-bottom: 15px;
  }

  .sectionFroala {
    margin-bottom: 10px;

    .fr-element {
      margin: 10px 0 0 20px !important;
    }

    .fr-placeholder {
      margin: 10px 0 0 20px !important;
    }

    .fr-box {
      margin-bottom: 10px !important;
    }
  }
}

.descriptionAdd .fr-box.fr-basic.fr-top {
  border: 1px solid #e6e6e6;
  border-radius: 6px;

  .fr-toolbar {
    border-bottom: 1px solid #e6e6e6;
    box-shadow: none;
    display: block;
  }
}

.requirementsAdd {
  .itinerary-label {
    margin-bottom: 15px;
  }

  .sectionFroala {
    margin-bottom: 10px;

    .fr-element {
      margin: 10px 0 0 20px !important;
    }

    .fr-placeholder {
      margin: 10px 0 0 20px !important;
    }

    .fr-box {
      margin-bottom: 10px !important;
    }
  }
}

.requirementsAdd .fr-box.fr-basic.fr-top {
  border: 1px solid #e6e6e6;
  border-radius: 6px;

  .fr-toolbar {
    display: none;
  }
}
