@import "styles/helpers/mixins";

.modal-overlay {
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: manipulation;
  width: 100%;
  z-index: 8888;
}

.modal-popup {
  @include display-flex();
  @include flex-direction(column);
  background: $flat-white;
  border-radius: 6px;
  padding: 30px;
  position: relative;
  width: 375px;

  &__close {
    @include transition(all 0.2s ease);
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;

    &:hover {
      color: $light-orange;
    }
  }

  @media screen and (max-height: 600px) {
    max-height: 100%;
    overflow-y: scroll;
  }
  @media #{$small} {
    padding: 10px;
    width: 300px;
  }
}

.modal-open {
  overflow: hidden;
  position: relative;
  width: calc(100% - 17px);

  .fixed-footer {
    @include transition(none);
    width: calc(100% - 17px);
  }
}
