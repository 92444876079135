@import "styles/helpers/colors";

.info {
  align-items: center;
  color: $light-grey;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  max-width: 250px;

  svg {
    fill: $light-grey;
    height: 8px;
    width: 8px;
  }

  .rating {
    align-items: center;
    display: flex;
  }

  span {
    color: $light-grey !important;
    font-size: 8px;
  }

  .data {
    font-size: 13px;
    padding: 5px;
  }
}
