.wrapper {
  background-color: rgba(#e6e6e6, 0.5);
  border-radius: 8px;
  min-height: 96px;
  min-width: 126px;
  text-align: center;
  width: 100%;

  .borderTop {
    background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-repeat: repeat-x;
    background-size: 50px 1px;
    border-radius: 8px;
    height: 96px;
  }

  .borderBottom {
    background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 50px 1px;
    border-radius: 8px;
    height: 100%;
  }

  .borderLeft {
    background-image: linear-gradient(to bottom, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-repeat: repeat-y;
    background-size: 1px 30px;
    border-radius: 8px;
    height: 100%;

    section {
      height: 100%;
    }
  }

  .borderRight {
    background-image: linear-gradient(to top, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-repeat: repeat-y;
    background-size: 1px 30px;
    border-radius: 8px;
    height: 100%;
  }

  .uploader {
    height: 30px;
    margin-top: 20px;
    width: 30px;
  }

  .title {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    opacity: 0.7;
    padding-bottom: 20px;
  }
}
