@import "styles/helpers/colors";

.react-datepicker-popper {
  box-shadow: 0 16px 36px 0 rgba($flat-black, 0.06);
  margin-left: -38px;
  z-index: 2;

  .button {
    display: none;
  }
}

.react-datepicker {
  border: 0;
  width: 270px;

  button {
    background-color: #fff;
    color: #333;
    height: 14px;
    width: 14px;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff;
  border-width: 0;
}

.react-datepicker__month-container {
  float: none;
}

.react-datepicker__navigation--previous {
  position: relative;
}
// .react-datepicker__navigation--previous:before {
//   content: '<';
//   color: red;
//   width: 15px;
//   height: 15px;
//   position: absolute;
//   left: 0;
//   top: 0;
// }

// .react-datepicker__navigation--next {
//   background: url() no-repeat;
//   width: 15px;
//   height: 15px;
//   border: none;
// }
