@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  height: 127px;

  @media (min-width: 767px) {
    height: 88px;
  }
}

.row {
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    "logo social"
    "copy copy";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1110px;
  padding: 24px 15px;

  @media (min-width: 767px) {
    grid-template-areas: "logo social copy";
    margin: auto;
    padding: 24px 0;
    width: 90%;
  }
}

.logo {
  align-items: center;
  display: grid;
  grid-area: logo;

  img {
    height: 40px;
    width: 60px;
  }
}

.social {
  align-items: center;
  display: grid;
  grid-area: social;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, auto);
  justify-items: end;
  justify-self: end;
  max-width: 160px;

  @media (min-width: 767px) {
    justify-self: center;
  }

  a {
    align-items: center;
    background-color: $transparent-grey;
    border-radius: 50%;
    display: grid;
    height: 40px;
    justify-content: center;
    overflow: hidden;
    width: 40px;
  }
}

.copyright {
  color: $light-grey;
  font-size: 12px;
  grid-area: copy;
  line-height: 1.5;

  a {
    color: $light-grey;
    text-decoration: underline;
  }

  @media (min-width: 767px) {
    align-items: center;
    display: grid;
  }
}

.facebook {
  fill: $facebook;
  height: 16px;
  width: 15px;
}

.twitter {
  fill: $twitter;
  height: 16px;
  width: 16px;
}

.instagram {
  height: 18px;
  width: 18px;
}
