@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  border-radius: 40px;
  color: #666;
  display: flex;
  justify-content: center;
  width: 150px;
}
