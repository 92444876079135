@import "styles/helpers/colors";
@import "styles/helpers/text";

.wrapper {
  .item {
    display: flex;
    width: 100%;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .border {
    border-bottom: 1px solid#e6e6e6;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  //Details Part
  .details {
    width: 100%;
  }
  //Adventure Part
  .thumb {
    position: relative;

    @media (max-width: 600px) {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  img {
    border-radius: 8px;
    height: 177px;
    object-fit: cover;
    width: 160px;

    @media (max-width: 600px) {
      height: 222px;
      width: 100%;
    }
  }

  .adCategory {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    height: 18px;
    line-height: 18px;
    margin-bottom: 6px;
  }

  .adTitle {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .adLocation {
    align-items: center;
    color: $light-grey;
    display: grid;
    font-size: 14px;
    grid-column-gap: 7px;
    grid-template-columns: auto 1fr;
    margin-bottom: 46px;
  }

  .locationIcon {
    fill: $light-grey;
    height: 12px;
    width: 12px;
  }

  //Adventure Details part(adventure)
  .adventure {
    display: flex;
  }

  .adventureDetail {
    padding-left: 30px;
    width: 100%;

    @media (max-width: 599px) {
      padding-left: 0;
    }
  }

  .desktopTripStatus {
    @media (max-width: 599px) {
      display: none;
    }
  }

  .mobileTripStatus {
    background: $flat-white;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    bottom: 0;
    position: absolute;
    right: 0;

    @media (min-width: 600px) {
      display: none;
    }
  }

  .status {
    float: right;
    padding: 0 20px;
  }

  .statusInfo {
    align-items: center;
    display: flex;
  }

  //Guide Information Part
  .guideInfo {
    align-items: center;
    background-color: #f8f8f9;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    position: relative;

    @media (max-width: 599px) {
      border-radius: 8px;
      padding-left: 15px;
    }
  }

  .guideBreadCrumb {
    align-items: center;
    display: flex;
  }

  .guideDeskTopBreadCrumb {
    align-items: center;
    display: flex;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .guideMobileBreadCrumb {
    align-items: center;
    display: flex;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .guideThumb {
    border-radius: 50%;
    height: 36px;
    margin: 5px 0;
    width: 36px;
  }

  .guideName {
    color: $general-text;
    font-family: $general-text-family;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 20px;
    padding: auto 0;
  }

  .txt {
    color: $grey-text;
    font-family: $general-text-family;
    font-size: 14px;
    height: 18px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }

  .message {
    height: 14px;
    margin-right: 12px;
    width: 18px;

    @media (max-width: 767px) {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  .messageTxt {
    color: $grey-text;
    font-family: $general-text-family;
    font-size: 14px;
    height: 18px;
    letter-spacing: 0.2px;
    line-height: 20px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .shot_vr {
    background-color: #d6d6d6;
    height: 24px;
    margin: 0 12px;
    width: 1px;
  }

  .big_vr {
    background-color: #d6d6d6;
    height: 73px;
    margin: 0 16px;
    width: 1px;
  }

  //Status Info
  .bookInfo {
    min-width: 80px;
    text-align: right;
  }

  .gudieTxt {
    color: #666;
    font-family: $general-text-family;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .bookStatusTxt {
    color: $light-orange;
    font-family: $general-text-family;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .date {
    color: $general-text;
    font-family: $general-text-family;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .payment {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    position: relative;

    @media (max-width: 500px) {
      display: none;
    }
  }

  .mobilePayment {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-top: 10px;
    position: relative;
    width: 100%;
    @media (min-width: 501px) {
      display: none;
    }
  }

  .paymentBtn {
    color: $light-grey;
    font-family: $general-text-family;
    font-size: 14px;
    font-weight: 600;
    padding: 9px 12px;
  }

  .paymentBtn:hover {
    background-color: $light-orange;
    border-radius: 8px;
    color: $flat-white;
    font-family: $general-text-family;
    font-size: 14px;
    font-weight: 600;
    padding: 9px 12px;
  }

  a {
    color: $flat-white;
    text-decoration: none;
  }
}
