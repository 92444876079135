@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  border: 1px solid $transparent-white;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 2px #ccc;
  text-align: left;
  width: 100%;

  @media (max-width: 992px) {
    margin-top: 55px;
  }

  @media (max-width: 768px) {
    border: 0;
    box-shadow: none;
  }

  header {
    border-bottom: 1px solid $very-light-grey;
    padding: 20px;

    svg {
      fill: $lighter-grey;
      height: 8px;
      width: 8px;
    }

    span {
      color: $basic-orange;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 17px;
    }

    h4 {
      color: $basic-orange;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-top: 15px;
    }

    p {
      color: $lighter-grey;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .avatar {
    border-radius: 8px;
    height: 60%;
    object-fit: contain;
    overflow: hidden;
    width: 100%;

    img {
      height: 350px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .sAvatar {
    img {
      border-radius: 50%;
      height: 35px;
      width: 35px;
    }
  }

  h5 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    margin: 0 !important;
    text-transform: capitalize;
  }

  .sGuideName {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }

  .guide {
    display: grid;
    grid-row-gap: 3px;
    grid-template-rows: repeat(3, auto);
    text-align: left;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .content {
    display: grid;
    grid-row-gap: 20px;
    grid-template-rows: repeat(3, auto);
    padding: 20px;

    @media (max-width: 992px) {
      display: none;
    }

    .input {
      align-items: center;
      border: 1px solid $very-light-grey;
      border-radius: 6px;
      color: $basic-grey;
      display: flex;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 19px;
      padding: 7px 16px;
      position: relative;
    }

    .inputMessage {
      align-items: unset !important;
    }

    .arrow_down {
      width: 13px;
    }

    .errorText {
      color: #f00 !important;
      margin-bottom: 5px;
      margin-left: 5px;
      padding-top: 5px;
      text-align: left !important;
    }

    svg {
      fill: $basic-grey;
      height: 16px;
      margin-right: 5px;
      stroke: $basic-grey;
      width: 16px;
    }

    .arrow_down {
      position: absolute;
      right: 5px;
    }
  }

  button {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .form_control {
    border-width: 0;
    box-shadow: none;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
  }

  .messageBox {
    border-width: 0;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 61px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    padding-left: 6px;
    resize: none;
    width: 100%;
  }

  .travelersInput {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 90%;
  }

  .terms {
    color: $lighter-grey;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    a {
      color: $basic-orange;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
