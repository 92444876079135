.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 90px;
  position: relative;

  .content {
    padding: 170px 0;
    @media (max-width: 991px) {
      padding: 113px 0;
    }
    @media (max-width: 576px) {
      padding: 97px 0 98px;
    }

    h3 {
      color: #fff;
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    p {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 40px;
      margin-top: 16px;
      max-width: 488px;
      opacity: 0.9;
    }

    a {
      display: inline-block;
    }
  }

  h1 {
    color: #fff;
    font-family: "Abril Fatface", cursive;
    font-size: 54px;
    font-weight: 400;
    line-height: 73px;
    @media (max-width: 576px) {
      font-size: 44px;
      line-height: 52px;
    }
  }
}

.ladakh.hero {
  .content {
    margin: auto;
    max-width: 740px;
    padding: 40px 0 90px;
    text-align: center;

    h1 {
      margin: auto;
      max-width: 500px;
    }

    p {
      max-width: 1060px;
    }
  }
}
