@import "styles/helpers/colors";
@import "styles/helpers/media";
@import "styles/helpers/mixins";

.book-adventure {
  @include display-flex();
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  margin: 0 auto;
  max-width: 1080px;
  min-height: calc(100vh - 67px - 88px);

  &__head {
    margin-bottom: 20px;
    text-align: center;

    h1 {
      font-size: 32px;

      a {
        color: #6c757d;
        text-decoration: underline;
      }
    }
  }

  &__form {
    width: 360px;

    @media #{$small} {
      width: 300px;
    }
  }

  &__price {
    @include display-flex();
    @include align-items(center);
    border: 1px solid $very-light-grey;
    border-radius: 6px;
    margin: 10px 0;
    padding: 13px 17px;
    position: relative;

    p {
      margin: 0;
    }

    span {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
