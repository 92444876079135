@import "styles/helpers/colors";

.wrapper {
  padding: 40px 15px 0;
  position: relative;

  .row {
    // @media (min-width: 767px) {
    //   margin: 0 auto;
    //   padding: 0 15px 100px;
    //   width: 90%;
    // }
  }

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .cards {
    // @media (min-width: 767px) {
    //   display: grid;
    //   grid-column-gap: 30px;
    //   grid-row-gap: 30px;
    // }
  }

  .see_more {
    align-items: center;
    color: $basic-grey;
    cursor: pointer;
    display: grid;
    font-size: 15px;
    font-weight: 600;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, auto);
    letter-spacing: 0.3px;
    padding: 30px 0 50px;
    text-align: center;
    text-transform: uppercase;

    span {
      text-align: right;
    }

    svg {
      height: 35px;
      width: 35px;
    }
  }
}
