.loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  .loader {
    // -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid rgba(0, 0, 0, 0.6);
    height: 60px;
    width: 60px;
  }

  /* Safari */
  // @-webkit-keyframes spin {
  //   0% {
  //     -webkit-transform: rotate(0deg);
  //   }

  //   100% {
  //     -webkit-transform: rotate(360deg);
  //   }
  // }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
