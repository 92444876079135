@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  margin-top: 20px;
  width: 100%;

  .adventureLength {
    color: $lighter-grey;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .item {
    margin-top: 20px;
    position: relative;
  }

  .backgroundImage {
    img {
      border-radius: 8px;
      height: 120px;
      object-fit: cover;
      width: 100%;
    }

    @media (max-width: 768px) {
      img {
        height: 280px;
      }
    }
  }

  .adventureInfo {
    bottom: 0;
    color: $flat-white;
    font-weight: 600;
    left: 50%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .title {
    margin-top: 15px;
  }
}
