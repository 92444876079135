/* stylelint-disable */
@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin justify-content($justify) {
  -ms-flex-pack: $justify;
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
  -ms-flex-align: $align;
}

@mixin radius($props) {
  -webkit-border-radius: $props;
  -moz-border-radius: $props;
  border-radius: $props;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin user-select($value) {
  -moz-user-select: $value;
  -ms-user-select: $value;
  -o-user-select: $value;
  -webkit-user-select: $value;
  user-select: $value;
}

@mixin display-inline-flex() {
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin mask-image($image) {
  -webkit-mask-image: url($image);
  mask-image: url($image);
}
/* stylelint-enable */
