@import "helpers/reset";
@import "helpers/transitions";
@import "helpers/text";
@import "helpers/colors";
@import "helpers/typography";
@import "helpers/media";
@import "helpers/mixins";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,600,700,900&amp;subset=latin-ext");

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive;
}

.App {
  @include display-flex();
  @include flex-direction(column);
  min-height: 100vh;

  &.modal-open {
    height: 100vh;
    overflow: hidden;
    width: 100%;
  }
}

button {
  font-family: "Source Sans Pro", sans-serif;
}

.checkbox {
  color: #333;
  cursor: pointer;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;
  padding-left: 35px;
  position: relative;
  text-align: left;
}

/* Hide the browser's default checkbox */
.checkboxInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.radioInput {
  cursor: pointer;
  display: none;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

/* When the checkbox is checked, add a orange background */
.checkboxInput:checked ~ .checkmark {
  background-color: #ff9514;
}

.checkboxInput:checked ~ .guideCheckmark {
  background-color: #ff9514;
  color: #fff;
}

.radioInput:checked ~ .radioMark {
  background-color: #fff;
}

/* Create a custom checkbox */
.checkmark {
  background-color: #ff9514;
  border-radius: 4px;
  height: 24px;
  left: 0;
  padding-top: 3px;
  position: absolute;
  top: 0;
  width: 24px;
}

.guideCheckmark {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.radioMark {
  background-color: #fff;
  border: 1px solid #ff9514;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  display: none;
  position: absolute;
}

.guideCheckmark::after {
  color: #fff;
  content: "";
  display: none;
  position: absolute;
}

.radioMark::after {
  content: "";
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.checkboxInput:checked ~ .checkmark::after {
  display: block;
}

.checkboxInput:checked ~ .guideCheckmark::after {
  display: block;
}

.radioInput:checked ~ .radioMark::after {
  display: block;
}
/* Style the checkmark/indicator */
.checkmark::after {
  border: solid#fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 3px;
  transform: rotate(45deg);
  width: 10px;
}

.guideCheckmark::after {
  border: solid#fff;
  border-width: 0 3px 3px 0;
  height: 15px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 10px;
}

.radioMark::after {
  background: #ff9514;
  border-radius: 50%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}

/*=========== spinner =============*/

.loaderSpinner {
  @include display-flex();
  @include flex(1);
  @include justify-content(center);
  @include align-items(center);
  width: 100%;
}

.loader {
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  height: 120px;
  margin: 30px auto 0;
  width: 120px;
}
/* stylelint-disable */
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* stylelint-enable */

.lazy-load-image-background {
  height: 100%;
  padding-top: 100%;
  width: 100%;

  img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.lazyImageNP {
  padding-top: 0 !important;
}

.finish_google_survey {
  color: #666;
  text-decoration: none;
}

.finish_google_survey:hover {
  color: #fff;
}

.finish_btn button:hover a {
  border: 0;
  color: #fff;
  text-decoration: none;
}

/* location */

.home-locations {
  margin: 0 auto;
  max-width: 1110px;
  padding: 80px 15px;
  width: 90%;

  ul {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px;
  }

  li {
    span {
      align-items: center;
      border-radius: 6px;
      display: flex;
      position: relative;
    }
  }

  li h3 {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0;
    margin-left: 15px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  li {
    img {
      height: 28px;
      width: 28px;
    }
  }

  li .rightArrow {
    height: auto;
    position: absolute;
    right: 20px;
    width: 9px;
  }
}

.home-locations ul li {
  cursor: pointer;
  padding: 20px;
  width: 16.6667%;
  @media (max-width: 992px) {
    width: 33.333%;
  }
  @media (max-width: 767px) {
    width: 33.333%;
  }
  @media (max-width: 650px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}

.location-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.location-text p {
  margin-bottom: 0;
  @media (max-width: 767px) {
    display: none;
  }
}

.leftArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px;

  &__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.rightArrow {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 50%;
  height: 30px;
  position: relative;
  text-align: center;
  width: 30px;

  &__arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.phone-icon {
  max-width: 30px;
}

.search-filter.header-filter {
  button {
    width: 260px;
    @media #{$mobile} {
      width: 100%;
    }
  }
}

@import "styles/components/searchFilter";
@import "styles/components/adventureAbout";
@import "styles/components/guidesFooter";
@import "styles/components/guideModal";
@import "styles/components/modal/modal";
@import "styles/components/fixedFooter";
