@import "styles/helpers/colors";
@import "styles/helpers/media";

.section {
  margin-top: 20px;

  .youtube {
    align-items: center;
    background-color: #f8f8f9;
    border-radius: 8px;
    color: #666;
    display: flex;
    font-family: "Source Sans Pro";
    font-size: 16px;
    height: 401px;
    justify-content: center;
    line-height: 28px;
    max-width: 730px;
  }

  .agree {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 18px;
  }

  span {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px;
  }
}
