$basic-grey: #333;
$light-grey: #666;
$lighter-grey: #666667;
$very-light-grey: #e6e6e6;
$transparent-grey: #f8f8f9;

$basic-red: #f00;

$basic-orange: #fa9923;
$light-orange: #ff9514;
$transparent-orange: #fa99231a;

$accident-grey: #464646;

$flat-white: #fff;
$flat-black: #000;
$transparent-white: #f7f7f7;

$twitter: #00aced;
$facebook: #3b5998;
