@import "styles/helpers/colors";

.wrapper {
  position: relative;

  .thumb {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: $flat-white;
    height: 280px;
    overflow: hidden;
    position: relative;

    &::after {
      background: linear-gradient(to bottom, #fff0 60%, #000000a8 100%);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    img {
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.description {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 125px;
  padding: 15px 0;
  word-break: break-word;

  p {
    color: $basic-grey;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 5px;
  }
}

.location {
  align-items: center;
  color: $basic-orange;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
  vertical-align: middle;
  vertical-align: middle;

  img {
    height: 16px;
    margin-right: 5px;
    width: 16px;
  }
}

.info {
  align-items: center;
  color: $light-grey;
  // display: grid;
  // grid-area: info;
  // grid-column-gap: 5px;
  // grid-template-columns: repeat(6, auto);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  max-width: 250px;

  // @media (max-width: 767px) {
  //   display: grid;
  // }

  // @media only screen and (max-width: 1169px) and (min-width: 768px) {
  //   display: block;
  // }

  // @media only screen and (min-width: 1170px) {
  //   display: grid;
  // }

  svg {
    fill: $light-grey;
    height: 8px;
    width: 8px;
  }

  .rating {
    align-items: center;
    display: flex;
  }
}
