h1 {
  font-size: 44px;
  line-height: 52px;

  @media (min-width: 767px) {
    font-size: 44px;
    line-height: 1.5;
  }

  @media (min-width: 1089px) {
    font-size: 68px;
    line-height: 76px;
  }
}

h3 {
  color: $basic-grey;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px;

  @media (min-width: 767px) {
    font-size: 38px;
  }
}

p {
  color: $light-grey;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;

  @media (min-width: 767px) {
    line-height: 1.7;
  }
}

hr {
  background-color: $light-grey;
  border-width: 0;
  color: $basic-grey;
  grid-area: divider;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
  width: 64px;
}

.short_hr {
  margin-bottom: 12px;
  width: 50px;
}
