@import "styles/helpers/colors";

.form-group {
  margin-bottom: 30px;

  input {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding: 16px;
  }

  label {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 12px;
  }
}

.errorBorder {
  border-color: $light-orange !important;
}
