.blog-page {
  padding-bottom: 128px;
}

.blog-header {
  display: flex;
  justify-content: space-between;
}

.blog-header h1 {
  color: #333;
  font-size: 34px;
  line-height: 46px;
}

.blog-header p {
  line-height: 28px;
}

.blog-header a {
  background-color: #ff9514;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 19px;
  line-height: 50px;
  text-align: center;
  width: 120px;
}

.blog-header {
  padding-top: 128px;
}

.blog-item {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 33.333%;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }

  .box {
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 16px 20px;
  }

  .box h3 {
    color: #ff9514;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .box h2 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 26px;
  }
}

.blog-item .imageInfo .main-img {
  border-top-left-radius: 6px;
  height: 380px;
  max-width: 100%;
  object-fit: cover;
}
