@import "styles/helpers/colors";

.wrapper {
  display: block;
  margin-bottom: 15px;
  position: relative;

  @media (min-width: 767px) {
    margin: 15px;
  }
}

.thumb {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $flat-white;
  height: 290px;
  overflow: hidden;
  position: relative;
}

.background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &::after {
    background: linear-gradient(to bottom, #fff0 60%, #000000a8 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.price {
  background-color: rgba($flat-black, 0.12);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  left: 15px;
  padding: 9px 10px;
  position: absolute;
  top: 15px;
  z-index: 1;
}

.edit_icons {
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.edit {
  background-color: rgba($flat-black, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 65px;
  top: 15px;
  z-index: 1;

  svg {
    fill: $flat-white;
    height: 18px;
    width: 18px;
  }
}

.delete {
  background-color: rgba($flat-black, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;

  svg {
    fill: $flat-white;
    height: 18px;
    width: 18px;
  }
}

.like {
  background-color: rgba($flat-black, 0.12);
  border-radius: 6px;
  padding: 9px 12px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;

  svg {
    fill: $flat-white;
    height: 18px;
    width: 18px;
  }
}

.guide {
  bottom: 15px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas:
    "face name"
    "face info";
  grid-template-columns: auto 1fr;
  left: 15px;
  position: absolute;
  z-index: 1;

  p {
    color: $flat-white;
    font-size: 14px;
    font-weight: 600;
    grid-area: name;
    margin-bottom: 0;
  }

  svg {
    fill: $flat-white;
    height: 8px;
    width: 8px;
  }
}

.info {
  align-items: center;
  display: grid;
  font-size: 75%;
  grid-area: info;
  grid-column-gap: 5px;
  grid-template-columns: repeat(4, auto);
}

.face {
  border-radius: 50%;
  grid-area: face;
  height: 36px;
  overflow: hidden;
  width: 36px;

  img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.description {
  background-color: $flat-white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 125px;
  padding: 15px;

  p {
    color: $basic-grey;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 10px;
  }
}

.type {
  color: $basic-orange;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.location {
  align-items: center;
  color: $light-grey;
  display: grid;
  font-size: 14px;
  grid-column-gap: 7px;
  grid-template-columns: auto 1fr;

  svg {
    fill: $light-grey;
    height: 12px;
    width: 12px;
  }
}
