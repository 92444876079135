.fr-wrapper {
  box-shadow: none !important;
}

.fr-toolbar {
  border-top: 1px solid #e6e6e6;
  box-shadow: none;
  display: none;
}

.fr-box {
  max-width: 840px;

  p {
    margin-bottom: 0;
  }
}
