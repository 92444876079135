@import "styles/helpers/colors";

.date {
  align-items: center;
  border: 1px solid $very-light-grey;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  height: 73px;
  justify-items: center;
  margin-bottom: 5px;
  margin-right: 7px;
  position: relative;
  text-align: center;
  transition: background-color 0.2s ease-out;
  width: 81px;

  &:hover {
    background-color: $basic-orange;

    p,
    span:first-child,
    span:last-child {
      color: $flat-white;
    }
  }

  p {
    bottom: 0;
    color: $basic-grey;
    font-size: 13px;
    font-weight: 600;
    height: 50%;
    left: 0;
    line-height: 1;
    margin: auto;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transition: color 0.1s ease-out;

    span:first-child {
      color: $basic-grey;
      font-size: 14px;
      font-weight: 600;
      transition: color 0.1s ease-out;
    }

    span:last-child {
      color: $light-grey;
      display: block;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.2px;
      margin-top: 7px;
      text-transform: none;
      transition: color 0.1s ease-out;
    }
  }
}

.calendar {
  bottom: -300px;
  height: 319px;
  margin-bottom: 20px;
  padding: 18px;
  text-align: center;
  width: 328px;
  z-index: 99;

  h3 {
    color: $basic-grey;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 10px !important;
  }

  .daysOfWeek {
    color: $lighter-grey;
    display: grid;
    font-size: 16px;
    grid-template-columns: repeat(7, 1fr);
    line-height: 20px;
  }

  .daysOfMonth {
    color: rgba($lighter-grey, 0.4);
    display: grid;
    font-size: 16px;
    grid-gap: 2px;
    grid-template-columns: repeat(7, 1fr);
    line-height: 40px;
    padding: 10px 0;

    li {
      background-color: $transparent-grey;
      border-radius: 6px;
      cursor: pointer;
      display: block;
      font-size: 16px;
    }

    .available {
      background-color: rgba($light-orange, 0.2);
      color: $light-orange;
    }
  }
}

.hidden {
  opacity: 0;
}
