.row {
  display: flex;
  flex-flow: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.adventures {
  background-color: transparent;
  margin-top: -130px;
  overflow: hidden;
  padding-bottom: 120px;
  padding-top: 30px;
  position: relative;
  z-index: 100;
  @media (max-width: 991px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media (max-width: 576px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  &::before {
    background: #f8f8f9;
    content: "";
    height: 100%;
    position: absolute;
    top: 130px;
    width: 100%;
    z-index: -1;
  }

  .title {
    margin-bottom: 60px;
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  .adventures-item {
    padding: 0 15px;
    width: 50%;
    @media (max-width: 991px) {
      margin: 0 auto 40px;
      max-width: 100%;
      width: 100%;
    }
    @media (max-width: 991px) {
      max-width: 100%;
    }

    .main-img {
      border-radius: 6px;
      max-width: 100%;
    }

    .status {
      color: #ff9514;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 18px;
      margin-bottom: 6px;
      margin-top: 18px;
      text-transform: uppercase;
    }
  }
}

.adventures-item {
  h2 {
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 38px;
    margin-bottom: 8px;
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 31px;
    }
  }

  .place {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 20px;

    img {
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .description {
    margin-bottom: 20px;

    a {
      color: #ff9514;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      color: #333;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 8px;
      margin-top: 9px;
    }
  }
}

.shop {
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  padding: 30px;
  @media (max-width: 576px) {
    flex-flow: wrap;
    padding: 20px 15px;
    position: relative;
  }

  b {
    display: block;
    margin-bottom: 7px;
  }

  .regular-price {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    @media (max-width: 576px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .promotional-price {
    color: #333;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 576px) {
      width: 100%;
    }

    span {
      color: #ff9514;
    }
  }

  .book-now {
    @media (max-width: 576px) {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    .orange-btn {
      display: inline-block;
      padding: 15.5px 10px;
    }
  }
}

.book-now button {
  @media (max-width: 576px) {
    border-radius: 0;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 100%;
  }
}

.ladakh.adventures {
  .adventures-item {
    margin-bottom: 30px;
    padding: 0 15px;
    width: 33.333%;
    @media (max-width: 991px) {
      margin: 0 auto 40px;
      max-width: 330px;
      width: 50%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }

    h2 {
      font-size: 20px;
    }
  }

  .adventures-item {
    .main-img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 380px;
      object-fit: cover;
      width: 100%;
    }

    .status {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .box {
    background: #fff;
    padding: 21px 20px;
  }

  .shop {
    align-items: center;
    border-radius: 0;
    border-top: 1px solid hsla(0, 0%, 0%, 0.1);
    box-shadow: none;
    padding: 0;
    padding-top: 20px;
  }

  .place {
    margin-bottom: 12px;
  }

  .regular-price span {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.ladakh .description {
  p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 9px;
  }
}

.ladakh .shop {
  .regular-price {
    @media (max-width: 576px) {
      width: auto;
    }
  }

  .book-now {
    @media (max-width: 576px) {
      height: auto;
      position: relative;
      right: 0;
      top: 0;
    }
  }
}

.imageInfo {
  position: relative;

  .review {
    background: transparent;
    border-radius: 0;
    bottom: 0;
    justify-content: inherit;
    margin: 0;
    position: absolute;
    width: 100%;

    img {
      border-radius: 50%;
    }
  }

  .rating {
    b {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    li {
      color: #fff;
    }
  }
}

.rating li {
  color: #666;
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-right: 6px;

  .fa-circle {
    font-size: 4px;
    margin-right: 6px;
    vertical-align: middle;
  }
}

.review {
  align-items: center;
  background-color: #f8f8f9;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 20px;
  @media (max-width: 576px) {
    flex-flow: wrap;
    padding: 15px 20px 0;
  }

  img {
    margin-right: 15px;
    vertical-align: middle;
    width: 42px;
  }
}

.review .user {
  @media (max-width: 576px) {
    width: 100%;
  }
}

.review .user b {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  @media (max-width: 576px) {
    position: relative;
    top: -5px;
  }
}

.adventures-item .card {
  background-color: transparent;
  border: 0;
}

/*********css for general****************/
.send_message {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 999;

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0;
    margin-top: 20px !important;
  }

  p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px;
  }
}

.send_center_div {
  background: #fff;
  border-radius: 5px;
  left: 50%;
  margin: auto;
  padding: auto;
  padding: 15px 17px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

@media (min-width: 768px) {
  .send_center_div {
    width: 400px !important;
  }
}

.noticePara {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-bottom: 10px;
  width: 60%;
}

.emailField {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.cancelText {
  background: none !important;
  border: 0 !important;
  color: #000 !important;
  float: right !important;
  font-size: 18px !important;
}

.signup_link {
  text-decoration: none;
}

.person_no {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 0 auto;
  padding: 0 0 0 12px;
  text-align: left;
  width: 88%;
}

.adventureBook.person_no {
  input {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px 0;
    width: 100%;
  }

  .bookDescription {
    color: #333;
    font-size: 15px;
    padding: 10px 0;
    width: 100%;
  }
}

.btn {
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  font-weight: normal;

  &.white-btn {
    background: #fff;
    color: #ff9514;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 18px;
    padding: 5px 12px;
    position: relative;
    top: 5px;
    @media (max-width: 576px) {
      background: rgba(255, 255, 255, 0.12);
      color: #fff;
    }
  }

  &.orange-btn {
    background-color: #ff9514;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 19px;
    padding: 15.5px 25px;
    text-transform: uppercase;
  }

  &.blur-btn {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
  }
}
