@import "styles/helpers/colors";

.hamburgeMenu {
  background-color: #fff;
  bottom: 0;
  height: 100vh !important;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.wrapper {
  align-items: center;
  border-bottom: 1px solid $very-light-grey;
  box-shadow: none;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr auto;
  height: 67px;
  padding: 0 15px;
  position: relative;
  z-index: 10;

  .icon_search,
  .icon_burger {
    fill: $basic-grey;
    height: 19px;
    width: 19px;
  }

  @media (min-width: 767px) {
    grid-template-columns: auto 1fr auto auto;
    padding: 0 40px;

    .icon-search {
      height: 18px;
      width: 18px;
    }
  }
}

.logo {
  align-items: center;
  display: grid;
  height: inherit;
  position: relative;
  z-index: 10;

  a {
    display: block;
  }

  svg {
    height: 38px;
    width: 58px;
  }
}

.nav {
  font-size: 14px;
  font-weight: 600;
  justify-self: end;

  a,
  span {
    color: $light-grey;
    display: inline-block;
    line-height: 67px;
    padding: 0 10px;
    position: relative;

    &::after {
      background-color: $light-grey;
      bottom: 1px;
      content: "";
      height: 2px;
      left: 0;
      margin: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: opacity 0.2s ease-out;
      width: calc(100% - 20px);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}

.right_column {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto;
  justify-items: end;
  position: relative;
  z-index: 100;

  @media (min-width: 767px) {
    align-items: center;
    border-right: 1px solid $very-light-grey;
    height: inherit;
    padding-right: 12px;
  }
}

.profile {
  align-items: center;
  display: grid;
  position: relative;

  div {
    border-radius: 50%;
    height: 40px;
    margin-left: 15px;
    margin-right: 0;
    overflow: hidden;
    width: 40px;
  }

  img {
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.buttons {
  align-items: center;
  padding-left: 14px;
  position: relative;
  z-index: 10;

  button {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    transition: color 0.12s ease-out;

    &:first-child {
      border: 1px solid $very-light-grey;
      color: $light-grey;
    }

    &:last-child {
      background-color: $transparent-grey;
      border: 1px solid $transparent-grey;
      color: $basic-grey;
    }

    &:hover {
      color: $basic-orange;
    }
  }

  span {
    color: $light-grey;
    font-size: 12px;
    padding: 0 12px;
  }

  li {
    button {
      color: $light-grey !important;
      line-height: 20px !important;
      width: 100% !important;
    }
  }
}

.desktopbtn {
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
}

.dropdown {
  background-color: $flat-white;
  border: 1px solid $transparent-white;
  border-radius: 6px;
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  display: block;
  position: absolute;
  right: -25px;
  top: 50px;
  width: 160px;
  z-index: 99;
  @media (max-width: 767px) {
    display: block;
    right: 0;
  }

  li {
    transition: background-color 0.14s ease-out;

    &:hover {
      background-color: $transparent-orange;

      a,
      button {
        color: $basic-orange;
      }
    }
  }

  a,
  button {
    color: $lighter-grey;
    display: block;
    height: 44px;
    line-height: 44px;
    padding-left: 20px;
    text-align: left;
    transition: color 0.14s ease-out;
  }
}

.home_page {
  border-color: rgba($flat-white, 0.2);

  a,
  span {
    color: $flat-white;
  }

  .dropdown {
    a {
      color: $lighter-grey;
    }
  }

  .nav a:hover,
  span:hover {
    &::after {
      background-color: $flat-white;
      opacity: 0.2;
    }
  }

  .right_column {
    border-color: rgba($flat-white, 0.2);
    position: relative;
    z-index: 10;
  }

  .icon_search,
  .icon_burger {
    fill: $flat-white;
  }

  .buttons span {
    color: $flat-white;
  }

  .buttons button {
    &:first-child {
      border-color: rgba($flat-white, 0.2);
      color: $flat-white;
    }

    &:last-child {
      background-color: rgba($flat-white, 0.2);
      border: 0;
      color: $flat-white;
    }
  }

  .logo {
    svg {
      fill: $flat-white;
    }
  }
}

.phoneNumber {
  color: #b7b7b7;
  font-size: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;

  a {
    color: #d6d3d3;
    text-decoration: none;
  }
  @media (max-width: 1310px) and (min-width: 860px) {
    width: 50%;
  }
  @media (max-width: 859px) and (min-width: 768px) {
    font-size: 10px;
    width: 40%;
  }
  @media (max-width: 400px) {
    font-size: 11px;
  }

  b {
    font-weight: bold;
  }
}

.hamburgerMenu {
  @media (max-width: 767px) {
    position: relative;
    top: 0;
  }
}

.mobileMenu {
  @media (max-width: 767px) {
    position: absolute;
  }
}

.profilePicture {
  @media (max-width: 767px) {
    display: none;
  }
}
