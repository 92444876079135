@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  border: 1px solid $transparent-white;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 2px #ccc;
  height: 100%;
  padding: 0 15px;
  text-align: left;
  width: 100%;

  iframe,
  img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    border: 0;
    box-shadow: none;
  }

  .categories {
    color: $basic-orange;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0;
  }

  .name {
    color: #000;
    font-family: Abril Fatface;
    font-size: 38px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 20px 20px 0;
  }

  .address {
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .icons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-left: 15px;
  }

  .icons_item {
    width: 150px;
  }

  .icon_container {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    margin-top: 14px;
  }

  .icon_wrapper {
    color: $basic-orange;
    font-size: 18px;
    margin-right: 10px;
  }

  .title {
    font-size: 12px;
    margin-right: 10px;
  }
}
