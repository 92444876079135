.userAboutSection {
  .fr-box {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    height: 750px;

    max-width: 740px;

    .fr-toolbar {
      border-bottom: 1px solid #e6e6e6;
      box-shadow: none;
      display: block;
    }
  }

  .fr-wrapper {
  }
}
