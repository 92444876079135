.guide-profile-page {
  padding-top: 41px;

  .details {
    display: flex;
    flex-flow: wrap;
    margin: 0 -15px;
  }
}

.profile-left {
  padding: 0 15px;
  width: 66.666667%;

  h1 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 9px;
    margin-top: 6px;
  }

  p {
    color: #666;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  a {
    color: #ff9514;
    font-size: 16px;
    line-height: 28px;
  }

  h3 {
    color: #333;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 7px;
    margin-top: 0;
  }
}

.profile-left figure {
  margin-top: 20px;
  text-align: center;

  img {
    width: 100%;
  }

  figcaption {
    color: #666;
    font-size: 14px;
    font-style: italic;
    line-height: 18px;
  }
}

.place {
  color: #333;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 20px;
  margin-bottom: 15px;
}

.guide-nav {
  li {
    color: #ff9514;
    display: inline-block;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-right: 7px;
    padding-right: 12px;
    position: relative;

    &::after {
      content: "\00B7";
      font-size: 21px;
      font-weight: bold;
      position: absolute;
      right: 0;
    }

    &:last-child::after {
      content: "";
    }
  }
}

.icon-views {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  padding-bottom: 50px;

  .icon {
    padding-left: 60px;
    position: relative;
    width: 25%;
  }

  .small-icon {
    background-color: #f8f8f9;
    border-radius: 50%;
    display: inline-block;
    height: 36px;
    left: 0;
    line-height: 30px;
    margin-right: 20px;
    position: absolute;
    vertical-align: top;
    width: 36px;
  }

  .small-icon img {
    position: relative;
    right: -20px;
  }

  span {
    display: block;
  }

  .name {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 15px;
    text-transform: uppercase;
  }

  .status {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0.17px;
    line-height: 18px;
  }
}

.profile-right {
  padding: 0 15px;
  width: 33.33333%;

  img {
    max-width: 100%;
  }
}

.top-info {
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 20px;
}

.top-info h5 {
  color: #ff9514;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 20px;
}

.guide-right-form {
  border-top: 1px solid #e6e6e6;
  margin-top: 20px;
  padding-top: 20px;

  select {
    background: transparent;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    width: 100%;
  }

  textarea {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    width: 100%;
  }

  button {
    background-color: #ff8c00;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 50px;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 100%;
  }
}

.guide-profile-page {
  padding-top: 41px;
}

.guide-adventures {
  color: #333;
  font-family: "Abril Fatface";
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;

  span {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    letter-spacing: 0.11px;
    line-height: 20px;
  }
}

.adventures-lg .box {
  position: relative;

  img {
    border-radius: 6px;
    max-height: 220px;
    object-fit: cover;
    width: 100%;
  }
}

.adventures-lg .small-content {
  bottom: 0;
  position: absolute;
  text-align: center;
  z-index: 1000;

  h5 {
    color: #fff;
    margin-bottom: 10px;
  }

  p {
    color: #fff;
    line-height: 20px;
    margin: 0;
    margin-bottom: 15px;
  }
}

.see-all-adventure {
  color: #ff9514;
  display: block;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 30px;
}

@media (max-width: 991px) {
  .profile-left {
    width: 100%;
  }

  .profile-right {
    width: 100%;
  }

  .icon-views {
    flex-flow: wrap;

    .icon {
      margin-bottom: 20px;
      padding-left: 60px;
      position: relative;
      width: 50%;
    }
  }

  .top-info .rating {
    left: 0;
    position: relative;
    top: 0;
  }
}
