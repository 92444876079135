.footer {
  padding: 24px 0;

  .flex {
    @media (max-width: 576px) {
      flex-flow: wrap;
    }
  }

  .col {
    width: 33.333%;
    @media (max-width: 576px) {
      &:last-child {
        width: 100%;
      }
    }
  }

  .social-icons {
    text-align: center;
    @media (max-width: 576px) {
      text-align: right;
      width: 63%;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }

  .copyright {
    color: #666;
    font-size: 12px;
    line-height: 19px;
    text-align: right;

    a {
      color: #808080;
      display: block;
    }
    @media (max-width: 576px) {
      text-align: left;
    }
  }
}
