@import "styles/helpers/colors";

.wrapper {
  // background: rgba(45, 45, 51, 0.7);
  border-radius: 0;

  @media (max-width: 768px) {
    bottom: 0;
    margin-top: 0;
  }

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0;
    margin-top: 20px !important;
  }

  .form {
    background: $flat-white;
    border-radius: 5px;
    margin: 0 auto;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
      left: 50%;
      margin-top: 0;
      min-width: 0;
      width: 100%;
      z-index: 10;
    }
  }

  .form_input {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px auto;
    padding: 0 0 0 12px;
    width: 88%;
  }

  .form_textArea {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 5px auto;
    width: 88%;
  }

  .inputField {
    border-width: 0;
    color: #333;
    font-size: 15px;
    padding: 10px;
    width: 80%;
  }

  textarea {
    border-width: 0;
    color: #333;
    font-size: 15px;
    height: 70px;
    padding: 10px 10px 10px 0 !important;
    width: 80%;
  }

  .cancel {
    background: none;
    border-width: 0;
    color: $flat-black;
    float: right;
    font-size: 18px;
  }

  .errorText {
    color: #f00 !important;
    margin-bottom: 10px;
    margin-left: 20px;
    padding-top: 5px;
    text-align: left !important;
  }

  .statement {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding-bottom: 10px;
    width: 60%;
  }

  .request {
    margin: 0 auto;
    text-align: center;
    width: 88%;
  }

  .contactBtn {
    background: #fa9923;
    border-radius: 8px;
    color: $flat-white;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-top: 20px;
    text-transform: uppercase;
    width: 100%;
  }
}
