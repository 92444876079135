.sectionFroala .fr-box.fr-basic {
  border-bottom: 1px solid #e6e6e6;
  margin: 1px 0 45px;
  padding: 0 0 30px;

  .fr-element {
    background-clip: padding-box;
    border-radius: 0 0 2px 2px;
    border-top: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #000;
    min-height: 52px;
    overflow-x: auto;
    padding: 0;
  }

  li {
    line-height: 23px;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    margin: 0;
    margin-top: 25px;
    padding: 0 0 15px;
  }

  td {
    border: 0;
  }
}

.sectionFroala p {
  color: #000;
}

.adventure-subTitle {
  margin: 0;
}

.froala_notice {
  background: #ef5350;
  color: #fff;
  display: block;
  font-size: 15px;
  max-width: 840px;
  padding: 8px 10px;
  text-decoration: none;
}

.included_list .fr-box.fr-basic {
  border: 0;
  margin: 0;
  padding: 0;

  li {
    color: #333;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.include_exclude {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 30px;
}

.adventureDetailButton {
  background: #fff !important;
  border-color: #fa9923 !important;
  color: #fa9923 !important;
}

.row-grid {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 88%;
}

.row-grid .new-access {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 10px 0 !important;
  padding: 0 !important;
  width: 130px !important;

  .select-children-age {
    background: transparent;
    border: 0;
    height: 39px;
    width: 100%;
  }
}

.box-item label {
  display: block;
  font-size: 12px;
  text-align: left;
}

.contact-detail-picker {
  border-width: 0;
  box-shadow: none;
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 15px;
  height: 50px;
  letter-spacing: 0.3px;
  line-height: 19px;
}

.input-style {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  color: #333;
  display: flex;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin: 10px auto;
  padding: 7px 16px;
  position: relative;
  width: 88%;

  .arrow-right {
    position: absolute;
    right: 5px;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #fa9923 !important;
}

.react-datepicker__day--selected {
  background-color: #fa9923 !important;
}
