.account-page {
  display: flex;
  flex-flow: wrap;

  .account-sidebar {
    width: 24%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .account-sidebar-menu a {
    border-bottom: 1px solid #e6e6e6;
    color: #666;
    display: block;
    font-family: "Avenir-Book";
    font-size: 15px;
    padding: 14px 14px 14px 26px;
    text-transform: capitalize;

    &:hover {
      color: #fd4d4f;
      text-decoration: none;
    }
  }

  .account-sidebar-menu {
    padding-right: 30px;
    padding-top: 24px;
  }

  .account-right {
    width: 76%;
    @media (max-width: 767px) {
      width: 100%;
    }

    h1 {
      color: #333;
      font-size: 38px;
      margin-bottom: 0;
      padding: 21px 0;
      text-transform: capitalize;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
    }
  }

  .user-image {
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    position: relative;

    label {
      color: #428bca;
      font-size: 14px;
      line-height: 1.4;
    }

    p {
      margin-bottom: 0;
    }
  }

  .user-form {
    padding: 35px 0;

    figure {
      border: 2px solid #999;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 30px;
      padding: 18px 0;
      position: relative;
      text-align: center;
      width: 164px;
    }

    .gender {
      display: flex;
      flex-flow: wrap;
    }

    label {
      color: #999;
      font-family: "Avenir-Book";
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    button {
      background: #fff;
      border: 1px solid #fd6061;
      border-radius: 4px;
      color: #fd6061 !important;
      display: inline-block;
      padding: 9px 18px;
      width: auto;
    }
  }
}

.user-form .gender > label {
  color: #999;
  margin-bottom: 22px;
  width: 100%;
}

.user-form .gender figcaption {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.user-form .form-group input {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-shadow: 0 0 0;
  font-size: 15px;
  height: 42px;
  padding: 6px 12px;
}
