.container {
  margin: auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
}

.privacy_wrapper {
  padding: 45px 0;
}

.privacy_col {
  background: #efefef;
  border-radius: 8px;
  padding: 30px;
}

h2 {
  font-size: 38px;
  font-weight: normal;
}

.privacy_col h2 {
  color: #333;
  font-family: "Abril Fatface", cursive;
  padding: 21px 0;
  text-transform: capitalize;
}

.profile_head {
  border-bottom: 1px solid #dfdfdf;
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: capitalize;
}

.terms_head span {
  background: #858789;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.privacy_col p {
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

.privacy_col h2 {
  color: #333;
  padding: 21px 0;
  text-transform: capitalize;
}

.privacy_list {
  margin: 15px 0;
  padding: 0;
}

.privacy_list li {
  display: block;
  margin-bottom: 15px;
  text-align: left;
}

.privacy_list li span {
  font-size: 18px;
  margin-bottom: 10px;
}
