@import "styles/helpers/colors";

.wrapper {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .row {
    background: #fff;
    border-radius: 5px;
    left: 50%;
    max-width: 450px;
    padding: 29px 17px;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    @media (max-width: 768px) {
      left: 50%;
      margin-top: 0;
      min-width: 0;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 10;
    }
  }

  h2 {
    color: #333;
    font-family: "Abril Fatface";
    font-size: 30px;
    line-height: 41px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 24px !important;
    }
  }

  p {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .button {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: 19px;
    margin-right: 20px;
    text-transform: uppercase;
    width: 100px;
  }

  .signUp {
    background-color: $basic-orange;
    border-radius: 6px;
    color: $flat-white;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    width: 100px;

    svg {
      fill: $flat-white;
      height: 12px;
      margin-left: 10px;
      width: 18px;
    }
  }

  .exitIcon {
    height: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
  }
}
