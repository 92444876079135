.react-tel-input {
  input {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 50px !important;
    letter-spacing: 0.3px;
    line-height: 19px;
    opacity: 0.5;
    padding-left: 10px;
    width: 100% !important;
  }
}
