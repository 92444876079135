.questions {
  background-color: #f3f3f3;
  background-size: cover;
  padding-bottom: 86px;
  padding-top: 89px;
}

.questions form {
  margin: 30px auto 0;
  max-width: 310px;

  .full-orange-btn.btn {
    background: #fff;
    border-radius: 6px;
    color: #ff9514;
    display: block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

.form-group {
  margin-bottom: 20px;

  input {
    background-position: 16px 18px;
    background-repeat: no-repeat;
    border: 0;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    min-height: 50px;
    padding: 10px 20px 10px 44px;
    width: 100%;
  }

  textarea {
    background-position: 16px 18px;
    background-repeat: no-repeat;
    border: 0;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 19px;
    min-height: 88px;
    padding: 15px 20px 10px 44px;
    width: 100%;
  }
}

.subTitle {
  color: #333;
  font-size: 38px;
  line-height: 51px;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 30px;
    line-height: 41px;
  }
}

.smallDescription {
  color: #666;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 4px;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.signup_popup {
  background: rgba(45, 45, 51, 0.7);
  border-radius: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  h2 {
    color: #333;
    font-family: Abril Fatface;
    font-size: 30px;
    line-height: 41px;
    margin: 0;
  }

  p {
    color: #666;
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 15px;
  }

  .guide_btn {
    background-color: #fa9923;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    min-width: 100px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
  }
}

.center_div {
  background: #fff;
  border-radius: 5px;
  left: 50%;
  margin: 0 auto;
  min-width: 450px;
  padding: 29px 17px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, 50%);
  width: auto;
}
