@import "styles/helpers/colors";

.wrapper {
  background-color: $flat-white;
  padding: 20px;
  width: 100%;

  .title {
    color: #333;
    font-weight: 600;
  }

  .content {
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    margin-top: 20px;
  }
}
